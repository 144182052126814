import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";

export const FollowupContext= createContext({
    filters: {
        from: null,
        to: null,
        authors: [],
        selectedAuthors: [],
        orderBy: null,
        orderDirection: null,
    },
    setFilters: () => {}
})

export const FollowupContextProvider = ({children}) => {
    const axios = useAxiosPrivate();

    const [filters, setFilters] = useState()

    useEffect(async() => {
        const response = await axios.get(`Users/GetAuthors`);

        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));

    }, [])

    return <FollowupContext.Provider value={{
        filters, 
        setFilters
    }}>
        {children}
    </FollowupContext.Provider>
}