import { DatePicker, Typography } from "antd"

const FollowupFromDateFilter = () => {
    const {Text} = Typography

    return <>
        <Text text="Order by">From</Text>
        <DatePicker size="large"
            placeholder="Select created date end"
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default FollowupFromDateFilter