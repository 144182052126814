import { Outlet } from "react-router-dom";
import { FollowupContextProvider } from "./FollowupContext";

const FollowupLayout = () => {
  return (
    <FollowupContextProvider>
      <Outlet />
    </FollowupContextProvider>
  );
};

export default FollowupLayout;
