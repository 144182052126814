import { DatePicker, Typography } from "antd"

const FollowupToDateFilter = () => {
    const {Text} = Typography;

    return <>
     <Text text="Order by">To</Text>
        <DatePicker size="large"
            placeholder="Select created date end"
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default FollowupToDateFilter