import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
const InviteUser = ({ isOpen, onClose }) => {
  const { Text, Title } = Typography;
  const [form] = Form.useForm();
  const axiosPrivate = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const handleCancel = () => {
    onClose();
  };

  const submit = async (e) => {
    setLoading(true);
    const response = await axiosPrivate.post("/Users/InviteUser", {
      email: e.email,
    });

    if (response && response.data.success) {
      message.success("User invitation sent!");
      handleCancel();
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <Modal
    title={
      <Title style={{ margin: 0 }} level={4}>
        Invite new user to your company
      </Title>
    }
      open={isOpen}
      onCancel={handleCancel}
      footer={
        <Flex align="center" justify="end">
          <Space>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              loading={loading}
              onClick={() => form.submit()}
            >
              Invite
            </Button>
          </Space>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Text>Email</Text>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please provide a valid email address.",
            },
          ]}
        >
          <Input placeholder="Enter new user email" size="large" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteUser;
