import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { CleanUpJobAcceptType, CleanUpJobStatus, OrderDirection } from "../../shared/enums";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { SearchContext } from "../../context/SearchContext";

export const CleanUpContext = createContext({
    filters: {
        authors: [],
        selectedAuthors:[],

        from: null,
        to: null,
        name: null,

        orderBy: null,
        sortDirection: null,
        
        statuses: [],
    },
    setFilters:() => {},

    page: 1,
    setPage: () => {},

    stats: {
        total: 0,
        done: 0,
        wating: 0,
        working: 0,
    },
    setStats: () => {},

    jobs:[],
    setJobs: () => {},

    loading: false,
    setLoading:()=>{},
});

export const CleanUpContextProvider = ({ children }) => {
    const {debouncedValue} = useContext(SearchContext)
    const axios = useAxiosPrivate();

    const perPage = 7;

    const [filters, setFilters] = useState();

    const [loading, setLoading] = useState(() => false);

    const [stats, setStats] = useState()

    const [page, setPage] = useState(() => 1);

    const [jobs, setJobs] = useState();

    useEffect(async () => {
        const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            statuses: [
                CleanUpJobStatus.Done,
                CleanUpJobStatus.Wating,
                CleanUpJobStatus.Working
            ],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));
    }, [])

    useEffect(async () => {
        const response = await axios.post(`/CleanUp/list`, {
            name: debouncedValue,
            authors: filters?.selectedAuthors,
            statuses: filters?.statuses,
            from: filters?.from,
            to: filters?.to,
            sortDirection: filters?.orderDirection,
            orderBy: filters?.orderBy,
            page: page,
            pageSize: perPage
        });

        setStats(prev => ({...prev, 
            total: response.data.total,
            done: response.data.done,
            wating: response.data.wating,
            working: response.data.working
        }));

        setJobs(response.data.items);
    }, [page, filters, debouncedValue])

    return <CleanUpContext.Provider value={{
        filters,
        setFilters,

        loading,
        setLoading,

        stats,
        setStats,

        page,
        setPage,
        
        jobs,
        setJobs
    }}>
        {children}
    </CleanUpContext.Provider>
} 