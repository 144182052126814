import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { MediaContext } from "../MediaContext";

export const CreatedDateFilter = () => {
  const { Text } = Typography;
  const {filters, setFilters} = useContext(MediaContext)

  return (
    <>
      <Text>To</Text>
      <DatePicker
        size="large"
        value={filters?.to}
        onChange={e => setFilters(prev => ({
          ...prev,
          to: e
        }))}
        placeholder="Select created date start"
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};
