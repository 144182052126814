import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Card from "antd/es/card/Card";
import {
  Button,
  Select,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import CreateSimpleEmail from "../Create/CreateSimpleEmail";
import { AiOutlineRollback } from "react-icons/ai";
import TextArea from "antd/es/input/TextArea";
import { EditorState } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { stateToHTML } from "draft-js-export-html";

const EditSimpleEmail = () => {
  const { id } = useParams();

  const [form] = Form.useForm();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [templateCategoryId, setTemplateCategoryId] = useState();
  const { Title, Text } = Typography;

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSelectedCategoryChanged = (selected) => {
    setSelectedCategory(selected);
  };

  const getHTML = () => {
    const contentState = editorState.getCurrentContent();
    const html = stateToHTML(contentState);
    return html;
  };

  const handleSave = async (e) => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Id", id);
    formData.append("Html", "");
    formData.append("Name", e.name);
    formData.append("Style", "");
    formData.append("CategoryId", e.category);
    formData.append("Description", e.description);
    formData.append("IsSimple", true);
    formData.append("SimpleHtml", getHTML());

    var response = await axiosPrivate.put("/Template", formData);

    if (!response || !response.data.success) {
      return;
    }

    setLoading(false);
    navigate("/Templates/Yours");
  };

  useEffect(async () => {
    const response = await axiosPrivate.get(`/Template/${id}`);

    const ctgResponse = await axiosPrivate.get(
      "/Template/GetTemplateCategories"
    );

    setTemplateCategoryId(response.data.data.templateCategoryId);

    const contentState = stateFromHTML(response.data.data.html);
    setEditorState(EditorState.createWithContent(contentState));

    setOptions(
      ctgResponse.data.data.map((d) => ({ value: d.id, label: d.name }))
    );

    form.setFieldsValue({
      name: response.data.data.name,
      description: response.data.data.description,
      category: response.data.data.templateCategoryId,
    });
  }, []);

  return (
    <>
      <Form onFinish={handleSave} form={form}>
        <Row gutter={12}>
          <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
            {editorState && (
              <CreateSimpleEmail
                editorState={editorState}
                setEditorState={setEditorState}
                update
              />
            )}
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
            <Card
              title={
                <Flex align="center" justify="space-between">
                  <Title level={5} style={{ margin: 0 }}>
                    Create your email design
                  </Title>
                  <Button
                    shape="circle"
                    onClick={() => navigate(-1)}
                    icon={<AiOutlineRollback />}
                  ></Button>
                </Flex>
              }
            >
              <Text>Name</Text>
              <Form.Item
                name="name"
                rules={[
                  { required: true, message: "A name is required" },
                  {
                    max: 50,
                    message: "Name should contain at most 20 characters",
                  },
                ]}
              >
                <Input
                  count={{
                    show: true,
                    max: 50,
                  }}
                  size="large"
                  placeholder="Enter a name for your design"
                  allowClear
                />
              </Form.Item>
              <Text>Category</Text>

              <Form.Item
                name="category"
                rules={[{ required: true, message: "A category is required" }]}
              >
                <Select
                  style={{ width: "100%" }}
                  options={options}
                  size="large"
                  placeholder="Select a category for your template"
                  value={selectedCategory}
                  onChange={handleSelectedCategoryChanged}
                />
              </Form.Item>

              <div>
                <Text>Description</Text>
                <Form.Item
                  name="description"
                  rules={[
                    { required: true, message: "A description is required" },{
                        max: 100,
                        message:
                          "Your description can have at most 100 characters",
                      },
                  ]}
                >
                  <TextArea placeholder="Enter a description for your template" size="large"  count={{
                      show: true,
                      max: 100,
                    }} />
                </Form.Item>
              </div>

              <Button
                block
                type="primary"
                size="large"
                htmlType="submit"
                className="primary-gradient-background"
                loading={loading}
              >
                Looks fine to me
              </Button>
            </Card>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default EditSimpleEmail;
