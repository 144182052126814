import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsCloudDownloadFill, BsTrashFill } from "react-icons/bs";
import { FaRegCopy } from "react-icons/fa";
import { IoMdArrowDropdown, IoMdMore } from "react-icons/io";
import { MdMoreHoriz } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const CampaignOptions = ({ campaign, onDelete }) => {
  const navigate = useNavigate();

  const items = [
    {
      label: "Edit campaign",
      key: "1",
      disabled: campaign.saveStatus != 0,
      icon: <AiFillEdit />,
      onClick: () => navigate(`/Campaigns/Edit/${campaign.id}/${campaign.campaignType}`),
    },
    {
      label: "View campaign details",
      key: "2",
      disabled: campaign.saveStatus != 1,
      icon: <TbListDetails />,
      onClick: () => navigate(`/Campaigns/Edit/${campaign.id}/${campaign.campaignType}`),
    },
    {
      label: "Delete selected campaign",
      key: "3",
      danger: true,
      icon: <BsTrashFill />,
      onClick: () => onDelete(campaign.id),
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} type="link" >
        <Button 
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore  style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default CampaignOptions;
