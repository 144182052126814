import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import env from "../../../env.json";
import InviteUser from "./InviteUser";
import UserAuthorizationFilter from "../UserFilters/UserAuthorizationFilter";
import UserBanFilter from "../UserFilters/UserBanFilter";
import UserOrderBy from "../UserFilters/UserOrderBy";
import UserOrderByDirection from "../UserFilters/UserOrderDirection";
import {
  Avatar,
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Progress,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { UserOutlined } from "@ant-design/icons";
import TableLoading from "../../../shared/TableLoading";
import useDebounce from "../../../hooks/useDebounce";
import { FiPlus } from "react-icons/fi";
import { SearchContext } from "../../../context/SearchContext";
import UserOptions from "./UserOptions";
import UpdateAllowances from "../UserActions/UpdateAllowances";
import UserRoles from "./UserRoles";
import CreateSenderModal from "../Senders/CreateSenderModal";

const UserList = () => {
  const { Title, Text } = Typography;
  const axiosPrivate = useAxiosPrivate();

  const [currentUser, setCurrentUser] = useState(() => null);
  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(7);
  const [page, setPage] = useState(1);
  const { debouncedValue } = useContext(SearchContext);

  const [showFilters, setShowFilters] = useState(false);

  const [inviteModalIsOpen, setInviteModalIsOpen] = useState(false);

  const [authorizationFilter, setAuthorizationFilter] = useState(() => []);
  const [banFilter, setBanFilter] = useState(() => []);
  const [orderBy, setOrderBy] = useState(() => "");
  const [direction, setDirection] = useState(() => "");

  const [allowanceModalOpen, setAllowanceModalOpen] = useState(() => false);
  const [userRolesModalOpen, setUserRolesModalOpen] = useState(() => false);
  const [senderModal, setSenderModal] = useState(() => false);

  const handleCloseInviteModal = () => {
    setInviteModalIsOpen(false);
  };

  const handleDelete = (id) => {
    setUsers((prev) => prev.filter((c) => c.id != id));

    setTotalRows((prev) => prev - 1);
  };

  const columns = [
    {
      title: "Data",
      render: (row) => (
        <Space>
          <Avatar
            size="large"
            icon={<UserOutlined />}
            src={`${env.fileUpload}${row.profileImagePath}`}
          />

          <Text strong>
            {row.firstname} {row.lastname}
          </Text>
        </Space>
      ),
    },
    {
      title: "Email",
      render: (data, row) => <Text strong>{row.email}</Text>,
    },
    {
      title: "Emails",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.emailAllowance} />
      ),
    },
    {
      title: "SMS",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.smsAllowance} />
      ),
    },
    {
      title: "Whatsapp",
      width: 150,
      render: (data, row) => (
        <UserAllowanceProgress value={data.whatsappAllowance} />
      ),
    },
    {
      width: "50px",
      render: (row) => (
        <>
          <UserOptions
            user={row}
            setAllowanceOpen={setAllowanceModalOpen}
            allowanceOpen={allowanceModalOpen}
            onDelete={handleDelete}
            handleBan={handleBlocked}
            handleUnban={handleUnblocked}
            setCurrentUser={setCurrentUser}
            userobject={row}
            setRolesOpen={setUserRolesModalOpen}
            rolesOpen={userRolesModalOpen}
            senderModalOpen={senderModal}
            setSenderModalOpen={setSenderModal}
          />
          {/*<Space>
          <UserToSender user={row} id={row.id} handleAdd={handleAdd} is={row.isSender} />

          {!row.lockoutEnabled && (
            <BlockUser user={row.id} handleBlocked={handleBlocked}></BlockUser>
          )}
          {row.lockoutEnabled && (
            <UnblockUser
              user={row.id}
              handleUnblocked={handleUnblocked}
            ></UnblockUser>
          )}

          <Button
            onClick={(e) => handleOpenModal(row.id)}
            icon={<FcEmptyTrash />}
            shape="circle"
          />

          <UserRoles userId={row.id} roles={row.userRoles} />
        </Space>*/}
        </>
      ),
    },
  ];

  const fetchUsers = async (page) => {
    setLoading(true);

    const response = await axiosPrivate.post(`Users`, {
      pageSize: perPage,
      page: page,
      authorizations: authorizationFilter,
      banStatus: banFilter,
      orderBy: orderBy,
      orderDirection: direction,
      search: debouncedValue,
    });

    setUsers(
      response.data.data.map((c) => {
        return { ...c, key: c.id };
      })
    );
    setTotalRows(response.data.count);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
  }, [authorizationFilter, banFilter, orderBy, direction, debouncedValue]);

  useEffect(() => {
    fetchUsers(page);
  }, [page, perPage]);

  const handleBlocked = (user) => {
    const updatedData = users.map((item) =>
      item.id === user ? { ...item, lockoutEnabled: true } : item
    );

    setUsers(updatedData);
  };
  const handleUnblocked = (user) => {
    const updatedData = users.map((item) =>
      item.id === user ? { ...item, lockoutEnabled: false } : item
    );

    setUsers(updatedData);
  };

  const handleAuthorizationFilterChanged = (e) => {
    setAuthorizationFilter(e);
  };

  const handleBanStatusChanged = (e) => {
    setBanFilter(e);
  };

  const handleOrderByChanged = (e) => {
    setOrderBy(e);
  };

  const handleOrderByDirectionChanged = (e) => {
    setDirection(e);
  };

  const onAllowanceUpdate = (id, emailValue, whatsappValue, smsValue) => {
    setUsers((prev) =>
      prev.map((c) =>
        c.id == id
          ? {
              ...c,
              emailAllowance: emailValue,
              smsAllowance: smsValue,
              whatsappAllowance: whatsappValue,
            }
          : c
      )
    );
  };

  const handleIsSender = (id) => {
    setUsers(prev => prev.map(c => c.id == id ? {...c, isSender: true } : c))
  }
  return (
    <>
      {allowanceModalOpen && (
        <UpdateAllowances
          open={allowanceModalOpen}
          id={currentUser}
          setOpen={setAllowanceModalOpen}
          onAllowanceUpdate={onAllowanceUpdate}
        />
      )}
      {userRolesModalOpen && (
        <UserRoles
          isOpen={userRolesModalOpen}
          setIsOpen={setUserRolesModalOpen}
          userId={currentUser}
          userRoles={users.find(c => c.id == currentUser)?.userRoles}
          setUserRoles={(roles) => {
            setUsers(prev => prev.map(c => c.id == currentUser ? {
              ...c,
              userRoles: roles
            } : c))
          }}
        />
      )}

      {senderModal&&(
        <CreateSenderModal setIsOpen={setSenderModal} isOpen={senderModal} user={users?.find(c => c.id == currentUser)} dataAdded={handleIsSender}/>
      )}
      <InviteUser isOpen={inviteModalIsOpen} onClose={handleCloseInviteModal} />

      <Space style={{ width: "100%" }} direction="vertical">
      <Flex align="center" justify="space-between">
              <Title level={4} style={{ margin: 0 }}>
                Team ({totalRows})
              </Title>
              <Space>
                <Button
                  size="large" style={{ borderRadius: 15 }}
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                 
                  Filter
                </Button>
                <Button
                  type="primary"
                  className="primary-gradient-background flex-button bold-button"
                  size="large"
                  style={{ borderRadius: 15 }}
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  onClick={() => setInviteModalIsOpen(true)}
                >
                  New member
                </Button>
              </Space>
            </Flex>
        
        {showFilters && (
          <div style={{ margin: 10 }}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <UserAuthorizationFilter
                  onChanged={handleAuthorizationFilterChanged}
                />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <UserBanFilter onChanged={handleBanStatusChanged} />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <UserOrderBy onChanged={handleOrderByChanged} />
              </Col>
              <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
                <UserOrderByDirection
                  onChanged={handleOrderByDirectionChanged}
                />
              </Col>
            </Row>
          </div>
        )}

        <Card className="zero-margin-padding" style={{boxShadow:"none"}}>
          <Table
            columns={columns}
            style={{ marginTop: 1 }}
            dataSource={users}
            locale={{
              emptyText: loading ? (
                <TableLoading />
              ) : (
                <Empty description="Looks like you have reached the end" />
              ),
            }}
            pagination={{
              current: page,
              pageSize: perPage,
              total: totalRows,
              onChange: (page, pageSize) => {
                setPage(page);
                setPerPage(pageSize);
              },
            }}
          />
        </Card>
      </Space>
    </>
  );
};

const UserAllowanceProgress = ({ value }) => {
  const { Text } = Typography;
  return (
    <Flex align="center" justify="start" gap={5}>
      <Text strong>{value}%</Text>
      <div style={{ width: "70px" }}>
        <Progress showInfo={false} percent={value} strokeColor={"#422afb"} />
      </div>
    </Flex>
  );
};
export default UserList;
