import { Avatar, Col, Row } from "antd"
import Stat from "../../shared/Stat"
import { FaProjectDiagram } from "react-icons/fa"
import { MdFirstPage, MdLastPage } from "react-icons/md"
import { useContext, useState } from "react"
import { DateFormat } from "../../dateformat"
import {UserOutlined} from '@ant-design/icons'
import env from '../../env.json'
import { AudienceListContext } from "./AudienceListContext"

const AudienceStats = () => {
    const {stats} = useContext(AudienceListContext)

    return <>
     <Row gutter={[24,24]}>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.total}
            name="Total"
            icon={<FaProjectDiagram style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.first).toLocaleDateString("en-US", DateFormat)}
            name="First"
            icon={<MdFirstPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.last).toLocaleDateString("en-US", DateFormat)}
            name="Last"
            icon={<MdLastPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.authorName}
            name="Most authored"
            icon={<Avatar icon={<UserOutlined/>} size="large" src={`${env.fileUpload}${stats?.authorImage}`} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
}

export default AudienceStats