import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DeleteCompany = ({ id, onDelete }) => {
  const axios = useAxiosPrivate();

  const onSubmit = async () => {
    const response = await axios.delete(`/Company/${id}`);

    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Popconfirm
        okText="Yes, delete it"
        onConfirm={() => onSubmit()}
        title="Are you sure?"
        description="Are you sure you want to delete this company?"
      >
        Delete selected company
      </Popconfirm>
    </>
  );
};

export default DeleteCompany;
