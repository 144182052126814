import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderCreatedDateToFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(EmailSenderContext);
  return (
    <>
      <Text>To</Text>
      <DatePicker
        size="large"
        defaultValue={filters.to}
        onChange={(e) => setFilters((prev) => ({ ...prev, to: e }))}
        placeholder="Select created date end"
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};

export default EmailSenderCreatedDateToFilter;
