import {
  Button,
  Typography,
  Flex,
  Space,
  Avatar,
  Tag,
  Empty,
  Select,
  Tooltip,
  Table,
  Row,
  Col,
  Card,
  Popover,
  Divider,
  Popconfirm,
  Dropdown,
  message,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import EditableName from "../EditableName";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import env from "../../../env.json";
import { BsTrash3Fill } from "react-icons/bs";
import AddEmailContact from "./AddEmailContact";
import { useNavigate, useParams } from "react-router-dom";
import TableLoading from "../../../shared/TableLoading";
import useDebounce from "../../../hooks/useDebounce";
import { BsInfoCircleFill } from "react-icons/bs";
import ESEmailList from "../Email/ESEmailList";
import { DateFormat } from "../../../dateformat";
import { FiPlus } from "react-icons/fi";
import { SearchContext } from "../../../context/SearchContext";
import { RiPassValidFill } from "react-icons/ri";
import { IoMdMore } from "react-icons/io";
import EmailQualityModal from "../Email/EmailQualityModal";
import { MdCleanHands } from "react-icons/md";
import CreateCleanUpModal from "../../cleanup/CreateCleanUpModal";
import InitilasAvatar from "../../../shared/IntialsAvatar";
const AudienceDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { debouncedValue } = useContext(SearchContext);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();

  const [audience, setAudience] = useState();

  const [countries, setCountries] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  const [countriesLoading, setCountriesLoading] = useState(false);

  const [industries, setIndustries] = useState();
  const [selectedIndustries, setSelectedIndustries] = useState();
  const [industriesLoading, setIndustriesLoading] = useState(false);

  const [contacts, setContacts] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(7);
  const [total, setTotal] = useState();
  const [deleting, setDeleting] = useState(false);
  useEffect(async () => {
    const response = await axios.get(`/TargetAudience/GetDetails?Id=${id}`);
    setAudience(response.data.data);

    setCountriesLoading(true);
    const countriesResponse = await axios.get(
      `/TargetAudience/GetAudienceCountry?Id=${id}`
    );
    setCountriesLoading(false);

    setCountries(
      countriesResponse.data.data.map((c) => {
        return { value: c.id, label: c.name };
      })
    );
    setSelectedCountries(
      countriesResponse.data.data.filter((c) => c.selected).map((c) => c.id)
    );

    setIndustriesLoading(true);
    const industryResponse = await axios.get(
      `/TargetAudience/GetAudienceIndustry?Id=${id}`
    );
    setIndustriesLoading(false);
    setIndustries(
      industryResponse.data.data.map((d) => {
        return { value: d.id, label: d.name };
      })
    );
    setSelectedIndustries(
      industryResponse.data.data.filter((i) => i.selected).map((i) => i.id)
    );
  }, []);

  useEffect(async () => {
    setLoading(true);
    const response = await axios.post(`/TargetAudience/GetAudienceContacts`, {
      page: page,
      pageSize: pageSize,
      audienceId: id,
      searchWord: debouncedValue,
    });
    setContacts(response.data.data);
    setTotal(response.data.count);
    setLoading(false);
  }, [page, pageSize, debouncedValue]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (row, data, index) => (
        <Text strong>{(data?.name ?? "") + " " + (data?.lastName ?? "")}</Text>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (data, row) => <Text strong>{row.email}</Text>,
    },
    {
      title: "Phone",
      key: "phoneNumber",
      render: (data, row) => <Text strong>{row.phoneNumber}</Text>,
    },
    {
      title: "",
      dataIndex: "",
      key: "actions",
      render: (row, data) => (
        <Flex align="center" justify="end">
          <EmailOptions
            setCurrent={setCurrent}
            setOpen={setValidityShow}
            id={row.id}
            email={row.email}
            onDelete={onDelete}
          />
        </Flex>
      ),
    },
  ];

  const [validityShow, setValidityShow] = useState(() => false);
  const [current, setCurrent] = useState();

  const onDelete = (id) => {
    setTotal((prev) => prev - 1);

    setContacts((prev) => prev.filter((c) => c.id != id));
  };

  const handleCountriesChanged = async (e) => {
    setCountriesLoading(true);

    const response = await axios.post(
      "/TargetAudience/AssignCountryToAudience",
      {
        id: audience.id,
        ids: e,
      }
    );
    setSelectedCountries(e);
    setCountriesLoading(false);
  };

  const handleIndustriesUpdate = async (e) => {
    setIndustriesLoading(true);

    const response = await axios.post(
      `/TargetAudience/AssignIndustryToAudience`,
      {
        id: audience.id,
        ids: e,
      }
    );

    setSelectedIndustries(e);
    setIndustriesLoading(false);
  };

  const countriesSelectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    onChange: (e) => handleCountriesChanged(e),
    size: "large",
    value: selectedCountries,
    options: countries,
    placeholder: "Select Item...",
    maxTagCount: "responsive",
    showSearch: true,
    filterOption: filterOption,
    loading: countriesLoading,
  };

  const industriesSelectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    size: "large",
    value: selectedIndustries,
    options: industries,
    placeholder: "Select Item...",
    maxTagCount: "responsive",
    showSearch: true,
    onChange: (e) => handleIndustriesUpdate(e),
    filterOption: filterOption,
    loading: industriesLoading,
  };

  const getSelectedLabels = () => {
    return selectedIndustries?.map((item) => {
      const option = industries.find((opt) => opt.value === item);
      return option ? option.label : item;
    });
  };

  const [cleanOpen, setCleanOpen] = useState(false);
  return (
    <>
      <CreateCleanUpModal
        onStartJob={(name, job) => {
          setAudience((prev) => ({
            ...prev,
            hasJobRuning: true,
            jobName: name,
            jobId: job,
          }));
        }}
        id={id}
        open={cleanOpen}
        setOpen={setCleanOpen}
      />
      {validityShow && (
        <EmailQualityModal
          email={current}
          open={validityShow}
          setOpen={setValidityShow}
        />
      )}
      <AddEmailContact
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        audienceId={id}
        onAddSuccess={(data) => {
          if (Array.isArray(data)) {
            setContacts([...contacts, ...data]);
            setTotal((prev) => prev + data.length);
          } else {
            setContacts([...contacts, data]);
            setTotal((prev) => prev + 1);
          }
        }}
      />

<Space className="w-100" size={24} direction="vertical">

<Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between">
                <Title level={4} style={{ margin: 0 }}>
                  Audience details 
                </Title>
            </Flex>
          }
        ></Card>
      <Card>


      <Row gutter={[24,24]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
        <Space className="w-100" direction="vertical" size={12}>

        <Space className="w-100" direction="vertical" size={0}>

        <Title style={{ margin: 5 }} level={4}>Main details</Title>
          <Card
            style={{
              position: "sticky",
              top: "80px",
              boxShadow:"none"
            }}
          >
            <Space direction="vertical" style={{ width: "100%" }}>
            <EditableName
                  name={audience?.name}
                  id={audience?.id}
                  setName={(e) => setAudience({ ...audience, name: e })}
                />
              <Space direction="vertical" style={{ width: "100%" }} size={0}>
                <Text>Selected countries</Text>
                <Select {...countriesSelectProps} />
              </Space>
              <Space direction="vertical" style={{ width: "100%" }} size={0}>
                <Text>Selected industries</Text>

                <Select
                  {...industriesSelectProps}
                  maxTagPlaceholder={(omittedValues) => (
                    <Popover
                      title="All selected industries"
                      content={
                        <SelectedIndustries items={getSelectedLabels()} />
                      }
                    >
                      <span>+{omittedValues.length} items</span>
                    </Popover>
                  )}
                />
              </Space>
            </Space>
          </Card>
          </Space>
          
          <Space className="w-100" direction="vertical" size={0}>

          <Title level={4} style={{ margin: 5 }}>Author</Title>
          <Card style={{boxShadow:"none"}} size="small">
            <Flex align="center" justify="start" gap={6}>
              <Avatar src={`${env.fileUpload}${audience?.authorImage}`} />
              <Text strong>{audience?.authorName}</Text>
            </Flex>
          </Card>
</Space>
                    <Space className="w-100" direction="vertical" size={0}>

          <Title level={4} style={{ margin: 5 }}>Created</Title>
          <Card style={{boxShadow:"none"}} size="small">
            <Flex align="center" justify="start" gap={6}>
              <Text strong> {new Date(audience?.createdAt).toLocaleDateString(
                "en-US",
                DateFormat
              )}</Text>
            </Flex>
          </Card>
              </Space>
</Space>
        </Col>

        <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
          <Space style={{ width: "100%" }} direction="vertical" size={24}>
            <ESEmailList
              industries={selectedIndustries}
              countries={selectedCountries}
              id={id}
            />

<Space style={{ width: "100%" }} direction="vertical">
<Flex align="center" justify="space-between">
                
                    <Title level={4} style={{ margin: 5 }}>
                      Your contacts ({total})
                    </Title>
                
                  <Flex align="center" justify="end" gap={6}>
                    {audience?.hasJobRuning ? (
                      <Button
                        size="large"
                        onClick={() => navigate(`/Jobs/${audience.jobId}`)}
                      >
                        View Clean up results
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        onClick={() => setCleanOpen(true)}
                        icon={<MdCleanHands />}
                        className="flex-button"
                      >
                        Start cleaning job
                      </Button>
                    )}

                    <Button
                      onClick={() => setIsOpen(true)}
                      type="primary"
                      size="large"
                      style={{ borderRadius: 15 }}
                      className="primary-gradient-background bold-button flex-button"
                      icon={<FiPlus style={{ fontSize: 20 }} />}
                    >
                      New members
                    </Button>
                  </Flex>
                </Flex>

            <Card className="zero-margin-padding" style={{boxShadow:"none"}}>
              <Table
                style={{ marginTop: 1 }}
                dataSource={contacts}
                columns={columns}
                pagination={{
                  current: page,
                  pageSize: pageSize,
                  total: total,
                  showSizeChanger: false,
                }}
                onChange={(pagination) => {
                  setPage(pagination.current);
                  setPageSize(pagination.pageSize);
                }}
                locale={{
                  emptyText: loading ? (
                    <TableLoading />
                  ) : (
                    <Empty description="Looks like you have reached the end" />
                  ),
                }}
              />
            </Card>
          </Space>
          </Space>
        </Col>
      </Row>
      </Card>
      </Space>

    </>
  );
};

const SelectedIndustries = ({ items }) => {
  return (
    <div style={{ maxWidth: "800px" }}>
      <Divider style={{ margin: "5px 0px" }} />
      <Flex align="start" justify="start" gap={2} wrap="wrap">
        {items?.map((c, ind) => (
          <Tag
            key={ind}
            style={{ margin: 0 }}
            className="black-color-tag"
            color="#e3e3e3"
          >
            {c}
          </Tag>
        ))}
      </Flex>
    </div>
  );
};

const EmailOptions = ({ setCurrent, setOpen, onDelete, id, email }) => {
  const items = [
    {
      key: 1,
      label: "Check validity",
      icon: <RiPassValidFill />,
      onClick: () => {
        setCurrent(email);
        setOpen(true);
      },
    },
    {
      key: 2,
      label: <RemoveEmail id={id} onDelete={onDelete} />,
      danger: true,
      icon: <BsTrash3Fill />,
    },
  ];

  return (
    <>
      <Dropdown menu={{ items }}>
        <Button
          type="text"
          icon={<IoMdMore style={{ fontSize: 20}} />}
          shape="circle"
          className="flex-button"
        ></Button>
      </Dropdown>
    </>
  );
};

const RemoveEmail = ({ id, onDelete }) => {
  const axios = useAxiosPrivate();

  const handleDelete = async () => {
    const response = await axios.post("/TargetAudience/DeleteEmailContact", {
      id: id,
    });

    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Popconfirm
        onConfirm={handleDelete}
        title="Are you sure?"
        description="Are you sure you want to remove this contact?"
        okText="Yes, remove it"
      >
        Remove contact
      </Popconfirm>
    </>
  );
};
export default AudienceDetails;
