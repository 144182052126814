import { Button, DatePicker, Flex, Typography } from "antd";
import { useContext } from "react";
import { TaskContext } from "../TaskContext";

const TaskFromDateFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(TaskContext);

  return (
    <>
      <Text>From</Text>
      <DatePicker
        size="large"
        defaultValue={filters?.from}
        value={filters?.from}
        onChange={(e) => setFilters((prev) => ({ ...prev, from: e }))}
        placeholder="Select created date start"
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};

export default TaskFromDateFilter;
