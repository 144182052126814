import React, { useState } from 'react'
import Header from '../Header'
import Kanban from '../Kanban/Kanban'
import { DealsTabs } from '../../../shared/enums'
import List from '../List/List'

const DealsManagement = () => {
  const [activeTab,setActiveTab] = useState(1)

  return (
    <>
      <Header activeTab={activeTab} setActiveTab={setActiveTab}/>
  {activeTab === DealsTabs.Kanban && <Kanban/>}
  {activeTab === DealsTabs.List && <List/>}
    </>
  )
}

export default DealsManagement