import {
  Button,
  Card,
  Dropdown,
  Flex,
  Select,
  Space,
  Typography,
} from "antd";
import { ContentState, EditorState, Modifier } from 'draft-js';

import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "draft-js/dist/Draft.css";
import { DownOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import SmartText from "../../../components/SmartText";

const CreateSimpleEmail = ({ editorState, setEditorState }) => {
  const ref = useRef();

  const { Title } = Typography;
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <Card
        title={
          <Formatters
            onEditorStateChange={handleEditorChange}
            editorState={editorState}
          />
        }
        className="zero-margin-padding"
      >
        <div>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            onEditorStateChange={(e) => setEditorState(e)}
          />
        </div>
      </Card>
    </Space>
  );
};

const Formatters = ({ onEditorStateChange, editorState }) => {
  const axios = useAxiosPrivate();

  const { Text, Title } = Typography;

  const [signatures, setSignatures] = useState([]);

  useEffect(async () => {
    const response = await axios.post("/Signature/GetPaged", {
      pageSize: 999,
      page: 1,
    });

    setSignatures(response.data.data);
  }, []);

  const insertData = (value) => {
    const contentState = editorState.getCurrentContent();
    const selectionState = editorState.getSelection();
    const contentStateWithEntity = contentState.createEntity(
      "TOKEN",
      "IMMUTABLE",
      { text: value }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const contentStateWithText = Modifier.replaceText(
      contentStateWithEntity,
      selectionState,
      value,
      editorState.getCurrentInlineStyle(),
      entityKey
    );
    const newEditorState = EditorState.push(
      editorState,
      contentStateWithText,
      "insert-characters"
    );
    onEditorStateChange(newEditorState);
  };

  const appendHtmlToEnd = (html) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentHtml = stateToHTML(currentContent);
    const updatedHtml = currentContentHtml + html;
    const newContentState = stateFromHTML(updatedHtml);

    const newEditorState = EditorState.createWithContent(newContentState);
    onEditorStateChange(newEditorState);
  };
  
  const onSignatureChanged = (e) => {
    const signature = signatures.find((c) => c.id == e);

    if (!signature) {
      return;
    }
    appendHtmlToEnd(signature.text)
  };

  const items = [
    {
      key: "1",
      label: "User fullname",
      onClick: () => insertData("-fullname-"),
    },
    {
      key: "2",
      label: "User firstname",
      onClick: () => insertData("-firstname-"),
    },
    {
      key: "3",
      label: "User lastname",
      onClick: () => insertData("-lastname-"),
    },
    {
      key: "4",
      label: "User email",
      onClick: () => insertData("-email-"),
    },
    {
      key: "5",
      label: "User phone number",
      onClick: () => insertData("-phone-"),
    },
  ];

  return (
    <>
      <Flex align="center" justify="space-between">
        <Title style={{ margin: 0 }} level={5}>
          Personalized email
        </Title>

        <Space >
          <Select
            placeholder="Select your signature"
            options={signatures.map((c) => ({
              label: c.name,
              value: c.id,
            }))}
            trigger={["click"]}
            style={{ width: "200px" }}
            onChange={(e) => onSignatureChanged(e)}
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Insert signature
                <DownOutlined />
              </Space>
            </a>
          </Select>

          <Dropdown
            menu={{
              items,
            }}
            trigger={["click"]}
          >
            <Button className="flex-button" onClick={(e) => e.preventDefault()}>
              <DownOutlined />
            </Button>
          </Dropdown>

          <SmartText text={editorState.getCurrentContent().getPlainText()} setText={(e) => {
            const newContentState = ContentState.createFromText(e);
            const newEditorState = EditorState.createWithContent(newContentState);

            onEditorStateChange(newEditorState)
          }}/>
        </Space>
      </Flex>
    </>
  );
};

export default CreateSimpleEmail;
