import { Card, Flex, Select, Space, Typography } from "antd";
import DealDetailsTimeline from "./DealDetailsTimeline/DealDetailsTimeline";
import useDealsDetailsCtx from "../../../../hooks/useDealsDetailsCtx";

const DealDetailsHistory = () => {
  const {currentActivityFilter, setCurrentActivityFilter} = useDealsDetailsCtx();

  const options = [
    {
      label: "All activities",
      value: 1,
    },
    {
      label: "Deal activity",
      value: 2,
    },
    {
      label: "File",
      value: 3,
    },
    {
      label: "Notes",
      value: 4,
    },
    {
      label: "Task",
      value: 5,
    },
  ];

  const { Title } = Typography;
  return (
    <>
      <Space className="w-100" direction="vertical">
        <Flex align="center" justify="space-between">
          <Title style={{ margin: 5 }} level={4}>
            History
          </Title>

          <Select
            defaultValue={1}
            value={currentActivityFilter}
            onChange={(e) => setCurrentActivityFilter(e)}
            options={options}
            size="large"
            style={{ width: "200px" }}
          />
        </Flex>
        <Card style={{ boxShadow: "none" }}>
          <DealDetailsTimeline />
        </Card>
      </Space>
    </>
  );
};

export default DealDetailsHistory;
