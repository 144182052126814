import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Form, Select, Typography } from "antd";

const CleanUpJobSettingsSelect = () => {
  const axios = useAxiosPrivate();
    const {Text, Title} = Typography;

  const [options, setOptions] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(async () => {
    setLoading(true);
    const response = await axios.post("/CleanUpSettings/list", {
      page: 1,
      pageSize: 9999,
    });

    setOptions(
      response.data.items?.map((c) => ({
        value: c.id,
        label: <Text>Host: <Text strong>{c.proxyHost}</Text>, User: <Text strong>{c.username}</Text></Text>,
      }))
    );
    setLoading(false);
  }, []);
  return (
    <>
      <Form.Item
        name="settingsId"
        rules={[
          {
            required: true,
            message: "Plase select the settings to use",
          },
        ]}
      >
        <Select
          loading={loading}
          options={options}
          className="w-100"
          size="large"
          placeholder="Select settings"
        />
      </Form.Item>
    </>
  );
};

export default CleanUpJobSettingsSelect;
