import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";

const SmsToDateFilter = () =>{
    const {Text} = Typography;
    const {filters, setFilters} = useContext(SmsListContext)
  return (
    <>
      <Text text="Order by">To</Text>
      <DatePicker
        size="large"
        placeholder="Select created date start"
        defaultValue={filters?.from}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            to: e,
          }))
        }
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
}


export default SmsToDateFilter