import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { MediaContext } from "../MediaContext";

export const CreatedFromDateFilter = () => {
  const { Text } = Typography;

  const {filters, setFilters} = useContext(MediaContext)


  return (
    <>
      <Text text="Order by">From</Text>
      <DatePicker
        size="large"
        value={filters.from}
        onChange={(e) => setFilters(prev => ({...prev,
          from: e
        }))}
        placeholder="Select created date start"
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};
