import { Button, Modal, Table, Tooltip, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect } from "react";
import { useState } from "react";
import { IoIosDownload } from "react-icons/io";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
const CampaignDocuments = ({ isOpen, setIsOpen }) => {
  const{Title} = Typography
  const {data, setData} = useContext(CreateCampaignContext)

  const axios = useAxiosPrivate();
  const [documents, setDocuments] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [total, setTotal] = useState(); 
  
  useEffect(async () => {

    const controller = new AbortController();

    const response = await axios.post("/Document/GetAll", {
      page: page,
      pageSize: 99999,
    }, {signal: controller.signal});

    setDocuments(
      response.data.data.map((c) => {
        return {
          key: c.id,
          ...c,
        };
      })
    );

    setTotal(response.data.count);

    return () => controller.abort();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      disabled:!data.canEdit
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "",
      dataIndex: "",
      render: (record, row) => (
        <Tooltip title="Download document">
          <Button
            shape="circle"
            type="link"
            icon={<IoIosDownload style={{ fontSize: 22 }} />}
          ></Button>
        </Tooltip>
      ),
    },
  ];
  
  const [selected, setSelected] = useState(data.documents) 
  
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        setSelected(selectedRowKeys)
    },
  };

const handleSave = () => {
    setData({...data, documents: selected})
    setIsOpen(false)
}

  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Select campaign documents
          </Title>
        }
        okText="Save"
        onOk={() => handleSave()}
      >
        <Table
          
          dataSource={documents}
          columns={columns}
          pagination={{
            current: page,
            pageSize: pageSize,
            total: total,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
          rowSelection={{
            ...rowSelection,
            selectedRowKeys: selected,
            type: "checkbox",
            getCheckboxProps: (selectedRowKeys) => {
              return {
                  disabled: !data.canEdit
              }}
          }}
        ></Table>
      </Modal>
    </>
  );
};

export default CampaignDocuments;
