import { Button, Card, Col, Flex, Row, Space, Typography } from "antd";
import CleanUpDetailsStats from "./CleanUpDeatailsStats";
import { useEffect, useState } from "react";
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import CleanUpSafe from "./CleanUpSafe";
import CleanUpInvalid from "./CleanUpInvalid";
import CleanUpRisky from "./CleanUpRisky";
import CleanUpUnknown from "./CleanUpUnknown";
import { useParams } from "react-router-dom";
import { DateFormat } from '../../../dateformat'
import ImmediateActionConfirmationModal from "./ImmediateActionConfirmationModal";
const CleanUpDetails = () => {
    const { Text, Title } = Typography;
    const axios = useAxiosPrivate();
    const { id } = useParams();

    const [data, setData] = useState()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(async () => {
        setLoading(true);
        const response = await axios.get(`/CleanUp/details/${id}`);

        setData(response.data)
        setLoading(false)
    }, [refresh])

    const [open, setOpen] = useState(false)
    return <>
    <ImmediateActionConfirmationModal refresh={setRefresh} open={open} setOpen={setOpen} id={id} amountToRemove={data?.total} jobType={data?.jobAcceptType}/>
        <Space size={24} direction="vertical" className="w-100">
            <CleanUpDetailsStats total={data?.total} safe={data?.safe} invalid={data?.invalid} risky={data?.risky} />
            <Card
                className="zero-margin-padding no-body-card end-to-end-header"
                title={
                    <Flex align="center" justify="space-between">
                        <Title level={4} style={{ margin: 0 }}>
                            Job details
                        </Title>

                        <Button onClick={() => setOpen(true)} size="large" type="primary" className="flex-button">Take immediate action</Button>
                    </Flex>
                }
            ></Card>

            <Card>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <Space className="w-100" direction="vertical">

                            <Flex align="center" justify="space-between">

                                <Text>Name</Text>
                                <Text strong>{data?.name}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>First validation</Text>
                                <Text strong>{data?.firstValidation ? new Date(data?.firstValidation).toLocaleDateString("en-US", DateFormat) : "-"}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Last validation</Text>
                                <Text strong>{data?.lastValidation ? new Date(data?.lastValidation).toLocaleDateString("en-US", DateFormat) : "-"}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Last action taken</Text>
                                <Text strong>{data?.lastActionTakeDate ? new Date(data?.lastActionTakeDate).toLocaleDateString("en-US", DateFormat) : "-"}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Type</Text>
                                <Text strong>{data?.jobAcceptType == 1 ? "Safe-only" : data?.jobAcceptType == 2 ? "Safe & Unknown" : "Safe & Accepts Mail"}</Text>
                            </Flex>
                        </Space>
                    </Col>
                    <Col span={12}>

                        <Space className="w-100" direction="vertical">

                            <Flex align="center" justify="space-between">

                                <Text>Author</Text>
                                <Text strong>{data?.author}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Proxy host</Text>
                                <Text strong>{data?.isBasicJob ? "Default" : data?.settings?.proxyHost}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Smtp port</Text>
                                <Text strong>{data?.isBasicJob ? "Default" : data?.settings?.smtpPort}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Proxy username</Text>
                                <Text strong>{data?.isBasicJob ? "Default" : data?.settings?.username}</Text>
                            </Flex>
                            <Flex align="center" justify="space-between">

                                <Text>Proxy port</Text>
                                <Text strong>{data?.isBasicJob ? "Default" : data?.settings?.proxyPort}</Text>
                            </Flex>
                        </Space>

                    </Col>
                </Row>

            </Card>

            <Card>

                <Row gutter={[24, 24]}>
                    <Col span={6}>
                        <Title level={4} style={{ margin: 5 }}>Safe</Title>
                        <CleanUpSafe refresh={refresh}/>
                    </Col>
                    <Col span={6}>
                        <Title level={4} style={{ margin: 5 }}>Invalid</Title>
                        <CleanUpInvalid refresh={refresh}/>
                    </Col>
                    <Col span={6}>
                        <Title level={4} style={{ margin: 5 }}>Risky</Title>
                        <CleanUpRisky refresh={refresh}/>
                    </Col>
                    <Col span={6}>
                        <Title level={4} style={{ margin: 5 }}>Unknown</Title>
                        <CleanUpUnknown refresh={refresh}/>
                    </Col>
                </Row>
            </Card>
        </Space>

    </>
}

export default CleanUpDetails;