import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmaiLSenderConfirmation, EmailSenderDefault, OrderDirection } from "../../../shared/enums";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";
import { SearchContext } from "../../../context/SearchContext";
import { faLaptopHouse } from "@fortawesome/free-solid-svg-icons";

export const EmailSenderContext = createContext({
    filters: {
        authors: [],
        selectedAuthors: [],

        from: null,
        to: null,

        statuses: [],
        defaults: [],

        orderBy: null,
        orderDirection: null
    },
    stats: {
        total: 0,
        confirmed: 0,
        first: null,
        last: null,
    },

    senders: [],
    setSenders: () => { },

    page: 1,
    setPage: () => { },

    loading: false,
    setLoading: () => { }
})

export const EmailSenderContextProvider = ({ children }) => {
    const { debouncedValue } = useContext(SearchContext);

    const [filters, setFilters] = useState()
    const [stats, setStats] = useState();
    const [page, setPage] = useState(() => 1)
    const perPage = 7;
    const [senders, setSenders] = useState(() => []);

    const [loading, setLoading] = useState(() => false);

    const axios = useAxiosPrivate();

    useEffect(async () => {
        const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev => ({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            statuses: [
                EmaiLSenderConfirmation.Confirmed,
                EmaiLSenderConfirmation.NotConfirmed,
            ],
            defaults: [
                EmailSenderDefault.Default,
                EmailSenderDefault.NotDefault
            ],
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
                value: d.id,
                label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath} />),
            }))
        }
        ));
    }, [])

    useEffect(async () => {
        await fetchSenders()
    }, [page, filters, debouncedValue])

    const fetchSenders = async () => {
        setLoading(true);

        const sendersResponse = await axios.post('/sender/getsenders', {
            page: page,
            pageSize: perPage,
            search: debouncedValue,
            authors: filters?.selectedAuthors,
            from: filters?.from,
            to: filters?.to,
            orderBy: filters?.orderBy,
            sortDirection: filters?.orderDirection,
            default: filters?.defaults?.includes(c => c == EmailSenderDefault.Default),
            notDefault: filters?.defaults?.includes(c => c == EmailSenderDefault.NotDefault),
            confirmed: filters?.statuses?.includes(c => c == EmaiLSenderConfirmation.Confirmed),
            notConfirmed: filters?.statuses?.includes(c => c == EmaiLSenderConfirmation.NotConfirmed)
        })

        setSenders(sendersResponse.data.items)

        setStats(prev => ({
            ...prev,
            total: sendersResponse.data.total,
            first: sendersResponse.data.first,
            last: sendersResponse.data.last,
            confirmed: sendersResponse.data.confirmed
        }))
        setLoading(false)
    }

    return <EmailSenderContext.Provider value={{
        filters,
        setFilters,

        stats,
        setStats,

        senders,
        setSenders,

        page,
        setPage,

        loading,
        setLoading
    }}>
        {children}
    </EmailSenderContext.Provider>
}