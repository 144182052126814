import { createContext, useState } from "react";

export const SubscribeContext = createContext({
  show: false,
  setShow: () => {},
  showCoupon: false,
  setShowCoupon: () => {}
});

export const SubscribeContextProvider = ({ children }) => {
  const [show, setShow] = useState(() => false);
  const [showCoupon, setShowCoupon] = useState(() => false)
  return (
    <SubscribeContext.Provider value={{ show, setShow, showCoupon, setShowCoupon}}>
      {children}
    </SubscribeContext.Provider>
  );
};
