import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const DeleteCoupon = ({ id, onDeleted }) => {
  const axios = useAxiosPrivate();

  const deleteCoupon = async () => {
    const response = await axios.delete(`/Coupon/${id}`);

    if (response.data.success) {
      onDeleted(id);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Popconfirm
        okText="Yes, delete it"
        title="Are you sure?"
        description="Are you sure you want to delete the selected coupon?"
        onConfirm={deleteCoupon}
      >
        Delete selected coupon
      </Popconfirm>
    </>
  );
};

export default DeleteCoupon;
