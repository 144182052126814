import { useState, useEffect, useSyncExternalStore } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Select, Typography } from "antd";
const TemplateUserSelect = ({ selectedUsersChanged }) => {
  const axiosPrivate = useAxiosPrivate();

  const [options, setOptions] = useState();

  const handleSelectedUsersChanged = (e) => {
    selectedUsersChanged(e);
  };
  const { Text } = Typography;

  useEffect(async () => {
    const response = await axiosPrivate.get(`Users/GetAuthors`);
    setOptions(
      response.data.data.map((d) => ({
        value: d.id,
        label: `${d.firstname} ${d.lastname}`,
      }))
    );
  }, []);

  return (
    <>
      <Text>Authors</Text>
      <Select
        options={options}
        mode="multiple"
        style={{ width: "100%" }}
        maxTagCount="responsive"
        size="large"
        onChange={(e) => handleSelectedUsersChanged(e)}
      />
    </>
  );
};

export default TemplateUserSelect;
