import { Select, Typography } from "antd";
import { EmaiLSenderConfirmation } from "../../../../shared/enums";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderConfirmationSelect = () => {
  const { Text } = Typography;

  const {filters, setFilters} = useContext(EmailSenderContext)

  const options = [
    {
      value: EmaiLSenderConfirmation.Confirmed,
      label: "Confirmed",
    },
    {
      value: EmaiLSenderConfirmation.NotConfirmed,
      label: "Not confirmed",
    },
  ];

  return (
    <>
      <Text>Status</Text>
      <Select
        defaultValue={filters?.statuses}
        options={options}
        onChange={e => setFilters(prev => ({...prev, statuses: e}))}
        style={{ width: "100%" }}
        size="large"
        mode="multiple"
      />
    </>
  );
};

export default EmailSenderConfirmationSelect;
