import { Select, Typography } from "antd";
import { EmailSenderDefault } from "../../../../shared/enums";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderDefaultSelect = () => {
    const {Text}= Typography;

    const options = [
        {
            value: EmailSenderDefault.Default,
            label: "Default"
        },
        {
            value: EmailSenderDefault.NotDefault,
            label: "No default"
        },
    ]

    const {filters, setFilters} = useContext(EmailSenderContext)

    return <>
        <Text>Default</Text>
        <Select className="w-100"
                placeholder="Select sender default"
                size="large"
                mode="multiple"
                defaultValue={filters?.defaults}
                onChange={(e) => setFilters(prev => ({...prev, defaults: e}))}
                options={options}
            />
    </>
}

export default EmailSenderDefaultSelect;