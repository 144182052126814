import { Button, Tooltip, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
const SendTestEmail = (props) => {
  const axiosPrivate = useAxiosPrivate();

  const sendEmail = async () => {
    const response = await axiosPrivate.post("/Template/SendTestEmail", {
      templateId: props.template,
    });

    if (response && response.data.success) {
      message.success("Nice, we send you and email.");
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <Tooltip title="Send test email">

    <Button
      onClick={sendEmail}
      shape="circle"
      icon={<BsFillRocketTakeoffFill />}
      />
      </Tooltip>
  );
};

export default SendTestEmail;
