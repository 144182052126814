import { Outlet } from "react-router-dom";
import { ThemeContext } from "../context/ThemeContext";
import { ConfigProvider } from "antd";
import { useContext } from "react";
const lighttheme = {
  token: {
    colorPrimary: "#422afb",
    colorError: "#e74a3b",
    colorLink: "#422afb",
    colorSuccess: "#1cc88a",
    colorTextBase: "#1b254b",
    colorText: "#1b254b",
    borderRadiusXS: 15,
    borderRadiusSM: 15,
    borderRadiusOuter: 15,
    borderRadiusLG: 15,
    footerBg: "white",
    colorBgContainer: "#fff",
  },
  components: {
    Pagination:{
      itemActiveBg:"#422afb",
      itemSize:42
    },
    Layout: {
      siderBg: "#fff",
      bodyBg: "#f5f8fe",
    },
    Collapse: {
      headerBg: "#fff",
    },
    Menu: {
      itemSelectedBg: "transparent",
      itemColor: "#8f9bba",
      itemSelectedColor: "#2d3748",
      itemHoverBg: "transparent",
      iconSize: 18,
      collapsedWidth: 0,
      itemMarginBlock: 0,
    },
  },
};

const darktheme = {
  token: {
    colorPrimary: "#422afb",
    colorError: "#e74a3b",
    colorLink: "#6851ff",
    colorSuccess: "#1cc88a",
    colorTextBase: "#fff",
    colorText: "#fff",
    colorBackground: "red",
    borderRadiusXS: 15,
    borderRadiusSM: 15,
    borderRadiusOuter: 15,
    borderRadiusLG: 15,
    colorBgBase: "#0e183e",
    colorBgContainer: "#111c44",
    footerBg: "#212121",
    defaultBg: "#fff",
    borderColor: "#fcfcfc",
    siderBg: "#fff",
    colorBorderSecondary: "#222e59",
    colorBgSpotlight: "#222d44",
    colorBorder: "#222e59",
  },
  components: {
    Layout: {
      siderBg: "#111c44",
      bodyBg: "#0b1437",
    },
    Menu: {},
    Button: {
      defaultBorderColor: "#374471",
    },
    Tag: {
      defaultBg: "#374471",
      defaultColor: "#fff",
    },
    Input: {},
    Collapse: {
      headerBg: "#111c44",
    },
    List: {},
    Tooltip: {},
    Select: {
      optionSelectedBg: "#f5f3ff13",
    },
    Pagination:{
      itemActiveBg:"#422afb",
      itemSize:42
    },
    Menu: {
      itemSelectedBg: "transparent",
      itemColor: "#8f9bba",
      itemSelectedColor: "#2d3748",
      itemHoverBg: "transparent",
      iconSize: 18,
      collapsedWidth: 0,
      itemMarginBlock: 0,
    },
  },
};

const Layout = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <ConfigProvider theme={theme == "dark" ? darktheme : lighttheme}>
      <main className="App">
        <Outlet />
      </main>
    </ConfigProvider>
  );
};

export default Layout;
