import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { CleanUpJobAcceptType, CleanUpJobType } from "../../shared/enums";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import CleanUpJobSettingsSelect from "./CleanUpJobSettingsSelect";

const CreateCleanUpModal = ({ open, setOpen, id, onStartJob }) => {
  const [form] = Form.useForm();

  const [step, setStep] = useState(1);

  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();
  const [currentTab, setCurrentTab] = useState(CleanUpJobType.Basic);
  const [advancedType, setAdvancedType] = useState(1);
  const [loading, setLoading] = useState(false);

  const submit = async (e) => {
    let job = {
      name: e.name,
      audienceId: id,
      jobAcceptType: e.jobAcceptType,
      jobType: currentTab,
      newSettings: advancedType == 2,
      settingsId: e.settingsId,
      settings: {
        fromEmail: e.fromEmail,
        helloName:e.helloName,
        proxyHost: e.proxyHost,
        proxyPort: e.proxyPort,
        proxyUserName: e.proxyUserName,
        proxyPassword: e.proxyPassword,
        smtpPort: e.smtpPort
      }
    };

    setLoading(true);

    const response = await axios.post('/CleanUp', job);

    if(response.data.success){
        onStartJob(e.name, response.data.id)
        setOpen(false);

    }else{
        message.error(response.data.message)
    }
    setLoading(false)
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Start cleanup for audience
        </Title>
      }
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>

          {step == 1 ? (
            <Button type="primary" onClick={() => setStep(2)} loading={loading}>
              Continue
            </Button>
          ) : (
            <Button
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              Save changes
            </Button>
          )}
        </Flex>
      }
    >
      <Space className="w-100" direction="vertical" size={24}>
        {step == 1 && (
          <Text style={{ fontSize: 16 }}>
            The audience cleanup job on TapZap is responsible for maintaining
            the quality of the email list by automatically identifying and
            removing email addresses that are no longer capable of receiving
            emails.
          </Text>
        )}

        {step == 2 && (
          <Form form={form} onFinish={submit}>
            <Space className="w-100" size={0} direction="vertical">
              <Tabs
                onChange={(e) => setCurrentTab(e)}
                items={[
                  {
                    key: CleanUpJobType.Basic,
                    label: "Basic",
                  },
                  {
                    key: CleanUpJobType.Advanced,
                    label: "Advanced",
                  },
                ]}
              />

              <Space className="w-100" size={0} direction="vertical">
                <Text>Name</Text>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input job name",
                    },
                  ]}
                >
                  <Input placeholder="Enter job name" size="large" />
                </Form.Item>
              </Space>
              <AcceptOptions />

              {currentTab == 1 ? (
                <>
                  <Text>
                    The advanced clean type allows for more control over the
                    clean up process, but only chose it if you know what you are
                    doing!
                  </Text>
                </>
              ) : (
                <Space className="w-100" direction="vertical">
                  <Tabs
                    items={[
                      {
                        key: 1,
                        label: "Use Existing",
                      },
                      {
                        key: 2,
                        label: "Create New",
                      },
                    ]}
                    onChange={(e) => setAdvancedType(e)}
                  />

                  {advancedType == 1 ? (
                    <Space direction="vertical" className="w-100" size={0}>
                      <Text>Settings</Text>
                      <CleanUpJobSettingsSelect/>
                    </Space>
                  ) : (
                    <>
                      <Row gutter={[12, 12]}>
                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>From email</Text>
                            <Form.Item name="fromEmail">
                              <Input
                                size="large"
                                placeholder="Enter from email"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>Hello name</Text>
                            <Form.Item name="helloName">
                              <Input
                                size="large"
                                placeholder="Enter hello name"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>

                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>Proxy host</Text>

                            <Form.Item name="proxyHost">
                              <Input
                                size="large"
                                placeholder="Enter proxy host"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>Proxy port</Text>

                            <Form.Item name="proxyPort">
                              <InputNumber
                                size="large"
                                placeholder="Enter from email"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>Username</Text>
                            <Form.Item name="proxyUserName">
                              <Input
                                size="large"
                                placeholder="Enter from email"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                        <Col span={12}>
                          <Space
                            className="w-100"
                            direction="vertical"
                            size={0}
                          >
                            <Text>Password</Text>

                            <Form.Item name="proxyPassword">
                              <Input
                                size="large"
                                placeholder="Enter from email"
                                allowClear
                              />
                            </Form.Item>
                          </Space>
                        </Col>
                      </Row>
                      <Space className="w-100" direction="vertical" size={0}>
                        <Text>SMTP port</Text>

                        <Form.Item name="smtpPort">
                          <InputNumber
                            size="large"
                            placeholder="Enter from email"
                            allowClear
                          />
                        </Form.Item>
                      </Space>
                    </>
                  )}
                </Space>
              )}
            </Space>
          </Form>
        )}

        {step == 1 && (
          <Text strong style={{ fontSize: 16 }}>
            * Selected options can not be changed after the job is created
          </Text>
        )}
      </Space>
    </Modal>
  );
};

const AcceptOptions = () => {
  const { Text } = Typography;

  return (
    <Space className="w-100" direction="vertical">
      <Text>Accept options</Text>
      <Form.Item
        name="jobAcceptType"
        rules={[
          {
            required: true,
            message: "Please select the accept options",
          },
        ]}
      >
        <Radio.Group className="w-100">
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Card size="small" style={{ boxShadow: "none" }}>
                <Radio value={CleanUpJobAcceptType.SafeOnly}>Safe</Radio>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" style={{ boxShadow: "none" }}>
                <Radio value={CleanUpJobAcceptType.SafeAndInvalid}>
                  Invalid
                </Radio>
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small" style={{ boxShadow: "none" }}>
                <Radio value={CleanUpJobAcceptType.SafeAndAccepntsMail}>
                  Accepts
                </Radio>
              </Card>
            </Col>
          </Row>
        </Radio.Group>
      </Form.Item>
    </Space>
  );
};
export default CreateCleanUpModal;
