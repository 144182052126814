import { useContext} from "react";
import { Select, Typography } from "antd";
import { MediaContext } from "../MediaContext";

const MediaAuthorSelect = () => {
  const { Text } = Typography;
  const {filters, setFilters} = useContext(MediaContext)

  const handleAuthorChanged = (e) => {
    setFilters(prev => ({
      ...prev,
      selectedAuthors: e
    }));
  };

  return (
    <>
      <Text>Authors</Text>
      {filters && (
        <Select
          size="large"
          maxTagCount="responsive"
          style={{ width: "100%" }}
          mode="multiple"
          allowClear
          options={filters?.authors}
          defaultValue={filters?.selectedAuthors}
          onChange={handleAuthorChanged}
        />
      )}
    </>
  );
};

export default MediaAuthorSelect;
