import { Badge, Flex, List, Modal, Radio, Spin, Tooltip, Typography, Space, message } from "antd";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { CreateCampaignContext } from "../../../../context/CreateCampaignContext";
import { RiMapPinFill } from "react-icons/ri";
import env from '../../../../env.json'
import InitilasAvatar from "../../../../shared/IntialsAvatar";
const SelectWhatsappFrom = ({ open, setOpen, handleSenderChanged }) => {
  const [senders, setSenders] = useState();
  const axios = useAxiosPrivate();
    const {Text, Title} = Typography
  const { data, setData } = useContext(CreateCampaignContext);

  useEffect(() => {
    const fetch = async () => {
      const response = await axios.get(
        "/Whatsapp/numbers?Page=1&PageSize=999999"
      );

      setSenders(response.data.data.map((c) => ({ ...c, loading: false })));
    };

    fetch();
  }, []);

  const updateSender = async (e) => {
    
    setSenders((prev) =>
      prev.map((c) => (c.id == e ? { ...c, loading: true } : c))
    );

    const response = await axios.put(`/Campaign/${data.id}/sender/${e}/whatsapp/set`);

    if(response.data.success){

        setSenders(prev => prev.map(c => c.id == e ? {
            ...c,
            selected: true
        } : {...c, selected:false}))

        setData(prev => ({...prev, whatsappPhoneNumberId: e}))
        
        handleSenderChanged(response.data);
    }else{
        message.error(response.data.message)
    }

    setSenders((prev) =>
      prev.map((c) => (c.id == e ? { ...c, loading: false } : c))
    );
  }
  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Select sender
        </Title>
      }
      footer={false}
    >
      <Radio.Group
        style={{ width: "100%" }}
        value={data.whatsappPhoneNumberId}
        disabled={!data.canEdit}
      >
        <List
          dataSource={senders}
          itemLayout="horizontal"
          style={{ height: "350px", overflowY: "auto", marginRight: "10px" }}
          renderItem={(item, index) => <List.Item actions={[
            <Spin spinning={item.loading}>

              <Radio value={item.id} onChange={(e) => updateSender(e.target.value)}/>
            </Spin>
            
          ]}>
             <List.Item.Meta
              avatar={
                <Tooltip title={item.creatorFullName}>
                  <InitilasAvatar
                    style={{ marginLeft: 20 }}
                    name={item.authorName}
                    src={`${env.fileUpload}${item.authorProfile}`}
                  />
                </Tooltip>
              }
              title={
               <Flex align="center" gap={10} justify="space-between" style={{width:"100%"}}>
                    <Space direction="vertical" size={0}>
                      <Flex align="center" justify="start" gap={5}>
                        <Title style={{ margin: 0 }} level={5}>
                         {item.id} {item.name}
                        </Title>
                        {item.default && (
                          <Badge
                            count="DEFAULT"
                            size="large"
                            style={{
                              backgroundColor: "#422afb",
                            }}
                          />
                        )}
                      </Flex>
                      <Text className="text-light">
                        {item.number}
                      </Text>
                    </Space>
                  </Flex>
              }
            />
          </List.Item>}
        ></List>
      </Radio.Group>
    </Modal>
  );
};

export default SelectWhatsappFrom;
