import { Col, Flex, List, Popover, Row, Typography } from "antd";
import { HiMiniInformationCircle } from "react-icons/hi2";
import { DateFormat } from "../../../dateformat";

const SmsMoreData = ({ details }) => {
  const { Title, Text } = Typography;
  return (
    <>
      <Title level={3}>More data</Title>

      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <List bordered dataSource={[{
                 name: "Successful deliveries",
                 description: "Number of all successful deliveries",
                 value: details.successfulDeliveries,
            },
            {
                name:"Phone number",
                description:"Phone number used to send the campaign",
                value: details.phoneNumber
            },
            {
                name:"Number region",
                description:"Region of the phone number",
                value: details.phoneNumberRegion
            },
            {
                name:"Errors",
                description: "Number of deliveries with erros",
                value: details.error
            }
        ]} 
            
            renderItem={(item) => (
                <List.Item>
                  <Flex
                    style={{ width: "100%" }}
                    align="center"
                    justify="space-between"
                  >
                    <Text strong>
                      {item.name}
                    </Text>
  
                    <Text className="icon-flex">{item.value} 

                    <Popover
                        content={<Text>{item.description}</Text>}
                      >
                        <HiMiniInformationCircle style={{ fontSize: 18 }} />
                      </Popover>

                    </Text>
                  </Flex>
                </List.Item>
              )}
            />
        </Col>

        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
        <List bordered dataSource={[
            {
                name:"First delivery",
                description:"Date of the first delivery",
                value: new Date(details.firstOpened).toLocaleDateString("en-US", DateFormat)
            },
            {
                name:"Audiences",
                description:"Audiences used to send sms",
                value: details.audiences
            },
            {
                name:"Last delivery",
                description:"Date of the last delivery",
                value: new Date(details.lastOpened).toLocaleDateString("en-US", DateFormat)
            },
            {
                name:"Processed",
                description:"Number of processed numbers",
                value: details.processed
            }
        ]} 
            
            renderItem={(item) => (
                <List.Item>
                  <Flex
                    style={{ width: "100%" }}
                    align="center"
                    justify="space-between"
                  >
                    <Text strong>
                      {item.name}
                    </Text>
  
                    <Text className="icon-flex">{item.value}
                    <Popover
                        content={<Text>{item.description}</Text>}
                      >
                        <HiMiniInformationCircle style={{ fontSize: 18 }} />
                      </Popover>
                    </Text>
                  </Flex>
                </List.Item>
              )}
            />
        </Col>
      </Row>
    </>
  );
};

export default SmsMoreData;
