import { Button, Dropdown, message } from "antd";
import { BsTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import { FaMapLocationDot } from "react-icons/fa6";
import { MdSms } from "react-icons/md";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { FaEdit } from "react-icons/fa";
const SmsSenderOptions = ({
  id,
  long,
  lat,
  onSetDefault,
  onDelete,
  status,
  showEdit,
  setCurrent
}) => {
  const axios = useAxiosPrivate();

  const remove = async () => {
    const response = await axios.delete(
      `/PhoneNumbers/numbers/${id}/deactivate`
    );
    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };

  const items = [
    {
      label: "Set as default",
      key: 1,
      icon: <MdSms />,
      onClick: () => onSetDefault(id),
    },
    {
      label:"Edit selected name",
      key: 4,
      icon:<FaEdit /> ,
      onClick: () => {
        setCurrent(id);
        showEdit(true);
      }
    },
    {
      label: "Checkout in maps",
      key: 2,
      icon: <FaMapLocationDot />,
      disabled: !long && !lat,
      onClick: () =>
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
          "_blank"
        ),
    },
    {
      label: "Deactivate current number",
      key: 3,
      danger: true,
      icon: <BsTrashFill />,
      disabled: status === 4,
      onClick: () => remove(),
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }} type="link">
        <Button
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default SmsSenderOptions;
