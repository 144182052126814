import {
  Avatar,
  Button,
  Card,
  Empty,
  Flex,
  Space,
  Tooltip,
  Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { DateFormat } from "../../../dateformat";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {
  CreateCampaignContext,
  CreateCampaignContextProvider,
} from "../../../context/CreateCampaignContext";
import { TbFlagSearch } from "react-icons/tb";

const WhatsAppTemplateCard = ({ template }) => {
  const { setCurrentStep } = useContext(CreateCampaignContext);
  const { Title, Text } = Typography;

  const naviagte = useNavigate();

  return (
    <Card>
      {template && (
        <Space size="large" style={{ width: "100%" }} direction="vertical">
          <Space style={{ width: "100%" }} size={0} direction="vertical">
            <Title
              type="link"
              style={{ margin: 0 }}
              level={4}
              onClick={() =>
                naviagte(`/Templates/Edit/Whatsapp/${template.groupId}`)
              }
            >
              {template.name}
            </Title>
            <Text className="text-light">{template.languages}</Text>
          </Space>

          <Flex align="center" justify="space-between">
            <Space>
              <Avatar 
                size="large"
                src={template.creatorPath}
                icon={<UserOutlined />}
              />
              <Text strong>{template.creatorUserName}</Text>
            </Space>

            <Text className="text-light">
              {new Date(template.createdAt).toLocaleDateString(
                "en-US",
                DateFormat
              )}
            </Text>
          </Flex>
        </Space>
      )}

      {!template && (
        <>
          <Flex align="center" justify="center" style={{ height: 400 }}>
            <div style={{ height: 160, textAlign: "center" }}>
              <Empty description="No template was selected" />

              <Button type="link" onClick={() => setCurrentStep(2)}>
                Select a template
              </Button>
            </div>
          </Flex>
        </>
      )}
    </Card>
  );
};

export default WhatsAppTemplateCard;
