import React from 'react';
import { Avatar } from 'antd';
import env from '../../../env.json'
const AvatarGroup = ({items, size}) => (
  <>

    <Avatar.Group size={size}
      maxCount={2}
     
    >
      {items?.map((c,ind) => <Avatar key={ind} size={size} src={`${env.fileUpload}${c.profileUrl}`}>
        {c.name[0]}{c.lastName[0]}
      </Avatar>)}
    </Avatar.Group>
    
  </>
);
export default AvatarGroup;