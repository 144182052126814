import { Segmented, Typography } from "antd";
import { useContext } from "react";
import { AudienceListContext } from "../AudienceListContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../shared/enums";

export const AudienceOrderDirectionFilter = () => {
    const { Text } = Typography;

    const { filters, setFilters } = useContext(AudienceListContext)

    return <>
    <div style={{ width: "100%" }}>
        <Text>Direction</Text>
        </div>
        <Segmented
        size="large"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            icon: <FaArrowUp/>,
          },
          {
            value: OrderDirection.Dsc,
            icon: <FaArrowDown />,
          },
        ]}
      />
    </>
}