import { Card, Typography, Flex, Button, Space, Skeleton } from "antd";
import { AiFillCheckCircle } from "react-icons/ai";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import { useContext } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useState } from "react";
import { IoIosRemoveCircle } from "react-icons/io";
import { responsiveArray } from "antd/es/_util/responsiveObserver";
const To = ({setAudienceCount}) => {
  const axios = useAxiosPrivate();

  const { Text, Title, Paragraph } = Typography;

  const { data, setCurrentStep } = useContext(CreateCampaignContext);
  const [loading, setLoading] = useState(false);
  const [audiences, setAudiences] = useState();
  const [sum, setSum] = useState(0);
  useEffect(async () => {
    setLoading(true);
    const response = await axios.post(`/TargetAudience/GetAudiecesDetails`, {
      ids: data.audienceIds,
    });
    setAudiences(response.data.data);
    var s = 0;
    response.data.data.forEach((d) => (s += d.totalContacts));
    setSum(s);
    setAudienceCount(s);
    setLoading(false);
  }, []);

  return (
    <Card
      title={
          <Flex align="center" justify="space-between">
            <Space>
              {data.audienceIds && data.audienceIds.length > 0 && (
                <AiFillCheckCircle style={{ fontSize: 22, color: "#1cc88a"}} />
              )}
              {(!data.audienceIds || data.audienceIds.length == 0) && (
                <IoIosRemoveCircle style={{ fontSize: 22, color: "#e74a3b" }} />
              )}
              <Title level={4} style={{ margin: 0 }}>
                To
              </Title>
            </Space>
            <Button type="link" size="large" onClick={() => setCurrentStep(1)}>
              Edit To
            </Button>
          </Flex>
      }
    >
      {loading && (<>
        <Space size="small">
          <Skeleton.Input size="small" active />
          <Title level={4} style={{ margin: 0 }}>
            total to be notified
          </Title>
        </Space>
        </>
      )}
      {!loading && (
        <>
          <Title level={4} style={{ margin: 0 }}>
            {sum} total to be notified
          </Title>
          <Text className="text-light">
            All subscribed contacts in these lists:
            {audiences && (
              <>
                {audiences.map((a, ind) => (
                    <Text strong key={ind}> {a.name}</Text>
                ))}
              </>
            )}
          </Text>
        </>
      )}
    </Card>
  );
};

export default To;
