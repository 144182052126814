import { Button, Dropdown } from "antd";
import { AiFillEdit } from "react-icons/ai";
import { BsTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
import DeleteSmsTemplate from "./DeleteSmsTemplate";

const SmsTemplateOptions = ({ id, onDelete, setCurrent, setShowUpdate }) => {
  const items = [
    {
      label: "Edit current template",
      key: 1,
      icon: <AiFillEdit />,
      onClick: () => {
        setCurrent(id);
        setShowUpdate(true);
      },
    },
    {
      label: <DeleteSmsTemplate id={id} onDelete={onDelete} />,
      danger: true,
      key: 2,
      icon: <BsTrashFill />,
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button
          className="flex-button"
          shape="circle"
          type="text"
          icon={<IoMdMore  style={{ fontSize: 20 }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default SmsTemplateOptions;
