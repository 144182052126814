import { Space, Select, Spin, Typography, Skeleton } from "antd";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useSearchParams } from "react-router-dom";

const TapZapAudience = ({
  selectedCountries,
  selectedIndustries,
  setSelectedCountries,
  setSelectedIndustries,
}) => {
  const { Text } = Typography;
  const axios = useAxiosPrivate();
  const [countries, setCountries] = useState();
  const [industries, setIndustries] = useState();

  const [loadingTotal, setLoadingTotal] = useState(false)
  const [totalImported, setTotalImported] = useState(0);

  const [loading, setLoading] = useState(false);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  useEffect(async () => {
    setLoading(true);

    const industriesResponse = await axios.get("/Industry");

    setIndustries(
      industriesResponse.data.data.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );

    const countrieResponse = await axios.get("/Address/countries");

    setCountries(
      countrieResponse.data.data.map((c) => {
        return {
          label: c.name,
          value: c.id,
        };
      })
    );

    setLoading(false);
  }, []);

  const industriesSelectProps = {
    mode: "multiple",
    style: {
      width: "100%",
    },
    onChange: (e) => setSelectedIndustries(e),
    placeholder: "Select Item...",
    maxTagCount: "responsive",
    showSearch: true,
    filterOption: filterOption,
    loading: loading,
    options: industries,
    size: "large",
  };

  const countriesSelectProps = {
    mode: "multiple",
    onChange: (e) => setSelectedCountries(e),
    style: {
      width: "100%",
    },
    size: "large",
    options: countries,
    placeholder: "Select Item...",
    maxTagCount: "responsive",
    showSearch: true,
    filterOption: filterOption,
    loading: loading,
  };

  useEffect(async () => {
    setLoadingTotal(true)
    const response = await axios.post("/Industry", {
      industryIds: selectedIndustries,
      countrieIds: selectedCountries,
    });

    setTotalImported(response.data.data);
    setLoadingTotal(false)
  }, [selectedCountries, selectedIndustries]);

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <label>Industries</label>
          <Select {...industriesSelectProps} />
        </div>

        <div>
          <label>Countries</label>
          <Select {...countriesSelectProps} />
        </div>

        {(!loadingTotal)&&
        <Text>Found {totalImported} possible contacts</Text>
        }
        {loadingTotal&&
          <Text>Found <Skeleton.Input active size="small"/> possible contacts</Text>
        }
      </Space>
    </>
  );
};

export default TapZapAudience;
