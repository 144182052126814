import { Button, Flex, Modal, Typography } from "antd";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  onDeleteConfirm,
  item,
}) => {
  const { Text, Title } = Typography;
  const handleDelete = () => {
    onDeleteConfirm(item, true);
    onClose();
  };

  const handleCancel = () => {
    onDeleteConfirm(item, false);
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onCancel={handleCancel}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Delete selected item?
        </Title>
      }
      onOk={handleDelete}

      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => handleCancel()}>Cancel</Button>
          <Button onClick={() => handleDelete()} danger>Yes, delete it</Button>
        </Flex>
      }
    >
      <Text>Deleting this item is a permanent action and cannot be undone. Once you delete this item, all associated data will be permanently removed and will not be recoverable. Please ensure that you really want to delete this item before proceeding</Text>
    </Modal>
  );
};

export default DeleteConfirmationModal;
