import env from "../../../env.json";
import CardDropDown from "./CardDropDown";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Flex, Space, Tag, Typography } from "antd";
import TemplateImage from "./TemplateImage";

const TemplateCard = ({ template, showDescription= true }) => {
  const { Title, Text } = Typography;
  return (
    <Card
      hoverable
      cover={<TemplateImage src={`${env.fileUpload}${template.templatePreviewPath}`}/>}
    >
      <Flex align="center" justify="space-between">
        <Tag>
          {template.templateCategory.name}
        </Tag>

        <CardDropDown template={template} />
      </Flex>

      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <div>
          <Title
            level={3}
          >
            {template.name}
          </Title>

            {showDescription&&<Text className="text-light">{template.description}</Text>}
        </div>

        <Flex
          align="center"
          justify="space-between"
        >
          <Space>
            <Avatar 
              src={env.fileUpload + template.userProfileImage}
              icon={<UserOutlined />}
            />
            <Text strong>
              {template.creatorFullName}
            </Text>
          </Space>

          <Text className="text-light">
            {new Date(template.createdAt).toLocaleDateString()}
          </Text>
        </Flex>
      </Space>
    </Card>
  );
};

export default TemplateCard;
