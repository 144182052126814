import {
  Card,
  Typography,
  Button,
  Space,
  Flex,
  Row,
  Col,
  Table,
  Avatar,
  Grid
} from "antd";
import emails from "../../assets/images/banner.png";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import CreateMedia from "./CreateMedia";
import { useState, useEffect, useRef, useContext } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import MediaAuthorSelect from "./Filters/MediaAuthorSelect";
import MediaOrderBy from "./Filters/MediaOrderBy";
import MediaOrderByDirection from "./Filters/MediaOrderByDirection";
import env from "../../env.json";
import { BsFillTrash3Fill } from "react-icons/bs";
import TableLoading from "../../shared/TableLoading";
import { FaRegFile } from "react-icons/fa";
import { DateFormat } from "../../dateformat";
import MediaItemDropDown from "./MediaItemDropDown";
import { FiPlus } from "react-icons/fi";
import CustomTableLocale from "../../shared/CustomTableLocale";
import { SearchContext } from "../../context/SearchContext";
import InitilasAvatar from "../../shared/IntialsAvatar";
import NoDataFound from "../../shared/NoDataFound";
import Promo from "../../shared/Promo";
import { CreatedDateFilter } from "./Filters/CreatedDateFilter";
import { CreatedFromDateFilter } from "./Filters/CreatedFromDateFilter";
import { MediaStats } from "./MediaStats";
import { MediaContext } from "./MediaContext";

const { useBreakpoint } = Grid;

const MediaList = () => {
  const { Title, Text } = Typography;
  const { debouncedValue } = useContext(SearchContext);

  const screens = useBreakpoint();

  const isMounted = useRef(true);

  const {filters, setFilters, showFilters, setShowFilters, setStats} = useContext(MediaContext)
  const [isAddOpen, setIsAddOpen] = useState(false);

  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(7);
  const [page, setPage] = useState(1);

  const [selectedDocuments, setSelectedDocuments] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const axiosPrivate = useAxiosPrivate();

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    if (item && item.length > 0) {
      await removeRangeDocuments();
      return;
    }

    await removeDocument(item);
  };

  const removeDocument = async (id) => {
    const response = await axiosPrivate.delete(`/Document/${id}`);
    if (response && response.data.data) {
      setDocuments(documents.filter((image) => image.id != id));
      setStats(prev => ({...prev, total: documents.filter(f => f.id != id).length }))
    }

  };

  const removeRangeDocuments = async () => {
    var response = await axiosPrivate.post("/Document/DeleteRange", {
      ids: selectedDocuments,
    });
    if (response && response.data.success) {
      setDocuments(documents.filter((f) => !selectedDocuments.includes(f.id)));
      setSelectedDocuments([]);
    }
  };

  const downloadDocument = async (id) => {
    const response = await axiosPrivate.get(`/Document/Download/${id}`);

    var blob = new Blob([response.data.data], { type: response.data.mimeType });
    var link = document.createElement("a");
    link.href =
      "data:" + response.data.mimeType + ";base64," + response.data.data; // window.URL.createObjectURL(blob);
    link.download = response.data.fileName;
    link.click();
  };

  const columns = [
    {
      title: "Name",
      render: (data, row, index) => (
        <Space>
          <Avatar
            icon={<FaRegFile />}
            shape="square"
            className="flex-button"
            src={
              row.mimeType?.includes("image")
                ? `${env.fileUpload}${row.path}`
                : ""
            }
          />
          <Text strong>{row.name}</Text>
        </Space>
      ),
    },
    {
      title: "Description",
      responsive: ['md'],
      render: (row, data) => <Text strong>{row.description}</Text>,
    },
    {
      title: "Date",
      responsive: ['md'],
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
    {
      title: "Author",
      responsive: ['md'],
      render: (row) => (
        <InitilasAvatar
          large
          name={row.creatorUserName}
          src={`${env.fileUpload}${row.userProfilePicture}`}
        />
      ),
    },
    {
      width: 10,
      title: "",
      render: (row) => (
        <Flex align="center" justify="end">
          <MediaItemDropDown
            id={row.id}
            name={row.path}
            onDelete={(id) => handleOpenModal(id)}
            onDownload={(id) => downloadDocument(id)}
          />
        </Flex>
      ),
    },
  ];

  const fetchdocuments = async (page, controller) => {
    setLoading(true);

    const response = await axiosPrivate.post(
      `/Document/GetAll`,
      {
        pageSize: perPage,
        page: page,
        authors: filters?.selectedAuthors,
        orderBy: filters?.orderBy,
        orderDirection: filters?.orderDirection,
        search: debouncedValue,
        from: filters?.from,
        to: filters?.to
      },
      { signal: controller.signal }
    );

    if (isMounted.current) {
      setDocuments(
        response.data.data?.map((doc) => {
          return { ...doc, key: doc.id };
        })
      );
      setStats(prev => ({...prev, total: response.data.count}));
      setLoading(false);
    }
  };

  useEffect(async () => {
    const controller = new AbortController();
    await fetchdocuments(1, controller);

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, [filters, debouncedValue]);

  useEffect(async () => {
    const controller = new AbortController();
    await fetchdocuments(page, controller);

    return () => {
      isMounted.current = false;
      controller.abort();
    };
  }, [page, perPage]);


  const onAdd = (val) => {
    if (documents?.length < perPage) {
      setDocuments([...documents, val]);
    }

    setStats(prev => ({...prev, total: prev.total + 1}));
  };
  return (
    <>
      <CreateMedia setIsOpen={setIsAddOpen} isOpen={isAddOpen} onAdd={onAdd} />
      {modalIsOpen && (
        <DeleteConfirmationModal
          isOpen={modalIsOpen}
          onClose={handleCloseModal}
          onDeleteConfirm={handleDeleteConfirm}
          item={itemToDelete}
        />
      )}

      <Space className="w-100" size={24} direction="vertical">

        <Promo
          background={emails}
          title="Media documents section"
          text="Check your campaign statistics daily, see all responses, ratings,
          clicks and reports here.
          You can edit and manage everything in the options panel."
          />

          <MediaStats/>
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex justify="space-between" align="center" wrap="wrap"
            style={{
              ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
            }}>
              <Title style={{ margin: 0, padding: 0 }} level={4}>
                Media
              </Title>
              <Flex gap={6} justify="end" align="center" wrap="wrap"
               style={{
                ...(screens.xs ? { 'width': '100%' } : {})
              }}>
                <Button
                  danger
                  size="large"
                  className="flex-button"
                  onClick={() => handleOpenModal(selectedDocuments)}
                  icon={<BsFillTrash3Fill />}
                  disabled={selectedDocuments?.length == 0}
                  block={screens.xs}
                >
                  Delete
                </Button>
                <Button
                  size="large"
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                  block={screens.xs}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  onClick={(e) => setIsAddOpen(true)}
                  block={screens.xs}
                >
                  New media
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
              <Row gutter={[24, 24]} style={{ margin: 24 }}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <MediaAuthorSelect />
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <CreatedFromDateFilter/>
                </Col>
                <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                  <CreatedDateFilter/>
                </Col>
                <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                  <MediaOrderBy />
                </Col>
                <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                  <MediaOrderByDirection />
                </Col>
              </Row>
          )}
        </Card>

          <Card className="zero-margin-padding">
            <Table
              style={{ marginTop: 1 }}
              columns={columns}
              locale={{emptyText: <NoDataFound
                onAdd={() => setIsAddOpen(true)}
                title="You have not created any media documents"
                description=" Click the New media button on the documents page to create a
                      new document"
                addText="New document"
              />}}
              pagination={{
                current: page,
                pageSize: perPage,
                total: filters?.total,
                onChange: (p, s) => {
                  setPage(p);
                  setPerPage(s);
                },
              }}
              scroll={{ x: true }}
              dataSource={documents}

              rowSelection={{
                mode: "checkbox",
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelectedDocuments(selectedRowKeys);
                },
              }}
            />
          </Card>
      </Space>
    </>
  );
};

export default MediaList;
