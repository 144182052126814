import {
  Tooltip,
  Typography,
  Skeleton,
  Space,
  Flex,
} from "antd";
import { IoMailSharp } from "react-icons/io5";
import { FaIndustry, FaMap } from "react-icons/fa";
const AudienceCardStats = ({ audience }) => {
  const { Text, Title } = Typography;
  return (
    <>
      <Space>
        <Tooltip title="Total contacts">
          <Text className="icon-flex">
            <IoMailSharp />
            {audience.totalContacts}
          </Text>
        </Tooltip>
        <Text className="bold-button">|</Text>

        <Tooltip title="Total countries">
          <Text className="icon-flex small-radius">
            <FaMap />
            {audience.totalCountries ?? 0}
          </Text>
        </Tooltip>

        <Text className="bold-button">|</Text>

        <Tooltip title="Total industries">
          <Text  className="icon-flex small-radius">
            <FaIndustry />
            {audience.totalIndustries ?? 0}
          </Text>
        </Tooltip>
      </Space>
    </>
  );
};

export default AudienceCardStats;
