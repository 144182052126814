import {
  Button,
  Card,
  Collapse,
  Divider,
  Flex,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useState } from "react";
import getTaskTypeIcon from "../../../../shared/TaskTypeIcon";
import useDealsDetailsCtx from "../../../../hooks/useDealsDetailsCtx";
import getTaskTypeText from "../../../../shared/TaskTypeText";
import { DateFormat } from "../../../../dateformat";
import { FaTrashCan } from "react-icons/fa6";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { DealActivityType } from "../../../../shared/enums";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
const CollapseHeader = ({ activity }) => {
  const { activites, setActivities, setHistory } = useDealsDetailsCtx();

  const [marking, setMarking] = useState(() => false);

  const axios = useAxiosPrivate();

  const markAsDone = async (id) => {
    setMarking(true);

    const response = await axios.post("/DealTasks/MarkTaskAsDone", {
      taskId: activity.id,
    });

    if (response.data.success) {
      const completedTask = activites.find((c) => c.id == id);

      setActivities((prev) => prev.filter((c) => c.id != id));

      let combinedDateTime = moment({
        year: completedTask.dueDate?.year(),
        month: completedTask.dueDate?.month(),
        day: completedTask.dueDate?.date(),
        hour: completedTask.dueTime?.hour(),
        minute: completedTask.dueTime?.minute(),
        second: completedTask.dueTime?.second(),
      });
      setHistory((prev) => [
        {
          id: uuidv4(),
          createdAt: new Date(),
          dealActivityType: DealActivityType.TaskCompleted,
          authorName: "",
          task: {
            id: id,
            createdAt: completedTask.createdAt,
            taskName: completedTask.name,
            taskType: completedTask.type,
            dueDate: `${combinedDateTime.toDate()}`,
            notes: completedTask.notes,
            completedDate: new Date(),
            reminder: completedTask.reminder,
            assignedUsers: completedTask.owners.map((c) => c.email),
          },
        },
        ...prev,
      ]);
    } else {
      message.error(response.data.message);
    }

    setMarking(false);
  };

  return (
    <Flex align="center" gap={"10px"} justify="space-between">
      <Flex gap={10}>
        <div style={{ marginTop: 5 }}>
          <Flex
            align="center"
            justify="center"
            style={{
              borderRadius: 20,
              width: 30,
              height: 30,
              background: "#e3e3e3",
            }}
          >
            {getTaskTypeIcon(activity.type, 20, "#1b254b")}
          </Flex>
        </div>
        <Space size={0} direction="vertical">
          <Flex align="center">
            <Typography.Title style={{ margin: 0, padding: 0 }} level={4}>
              {activity?.isFollowUp && "Follow-up: "} {activity?.name}
            </Typography.Title>
          </Flex>

          <Typography.Text>
            {activity?.dueDate ? (
              <>
                {" "}
                {new Date(activity?.dueDate)?.toLocaleDateString(
                  "en-US",
                  DateFormat
                )}
              </>
            ) : (
              <>No due date</>
            )}
          </Typography.Text>
        </Space>
      </Flex>
      <Space align="center">
        <Space size={0}>
          <Tag className="icon-flex black-color-tag" color="#e3e3e3">
            {getTaskTypeIcon(activity.type, 15)}{" "}
            {getTaskTypeText(activity.type)}
          </Tag>

          {activity?.overDue ? (
            <Tag bordered={false} className="black-color-tag" color="red">
              Overdue
            </Tag>
          ) : (
            <Tag className="black-color-tag" color="#e3e3e3">
              {activity?.dueToday ? "Due Today" : "Due later"}
            </Tag>
          )}

          <Button
            loading={marking}
            size="large"
            onClick={() => markAsDone(activity?.id)}
          >
            Mark as done
          </Button>
        </Space>
      </Space>
    </Flex>
  );
};

const CollapseBody = ({ activity, removeActivity }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;

  const handleDelete = async () => {
    setLoading(true);

    const response = await axios.delete(`/DealTasks/${activity.id}`);

    if (response.data.success) {
      removeActivity(activity.id);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const columns = [
    {
      title: "Dates",
      render: (data) => (
        <Space direction="vertical" size={0}>
          <Text strong>
            {" "}
            Due date:{" "}
            {data?.dueDate ? (
              <>
                {new Date(data.dueDate).toLocaleDateString("en-US", DateFormat)}{" "}
              </>
            ) : (
              <>-</>
            )}
          </Text>
          {data.completedDate && (
            <Text className="text-light">
              Finished date:{" "}
              {new Date(data.completedDate).toLocaleDateString(
                "en-US",
                DateFormat
              )}
            </Text>
          )}
        </Space>
      ),
    },
    {
      title: "Reminder",
      render: (data) => (
        <Text>{data.reminder ? "Remind" : "Do not remind"}</Text>
      ),
    },
    {
      title: "Notes",
      render: (data) => <Text>{data.notes}</Text>,
    },
    {
      width: 100,
      render: (text, record, index) => {
        return (
          <Popconfirm
            title="Delete this task"
            description="Are your sure you want to delete this task?"
            okText="Yes, delete it"
            onConfirm={() => handleDelete()}
          >
            <Button
              loading={loading}
              danger
              type="text"
              className="flex-button"
            >
              Delete
            </Button>
          </Popconfirm>
        );
      },
    },
  ];

  const data = [
    {
      dueDate: activity.dueDate,
      reminder: activity.reminder,
      notes: activity.notes,
      id: activity.id,
      done: activity.done,
      completedDate: activity.completedDate,
    },
  ];

  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Table columns={columns} dataSource={data} pagination={false} />
    </>
  );
};

const DealDetailsActivites = () => {
  const { Title, Text } = Typography;
  const { activites, setActivities, setHistory } = useDealsDetailsCtx();

  const handleRemoveActivity = (id) => {
    setActivities((prev) => prev.filter((c) => c.id != id));
  };

  return (
    <>
      <Title style={{ margin: 5 }} level={4}>
        Upcoming Activites
      </Title>

      <Flex vertical gap={"10px"}>
        {!activites || activites.length == 0 ? (
          <Card style={{ boxShadow: "none" }}>
            <Text className="text-light" strong>
              No activities found for this deal!
            </Text>
          </Card>
        ) : (
          <Space direction="vertical" className="w-100">
            {activites.map((activity, ind) => (
              <Collapse
                expandIconPosition="end"
                items={[
                  {
                    key: ind,
                    label: (
                      <CollapseHeader key={`ch${ind}`} activity={activity} />
                    ),
                    children: (
                      <CollapseBody
                        key={`cb${ind}`}
                        activity={activity}
                        removeActivity={handleRemoveActivity}
                      />
                    ),
                  },
                ]}
              />
            ))}
          </Space>
        )}
      </Flex>
    </>
  );
};

export default DealDetailsActivites;
