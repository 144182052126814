import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";
import { Segmented, Typography } from "antd";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../../shared/enums";

const SmsOrderDirectionFilter = () => {
    const { filters, setFilters } = useContext(SmsListContext);
  const { Text } = Typography;

  return (
    <>
       <div style={{ width: "100%" }}>
        <Text>Direction</Text>
      </div>
      <Segmented
        size="large"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            icon: <FaArrowUp />,
          },
          {
            value: OrderDirection.Dsc,
            icon: <FaArrowDown />,
          },
        ]}
      />
    </>
  );
}

export default SmsOrderDirectionFilter;