import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Segmented,
  Space,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import Api from "../Api";
import UploadCsv from "../UploadCsv";

const AddEmailContact = ({ audienceId, onAddSuccess, isOpen, setIsOpen }) => {
  const [form] = Form.useForm();
  const { Text, Title } = Typography;

  const [audienceType, setAudienceType] = useState(1);

  const axios = useAxiosPrivate();
  const [file, setFile] = useState();

  const[headers, setHeaders] =useState()
  const[hasHeader, setHasHeaders] = useState();

  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(null);
  const [url, setUrl] = useState(null);
  const [auth, setAuth] = useState(null);
  const [http, setHttp] = useState("http://");

  const upload = async () => {
    if (!file) {
      message.error("Please provide a file");
      return;
    }

    const formData = new FormData();
    formData.append("AudienceId", audienceId);
    formData.append("File", file);
    formData.append("HasHeader", hasHeader);

      let counter = 0;
      headers.forEach((header, ind) => {
        if (header.canBeSend) {
          formData.append(`csvHeaders[${counter}].name`, header.name);
          formData.append(`csvHeaders[${counter}].value`, header.value);
          formData.append(`csvHeaders[${counter}].ind`, header.ind);
          counter += 1;
        }
      });
    const response = await axios.post(
      "/TargetAudience/AddAudienceCsv",
      formData
    );

    if (response.data.success) {
      message.success("Audience added");
      onAddSuccess(response.data.data);
    } else {
      message.error(response.data.message);
    }
  };

  const submit = async (e) => {
    
    const response = await axios.post("/TargetAudience/AddEmailContact", {
      email: e.email,
      phone: e.phone,
      name: e.name,
      lastname: e.lastname,
      audienceId: audienceId,
    });

    if (response.data.success) {
      onAddSuccess(response.data.data);
      form.resetFields();
      message.success("Contact added");
    } else {
      message.error(response.data.message);
    }

  };

  const addApi = async () => {
    const response = await axios.post("/TargetAudience/AddAudienceApi", {
      id: audienceId,
      authorization: auth,
      method: method,
      url: http + url,
    });

    if (response.data.success) {
      onAddSuccess(response.data.data);
      message.success("Contacts added!");
    } else {
      message.error(response.data.message);
    }
  };

  const save = async () => {
    setLoading(true)
    if (audienceType == 1) {
      await upload();
    } else if (audienceType == 2) {
      form.submit();
    } else {
      await addApi();
    }
    setLoading(false)
  };

  return (
    <Modal
    title={
      <Title style={{ margin: 0 }} level={4}>
        Add new email contact
      </Title>
    }
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={
        <Flex align="center" justify="end" gap={6}>
          <Button onClick={() => setIsOpen(false)}>Cancel</Button>
          <Button type="primary" onClick={() => save()} loading={loading}>
            Save
          </Button>
        </Flex>
      }
    >
      <Space style={{ width: "100%" }} direction="vertical">
        <Segmented
          block
          onChange={(e) => setAudienceType(e == "csv" ? 1 : e == "api" ? 3 : 2)}
          options={[
            {
              label: (
                <div
                  style={{
                    padding: 4,
                  }}
                >
                  <Title level={5}>CSV</Title>
                  <Text>Import a csv file</Text>
                </div>
              ),
              value: "csv",
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                  }}
                >
                  <Title level={5}>SINGLE</Title>
                  <Text>Import one by one</Text>
                </div>
              ),
              value: "single",
            },
            {
              label: (
                <div
                  style={{
                    padding: 4,
                  }}
                >
                  <Title level={5}>API</Title>
                  <Text>Use an endpoint</Text>
                </div>
              ),
              value: "api",
            },
          ]}
        />

        {audienceType == 2 && (
          <Form onFinish={submit} form={form}>
            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Text>Name</Text>
                <Form.Item
                  name="name"
                  rules={[{ required: true, message: "Provide a name" }]}
                >
                  <Input placeholder="Set a name" size="large"/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Text>Lastname</Text>
                <Form.Item
                  name="lastname"
                  rules={[
                    { required: true, message: "Please provide a lastname" },
                  ]}
                >
                  <Input placeholder="Set a lastname" size="large"/>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Text>Email</Text>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please provide an email",
                    },
                  ]}
                >
                  <Input placeholder="Set an email" size="large"/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <Text>Phone</Text>
                <Form.Item name="phone">
                  <Input placeholder="Set a phone number" size="large"/>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

        {audienceType == 1 && (
          <Space style={{ width: "100%" }} direction="vertical">
            <UploadCsv file={file} setFile={setFile} headers={headers} setHeaders={setHeaders} hasHeader={hasHeader} setHasHeaders={setHasHeaders} />
          </Space>
        )}
        {audienceType == 3 && (
          <Api
            method={method}
            url={url}
            auth={auth}
            http={http}
            setMethod={setMethod}
            setUrl={setUrl}
            setAuth={setAuth}
            setHttp={setHttp}
          />
        )}
      </Space>
    </Modal>
  );
};

export default AddEmailContact;
