import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { MdDarkMode } from "react-icons/md";
import { TbSunFilled } from "react-icons/tb";

const ThemeButton = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleThemeChange = () => {
    setTheme(prev => {
        if(prev == "light"){
            return "dark"
        }
        return "light"
    })
  };
  return (
    <div
      onClick={() => handleThemeChange()}
      style={{
        position: "absolute",
        width: 45,
        height: 45,
        borderRadius: 45,
        top: "92%",
        right: "1%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background:
          "linear-gradient(189deg, rgba(99,92,193,1) 0%, rgba(66,51,238,1) 100%)",
      }}
    >
      {theme == "light" && (
        <MdDarkMode style={{ color: "white", fontSize: 22 }} />
      )}

      {theme == "dark" && (
        <TbSunFilled style={{ color: "white", fontSize: 22 }} />
      )}
    </div>
  );
};

export default ThemeButton;
