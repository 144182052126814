import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Space } from "antd";
import { SizeContextProvider } from "antd/es/config-provider/SizeContext";
import TextArea from "antd/es/input/TextArea";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import { useContext } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useForm } from "antd/es/form/Form";

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const AddNote = ({ form}) => {
  const { data, setData } = useContext(CreateCampaignContext);

  const onFinish = (values) => {
    setData({ ...data, notes: values.notes });
  };
  return (
    <Form
      form={form}
      name="dynamic_form_item"
      {...formItemLayoutWithOutLabel}
      onFinish={onFinish}
      style={{ width: "100%" }}
      values={data.notes}
    >
      <Form.List name="notes" initialValue={data.notes}>
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field, index) => (
              <Form.Item
                {...formItemLayoutWithOutLabel}
                required={false}
                key={field.key}
              >
                <Row>
                  <Col span={fields.length > 1 ? 22 : 24}>
                    <Form.Item
                      {...field}
                      style={{ padding: 0, margin: 0 }}
                      validateTrigger={["onChange", "onBlur"]}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Please enter a note or delete this field.",
                        },
                      ]}
                    >
                      <Input placeholder="Enter note" disabled={!data.canEdit}/>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                        disabled={!data.canEdit}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}
                disabled={!data.canEdit}
              >
                Add note
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  );
};
export default AddNote;
