import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import KanbanColumn from "./KanbanColumn";
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CardStatus } from "../../../shared/enums";
import { createPortal } from "react-dom";
import KanbanCard from "./KanbanCard";
import KanbanCardAdd from "./KanbanCardAdd";
import KanbanWonDealModal from "./KanbanWonDealModal";
import KanbanLostDealModal from "./KanbanLostDealModal";
import { Card, message } from "antd";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DeleteDeal from "./DeleteDeal";
import { BsTrashFill } from "react-icons/bs";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { PiNoteBlankFill } from "react-icons/pi";
import { DealListContext } from "../DealListContext";
import { SearchContext } from "../../../context/SearchContext";

const Kanban = () => {
  const {stats, setStats, filters, refreshStats} = useContext(DealListContext)
  const{ debouncedValue} = useContext(SearchContext)

  const axios = useAxiosPrivate();
  const [activeCard, setActiveCard] = useState(null);
  const [kanbanCards, setKanbanCards] = useState([]);
  const [showWonDealModal, setShowWonDealModal] = useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);
  
  const [lastDragEvent, setLastDragEvent] = useState();
  const navigate = useNavigate()
  const initialCardPositionRef = useRef({});
  const overItemsForDeltaRef = useRef({});

  function onDragStart(event) {
    const { delta, active, over } = event;
    initialCardPositionRef.current = {
      card: { ...active.data.current.card },
    };
    initialCardPositionRef.current.index = kanbanCards.indexOf(
      active.data.current?.card
    );
    setActiveCard(active.data.current?.card);
  }

  const onDragEnd = async (event) => {
    overItemsForDeltaRef.current = {};
    const { delta, active, over } = event;
    const activeCardId = active.id;
    const overCardId = over.id;
    if (!over) return;
    const isActiveAsCard = active.data.current?.type === "card";
    const isOverAsCard = over.data.current?.type === "card";
    const isOverAsColumn = over.data.current.type === "column";

    if (isOverAsCard && isActiveAsCard) {
      const movedToStatus = over.data.current.card.status;

      if (
        CardStatus.Won === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event);
        setShowWonDealModal(true);
        return;
      }
      if (
        CardStatus.Closed === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event)
        setShowLostDealModal(true);
        return;
      }

    }
    if (isActiveAsCard && isOverAsColumn) {
      const movedToStatus = over.data.current.column.id;
  
      if (
        CardStatus.Won === movedToStatus &&
        initialCardPositionRef.current.card.status != movedToStatus
      ) {
        setLastDragEvent(event)
        setShowWonDealModal(true);
        return;
      }

    }
    const response = await axios.post(`/Deal/UpdateDealPosition`,{
      id: active.data.current.card.id,
      index: active.data.current.sortable.index,
      newStage:active.data.current.card.status,
      deals: active.data.current.sortable.items
    });
    
    setStats(prev => ({...prev, 
      new: kanbanCards.filter(c => c.status == CardStatus.New).length,
      qualifying: kanbanCards.filter(c => c.status == CardStatus.Qualifying).length,
      demoScheduled: kanbanCards.filter(c => c.status == CardStatus.DemoScheduled).length,
      pendingCommitment: kanbanCards.filter(c => c.status == CardStatus.PendingCommitment).length,
      inNegotiation: kanbanCards.filter(c => c.status == CardStatus.InNegotiation).length,
      won: kanbanCards.filter(c => c.status == CardStatus.Won).length,
      lost: kanbanCards.filter(c => c.status == CardStatus.Closed).length
    }))

    if(!response.data.success)
    {
      message.error(response.data.message)
    }
  }

  const onDragOver = useCallback(
    (event) => {
      const { delta, active, over } = event;
      if (!over) return;

      const deltaKey = `${delta.x}:${delta.y}`;
      // Check if this delta has already been processed for the current over element
      if (overItemsForDeltaRef.current[deltaKey] != undefined) {
        return;
      }
      const activeCardId = active.id;
      const overCardId = over.id;
      if (activeCardId === overCardId) return;
      const isActiveAsCard = active.data.current?.type === "card";
      const isOverAsCard = over.data.current?.type === "card";
      const isOverAsColumn = over.data.current.type === "column";
      if (isActiveAsCard && isOverAsCard) {
        setKanbanCards((previousKanbanCards) => {
          const activeCardIndex = previousKanbanCards.findIndex(
            (card) => card.id === activeCardId
          );
          const overCardIndex = previousKanbanCards.findIndex(
            (card) => card.id === overCardId
          );

          if (
            previousKanbanCards[activeCardIndex].status !=
            previousKanbanCards[overCardIndex].status
          ) {
            previousKanbanCards[activeCardIndex].status =
              previousKanbanCards[overCardIndex].status;

            return arrayMove(
              kanbanCards,
              activeCardIndex,
              overCardIndex - 1 != -1 ? overCardIndex - 1 : overCardIndex
            );
          }
          return arrayMove(previousKanbanCards, activeCardIndex, overCardIndex);
        });
      }
      if (isOverAsColumn && isActiveAsCard) {
        setKanbanCards((previousKanbanCards) => {
          const activeIndex = previousKanbanCards.findIndex(
            (card) => card.id === activeCardId
          );

          previousKanbanCards[activeIndex].status = over.id;
          return arrayMove(previousKanbanCards, activeIndex, -1);
        });
      }
      overItemsForDeltaRef.current[deltaKey] = over;
    },
    [kanbanCards]
  );

  function moveCardToStatus(status) {
    setKanbanCards((prev) => {
      const previousCard = prev.find(
        (el) => el.id === initialCardPositionRef.current.card.id
      );
      const previousCardIndex = prev.indexOf(previousCard);
      initialCardPositionRef.current.index = previousCardIndex;
      prev[previousCardIndex].status = status;
      return arrayMove(prev, previousCardIndex, 0);
    });
  }

  const cardDropdownOptions = [
    {
      key: "4",
      label: "Add Note",
      onClick:(e) => {
        e.domEvent.stopPropagation()
        navigate(`/Deals/Details/${initialCardPositionRef.current.card.id}#note`)
      },
      icon:<PiNoteBlankFill/>
    },

    {
      key: "1",
      label: "Move to Won",
      onClick:(e) => {
        e.domEvent.stopPropagation()
        setShowWonDealModal(true);
        moveCardToStatus(CardStatus.Won);
      },
      icon:<FaThumbsUp/>
    },
    {
      key: "2",
      label: "Move to Lost",
      onClick:(e) => {
        e.domEvent.stopPropagation()
        setShowLostDealModal(true);
        moveCardToStatus(CardStatus.Closed);
      },
      icon:<FaThumbsDown/>
    },
    {
      key: "3",
      icon: <BsTrashFill/>,
      onClick:(e) => {e.domEvent.stopPropagation()},
      label:<DeleteDeal card={initialCardPositionRef} cards={kanbanCards} setCards={setKanbanCards} setTotalRevenues/>,
      danger: true,
    },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3,
      },
    })
  );

  useEffect(async () => {
    setKanbanCards([])
    filters?.selectedStatuses.forEach(async (s,ind) => {
      const response = await axios.post(`/Deal`,{
        page: 1,
        stage: s.value,
        pageSize:10,
        from: filters?.createdFromDate,
        to: filters?.createdToDate,
        companyIds: filters.selectedCompanies,
        productIds: filters.selectedProducts,
        name: debouncedValue
      });

      setKanbanCards(prev => [...prev, ...response.data.dealResponseDtos.map((c,ind1) => ({
        ind: ind1 + 1,
        title: c.title,
        id: c.id,
        status: c.stage,
        company: c.company,
        companyCount: c.companyCount,
        contact: c.contactCount,
        amount: c.amount,
        owners: c.owners,
        createdOn: c.createdOn,
        closeDate: c.closeDate,
        position: c.position,
        products:  c.products?.map(c => ({name: c.name, id: c.id}))
      }))])
    })
  }, [debouncedValue,filters.selectedCompanies, filters.selectedProducts, filters.selectedStatuses, filters.createdFromDate, filters.createdToDate])

  return (
    <Card  className="zero-margin-padding" style={{marginTop:24}}>
      {useMemo(() => {
        return (
          <DndContext
            onDragStart={onDragStart}
            sensors={sensors}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
            autoScroll={{
              layoutShiftCompensation: false,
              threshold: { x: 0.3, y: 0.3 },
            }}
          >
            <div className="mainKanbanContainer">
              <SortableContext
                items={filters?.selectedStatuses?.map(c => c.value)}
                strategy={horizontalListSortingStrategy}
                id="columns"
              >
                {filters.selectedStatuses.map((column) => (
                  <KanbanColumn 
                    key={column.value}
                    column={column}
                    cardDropdownOptions={cardDropdownOptions}
                    cardFromDropdownObj={initialCardPositionRef}
                    setKanbanCards={setKanbanCards}
                    cards={kanbanCards.filter(
                      (card) => card.status == column.value
                    )}
                  />
                ))}
              </SortableContext>
              {createPortal(
                <DragOverlay>
                  {activeCard && (
                    <KanbanCard card={activeCard} className={"dragging"} />
                  )}
                </DragOverlay>,
                document.body
              )}
            </div>
          </DndContext>
        );
      }, [kanbanCards, activeCard, filters.cardItems, filters.selectedStatuses])}
      
      <KanbanCardAdd setKanbanCards={setKanbanCards} />

      <KanbanWonDealModal
        open={showWonDealModal}
        setOpen={setShowWonDealModal}
        setKanbanCards={setKanbanCards}
        initialCardPositionRef={initialCardPositionRef}
        dragEvent={lastDragEvent}
      />

      <KanbanLostDealModal
        open={showLostDealModal}
        dragEvent={lastDragEvent}
        setOpen={setShowLostDealModal}
        setKanbanCards={setKanbanCards}
        initialCardPositionRef={initialCardPositionRef}
      />
    </Card>
  );
};

export default Kanban;
