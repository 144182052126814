import { Form, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";

const AssignedUsersSelect = ({
  name = "assignedUser",
  label = "Owner",
  required = true,
  setDefault,
  placeholder,
}) => {
  const { Text } = Typography;

  const axios = useAxiosPrivate();

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get("/users/GetAuthors");

    setOptions(
      response?.data?.data?.map((c) => ({
        value: c.id,
        label: {
          firstName: c.firstname,
          lastName: c.lastname,
          profile:c.profileImagePath
        }
      }))
    );

    if(setDefault){

      response.data.data.forEach(c => {
        if(c.isYou){
          setDefault(c.id)
        }
      })
    }
    setLoading(false);
  }, []);

  return (
    <>

    <Space direction="vertical" size={0} className="w-100">

      <Text>{label}</Text>
      <Form.Item name={name} rules={[{ required: required }]}>
        <Select
          loading={loading}
          mode="multiple"
          size="large"
          placeholder={placeholder}
          
          >
          {options.map((option) => {
            return (
              <Select.Option key={option.value} value={option.value}>
                <AuthorSelectListItem firstname={option.label.firstName}
                  lastname={option.label.lastName}
                  profile={option.label.profile}/>
                
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
            </Space>
    </>
  );
};

export default AssignedUsersSelect;
