import { useContext } from "react";
import { FollowupContext } from "../FollowupContext";
import { Segmented, Typography } from "antd";
import { OrderDirection } from "../../../shared/enums";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

const FollowupOrderDirectionFilter = () => {

    const {filters, setFilters} = useContext(FollowupContext)
    const {Text} = Typography

    return <>
       <div style={{ width: "100%" }}>
        <Text>Direction</Text>
      </div>
      <Segmented
        size="large"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            icon: <FaArrowUp />,
          },
          {
            value: OrderDirection.Dsc,
            icon: <FaArrowDown />,
          },
        ]}
      />
    </>
}

export default FollowupOrderDirectionFilter;