import {
  Form,
  Button,
  Flex,
  Modal,
  Row,
  Typography,
  Steps,
  Card,
  Space,
  Input,
  Col,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import SelectableTemplate from "../../../components/SelectableTemplate";

const CreateNewStep = () => {
  const { Title, Text } = Typography;
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      title: "Details",
      key: 1,
      description: "Provide details such as name and description for your step",
    },
    {
      title: "Email Templates",
      key: 2,
      description: "Assign email templates for your step",
    },
    {
      title: "Notification Templates",
      key: 3,
      description: "Assign notification templates for your step",
    },
    {
      title: "Final look",
      key: 4,
      description: "Give your step a final check",
    },
  ];
  return (
    <>
      <Row gutter={[24, 24]}>
        <Col span={5}>
          <Card>
            <Steps
              onChange={(e) => setCurrentStep(e)}
              direction="vertical"
              size="large"
              current={currentStep}
              items={steps}
            />
          </Card>
        </Col>
        <Col span={19}>
          {currentStep == 0 && (
            <Card>
              <Space direction="vertical" className="w-100" size={24}>
                <Space direction="vertical" style={{ width: "500px" }} size={0}>
                  <Text>Name</Text>
                  <Input placeholder="Enter step name" size="large" />
                </Space>

                <Space direction="vertical" className="w-100" size={0}>
                  <Text>Description</Text>
                  <TextArea
                    rows={10}
                    placeholder="Enter step description"
                    size="large"
                  />
                </Space>

                <Flex align="center" justify="end" gap={6}>
                  <Button>Cancel</Button>
                  <Button type="primary">Continue to templates</Button>
                </Flex>
              </Space>
            </Card>
          )}
          {currentStep == 1 && (
            <Card title="Select your template">
              <SelectableTemplate />
            </Card>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CreateNewStep;
