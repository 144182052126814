import { useContext } from "react";
import { CleanUpContext } from "../CleanupContext";
import { CleanUpJobStatus } from "../../../shared/enums";
import { Select, Typography } from "antd";

const CleanUpJobStatusFilter = () => {
  const { filters, setFilters } = useContext(CleanUpContext);

  const statuses = [
    {
      value: CleanUpJobStatus.Done,
      label: "Done",
    },
    {
      value: CleanUpJobStatus.Working,
      label: "Working",
    },
    {
      value: CleanUpJobStatus.Wating,
      label: "Wating",
    },
  ];

  const { Text } = Typography;
  return (
    <>
      <Text>Statuses</Text>
      <Select
        size="large"
        style={{ width: "100%" }}
        options={statuses}
        mode="multiple"
        defaultValue={filters?.statuses}
        placeholder="Select filter order by column"
        onChange={(e) => setFilters((prev) => ({ ...prev, statuses: e }))}
      />
    </>
  );
};

export default CleanUpJobStatusFilter;
