import { useParams } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailEditor } from "react-email-editor";
import {
  Button,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Space,
  Typography,
} from "antd";
import logo from '../../../assets/images/logo.png'
import TextArea from "antd/es/input/TextArea";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ThemeContext } from "../../../context/ThemeContext";

const CreateTemplate = () => {
  const { theme } = useContext(ThemeContext)
  const [form] = Form.useForm();

  const { Title, Text } = Typography;
  const { defaultId } = useParams();

  const emailEditorRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [options, setOptions] = useState();

  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleSelectedCategoryChanged = (selected) => {
    setSelectedCategory(selected);
  };

  const handleReady = async () => {
    if (loaded) {
      return;
    }
    setLoaded(true);

    if (defaultId) {
      var response = await axiosPrivate.get(`/Template/${defaultId}`);
      emailEditorRef.current.editor.loadDesign(
        JSON.parse(response.data.data.style)
      );
    }
  };

  const handleSave = async (e) => {
    setLoading(true);
    await emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Html", html);
      formData.append("Name", e.name);
      formData.append("Style", JSON.stringify(design));
      formData.append("CategoryId", e.category);
      formData.append("Description", e.description);

      var response = await axiosPrivate.post("/Template", formData);

      if (!response || !response.data.success) {
        return;
      }

      setLoading(false);
      navigate("/Templates/Yours");
    });
  };

  useEffect(async () => {
    const response = await axiosPrivate.get("/Template/GetTemplateCategories");

    setOptions(response.data.data.map((d) => ({ value: d.id, label: d.name })));
  }, []);

  const [saveOpen, setSaveOpen] = useState(false)
  const [showBottom, setShowBottom] = useState(false);
  return (
    <>
      <CreateTemplateModal options={options} open={saveOpen} form={form} setOpen={setSaveOpen} adding={loading} onAdd={handleSave} />
      <EmailEditor
        ref={emailEditorRef}
        onReady={handleReady}
        style={{ flex: 1 }}
      />

      <div style={{
        height: "60px",
        width: "100%",
        position: "fixed",
        bottom: 0,
        display: showBottom ? "block" : "none",
      }}>

        <Flex style={{
          backdropFilter: "blur(24px)",
          background: theme == "dark" ? "hsla(228, 65%, 13%, .1)" : "hsla(0,0%,100%,.1)",
          marginLeft: 5,
          height: "60px",
          marginRight: 5,
          borderTop: `1px solid #cbd5e0`
        }} align="center" justify="space-between" gap={6}>
          <Flex align="center" justify="start" gap={6}>
            <img src={logo} width={30} loading="lazy" alt="logo" />
            <Text strong>&copy; Tretek {new Date().getFullYear()}.</Text>
          </Flex>
          <Space size={6}>
            <Button size="large" onClick={() => navigate(-1)}>Cancel</Button>
            <Button size="large" type="primary" onClick={() => setSaveOpen(true)}>Save changes</Button>
          </Space>
        </Flex>
      </div>

      <Button shape="circle"
        className="flex-button"
        onClick={() => setShowBottom(prev => !prev)}
        icon={showBottom ? <FaAngleDown /> : <FaAngleUp />}
        style={{
          marginBottom: showBottom ? 45 : 6,
          position: "absolute",
          left: "50%",
          bottom: 0,
          transform: "translate(-50%)"
        }}>
      </Button>
    </>
  );
};


const CreateTemplateModal = ({ open, setOpen, adding, onAdd, form, options }) => {
  const { Title, Text } = Typography

  return <Modal footer={
    <Flex align="center" justify="end" gap={6}>
      <Button onClick={() => setOpen(false)}>Cancel</Button>
      <Button loading={adding} type="primary" onClick={() => form.submit()}> Save changes</Button>
    </Flex>
  } open={open} onCancel={() => setOpen(false)} title={<Title style={{ margin: 0 }} level={4}>
    Save template changes
  </Title>}>
    <Form onFinish={onAdd} form={form}>
      <Text>Name</Text>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "A name is required" }, {
          max: 50, message: "Name should be at most 50 characters long"
        }]}
      >
        <Input
          size="large"
          placeholder="Enter a name for your design"
          allowClear
          count={{
            show: true,
            max: 50
          }}
        />
      </Form.Item>
      <Text>Category</Text>

      <Form.Item
        name="category"
        rules={[{ required: true, message: "A category is required" }]}
      >
        <Select
          size="large"
          style={{ width: "100%" }}
          options={options}
          placeholder="Select a category for your template"
        />
      </Form.Item>

      <div>
        <Text>Description</Text>
        <Form.Item
          name="description"
          rules={[
            { max: 100, message: "Your description can have at most 100 characters" },
            { required: true, message: "A description is required" },
          ]}
        >
          <TextArea size="large" count={{
            show: true,
            max: 100
          }} placeholder="Enter a description for your template" />
        </Form.Item>
      </div>
    </Form>
  </Modal>
}

export default CreateTemplate;
