import {
  Badge,
  Flex,
  List,
  Modal,
  Radio,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import env from "../../../../env.json";
import InitialsAvatar from "../../../../shared/IntialsAvatar";
import { RiMapPinFill } from "react-icons/ri";
import { CreateCampaignContext } from "../../../../context/CreateCampaignContext";

const SelectSmsFrom = ({ open, setOpen, handleSenderChanged }) => {
  const { id } = useParams();
  const {data, setData} = useContext(CreateCampaignContext)
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [senders, setSenders] = useState();


  useEffect(async () => {
    const response = await axios.get(`/PhoneNumbers/senders/${id}`);

    setSenders(response.data.items?.map((c) => ({ ...c, loading: false })));
  }, []);

  const updateSender = async (e) => {
   
    setSenders((prev) =>
      prev.map((c) => (c.id == e ? { ...c, loading: true } : c))
    );

    const response = await axios.put(`/Campaign/${data.id}/sender/${e}/sms/set`);

    if(response.data.success){

        setSenders(prev => prev.map(c => c.id == e ? {
            ...c,
            selected: true
        } : {...c, selected:false}))

        setData(prev => ({...prev, smsSenderId: e}))
        
        handleSenderChanged(response.data);
    }else{
        message.error(response.data.message)
    }

    setSenders((prev) =>
      prev.map((c) => (c.id == e ? { ...c, loading: false } : c))
    );
  }

  return (
    <Modal
      footer={false}
      open={open}
      onCancel={() => setOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Select sender
        </Title>
      }
      onOk={() => setOpen(false)}
    >
        <Radio.Group
        style={{ width: "100%" }}
        value={data.smsSenderId}
        disabled={!data.canEdit}
      >
      <List
        dataSource={senders}
        itemLayout="horizontal"
        style={{ height: "350px", overflowY: "auto", marginRight: "10px" }}
        renderItem={(item, index) => (
          <List.Item
            actions={[
              <Spin spinning={item.loading}>

                <Radio value={item.id} onChange={(e) => updateSender(e.target.value)}/>
              </Spin>
              
            ]}
          >
            <List.Item.Meta
              avatar={
                <Tooltip title={item.creatorFullName}>
                  <InitialsAvatar
                    style={{ marginLeft: 20 }}
                    name={item.authorName}
                    src={`${env.fileUpload}${item.authorProfile}`}
                  />
                </Tooltip>
              }
              title={
               <Flex align="center" gap={10} justify="space-between" style={{width:"100%"}}>
                    <Space direction="vertical" size={0}>
                      <Flex align="center" justify="start" gap={5}>
                        <Title style={{ margin: 0 }} level={5}>
                          {item.number}
                        </Title>
                        {item.default && (
                          <Badge
                            count="DEFAULT"
                            size="large"
                            style={{
                              backgroundColor: "#422afb",
                            }}
                          />
                        )}
                      </Flex>
                      <Text className="text-light icon-flex" style={{ fontWeight: 400 }}>
                        <RiMapPinFill/> 
                        {item.region}
                      </Text>
                    </Space>
                    <img src={`https://flagsapi.com/${item.countryIso}/shiny/32.png`}/>
                  </Flex>
              }
            />
          </List.Item>
        )}
      ></List>
      </Radio.Group>
    </Modal>
  );
};

export default SelectSmsFrom;
