import { Col, Row } from "antd";
import Stat from "../../shared/Stat";
import { MdCleaningServices } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import { RiDraftFill } from "react-icons/ri";
import { useContext } from "react";
import { CleanUpContext } from "./CleanupContext";

const CleanUpStats = () => {
  const { stats } = useContext(CleanUpContext);

  return (
    <>
      <Row gutter={[24, 24]}>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            showShadow={false}
            name="Total"
            value={stats?.total}
            icon={<MdCleaningServices style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            showShadow={false}
            name="Done"
            value={stats?.done}
            icon={<FaCalendarCheck style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            showShadow={false}
            name="Wating"
            value={stats?.wating}
            icon={<BiSolidErrorCircle style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            showShadow={false}
            value={stats?.working}
            name="Working"
            icon={<RiDraftFill style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default CleanUpStats;
