import {
  Button,
  Checkbox,
  Flex,
  Form,
  Radio,
  Select,
  Space,
  Typography,
} from "antd";
import logo from "../../../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { CompanyStepsContext } from "./CompanyStepsContext";

const FifthStep = () => {
  const teamOptions = [
    {
      value: "0-1",
      label: "0-1 employee",
    },
    {
      value: "2-10",
      label: "2-10 employees",
    },
    {
      value: "11-50",
      label: "11-50 employees",
    },
    {
      value: "51-250",
      label: "51-250 employees",
    },
    {
      value: ">251",
      label: ">251 employees",
    },
  ];

  const audienceOptions = [
    {
      value: "1-500",
      label: "1-500",
    },
    {
      value: "500-1,500",
      label: "500-1,500",
    },
    {
      value: "1,500-100,000",
      label: "1,500-100,000",
    },
    {
      value: "100,000-500,000",
      label: "100,000-500,000",
    },
    {
      value: "500,000-2 million",
      label: "500,000-2 million",
    },
    {
      value: "More than 2 million",
      label: "More than 2 million",
    },
  ];

  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { setCurrentStep, organisation, setOrganisation } =
    useContext(CompanyStepsContext);

  useEffect(() => {
    form.setFieldsValue(organisation);
  }, []);

  const onSubmit = (e) => {
    setOrganisation(e);
    setCurrentStep((prev) => prev + 1);
  };

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "600px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>Tell us about your organisation</Title>
            <Text style={{ fontSize: 16 }}>
              Your answers will help us recommend the best plan to support your
              marketing efforts.
            </Text>
          </Space>

          <Form form={form} onFinish={onSubmit}>
            <Space className="w-100" direction="vertical" size={0}>
              <Text>How many people are on your team?</Text>
              <Form.Item name="noTeam">
                <Select size="large" options={teamOptions} allowClear />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>How many contacts do you need to have?</Text>
              <Form.Item name="noAudiences">
                <Select size="large" options={audienceOptions} allowClear />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>Do you sell online?</Text>

              <Form.Item name="onlineStore">
                <Radio.Group>
                  <Radio value={true}>Yes</Radio>
                  <Radio value={false}>No</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>

            <Form.Item name="recieveUpdates" valuePropName="checked">
              <Checkbox>
                I don't want tp recieve product updates, marketing tips, or
                promotial conent from Tapzap. To konw more about how we process
                personal data please read the{" "}
                <Text
                  onClick={() => navigate("/policy/terms-of-usage")}
                  type="link"
                  className="underline-link"
                >
                  Privacy Policy
                </Text>
              </Checkbox>
            </Form.Item>
          </Form>

          <Flex className="w-100" justify="end" gap={6}>
            <Button
              onClick={() => setCurrentStep((prev) => prev - 1)}
              size="large"
              type="link"
            >
              Back
            </Button>
            <Button onClick={() => form.submit()} size="large" type="primary">
              Continue
            </Button>
          </Flex>
        </Space>
      </Flex>
    </>
  );
};

export default FifthStep;
