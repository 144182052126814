import { useState, useEffect } from "react";
import { Select, Typography } from "antd";
import ThemeLabel from "../../../shared/ThemeLabel";

const UserBanFilter = ({ onChanged }) => {
  const [options, setOptions] = useState([
    {
      value: true,
      label: "Baned",
    },
    {
      value: false,
      label: "Unbaned",
    },
  ]);
  const {Text} = Typography
  
  const handleSelectedUsersChanged = (e) => {
    onChanged(e);
  };

  return (
    <>
      <ThemeLabel text="Ban status"></ThemeLabel>

      <Select
        style={{ width: "100%" }}
        options={options}
        mode="multiple"
        onChange={handleSelectedUsersChanged}
        size='large'
        defaultValue={[options[0], options[1]]}
        className="little-transparent-input"
      />
    </>
  );
};

export default UserBanFilter;
