import { Button, Card, Flex, message, Modal, Typography } from "antd";
import { Editor} from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { useEffect, useRef, useState } from "react";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { MdClose } from "react-icons/md";
import { useParams } from "react-router-dom";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
const AddNote = ({ setCurrentActiveAction, open, setOpen}) => {
  const editor = useRef(null);
  const {Title} = Typography

  const {setDealDetails} = useDealsDetailsCtx();

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const axios = useAxiosPrivate();
   const {id} = useParams();
  const [loading, setLoading] = useState(false)
  function focusEditor() {
    editor.current.focusEditor();
  }

  useEffect(() => {
    if (!editor.current) return;
    focusEditor();
  }, []);

  function onClose(e) {
    e.stopPropagation();
    setCurrentActiveAction(null);
  }

  const saveNote = async () => {
    setLoading(true);

    const contentState = editorState.getCurrentContent();
    const rawContent = draftToHtml(convertToRaw(contentState));

    const response = await axios.post('/Deal/AddNote', {
      dealId: id,
      note: rawContent
    });

    if(response.data.success){
      setDealDetails(prev => ({
        ...prev, 
        note: rawContent
      }))
      setOpen(false)
    }else{
      message.error(response.data.message);
    }
    setLoading(false)
  }

  return (
    <Modal
     open={open}
     onCancel={() => setOpen(false)}
     title={ <Title style={{ margin: 0 }} level={4}>
     Create note for deal
   </Title>}
   footer={<Flex align="center" justify="end" gap={6}>
    <Button onClick={() => setOpen(false)}>Cancel</Button>
    <Button loading={loading} onClick={() => saveNote()} type="primary">Save changes</Button>
   </Flex>}
    > 
      <Editor
        wrapperClassName="addNoteQuill"
        ref={editor}
        editorState={editorState}
        onEditorStateChange={(e) => setEditorState(e)}
        toolbar={{
          options: ["inline"],
        }}
      />
    </Modal>
  );
};

export default AddNote;
