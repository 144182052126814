import { Row } from "antd";
import CampaignSteps from "./CampaignSteps";
import { CreateCampaignContextProvider } from "../../../context/CreateCampaignContext";
import { AudienceListContext, AudienceListContextProvider } from "../../audience/AudienceListContext";
const CreateCampaign = () => {
  return (
    <CreateCampaignContextProvider>
      <AudienceListContextProvider>

        <Row gutter={12}>
          <CampaignSteps />
        </Row>
      </AudienceListContextProvider>
    </CreateCampaignContextProvider>
  );
};

export default CreateCampaign;
