import {
  Input,
  Space,
  Row,
  Col,
  Select,
  Typography,
  Flex,
  Button,
  Popover,
  Tooltip,
  Tag,
} from "antd";
import { MdCopyAll, MdSportsEsports } from "react-icons/md";
import { useState } from "react";
import { FcTreeStructure } from "react-icons/fc";
import axios from "axios";
import { TbCloudDataConnection} from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const Api = ({method, url, auth, setMethod, setUrl, setAuth, http, setHttp}) => {
  const navigate = useNavigate();

  const { Text, Paragraph, Title } = Typography;
  const { Option } = Select;
  const [totalImported, setTotalImported] = useState(0);
  const selectBefore = (
    <Select defaultValue="http://" onChange={(e) => setHttp(e)}>
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  const jsonString = `{
    "data": [
    {
        "name": "John",
        "lastname": "Doe",
        "phone": "+01 123 123",
        "email": "john@doe.com"
    }],
    "success": true,
    "message": "Response finished with success"
}`;

  const [loading, setLoading] = useState(false);
  const [httpStatus, setHttpStatus] = useState();

  const readData = () => {
    setHttpStatus();
    setLoading(true);

    const config = {
      headers: {
        Authorization: auth,
      },
    };

    if (method == "POST") {
      axios
        .post(http + url, {}, config)
        .then((response) => {
          setHttpStatus(response.status);
          
          if(response.data.success){
            setTotalImported(response?.data?.data.length)
          }
        })
        .catch((error) => {
            if (error.response) {
                setHttpStatus(error.response.status);
            }
        })
        .finally(() => setLoading(false));
    } else {
      axios
        .get(http + url, config)
        .then((response) => {
          setHttpStatus(response.status);
          
          if(response.data.success){
            setTotalImported(response?.data?.data.length)
          }
        })
        .catch((error) => {
          if (error.response) {
            setHttpStatus(error.response.status);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div>
          <label>Authorization</label>
          <Input size="large"
            placeholder="Enter authroization method and token"
            onChange={(e) => setAuth(e.target.value)}
          />
        </div>

        <Row gutter={12}>
          <Col span={6}>
            <label>Method</label>
            <Select
              onChange={(e) => setMethod(e)}
              style={{ width: "100%" }}
              size="large"
              options={[
                { value: "GET", label: "GET" },
                { value: "POST", label: "POST" },
              ]}
            />
          </Col>
          <Col span={18}>
            <label>Url</label>
            <Input 
              size="large"
              addonBefore={selectBefore}
              placeholder="mysite.com/api/getusers"
              onChange={(e) => setUrl(e.target.value)}
            />
          </Col>
        </Row>

        <Flex align="center" justify="space-between">
          <Text>Found {totalImported} possible contacts</Text>

          <Space>
            {httpStatus && (<Tag color={httpStatus == 200 ? "success" : "error"}>HTTP {httpStatus}</Tag>)}

            <Tooltip title="Test connection">
              <Button
                type="link"
                icon={<TbCloudDataConnection />}
                loading={loading}
                onClick={readData}
              />
            </Tooltip>

            <Popover
              width={100}
              trigger="click"
              content={
                <>
                  <Space direction="vertical">
                    <Title level={5}>TapZap subscriber template</Title>

                    <Paragraph style={{ whiteSpace: "pre-wrap" }} code copyable>
                      {jsonString}
                    </Paragraph>
                  </Space>
                </>
              }
            >
              <Tooltip title="Check response structure">
                <Button type="link" icon={<FcTreeStructure />} />
              </Tooltip>
            </Popover>
          </Space>
        </Flex>
      </Space>
    </>
  );
};

export default Api;
