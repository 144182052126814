import { useState } from "react";
import { DatePicker, Typography } from "antd";
const TemplateDateFilter = ({dateFilter, dateChanged}) => {
  const {RangePicker}  = DatePicker
  const {Text} = Typography
  return (
    <>
    <Text>Date filter</Text>
    <RangePicker size="large" style={{width:"100%", borderRadius:15}} onChange={(e) => {
      dateChanged(e)
    }}/>
    </>
  );
};

export default TemplateDateFilter;
