import { createContext, useContext, useEffect, useState } from "react";
import { OrderDirection } from "../../../shared/enums";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";
import { SearchContext } from "../../../context/SearchContext";

export const SmsListContext = createContext({
    stats: {
        total: 0,
        first:null,
        last: null,
        authorName:"",
        authorProfile:""
    },
    setStats: () => {},

    filters: {
        authors: [],
        selectedAuthors:[],

        from: null,
        to:null,

        orderBy: null,
        orderDirection: OrderDirection.Dsc
    },
    setFilter:() => {},

    loading: false,
    setLoading: () => {},

    page: 1,
    setPage: () => {},

    templates: [],
    setTemplates: () => {}
})

export const SmsListContextProvider = ({children}) => {
    const {debouncedValue} = useContext(SearchContext)
    const [stats, setStats] = useState()
    const [filters, setFilters] = useState()
    const [loading, setLoading] = useState(() => false);

    const [templates, setTemplates] = useState()

    const [page, setPage] = useState(1);

    const axios = useAxiosPrivate();
    const perPage = 7;

    useEffect(async() => {
        const response = await axios.get(`Users/GetAuthors`);

        
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            from: null,
            to: null,
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));
    }, [])

    useEffect(async () => {
        setLoading(true);

    const response = await axios.post(`/SmsTemplate/List`, {
        page:page,
        pageSize:perPage,

        search: debouncedValue,
        authors: filters?.selectedAuthors,

        from: filters?.from,
        to: filters?.to,

        orderBy: filters?.orderBy,
        sortDirection: filters?.orderDirection
    });

    setTemplates(
      response.data.items.map((c) => ({
        name: c.name,
        author: c.authorName,
        createdAt: c.createdAt,
        profile: c.authorProfile,
        text: c.text,
        id: c.id,
      }))
    );

    setStats(prev => ({...prev, 
        total: response.data.total,
        first: response.data.first,
        last: response.data.last,
        authorName: response.data.authorName,
        authorProfile: response.data.authorImage
    }));
    setLoading(false);
    }, [page, filters, debouncedValue])

    return <SmsListContext.Provider value={{
        stats,
        setStats,

        filters,
        setFilters,

        loading,
        setLoading,
        
        page,
        setPage,

        templates,
        setTemplates
    }}>
        {children}
    </SmsListContext.Provider>
}