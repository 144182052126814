import { Button, Flex, Layout, Space, Typography } from "antd"
import { Outlet } from "react-router-dom"
import logo from '../assets/images/logo.png'
import { IoMenu } from "react-icons/io5";
import SideMenu from "./Navigation/SideMenu";
import useLocalStorage from "../hooks/useLocalStorage";
import { useContext, useState } from "react";
import UserProfileMenu from "./Navigation/Header/UserProfileMenu";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ThemeContext } from "../context/ThemeContext";

const FullHeightApplicationLayout = () => {
    const { Text, Title } = Typography
    const { theme } = useContext(ThemeContext)

    const [collapseValue, setCollapseValue] = useLocalStorage("collapse", true);
    const [collapsed, setCollapsed] = useState(() => collapseValue);

    const updateCollapse = (e) => {
        setCollapseValue(e);
        setCollapsed(e);
    };

    const [showHeader, setShowHeader] = useState(false)

    return <>
        <Layout style={{ height: "100vh", width: "100%" }}>
            <SideMenu collapsed={collapsed} setCollapsed={updateCollapse} />

            <div style={{ height: "100vh", display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{
                    width:"100%",
                    height: "70px",
                    marginTop: showHeader ? 10 : -70,
                    position: 'fixed',
                }}>

                <Flex
                    align="center"

                    justify="space-between"
                    gap={6}
                    style={{
                        border: `1px solid #cbd5e0`,
                        marginLeft: 5,
                        padding: "0.5rem 0px",
                        maxWidth:"100%",
                        width:"99%",
                        minWidth:"80px",
                        borderRadius: "10px",
                        marginLeft: "10px",
                        marginRight: 10,
                        backdropFilter: "blur(24px)",
                        background: theme == "dark" ? "hsla(228, 65%, 13%, .1)" : "hsla(0,0%,100%,.1)",
                    }}
                >

                    <Space>
                        <Button
                            style={{
                                fontSize: 22,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            shape="circle"
                            size="large"
                            type="text"
                            onClick={() => updateCollapse(!collapsed)}
                            icon={<IoMenu />}
                        />

                        <Flex vertical align="start" justify="center" gap={0}>
                            <Text style={{ padding: 0, margin: 0 }}>
                                Designs / Email / Create
                            </Text>
                            <Title style={{ padding: 0, margin: 0, marginTop: "-10px" }}>
                                Email Desings
                            </Title>
                        </Flex>
                    </Space>
                    <div style={{marginRight:6}}>
                        <UserProfileMenu showSearch={true} />
                    </div>
                </Flex>
                </div>

                <Button onClick={() => setShowHeader(prev => !prev)} shape="circle" className="flex-button" icon={showHeader ? <FaAngleUp /> : <FaAngleDown />} style={{ marginTop: showHeader ? 68 : 6, position: "absolute", left: "50%", transform: "translate(-50%)" }}>
                </Button>
                <Outlet />
            </div>
        </Layout>

    </>
}
export default FullHeightApplicationLayout