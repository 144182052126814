import { Button, Drawer, Flex, Form, message } from "antd";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useState } from "react";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import { AiFillSave } from "react-icons/ai";
import ContactsMultiselect from "../../../FormItems/ContactsMultiselect";
import ProductsMultiSelect from "../../../FormItems/ProductsMultiSelect";
import { useParams } from "react-router-dom";

const AddProductDrawer = ({ open, setOpen }) => {
  
  const axios = useAxiosPrivate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const { products, setProducts } = useDealsDetailsCtx();

  function onClose() {
    setOpen(false);
  }

  const onSubmit = async (e) => {
    setLoading(true);
    const response = await axios.put("/Deal/Products", {
      dealId: id,
      productIds: e.products,
    });

    if (response.data.success) {
      setOpen(false);
      setProducts(
        response.data.products?.map((c) => ({
          name: c.name,
          id: c.id,
          createdAt: c.createdAt,
          creatorName: c.creatorName,
          creatorProfilePicture: c.creatorProfilePicture,
        }))
      );
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <Drawer
      open={open}
      destroyOnClose
      onClose={onClose}
      title="Edit Contacts"
    >
      <Form
        initialValues={{products: products?.map((el) => el.id)}}
        layout="vertical"
        preserve={false}
        onFinish={onSubmit}
      >
        <ProductsMultiSelect />
        <Flex justify="end" gap={10} style={{ marginTop: "auto" }}>
          <Button type="text" onClick={onClose}>
            Close
          </Button>
          <Button
            icon={<AiFillSave />}
            className="flex-button"
            htmlType="submit"
            type="primary"
            loading={loading}
          >
            Save changes
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddProductDrawer;
