import { Flex, Typography } from "antd";
import logo from "../../../assets/images/logo.png";
import { Footer } from "antd/es/layout/layout";
const AppFooter = () => {
  const { Text } = Typography;

  return (
    <Footer>
      <Flex align="center" justify="space-between">
        <img src={logo} width={30} loading="lazy" alt="logo" />
        <Text>&copy; Tretek {new Date().getFullYear()}.</Text>
        <div></div>
      </Flex>
    </Footer>
  );
};

export default AppFooter;
