import React, { useContext } from 'react'
import WonDealModal from '../WonDealModal'
import { useParams } from 'react-router-dom'
import useDealsDetailsCtx from '../../../hooks/useDealsDetailsCtx'
import { CardStatus } from '../../../shared/enums'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { message } from 'antd'
import { DealListContext } from '../DealListContext'

const DealDetaisWonModal = ({open,setOpen}) => {

  const axios = useAxiosPrivate();

    const {id} = useParams()

    const {setDealStatus} = useDealsDetailsCtx()
    const {refreshStats} = useContext(DealListContext);

    const changeDealStatus = async (e) => {
     
      const response = await axios.post('/Deal/UpdateDealStatus', {
        id: id,
        newStage: CardStatus.Won,
        closeDate: e.closeDate,
        totalRevenue: e.revenue,
        reason: e.closedReason
      });

      if(response.data.success){
        setDealStatus(prev=>({...prev,status:CardStatus.Won}))
        setOpen(false)
        await refreshStats();
      }else{
        message.error(response.data.message)
      }
    }

    function onClose() {
        setOpen(false)
    }

  return (
    <WonDealModal onSubmit={changeDealStatus} open={open} onClose={onClose}/>
  )
}

export default DealDetaisWonModal