import { Card, Col, Flex, Row, Space, Spin, Statistic, Typography } from "antd";
import { TbBounceLeftFilled } from "react-icons/tb";
import { MdMarkEmailRead, MdOutlineCallMade } from "react-icons/md";
import CountUp from "react-countup";
import { IoMdInformationCircle } from "react-icons/io";
import ContactReachedPerMonthChart from "../../home/Charts/ContactReachedPerMonthChart";
import { BiSolidErrorAlt } from "react-icons/bi";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import ContactReachedPerDayChart from "./ContactsReachedPerDayChart";

const formatter = (value) => <CountUp end={value} separator="," />;
const EmailStats = ({ id }) => {
  const [data, setData] = useState(() => ({
    processed: 0,
    processedLoading: true,
    open: 0,
    openLoading: true,
    click: 0,
    clickLoading: true,
    delivered: 0,
    deliveredLoading: true,
    bounce: 0,
    bounceLoading: true,
    reports: 0,
    reportsLoading: true,
  }));

  const { Title, Text } = Typography;
  const axions = useAxiosPrivate();

  useEffect(async () => {
    const statuses = [
      "processed",
      "open",
      "click",
      "delivered",
      "bounce",
      "reports",
    ];

    for (const status of statuses) {
      const response = await axions.get(
        `/Campaign/GetStats?id=${id}&status=${status}`
      );

      if (status == statuses[0]) {
        setData((prev) => ({
          ...prev,
          processed: response.data.data,
          processedLoading: false,
        }));
      } else if (status == statuses[1]) {
        setData((prev) => ({
          ...prev,
          open: response.data.data,
          openLoading: false,
        }));
      } else if (status == statuses[2]) {
        setData((prev) => ({
          ...prev,
          click: response.data.data,
          clickLoading: false,
        }));
      } else if (status == statuses[3]) {
        setData((prev) => ({
          ...prev,
          delivered: response.data.data,
          deliveredLoading: false,
        }));
      } else if (status == statuses[4]) {
        setData((prev) => ({
          ...prev,
          bounce: response.data.data,
          bounceLoading: false,
        }));
      } else {
        setData((prev) => ({
          ...prev,
          reports: response.data.data,
          reportsLoading: false,
        }));
      }
    }
  }, []);

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Row gutter={12}>
          <Stat
            value={data?.processed}
            name="Requests"
            color="#0079FF"
            description=""
            loading={data?.processedLoading}
            icon={<MdOutlineCallMade style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            value={data?.delivered}
            name="Delivered"
            color="#00DFA2"
            description=""
            loading={data.deliveredLoading}
            icon={<MdMarkEmailRead style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            value={data?.open}
            name="Opened"
            loading={data.openLoading}
            color="#f0a100"
            description=""
            icon={<IoMdInformationCircle style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            value={data?.click}
            name="Clicked"
            loading={data.clickLoading}
            color="#FF0060"
            description=""
            icon={<IoMdInformationCircle style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            value={data?.bounce}
            name="Bounced"
            color="#4F200D"
            loading={data.bounceLoading}
            description=""
            icon={<TbBounceLeftFilled style={{fontSize:20, color:"#422afb"}}/>}
          />
          <Stat
            value={data?.reports}
            name="Reports"
            color="#FE6244"
            loading={data.reportsLoading}
            description=""
            icon={<BiSolidErrorAlt style={{fontSize:20, color:"#422afb"}}/>}
          />
        </Row>

        <ContactReachedPerDayChart id={id} />
      </Space>
    </>
  );
};

const Stat = ({ value, name, color, description, icon, loading }) => {
  const { Text } = Typography;
  const {theme} = useContext(ThemeContext) 
  return (
    <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
      <Card className="shadowless" size="small" style={{ marginTop: 12, boxShadow:"none" }}>
        <Spin spinning={loading}>
          <Flex align="center" justify="start" gap={6}>
            <Flex align="center" justify="center" style={{
                width: "50px",
                height: "50px",
                background: theme == "light" ? "#f4f7fe" : "#1b254b",
                borderRadius: "50px",
              }}>
              {icon}
            </Flex>

            <Space direction="vertical" size={0}>
              <Text className="text-light">{name}</Text>
              <Statistic
                formatter={formatter}
                style={{ marginTop: "-5px" }}
                value={value}
                precision={0}
                valueStyle={{ fontWeight: 600 }}
              />
            </Space>
          </Flex>
        </Spin>
      </Card>
    </Col>
  );
};
export default EmailStats;
