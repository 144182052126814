import { Flex, Form, Select, Space, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { CardStatus } from "../../../shared/enums";
import { FaFolderOpen, FaTrophy } from "react-icons/fa";
import { MdOutlineEqualizer } from "react-icons/md";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoMdCloseCircle } from "react-icons/io";

const DealSelect = () => {
  const { Text } = Typography;

  const [loading, setLoading] = useState(false);

  const [deals, setDeals] = useState();

  const axios = useAxiosPrivate();

  useEffect(async () => {
    setLoading(true);
    const response = await axios.get("/Deal/simplified");

    setDeals(
      response.data.items.map((c) => ({
        label: (
          <Flex align="center" justify="start" gap={2}>
            {c.stage == CardStatus.New ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <FaFolderOpen style={{ fontSize: 16 }} />
                  Open
                </Flex>
              </Tag>
            ) : c.stage == CardStatus.Qualifying ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <MdOutlineEqualizer style={{ fontSize: 16 }} />
                  Qualifying
                </Flex>
              </Tag>
            ) : c.stage == CardStatus.DemoScheduled ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <AiFillSchedule style={{ fontSize: 16 }} />
                  Demo Scheduled
                </Flex>
              </Tag>
            ) : c.stage == CardStatus.PendingCommitment ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <RiGitCommitFill style={{ fontSize: 16 }} />
                  Pending Commitment
                </Flex>
              </Tag>
            ) : c.stage == CardStatus.InNegotiation ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <FaHandshakeSimple style={{ fontSize: 16 }} />
                  In Negotiation
                </Flex>
              </Tag>
            ) : c.stage == CardStatus.Won ? (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <FaTrophy style={{ fontSize: 16 }} />
                  Won
                </Flex>
              </Tag>
            ) : (
              <Tag size="large">
                <Flex align="center" justify="center" gap={3}>
                  <IoMdCloseCircle style={{ fontSize: 16 }} />
                  Closed
                </Flex>
              </Tag>
            )}
            {c.title}
          </Flex>
        ),
        value: c.id,
      }))
    );
    setLoading(false);
  }, []);

  return (
    <>
      <Space direction="vertical" size={0} className="w-100">
        <Text>Deal</Text>
        <Form.Item
          name="dealId"
          rules={[
            {
              required: true,
              message: "Please select a deal",
            },
          ]}
        >
          <Select
            className="w-100"
            options={deals}
            loading={loading}
            size="large"
            placeholder="Select task deal"
          />
        </Form.Item>
      </Space>
    </>
  );
};

export default DealSelect;
