import { useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import Card from "antd/es/card/Card";
import {
  Avatar,
  Space,
  Table,
  Typography,
} from "antd";
import TableLoading from "../../shared/TableLoading";
import useDebounce from "../../hooks/useDebounce";
import CampaignOptions from "./CampaignOptions";
import InitilasAvatar from "../../shared/IntialsAvatar";
import env from "../../env.json";
import NoDataFound from "../../shared/NoDataFound";
import { CampaignContext } from "./CampaignContext";

const ListCampaigns = () => {
  const {campaigns, stats, setCampaings, page, setPage, campaignsLoading, setStats} = useContext(CampaignContext)

  const { Title, Text, Link } = Typography;
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [deleteConfirmed, setDeleteConfirmed] = useState(false);

  const handleOpenModal = (item) => {
    setModalIsOpen(true);
    setItemToDelete(item);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async (item, isConfirmed) => {
    setItemToDelete(null);
    setDeleteConfirmed(isConfirmed);

    if (!isConfirmed) {
      return;
    }

    await handleDeleteCampaing(item);
  };

  

  const campaingColumns = [
    {
      width: 40,
      render: (data, row) => (
        <Avatar
          size="small"
          style={{ background: `${row.color}`, border: "none" }}
        />
      ),
    },
    {
      title: "Name",
      render: (data, row, index) => (
        <Text
          type="link"
          strong
          style={{ fontWeight: "bold", textDecoration: "underline" }}
          onClick={() => {
            if (row.campaignStaus == 2) {
              navigate(`/Campaigns/Details/${row.id}`);
            } else {
              navigate(`/Campaigns/Edit/${row.id}/${row.campaignType}`);
            }
          }}
        >
          {row.name}
        </Text>
      ),
    },
    {
      title: "Audience",
      responsive: ['md'],
      render: (data, row) => {
        return (
          <>
            {row.targetAudience?.length == 0 && "-"}
            {row.targetAudience?.length > 0 && (
              <Text
                strong
                type="link"
                style={{ textDecoration: "underline" }}
                onClick={() =>
                  navigate(`/audience/details/${row.targetAudience[0].id}`)
                }
              >
                {row.targetAudience?.length <= 1
                  ? row.targetAudience[0].name
                  : `${row.targetAudience[0].name}, `}
              </Text>
            )}
            {row.targetAudience?.length > 1 ? (
              <Text type="link" strong>
                +{row.targetAudience?.length - 1} more...
              </Text>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "Dates & Reach",
      responsive: ['sm'],
      render: (data, row) => {
        return (
          <Space>
            <Text strong>
              {row.startNow ? (
                "On approve"
              ) : row.dateStarted && row.dateEnded ? (
                <>
                  {new Date(row.dateStarted).toLocaleDateString()} -{" "}
                  {new Date(row.dateEnded).toLocaleDateString()}
                </>
              ) : row.dateStarted ? (
                <>{new Date(row.dateStarted).toLocaleDateString()}</>
              ) : row.dateEnded ? (
                <>{new Date(row.dateEnded).toLocaleDateString()}</>
              ) : (
                ""
              )}
            </Text>
            <Text strong>|</Text>
            <Text strong>{row.totalReached}</Text>
          </Space>
        );
      },
    },
    {
      title: "Date",
      responsive: ['md'],
      render: (data, row) => (
        <Space>
          <Text strong>{new Date(row.createdAt).toLocaleDateString()}</Text>
          {row.updatedAt ? (
            <>
              <Text strong>-</Text>
              <Text strong>{new Date(row.updatedAt).toLocaleDateString()}</Text>
            </>
          ) : (
            ""
          )}
        </Space>
      ),
    },
    {
      title: "Author",
      responsive: ['md'],
      render: (row) => (
        <InitilasAvatar
          large
          name={row.creatorFullName}
          src={`${env.fileUpload}${row.userProfileImage}`}
        />
      ),
    },
    {
      width: 10,
      render: (data, row) => (
        <CampaignOptions
          campaign={row}
          onDelete={(id) => handleOpenModal(id)}
        />
      ),
    },
  ];

  const handleDeleteCampaing = async (id) => {
    var response = await axiosPrivate.delete(`/Campaign/${id}`);

    if (response.data && response.data.success) {
      setCampaings(campaigns.filter((c) => c.id != id));
      setStats((prev) => ({...prev, all: prev.all - 1}));
    }
  };

  const [addOpen, setAddOpen] = useState(false);

  return (
    <>
      <DeleteConfirmationModal
        isOpen={modalIsOpen}
        onClose={handleCloseModal}
        onDeleteConfirm={handleDeleteConfirm}
        item={itemToDelete}
      />
       
        <Card className="zero-margin-padding">
          {campaignsLoading && <TableLoading />}
          {!campaignsLoading && (
            <Table
            style={{ marginTop: 1 }}
              columns={campaingColumns}
              dataSource={campaigns}
              pagination={{
                current: page,
                pageSize: 7,
                total: stats?.all,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
              locale={{emptyText: <NoDataFound
                addText="New campaign"
                onAdd={() => setAddOpen(true)}
                description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
                title="You have not created any email campaigns yet"
              />}}
              />
            )}
        </Card>  
    </>
  );
};

export default ListCampaigns;
