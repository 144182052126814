import { Col, Row } from "antd";
import EmailSenderAuthorSelect from "./Filters/EmailSenderAuthorSelect";
import EmailSenderConfirmationSelect from "./Filters/EmailSenderConfirmationSelect";
import EmailSenderCreatedDateFromSelect from "./Filters/EmailSenderCreatedDateFromSelect";
import EmailSenderCreatedDateToFilter from "./Filters/EmailSenderCreatedDateToFilter";
import EmailSenderDefaultSelect from "./Filters/EmailSenderDefaultSelect";
import EmailSenderOrderBySelect from "./Filters/EmailSenderOrderBySelect";
import EmailSenderOrderDirectionSelect from "./Filters/EmailSenderOrderDirectionSelect";

const SenderFilters = () => {
  return (
    <>
      <Row gutter={[24, 24]} style={{ margin: "24px" }}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <EmailSenderAuthorSelect />
        </Col>
        <Col xs={24} sm={24} md={7} lg={7} xl={7} xxl={7}>
          <EmailSenderConfirmationSelect />
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
        <EmailSenderDefaultSelect />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
        <EmailSenderCreatedDateFromSelect />
        </Col>
        <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
        <EmailSenderCreatedDateToFilter />
        </Col>

        <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>   
            <EmailSenderOrderBySelect />
        </Col>
        <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
            <EmailSenderOrderDirectionSelect />
        </Col>
      </Row>
    </>
  );
};

export default SenderFilters;
