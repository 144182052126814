import { Card, Empty, Flex, Space, Table, Typography } from "antd";
import { useContext, useEffect, useState } from "react";
import SetDefaultSender from "./SetDefaultSender";
import TableLoading from "../../../shared/TableLoading";
import { DateFormat } from "../../../dateformat";
import { FaCheck } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { EmailSenderContext } from "./EmailSenderContext";
import NoDataFound from "../../../shared/NoDataFound";

const SenderTable = () => {

  const {loading, senders, setSenders, page, setPage, stats, setStats} = useContext(EmailSenderContext)

  const { Text } = Typography;

  const handleDefaultChanged = (id) => {
    setSenders((prev) =>
      prev.map((d) =>
        d.id == id ? { ...d, default: true } : { ...d, default: false }
      )
    );
  };

  const columns = [
    {
      title: "Name",
      render: (data, row) => (
        <Flex align="center" justify="start" gap={6}>
          {!row.confirmed && (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IoClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
          {row.confirmed && (
            <div
              style={{
                width: 20,
                height: 20,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}
            <Text strong>{row.nickName}</Text>
        </Flex>
        
      ),
    },
    {
      title: "Email",
      responsive:['sm'],
      render: (row, data) => <>
      <Text strong>{row.email}</Text>
      </>
    },
    {
      title: "Nickname",
      responsive:['md'],
      render: (data, row) => <Text strong>{row.fullName}</Text>,
    },
    {
      title: "Default",
      responsive:['md'],
      render: (data, row) => (
        <Flex align="center" justify="center">
          <Text strong style={{ textAlign: "center" }}>
            {row.default ? "Default" : "-"}
          </Text>
        </Flex>
      ),
    },
    {
      title: "Date",
      responsive:['md'],
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-Us", DateFormat)}
        </Text>
      ),
    },
    {
      width: 60,
      render: (data, row) => (
        <SetDefaultSender
          confirmed={row.confirmed}
          handleDeleted={handleDeleted}
          disabled={row.default}
          id={row.id}
          handleDefaultChanged={handleDefaultChanged}
        />
      ),
    },
  ];

  const handleDeleted = (id) => {
    
    setStats(prev => ({...prev, total: prev.total - 1}));

    if (senders.length - 1 == 0 && page > 1) {
      setPage(page - 1);
    } else {
      setSenders(prev => prev.filter((c) => c.id != id));
    }
  };

  return (
    <>
      <Card className="zero-margin-padding">
        {loading ? (
          <TableLoading />
        ) : (
          <Table
            dataSource={senders}
            columns={columns}
            style={{ marginTop: 1 }}
            locale={{emptyText: <NoDataFound
              addText="New sender"
              description="Click the New Sender button on the sender/email page to create a new email sender"
              title="You have not created any email senders yet"
            />}}
            pagination={{
              current: page,
              pageSize: 7,
              total: stats?.total,
              onChange: (p, pageSize) => {
                setPage(p);
              },
            }}
          />
        )}
      </Card>
    </>
  );
};

export default SenderTable;
