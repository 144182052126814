import {
  Button,
  Card,
  Col,
  Flex,
  Grid,
  Progress,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import CleanUpStats from "./CleanUpStats";
import { useContext, useState } from "react";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import CleanUpJobAuthorFilter from "./Filters/CleanUpJobAuthorFilter";
import CleanUpJobFromDateFilter from "./Filters/CleanUpJobFromDateFilter";
import CleanUpJobToDateFilter from "./Filters/CleanUpJobToDateFilter";
import CleanUpJobOrderByFilter from "./Filters/CleanUpJobOrderByFilter";
import CleanUpJobOrderDirectionFilter from "./Filters/CleanUpJobOrderDirectionFilter";
import CleanUpJobStatusFilter from "./Filters/CleanUpJobStatusFilter";
import { CleanUpContext } from "./CleanupContext";
import TableLoading from "../../shared/TableLoading";
import NoDataFound from "../../shared/NoDataFound";
import { useNavigate } from "react-router-dom";
import CleanUpListOptions from "./CleanUpListOptions";
import InitilasAvatar from "../../shared/IntialsAvatar";
import env from "../../env.json";
import { DateFormat } from "../../dateformat";

const { useBreakpoint } = Grid;

const CleanUpList = () => {
  const screens = useBreakpoint();
  const navigation = useNavigate();

  const { page, setPage, stats, setStats, loading, setLoading, jobs, setJobs } =
    useContext(CleanUpContext);

  const [showFilters, setShowFilters] = useState(false);

  const { Title, Text } = Typography;

  const navigate = useNavigate();

  const jobColumns = [
    {
      title: "Name",
      render: (row) => (
        <Text type="link" strong onClick={() => navigate(`/Jobs/${row.id}`)}>
          {row.name}
        </Text>
      ),
    },
    {
      title: "Safe",
      render: (row) => <Text strong>{row.safe == 0 ? '-' : row.safe}</Text>,
    },
    {
      title: "Audience",
      render: (row) => (
        <Text
          onClick={() => navigate(`/audience/details/${row.targetAudienceId}`)}
          type="link"
          strong
        >
          {row.audienceName}
        </Text>
      ),
    },
    {
        title:"Created", 
        render: (row) => <Text strong>{new Date(row.created).toLocaleDateString("en-US", DateFormat)}</Text>
    },
     {
      title: "Handled",
      width:300,
      render: (row) => (
        <Flex align="center" justify="start" gap={6}>
          <Progress
            percent={row.total > 0 ? (row.handled / row.total) * 100 : 0}
            showInfo={false}
            style={{ width: "150px" }}
          />
          <Text strong>{row.handled} / {row.total}</Text>
        </Flex>
      ),
    },
    {
      title: "Author",
      render: (row) => (
        <InitilasAvatar
          large
          name={`${row.authorName}`}
          src={`${env.fileUpload}${row.authorProfile}`}
        />
      ),
    },
    {
      width: 10,
      title: "",
      render: (row) => <CleanUpListOptions id={row.id} />,
    },
  ];
  return (
    <Space direction="vertical" style={{ width: "100%" }} size={24}>
      <CleanUpStats />

      <Card
        className="zero-margin-padding no-body-card end-to-end-header" style={{boxShadow:"none"}}
        title={
          <>
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title style={{ margin: 0 }} level={4}>
                Clean ups
              </Title>

              <Flex
                wrap="wrap"
                gap={10}
                style={{
                  ...(screens.xs ? { flexBasis: "100%", gap: "10px" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button"
                  block={screens.xs}
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>

                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="flex-button bold-button"
                  onClick={() => navigate("/audience")}
                  icon={<FiPlus style={{ fontSize: 18 }} />}
                >
                  Select Audience
                </Button>
              </Flex>
            </Flex>
          </>
        }
      >
        {showFilters && (
          <Row gutter={[24, 24]} style={{ margin: "24px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <CleanUpJobAuthorFilter />
            </Col>

            <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
              <CleanUpJobStatusFilter />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <CleanUpJobFromDateFilter />
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
              <CleanUpJobToDateFilter />
            </Col>

            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
              <CleanUpJobOrderByFilter />
            </Col>
            <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
              <CleanUpJobOrderDirectionFilter />
            </Col>
          </Row>
        )}
      </Card>

      <Card className="zero-margin-padding" style={{boxShadow:"none"}}>
        {loading && <TableLoading />}
        {!loading && (
          <Table
            style={{ marginTop: 1 }}
            columns={jobColumns}
            dataSource={jobs}
            pagination={{
              current: page,
              pageSize: 7,
              total: stats?.all,
              onChange: (page, pageSize) => {
                setPage(page);
              },
            }}
            locale={{
              emptyText: (
                <NoDataFound
                  addText="New cleanup job"
                  description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
                  title="You have not created any email campaigns yet"
                />
              ),
            }}
          />
        )}
      </Card>
    </Space>
  );
};

export default CleanUpList;
