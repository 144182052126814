import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Divider,
  Flex,
  message,
  Space,
  Tag,
  Typography,
} from "antd";
import useDealsDetailsCtx from "../../../../hooks/useDealsDetailsCtx";
import { TbArrowBackUp } from "react-icons/tb";
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from "react-icons/md";
import { PiNoteBlankFill } from "react-icons/pi";
import { GrTasks } from "react-icons/gr";
import { FaFile } from "react-icons/fa6";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import DealDetailsActions from "../DealDetailsMain/DealDetailsActions/DealDetailsActions";
import AddTaskDrawer from "../../AddTaskDrawer";
import useFormItemStatus from "antd/es/form/hooks/useFormItemStatus";
import AddFileModal from "../DealDetailsMain/DealDetailsActions/AddFile";
import AddNote from "../DealDetailsMain/DealDetailsActions/AddNote";
const DealDetailsHeader = () => {
  const [ids, setIds] = useState();

  const { Text, Title } = Typography;

  const { dealDetails, setActivities, activites } = useDealsDetailsCtx();
  const navigate = useNavigate();

  const { id } = useParams();

  const axios = useAxiosPrivate();

  const [addFileOpen, setAddFileOpen] = useState(false);
  const [addNoteOpen, setAddNoteOpen] = useState(false);

  const [loading, setLoading] = useState(false);
  const [addTaskOpen, setAddTaskOpen] = useState(false);

  useEffect(async () => {
    const response = await axios.get(`/Deal/navigation/${id}`);
    setIds(response.data.ids);
  }, []);

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.post(`/DealTasks/CreateTask`, {
      taskType: e.type,
      name: e.name,
      dueDate: e.dueDate,
      reminder: e.reminder,
      highPriority: e.taskPriority,
      notes: e.notes,
      ownerIds: e.assignedUser,
      dealId: id,
      dueTime: e.dueTime,
      reminderType: e.reminderType,
    });

    if (response.data.success) {
      setActivities((prev) => [
        ...prev,
        {
          ind: activites.length,
          id: response.data.id,
          name: response.data.name,
          notes: response.data.notes,
          owners: response.data.owners,
          reminder: response.data.reminder,
          dueDate: e.dueDate,
          dueTime: e.dueTime,
          createdAt: response.data.createdAt,
          type: response.data.taskType,
          done: response.data.done,
          overDue: response.data.overDue,
        },
      ]);
      setAddTaskOpen(false);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <>
      <AddFileModal open={addFileOpen} setOpen={setAddFileOpen} />
      <Space className="w-100" direction="vertical" size={24}>
        <Card
          className="zero-margin-padding"
          title={
            <Space direction="vertical" className="w-100" size={0}>
              <Flex justify="space-between" align="center">
                <Flex align="center" justify="start" gap={12}>
                  <Button
                    type="link"
                    onClick={() => navigate("/Deals/Management")}
                    icon={<TbArrowBackUp style={{ fontSize: 20 }} />}
                  />
                  <Space direction="vertical" size={0}>
                    <Title level={4} style={{ margin: 0 }}>
                      {dealDetails.name}
                    </Title>
                  </Space>
                </Flex>

                <Space>
                  <Card
                    bodyStyle={{ padding: 0 }}
                    style={{ boxShadow: "none" }}
                  >
                    <Flex align="center" justify="end" gap={6}>
                      <Button
                        size="large"
                        type="text"
                        className="flex-button"
                        disabled={ids?.indexOf(id) + 1 == 1}
                        icon={
                          <MdOutlineNavigateBefore style={{ fontSize: 22 }} />
                        }
                        onClick={() =>
                          navigate(`/Deals/Details/${ids[ids.indexOf(id) - 1]}`)
                        }
                      ></Button>
                      <Text>
                        {ids?.indexOf(id) + 1} / {ids?.length ?? 0}
                      </Text>
                      <Button
                        type="text"
                        size="large"
                        className="flex-button"
                        disabled={ids?.indexOf(id) + 1 == ids?.length}
                        icon={
                          <MdOutlineNavigateNext style={{ fontSize: 22 }} />
                        }
                        onClick={() =>
                          navigate(`/Deals/Details/${ids[ids.indexOf(id) + 1]}`)
                        }
                      ></Button>
                    </Flex>
                  </Card>
                  <Button
                    size="large"
                    className="flex-button"
                    icon={<FaFile />}
                    onClick={() => setAddFileOpen(true)}
                  >
                    File
                  </Button>

                  <Button
                    size="large"
                    className="flex-button"
                    icon={<GrTasks />}
                    onClick={() => setAddTaskOpen(true)}
                  >
                    Task
                  </Button>

                  <Button
                    size="large"
                    className="flex-button"
                    icon={<PiNoteBlankFill />}
                    onClick={() => setAddNoteOpen(true)}
                  >
                    Note
                  </Button>
                </Space>
              </Flex>
            </Space>
          }
        ></Card>
        {/*<DealDetailsActions />*/}

        <AddNote open={addNoteOpen} setOpen={setAddNoteOpen} />
        <AddTaskDrawer
          open={addTaskOpen}
          setOpen={setAddTaskOpen}
          onSubmit={onSubmit}
          loading={loading}
        />
      </Space>
    </>
  );
};

export default DealDetailsHeader;
