import { Outlet } from "react-router-dom";
import { CleanUpContextProvider } from "./CleanupContext";
import CleanUpList from "./CleanUpList";

const CleanUpLayout = () => {
    return <CleanUpContextProvider>
        <CleanUpList/>
    </CleanUpContextProvider>
}

export default CleanUpLayout;
