import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { OrderDirection } from "../../shared/enums";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { SearchContext } from "../../context/SearchContext";

export const ProductListContext = createContext({
    filters: {
        authors: [],
        selectedAuthors: [],

        from: null,
        to:null,

        orderBy: null,
        orderDirection: null
    },
    setFilters: () => {},

    showFilters: false,
    setShowFilters: () => {},

    products: [],
    setProducts:() => {},

    stats: {
        total: 0,
        first: null,
        last: null,
        authorProfile: null,
        authorName: null
    },
    setStats: () => {},

    page: 1,
    setPage: () => {},

    loading: false,
    setLoading: () => {},
});

export const ProductListContextProvider = ({ children }) => {

    const axios = useAxiosPrivate();
    const perPage = 7; 

    const {debouncedValue} = useContext(SearchContext)
    const [showFilters, setShowFilters] = useState(false)

    const [filters, setFilters] = useState();
    const [stats, setStats] = useState();
    const [products, setProducts] = useState();
    const [page, setPage] = useState(() => 1);
    const [loading, setLoading] = useState(false)

    useEffect(async () => {
        setLoading(true);
        const response = await axios.post(`/Product/list`, {
            search: debouncedValue,
            page: page,
            pageSize: perPage,

            authors: filters?.selectedAuthors,
            from: filters?.from,
            to: filters?.to,

            orderBy: filters?.orderBy,
            sortDirection: filters?.orderDirection
        });
        
        setProducts(response.data.items);
        setStats(prev => ({
            ...prev, 
            total: response.data.total,
            first: response.data.first,
            last: response.data.last,
            authorProfile: response.data.authorProfile,
            authorName: response.data.authorName
        }))

        setLoading(false);
    }, [page, filters, debouncedValue])

    useEffect(async() => {
        const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));

    }, [])

    return <ProductListContext.Provider value={{
        showFilters,
        setShowFilters,

        filters,
        setFilters,

        loading,
        setLoading,

        page,
        setPage,

        products,
        setProducts,

        stats,
        setStats
    }}>
        {children}
    </ProductListContext.Provider>
}