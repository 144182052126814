import { Card, Typography, Flex, Button, Space, Empty } from "antd";
import useConfig from "antd/es/config-provider/hooks/useConfig";
import { useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { MdAirlineSeatIndividualSuite } from "react-icons/md";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { IoIosRemoveCircle } from "react-icons/io";

const SendTime = () => {
  const { Text, Title } = Typography;
  const { data, setCurrentStep } = useContext(CreateCampaignContext);

  return (
    <Card
      title={
        <>
          <Flex align="center" justify="space-between">
            <Space>
              {!data.startNow && (!data.startDate || !data.endDate) && (
                <IoIosRemoveCircle style={{ fontSize: 22, color: "#e74a3b" }} />
              )}
              {(data.startNow || (data.startDate || data.endDate)) &&
              <AiFillCheckCircle style={{ fontSize: 22, color: "#1cc88a" }} />
              }
              <Title level={4} style={{ margin: 0 }}>
                Send time
              </Title>
            </Space>
            <Button type="link" size="large" onClick={() => setCurrentStep(3)}>
              Edit Send Time
            </Button>
          </Flex>
        </>
      }
    >
      {data.startNow && (
        <>
          <Title level={4} style={{ margin: 0 }}>
            Scheduled for NOW!
          </Title>
          <Text className="text-light">
            This campaign is scheduled to start 1-5 minutes after it gets saved
          </Text>
        </>
      )}
      {!data.startNow && data.startDate && data.endDate && (
        <>
          <Title level={4} style={{ margin: 0 }}>
            Scheduled for later
          </Title>
          <Text className="text-light">
            This campaign is scheduled to start 1-5 minutes sometime on{" "}
            {new Date(data.startDate).toLocaleDateString()} and before{" "}
            {new Date(data.endDate).toLocaleDateString()}.
          </Text>
        </>
      )}

      {!data.startNow && (!data.startDate || !data.endDate) && (
        <Empty description="No date boundries where provided" />
      )}
    </Card>
  );
};

export default SendTime;
