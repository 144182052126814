import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import NoDataFound from "../../../shared/NoDataFound";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

const CreateFollowUp = () => {
  const navigate = useNavigate();

  const stepColumns = [
    {
      title: "Name",
    },
    {
      title: "Description",
    },
  ];
  const { Text, Title } = Typography;

  return (
    <>
      <Card>
        <Form>
          <Row gutter={[24, 24]}>
            <Col xl={6} xxl={6} lg={6} md={24}>
              <Title style={{ margin: 5 }} level={4}>
                Follow up details
              </Title>
              <Card style={{ boxShadow: "none" }}>
                <Space className="w-100" direction="vertical">
                  <Space className="w-100" direction="vertical" size={0}>
                    <Text>Name</Text>
                    <Input size="large" placeholder="Enter name" />
                  </Space>

                  <Space className="w-100" direction="vertical" size={0}>
                    <Text>Name</Text>
                    <TextArea
                      rows={10}
                      size="large"
                      placeholder="Enter description"
                    />
                  </Space>
                </Space>
              </Card>
            </Col>

            <Col xl={18} xxl={18} lg={18} md={24}>
              <Space className="w-100" direction="vertical">
                <Flex align="center" justify="space-between">
                  <Title style={{ margin: 5 }} level={4}>
                    Steps
                  </Title>

                  <Button
                    type="primary"
                    size="large"
                    className="flex-button"
                    icon={<FiPlus style={{ fontSize: 18 }} />}
                    onClick={() => navigate("/followup/create/step")}
                  >
                    Create a new step
                  </Button>
                </Flex>
                <Card
                  className="zero-margin-padding"
                  style={{ boxShadow: "none" }}
                >
                  <Table
                    columns={stepColumns}
                    locale={{
                      emptyText: (
                        <NoDataFound
                          addText="New step"
                          onAdd={() => navigate("/followup/create/step")}
                          description="Click the New Campaign button on the campaigns/email page to create a new email campaign"
                          title="You have not created any email campaigns yet"
                        />
                      ),
                    }}
                  />
                </Card>

                <Flex align="center" justify="end" gap={6}>
                  <Button>Cancel</Button>
                  <Button type="primary">Save changes</Button>
                </Flex>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  );
};

export default CreateFollowUp;
