import { useContext, useState } from "react";
import { Flex, Segmented, Select, Typography } from "antd";
import ThemeLabel from "../../../shared/ThemeLabel";
import { MediaContext } from "../MediaContext";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../shared/enums";

const MediaOrderByDirection = () => {
  const { filters, setFilters } = useContext(MediaContext);
  const { Text } = Typography;

  return (
    <>
     <div style={{ width: "100%" }}>
        <Text>Direction</Text>
      </div>
      <Segmented
        size="large"
        default={filters?.orderDirection}
        defaultValue={filters?.orderDirection}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
        options={[
          {
            value: OrderDirection.Asc,
            icon: <FaArrowUp />,
          },
          {
            value: OrderDirection.Dsc,
            icon: <FaArrowDown />,
          },
        ]}
      />
    </>
  );
};

export default MediaOrderByDirection;
