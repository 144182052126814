import {
  CompanyStepsContext,
} from "./CompanyStepsContext";
import { Button, Col, Grid, Row, Space } from "antd";
import { useContext, useState } from "react";
import ThirdStep from "./ThirdStep";
import FourthStep from "./FourthStep";
import FifthStep from "./FifthStep";
import SixthStep from "./SixthStep";

const { useBreakpoint } = Grid;

const CompanyStepsLayout = () => {
  

  const { currentStep, setCurrentStep } = useContext(CompanyStepsContext);

  return (
    <>
      {currentStep == 1 ? (
        <ThirdStep />
      ) : currentStep == 2 ? (
        <FourthStep />
      ) : currentStep == 3 ? (
        <FifthStep />
      ) : (
        <SixthStep />
      )}
    </>
  );
};

export default CompanyStepsLayout;
