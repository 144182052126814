import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Grid,
} from "antd";
import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { DateFormat } from "../../dateformat";
import env from "../../env.json";
import CompanyOptions from "./CompanyOptions";
import TableLoading from "../../shared/TableLoading";
import AddCompanyModal from "../deals/Modals/AddCompanyModal";
import UpdateComapny from "./UpdateCompany";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { CompanyListContext } from "./CompanyListContext";
import CompanyStats from "./CompanyStats";
import CompanyAuthorFilter from "./Filters/CompanyAuthorFilter";
import CompanyFromDateFilter from "./Filters/CompanyFromDateFilter";
import CompanyToDateFilter from "./Filters/CompanyToDateFilter";
import CompanyOrderByFilter from "./Filters/CompanyOrderByFilter";
import CompanyOrderDirectionFilter from "./Filters/CompanyOrderDirectionFilter";
import InitilasAvatar from "../../shared/IntialsAvatar";

const { useBreakpoint } = Grid;

const CompanyList = () => {
  const { Title, Text, Link } = Typography;

  const { page, setPage, setStats, stats, showFilters, setShowFilters, companies, setCompanies, loading } = useContext(CompanyListContext);

  const [showAdd, setShowAdd] = useState(false);
  const navigate = useNavigate();

  const screens = useBreakpoint();

  const [showEdit, setShowEdit] = useState(false);
  const [current, setCurrent] = useState();
  const perPage = 7;

  const columns = [
    {
      title: "Name",
      render: (data, row, index) => <Text strong>{row.name}</Text>,
    },
    {
      title: "Deals",
      render: (data, row, index) => (
        <div>
          {row.deals?.map((c, ind) => (
            <Text
              key={ind}
              strong
              type="link"
              onClick={() => navigate(`/Deals/Details/${c.id}`)}
            >
              {c.name}
              {ind < row.deals?.length - 1 && ", "}
            </Text>
          ))}

          {row.noDeals > 2 && (
            <Text strong type="link">
              , +{row.noDeals - 2} more..
            </Text>
          )}

          {row.noDeals == 0 && <Text>-</Text>}
        </div>
      ),
    },
    {
      title: "Owners",
      width: 50,
      render: (data, row, index) => (
        <Avatar.Group maxCount={2}>
          {row.owners?.map((c, ind) => (
            <Avatar key={c.ind} src={`${env.fileUpload}${c.profileUrl}`}>
              {c?.firstname[0]}
              {c?.lastname[0]}
            </Avatar>
          ))}
        </Avatar.Group>
      ),
    },
    {
      title: "Industry",
      render: (row) => <Text strong>{row.industry ?? "-"}</Text>,
    },
    {
      title: "Phone",
      render: (data, row, index) => <Text strong>{row.phone ?? "-"}</Text>,
    },
    {
      title: "Date",
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
    {
      title:"Author",
      render: (data, row) => (
        <InitilasAvatar
          large
          name={`${row.authorUserName}`}
          src={`${env.fileUpload}${row.authorProfile}`}
        />
      )
    },
    {
      title: "",
      width: 50,
      render: (data, row, index) => (
        <CompanyOptions
          onDelete={onDelete}
          id={row.id}
          setCurrent={setCurrent}
          setShowEditing={setShowEdit}
        />
      ),
    },
  ];

  const onDelete = (id) => {
    setStats((prev) => ({...prev, all: prev.all - 1}));

    setCompanies((prev) => prev.filter((c) => c.id != id));
  };

  const onAdd = (e) => {
    setStats((prev) => ({...prev, all: prev?.all + 1}));

    setCompanies((prev) => [...prev, e]);
  };

  const onUpdate = (e) => {
    setCompanies((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              ...c,
              name: e.name,
              phone: e.phone,
              owners: e.owners,
              industry: e.industry,
              website: e.website,
            }
          : c
      )
    );
  };
  return (
    <>
      <Space direction="vertical" size={24} className="w-100">
        <CompanyStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Companies
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { gap: "10px", width: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button"
                  block={screens.xs}
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  onClick={() => setShowAdd(true)}
                >
                  New company
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CompanyAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <CompanyFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <CompanyToDateFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <CompanyOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <CompanyOrderDirectionFilter />
              </Col>
            </Row>
          )}
        </Card>

        <Card className="zero-margin-padding">
          {loading && <TableLoading />}
          {!loading && (
            <Table
              style={{ marginTop: 1 }}
              columns={columns}
              dataSource={companies}
              locale={{
                emptyText: (
                  <NoDataFound
                    addText="New Company"
                    title="You have not created any companies yet"
                    description="Click the new company button on Dealing/Companies to create a new company"
                    onAdd={() => setShowAdd(true)}
                  />
                ),
              }}
              pagination={{
                current: page,
                pageSize: perPage,
                total: stats?.all,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
            ></Table>
          )}
        </Card>
      </Space>

      <AddCompanyModal open={showAdd} setOpen={setShowAdd} onAdd={onAdd} />

      {showEdit && (
        <UpdateComapny
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default CompanyList;
