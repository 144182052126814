import { Flex, Segmented, Select, Typography } from "antd";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { OrderDirection } from "../../../../shared/enums";
import { useContext, useState } from "react";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderOrderDirectionSelect = () => {
  const { Text } = Typography
  const { filters, setFilters } = useContext(EmailSenderContext)

  return <>
  <div style={{width:"100%"}}>

    <Text>Direction</Text>
  </div>
    <Segmented
      size="large"
      default={filters?.orderDirection}
      defaultValue={filters?.orderDirection}
      onChange={(e) => setFilters((prev) => ({ ...prev, orderDirection: e }))}
      options={[
        {
          value: OrderDirection.Asc,
          icon: <FaArrowUp />,
        },
        {
          value: OrderDirection.Dsc,
          icon: <FaArrowDown />,
        },
      ]}
    /></>
}

export default EmailSenderOrderDirectionSelect