import { Flex, Form, Select, Space, Typography } from "antd";
import React from "react";
import { TaskStatus } from "../../../shared/enums";
import getTaskTypeIcon from "../../../shared/TaskTypeIcon";

const TaskTypeSelect = ({ name = "type" }) => {

  const {Text} = Typography;
  
  const options = [
    {
      value: TaskStatus.ToDo,
      label: "To Do",
    },
    {
      value: TaskStatus.Email,
      label: "Email",
    },
    {
      value: TaskStatus.Call,
      label: "Call",
    },
    {
      value: TaskStatus.Meeting,
      label: "Meeting",
    },
    {
      value: TaskStatus.Lunch,
      label: "Lunch",
    },
    {
      value: TaskStatus.Deadline,
      label: "Deadline",
    },
  ];
  return (
    <Space size={0} className="w-100" direction="vertical">
      <Text>Task type</Text>
    <Form.Item name={name} initialValue={TaskStatus.ToDo}>
      <Select size="large" defaultValue={TaskStatus.ToDo}>
        {options.map((option) => (
          <Select.Option value={option.value}>
            <Flex align="center" gap={"10px"}>

            {getTaskTypeIcon(option.value)}{option.label}
            </Flex>
            </Select.Option>
        ))}
      </Select>
    </Form.Item>
        </Space>
  );
};

export default TaskTypeSelect;
