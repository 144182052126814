import { arrayMove } from "@dnd-kit/sortable";
import React, { memo, useContext } from "react";
import LostDealModal from "../LostDealModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { message } from "antd";
import { CardStatus } from "../../../shared/enums";
import { DealListContext } from "../DealListContext";

const KanbanLostDealModal = memo(
  ({ open, setOpen, setKanbanCards, initialCardPositionRef, dragEvent }) => {
    const axios = useAxiosPrivate();

    const {stats, setStats} = useContext(DealListContext)

    function onClose() {
      if (!initialCardPositionRef.current) return;

      setKanbanCards((prev) => {
        const movedCard = prev.find(
          (card) => card.id === initialCardPositionRef.current.card.id
        );
        const movedCardIndex = prev.indexOf(movedCard);
        prev[movedCardIndex] = initialCardPositionRef.current.card;
        return arrayMove(
          prev,
          movedCardIndex,
          initialCardPositionRef.current.index
        );
      });
      initialCardPositionRef.current = {};
      setOpen(false);
    }

    const onSubmit = async (e) => {
      if (dragEvent) {
        const { delta, active, over } = dragEvent;
        
        console.log(initialCardPositionRef);
        
        const response = await axios.post(`/Deal/UpdateDealPosition`, {
          id: active.data.current.card.id,
          index: active.data.current.sortable.index,
          newStage: CardStatus.Closed,
          deals: active.data.current.sortable.items,
          closeDate: e.closeDate,
          additionalComments: e.additionalComments,
          lostReason: e.reason,
        });

        console.log(active.data.current)
        if (response.data.success) {
          setOpen(false);
          setStats(prev => ({
            ...prev,
            lost: prev.lost + 1,
            new: initialCardPositionRef.current.card.status == CardStatus.New ? prev.new - 1: prev.new,
            qualifying: initialCardPositionRef.current.card.status == CardStatus.Qualifying ? prev.qualifying - 1: prev.qualifying,
            demoScheduled: initialCardPositionRef.current.card.status == CardStatus.DemoScheduled ? prev.demoScheduled - 1: prev.demoScheduled,
            pendingCommitment: initialCardPositionRef.current.card.status == CardStatus.PendingCommitment ? prev.pendingCommitment - 1: prev.pendingCommitment,
            inNegotiation : initialCardPositionRef.current.card.status == CardStatus.InNegotiation ? prev.inNegotiation - 1: prev.inNegotiation,
            won: initialCardPositionRef.current.card.status == CardStatus.Won ? prev.won - 1: prev.won
          }))
        } else {
          message.error(response.data.message);
        }
      } else {
        const response = await axios.post("/Deal/UpdateDealStatus", {
          id: initialCardPositionRef.current.card.id,
          newStage: CardStatus.Closed,
          lostReason: e.reason,
          additionalComments: e.additionalComments,
        });

        if (response.data.success) {
          setOpen(false);

          setStats(prev => ({
            ...prev,
            lost: prev.lost + 1,
            new: initialCardPositionRef.current.card.status == CardStatus.New ? prev.new - 1: prev.new,
            qualifying: initialCardPositionRef.current.card.status == CardStatus.Qualifying ? prev.qualifying - 1: prev.qualifying,
            demoScheduled: initialCardPositionRef.current.card.status == CardStatus.DemoScheduled ? prev.demoScheduled - 1: prev.demoScheduled,
            pendingCommitment: initialCardPositionRef.current.card.status == CardStatus.PendingCommitment ? prev.pendingCommitment - 1: prev.pendingCommitment,
            inNegotiation : initialCardPositionRef.current.card.status == CardStatus.InNegotiation ? prev.inNegotiation - 1: prev.inNegotiation,
            won: initialCardPositionRef.current.card.status == CardStatus.Won ? prev.won - 1: prev.won
          }))
        } else {
          message.error(response.data.message);
        }
      }
    };
    return <LostDealModal open={open} onClose={onClose} onSubmit={onSubmit} />;
  }
);

export default KanbanLostDealModal;
