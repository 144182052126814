import { createContext, useState } from "react";
import useLocalStorage from "../hooks/useLocalStorage";

export const ThemeContext = createContext({
  theme: "light",
  setTheme: () => {},
});

export const ThemeContextProvider = ({ children }) => {
  const [themeValue, setThemeValue] = useLocalStorage("theme", "light"); 
  
  const [theme, setThemeState] = useState(() => themeValue);

  const setTheme = (val) => {
    setThemeValue(val)
    setThemeState(val);
  }
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
