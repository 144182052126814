import { Button, Divider, Dropdown } from "antd";
import { SmileOutlined } from "@ant-design/icons";
import { IoIosKey, IoIosSettings } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineTeam } from "react-icons/ai";
import { MdEmail, MdOutlineLanguage, MdOutlinePayment } from "react-icons/md";
import { FaSignature } from "react-icons/fa";
import useAuth from "../../../hooks/useAuth";
import { RiPriceTagFill } from "react-icons/ri";
import { BiSolidCoupon } from "react-icons/bi";

const Settings = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: <Link to="/settings">Api keys & Proxy</Link>,
      icon: <IoIosKey />,
    },
    {
      key: "3",
      label: <Link to="/Users">Team</Link>,
      icon: <AiOutlineTeam />,
    },
    {
      type: "divider",
      label: (
        <Divider plain="Hello">
          <p>Your Divider Text Here</p>
        </Divider>
      ),
    },
    {
      key: "6",
      label: <Link to="/Templates/SignatureList">Signatures</Link>,
      icon: <FaSignature />,
    },
    {
      type: "divider",
      label: (
        <Divider>
          <p>Your Divider Text Here</p>
        </Divider>
      ),
    },
    {
      key: "7",
      label: <Link to="/Subscriptions">Subscriptions</Link>,
      icon: <MdOutlinePayment />,
    },
  ];

  if (auth?.roles?.find((c) => c == "ApplicationOwner")) {
    items.push({
      key: "8",
      label: <Link to="/Manage/Prices">Prices & Limits</Link>,
      icon: <RiPriceTagFill />,
    });

    items.push({
      key: "9",
      label: <Link to="/Manage/Coupons">Coupons</Link>,
      icon: <BiSolidCoupon />,
    });
  }

  return (
    <>
      <Button
        shape="circle"
        type="text"
        className="flex-button"
        onClick={() => navigate("/settings")}
        icon={<IoIosSettings style={{ fontSize: 18.5 }} />}
      ></Button>
    </>
  );
};

export default Settings;
