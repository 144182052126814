import HeaderActions from "./HeaderActions";
import env from "../../../env.json";
import {
  Dropdown,
  Avatar,
  Typography,
  Divider,
  Flex,
  Button,
  Grid
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useLogout from "../../../hooks/useLogout";
import { useNavigate } from "react-router-dom";
import { MdOutlineSettings, MdSecurity, MdLogout } from "react-icons/md";
import { ThemeContext } from "../../../context/ThemeContext";
import useAuth from "../../../hooks/useAuth";
import Balance from "./Balance";
import Search from "./Search";
import { MdDarkMode } from "react-icons/md";
import { TbSunFilled } from "react-icons/tb";
import Notifications from "./Notifications";
import Settings from "./Settings";

const { useBreakpoint } = Grid;

const UserProfileMenu = ({ collapsed, setCollapsed, showSearch= true }) => {
  const logout = useLogout();
  const navigate = useNavigate();
  const axios = useAxiosPrivate();
  const { theme, setTheme } = useContext(ThemeContext);
  const screens = useBreakpoint();

  const [user, setUser] = useState();

  const { setAuth } = useAuth();

  useEffect(async () => {
    const response = await axios.get("Users/CurrentUser");

    setUser(response.data.data);
    setAuth(prev => ({ ...prev, subType: response.data.data.subscriptionType }))
  }, []);

  const { Text } = Typography;

  const items = [
    {
      key: "1",
      label: (
        <>
          <Text>
            👋 Hey, {user?.firstname} {user?.lastname}
          </Text>
          <Divider style={{ margin: 0 }} />
        </>
      ),
    },
    {
      key: "2",
      label: "Profile Settings",
      icon: <MdOutlineSettings />,
    },
    {
      key: "3",
      label: "Your Security",
      icon: <MdSecurity />,
    },
    {
      key: "4",
      danger: true,
      label: "Log Out",
      icon: <MdLogout />,
    },
  ];

  const handleMenuClick = (e) => {
    if (e.key == 4) {
      logout();
    } else if (e.key == 2) {
      navigate("/Users/profile");
    } else if (e.key == 3) {
      navigate("/Users/Security");
    }
  };

  // const { theme } = useContext(ThemeContext);

  return (
    <>
      <Flex
        align="center"
        wrap="wrap"
        style={{
          background: theme == "dark" ? "#111c44" : "white",
          padding: 5,
          borderRadius: screens.xs ? "20px" : "50px",
          boxShadow: theme == "dark" ? "0 0 #0000" : "rgba(112, 144, 176, 0.18) 14px 17px 40px 4px",
          marginTop: screens.xs ? "15px" : "0",
          width: screens.xs ? "100%" : "unset",
        }}
      >
        {/* <HeaderActions collapsed={collapsed} setCollapsed={setCollapsed} /> */}
        <Flex 
        style={{
          width: screens.xs ? "100%" : "unset",
          padding: screens.xs ? "5px 15px" : "0"
        }}>
          {showSearch&&<Search />}
        </Flex>
        <Flex
          align="center"
          justify="center"
          style={{
            ...(screens.xs ? {'justifyContent': 'space-between', 'width': '100%', 'padding': '5px 15px'} : {})
          }}
        >
          <Balance />

          <Notifications />

          <Settings />

          <Button
            shape="circle"
            className="flex-button"
            type="text"
            icon={theme == "light" ? <MdDarkMode style={{ fontSize: 18.5 }} /> : <TbSunFilled style={{ fontSize: 18.5 }} />}
            onClick={() => {
              if (theme == "light") {
                setTheme("dark");
              } else {
                setTheme("light");
              }
            }}
          />

          {user && (
            <Dropdown
              menu={{ items, onClick: handleMenuClick }}
              trigger={["click"]}
            >
              <Avatar
                size="large"
                shape="circle"
                icon={<UserOutlined />}
                src={`${env.fileUpload}${user?.profileImagePath}`}
              />
            </Dropdown>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default UserProfileMenu;
