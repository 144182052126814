import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  message,
} from "antd";
import { useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const AddCoupon = ({ isOpen, setIsOpen, onAdd }) => {
  const axios = useAxiosPrivate();
  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [canUseAudience, setCanUseAudience] = useState(() => false);

  const onSubmit = async (e) => {
    setLoading(true);
    const response = await axios.post(`/Coupon`, {
      canUseAudience: canUseAudience,
      ...e,
    });

    if (response.data.success) {
      setIsOpen(false);
      onAdd(response.data);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  return (
    <>
      <Modal
        open={isOpen}
        onCancel={() => setIsOpen(false)}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Add new coupon
          </Title>
        }
        footer={
          <Flex align="center" justify="end" gap={12}>
            <Button onClick={() => setIsOpen(false)}>Cancel</Button>

            <Button
              loading={loading}
              type="primary"
              onClick={() => form.submit()}
            >
              Save changes
            </Button>
          </Flex>
        }
      >
        <Divider style={{ margin: "5px 0px" }}></Divider>

        <Form
          form={form}
          onFinish={(e) => onSubmit(e)}
          style={{ marginTop: 10 }}
        >
          <Space size={0} className="w-100" direction="vertical">
            <Space className="w-100" direction="vertical" size={0}>
              <Text>Name</Text>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required",
                  },
                  {
                    max: 100,
                    message: "Max length for name is 100",
                  },
                ]}
              >
                <Input
                  placeholder="Assign a name"
                  size="large"
                  count={{
                    show: true,
                    max: 100,
                  }}
                />
              </Form.Item>
            </Space>

            <Space direction="vertical" className="w-100" size={0}>
              <Text>Code</Text>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: "Code is required",
                  },
                  {
                    max: 124,
                    message: "Max length for code is 124",
                  },
                ]}
              >
                <Input
                  placeholder="Assign a code"
                  size="large"
                  count={{
                    show: true,
                    max: 124,
                  }}
                />
              </Form.Item>
            </Space>

            <Row gutter={12}>
              <Col xs={12}>
                <Space direction="vertical" className="w-100" size={0}>
                  <Text>Valid to</Text>
                  <Form.Item name="validTo">
                    <DatePicker
                      size="large"
                      style={{ borderRadius: 15 }}
                      className="w-100"
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col xs={12}>
                <Space direction="vertical" className="w-100" size={0}>
                  <Text>Max usage</Text>
                  <Form.Item
                    name="maxUsages"
                    rules={[
                      {
                        type: "number",
                        min: 0,
                        message: "Value must be at least 0!",
                      },
                    ]}
                  >
                    <InputNumber
                      placeholder="Assign max usage"
                      size="large"
                      className="w-100"
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Space direction="vertical" size={0} className="w-100">
              <Text>Amount</Text>
              <Form.Item
                name="value"
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "number",
                    min: 0,
                    message: "Value must be at least 0!",
                  },
                ]}
              >
                <InputNumber
                  placeholder="Assign amount"
                  size="large"
                  className="w-100"
                />
              </Form.Item>

              <Form.Item>
                <Checkbox onChange={(e) => setCanUseAudience(e.target.checked)}>
                  Works on audiences
                </Checkbox>
              </Form.Item>

              {canUseAudience && (
                <Space className="w-100" direction="vertical" size={0}>
                  <Text>Audience valid timespan</Text>
                  <Form.Item name="AudienceCouponLifetime">
                    <Select 
                      options={[
                        {
                          label:"One month",
                          value:1
                        },
                        {
                          label:"Six months",
                          value:2
                        },
                        {
                          label:"One year",
                          value:3
                        },
                        {
                          label:"Forever",
                          value: 4
                        }
                      ]}
                      size="large"
                      className="w-100"
                      style={{ borderRadius: 20 }}
                    />
                  </Form.Item>
                </Space>
              )}
            </Space>
          </Space>
        </Form>
      </Modal>
    </>
  );
};

export default AddCoupon;
