import { Popconfirm } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";

const DeleteAudience = ({ id, onDelete }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false)

  const handleDelete = async () => {
    setLoading(true)
    const response = await axios.post("/TargetAudience/DeleteAudience", {

      id: id,
    });

    onDelete(id);
    setLoading(false)
  };

  return (
    <>
      <Popconfirm
        style={{
          backdropFilter: "blur(10px)",
          background: "rgba(255,255,255,.2) !important",
        }}
        title="Delete this audience"
        description="Are you sure to delete this audience?"
        okText="Delete"
        cancelText="Cancel"
        onConfirm={handleDelete}
      >Delete this audience</Popconfirm>
    </>
  );
};
export default DeleteAudience;
