import {
  Input,
  Space,
  Form,
  ColorPicker,
  Row,
  Col,
  Typography,
  Flex,
  Button,
} from "antd";
import { MdCampaign, MdSubject } from "react-icons/md";
import { HiOutlineLink } from "react-icons/hi";
import { FaStickyNote } from "react-icons/fa";
import { FcDocument } from "react-icons/fc";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import CampaignNotes from "../Notes/CampaignNotes";
import CampaignDocuments from "../Documents/CampaignDocuments";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GrNext } from "react-icons/gr";
import SmartText from "../../../components/SmartText";

const CampaignStarterData = () => {

  const {type} = useParams();

  const { data, currentStep, setCurrentStep, setData } = useContext(
    CreateCampaignContext
  );

  const naviagte = useNavigate();
  const [form] = Form.useForm();

  const [notesOpen, setNotesOpen] = useState(false);
  const [documentsOpen, setDocumentsOpen] = useState();

  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const { Text } = Typography;
  const { TextArea } = Input;

  useEffect(() => {
    form.setFieldsValue({
      name: data.name,
      color: data.color ?? "#ff00ff",
      subject: data.subject,
      unSuburl: data.url,
      goal: data.goal,
    });
  }, [data.loaded]);

  const submit = async (e) => {
    setLoading(true);
    var color = e.color.metaColor
      ? e.color.toHexString()
      : data.id
      ? data.color
      : "#ff00ff";

    const response = await axios.post(`/Campaign/Step1`, {
      color: color,
      name: e.name,
      subject: e.subject,
      unSuburl: e.unSuburl,
      goal: e.goal,
      notes: data.notes,
      documentIds: data.documents,
      id: data.id,
      campaignType: parseInt(type)
    });

    setData({ ...data, id: response.data.data.id, campaignType: type });

    setLoading(false);

    if (response.data.success) {
      naviagte(`/Campaigns/Edit/${response.data.data.id}/${type}`);
      setCurrentStep(currentStep + 1);
    }
  };

  return (
    <>
      <CampaignNotes isOpen={notesOpen} setIsOpen={setNotesOpen} />
      <CampaignDocuments isOpen={documentsOpen} setIsOpen={setDocumentsOpen} />

      <Form onFinish={submit} form={form}>
        <Space style={{ width: "100%" }} direction="vertical">
        <div>
        <Text>Color</Text>
        <Form.Item name="color" >
                    <ColorPicker value={data.color} disabled={!data.canEdit} size="large" style={{borderRadius:15}} showText />
                  </Form.Item>
                  </div>
          <div>
            <Text>Name</Text>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter a name for your campaign",
                },
              ]}
            >
              <Input
                disabled={!data.canEdit}
               
                onChange={(e) => setData({ ...data, name: e.target.value })}
                allowClear style={{minWidth:"300px", width:"450px"}}
                placeholder="Enter campaing name"
                size="large"
              />
            </Form.Item>
          </div>
          {
            (type==0)&& <><div>
            
            <Text>Subject</Text>
            <Form.Item
              name="subject"
              initialValue={data.subject}
              rules={[
                {
                  required: true,
                  message: "Please provide a subject for your campaign",
                },
              ]}
            >
              <Input
                disabled={!data.canEdit}
                onChange={(e) => setData({ ...data, subject: e.target.value })}
                allowClear
                size="large"
                style={{minWidth:"300px", width:"450px"}}
                placeholder="Enter subject"
                suffix={<SmartText text={data.subject} setText={(e) => {setData({ ...data, subject: e }); form.setFieldValue("subject", e)}}/>}
              />
            </Form.Item>
          </div>
         

          <div>
            <Flex align="center" justify="space-between" style={{marginBottom:5}}>

            <Text>Preview text</Text>
            <SmartText text={data.goal} setText={(e) => {setData({...data, goal: e}); form.setFieldValue("goal", e)}}/>
            </Flex>
            <Form.Item name="goal" initialValue={data.goal}>
              <TextArea
                disabled={!data.canEdit}
                rows={6}
                size="large"
                showCount
                placeholder="Enter preview text for your campaign"
                value={data.goal}
                onChange={(e) => setData({ ...data, goal: e.target.value })}
                
                ></TextArea>
            </Form.Item>
          </div></>
              }
          <Flex align="center" justify="space-between">
            <Space>
              <Button
                type="link"
                icon={<FaStickyNote />}
                onClick={() => setNotesOpen(true)}
              >
                Create notes {data.notes?.length}
              </Button>

              {(type==0)&&
              <Button
              type="link"
              icon={<FcDocument />}
              onClick={() => setDocumentsOpen(true)}
              >
                Select documents {data.documents?.length}
              </Button>
              }
            </Space>

            <Button
              disabled={!data.canEdit}
              loading={loading}
              type="primary"
              htmlType="submit"
              icon={<GrNext/>}
              className="flex-button"
            >
              Continue to your audience
            </Button>
          </Flex>
        </Space>
      </Form>
    </>
  );
};

export default CampaignStarterData;
