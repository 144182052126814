import { Button, Dropdown, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import { TbTrash, TbUserCheck } from "react-icons/tb";
import DeleteSender from "./DeleteSender";
import { IoMdMore } from "react-icons/io";
import { BsBookmarkFill } from "react-icons/bs";
import { GiTestTubes } from "react-icons/gi";

const SetDefaultSender = ({ id, handleDefaultChanged, disabled, confirmed, handleDeleted }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const items = [
    {
      icon:<BsBookmarkFill />,
      key: "1",
      label: "Set as default",
      disabled: disabled
    },
    {
        key: "2",
        icon: <GiTestTubes/>,
        label: "Recieve test email",
    },
    {
      key: "3",
      icon:<TbUserCheck/>,
      label: "Confirm selected sender",
      disabled:confirmed
    },
    {
      key: "4",
      icon:<TbTrash/>,
      label:<DeleteSender id={id} deleted={handleDeleted} />,
      danger: true
    }
   /* {
        key:"3",
        icon:<FcEditImage/>,
        label:"Edit sender"
    }*/
  ];

  const handleConfirm = async () => {
    setLoading(true);
    const response = await axios.post("/Sender/GetSenderConfirmation", {
      id: id,
    });

    if (response.data.success) {
      message.success("Confirmation message was sent!");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };
  
  const submit = async () => {
    setLoading(true);
    const response = await axios.post(`/Sender/SetDefault`, { id: id });

    if (response.data.success) {
      handleDefaultChanged(id);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  const handleMenuClick = async (e) => {
    if (e.key == "1") {
      await submit();
    }else if(e.key == "2"){
      await sendTestEmail()
    }else if(e.key == "3"){
      await handleConfirm();
    }
  };

  const sendTestEmail = async () => {
    const response = await axios.post('/Sender/SendTestEmail', {
      id: id
    });

    if(response){
      message.success("Test email was sent!");      
    }else{
      message.error(response.data.message)
    }
  }
  
  return (
    <Dropdown
      menu={{
        items,
        onClick: handleMenuClick,
      }}
      trigger={["click"]}
    >
      <Button type="text" shape="circle" onClick={(e) => e.preventDefault()}>
        <IoMdMore style={{fontSize:20}}/>
      </Button>
    </Dropdown>
  );
};

export default SetDefaultSender;
