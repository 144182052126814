import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AssignedUsersSelect from "../deals/FormItems/AssignedUsersSelect";

const UpdateComapny = ({ show, setShow, onUpdate, id }) => {
  const { Text, Title } = Typography;

  const [loading, setLoading] = useState(false);
  const axios = useAxiosPrivate();

  const [form] = Form.useForm();

  const onSubmit = async (e) => {
    setLoading(true);

    const response = await axios.put("/Company", {
      ...e,
      id: id,
    });

    if (response.data.success) {
      onUpdate(response.data);
      setShow(false)
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  useEffect(async () => {
    const response = await axios.get(`/Company/${id}`);

    if (response.data.success) {
      form.setFieldValue("name", response.data.name);
      form.setFieldValue("phoneNumber", response.data.phone);
      form.setFieldValue(
        "owners",
        response.data.owners?.map((c) => c.id)
      );
      form.setFieldValue("website", response.data.website)
      form.setFieldValue("industry", response.data.industry)
    } else {
      message.error(response.data.message);
    }
  }, []);
  return (
    <Modal
      title={
        <Title style={{ margin: 0, padding: 0 }} level={4}>
          Edit selected company
        </Title>
      }
      open={show}
      onCancel={() => setShow(false)}
      footer={
        <Flex align="center" justify="end" gap={10}>
          <Button onClick={() => setShow(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Divider style={{ margin: "5px 0px" }} />
      <Form form={form} onFinish={onSubmit}>
        <Space className="w-100" size={0} direction="vertical">
          <Text>Name</Text>
          <Form.Item name="name">
            <Input placeholder="Enter company name" size="large" />
          </Form.Item>
        </Space>

          <AssignedUsersSelect name="owners" />
        
        <Row gutter={12}>
          <Col xs={24} md={12}>
            <Space size={0} className="w-100" direction="vertical">
              <Text>Industry</Text>
              <Form.Item name="industry">
                <Input size="large" placeholder="Enter an industry" />
              </Form.Item>
            </Space>
          </Col>
          <Col xs={24} md={12}>
            <Space size={0} className="w-100" direction="vertical">
              <Text>Phone number</Text>
              <Form.Item name="phoneNumber">
                <Input placeholder="Enter company phone number" size="large" />
              </Form.Item>
            </Space>
          </Col>
        </Row>

        <Space size={0} className="w-100" direction="vertical">
          <Text>Website</Text>
          <Form.Item name="website">
            <Input size="large" placeholder="Enter a website" />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default UpdateComapny;
