import { Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { BsCalendarDateFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { MdEmail, MdTitle } from "react-icons/md";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderOrderBySelect = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(EmailSenderContext)

  const options = [
    {
      value: "created",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <BsCalendarDateFill />
        Date created
      </Flex>
    },
    {
      value: "name",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <MdTitle />
        Name
      </Flex>
      ,
    },
    {
      value: "email",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <MdEmail />
        Email
      </Flex>
      ,
    },
    {
      value: "nickname",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <FaUser />
        Nickname
      </Flex>
      ,
    },
  ];

  return <>
    <Text text="Order by">Order by</Text>
    <Select
      size="large"
      style={{ width: "100%" }}
      options={options}
      defaultValue={filters?.orderBy}
      onChange={e => setFilters(prev => ({ ...prev, orderBy: e }))}
      placeholder="Select filter order by column"
    />
  </>
}

export default EmailSenderOrderBySelect;