import { Outlet, useNavigate } from "react-router-dom";
import { Button, Card, Col, Flex, Row, Space, Typography, Grid } from "antd";
import { useContext, useState } from "react";
import CreateCampaignModal from "./CreateCampaignModal";
import Stats from "./CampaignStats";
import { CampaignContext } from "./CampaignContext";
import CampaignAuthorSelect from "./Filters/CampaignAuthorSelect";
import CampaignStatusSelect from "./Filters/CampaignStatusSelect";
import CampaignOrderBy from "./Filters/CampaignOrderBy";
import CampaingOrderByDirection from "./Filters/CampaignOrderByDirection";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { CampaignType } from "../../shared/enums";
import CreateFromDateFilter from "./Filters/CreatedDateFilter";
import CreatedToDateFilter from "./Filters/CreatedToDateFilter";
import CampaignTypeSelect from "./Filters/CampaignTypeSelect";
import Promo from "../../shared/Promo";
import emails from "../../assets/images/background.png";
import { FaArrowRight, FaSort } from "react-icons/fa";

const { useBreakpoint } = Grid;

const CampaignListsLayout = () => {
  const { Title, Text } = Typography;
  const { showFilters, setShowFilters } = useContext(CampaignContext);
  const [addOpen, setAddOpen] = useState(false);
  const screens = useBreakpoint();

  const navigate = useNavigate();

  return (
    <>
      <CreateCampaignModal open={addOpen} setOpen={setAddOpen} />
      <Space direction="vertical" style={{ width: "100%" }} size={24}>
        <Promo
          background={emails}
          title="Campaign’s List Overview"
          text="Check your campaign statistics daily, see all responses, ratings,
              clicks and reports here. <br />
              You can edit and manage everything in the options panel."
          endElements={
            <Button
              icon={<FaArrowRight />}
              size="large"
              className="flex-button"
              onClick={() => navigate("/")}
            >
              Marketing statistics
            </Button>
          }
        />

        <Stats total={0} type={CampaignType.SMS} />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <>
              <Flex align="center" justify="space-between" wrap="wrap"
                style={{
                  ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
                }}
              >
                <Title style={{ margin: 0 }} level={4}>
                  Campaigns
                </Title>

                <Flex wrap="wrap" gap={10}
                  style={{
                    ...(screens.xs ? { 'flexBasis': '100%', 'gap': '10px' } : {})
                  }}
                >
                  <Button
                    size="large"
                    className="flex-button"
                    block={screens.xs}
                    onClick={() => setShowFilters(!showFilters)}
                    icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                  >
                    Filter
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    block={screens.xs}
                    className="flex-button bold-button"
                    onClick={() => setAddOpen(true)}
                    icon={<FiPlus style={{ fontSize: 18 }} />}
                  >
                    New campaign
                  </Button>
                </Flex>
              </Flex>
            </>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: "24px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CampaignAuthorSelect />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <CampaignStatusSelect />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <CampaignTypeSelect />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <CreateFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <CreatedToDateFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <CampaignOrderBy />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <CampaingOrderByDirection />
              </Col>
            </Row>
          )}
        </Card>

        <Outlet />
      </Space>
    </>
  );
};

export default CampaignListsLayout;
