import { Button, Flex, Input, Space, Tag, Typography } from "antd";
import { useContext } from "react";
import { MdArrowBack, MdOutlineArrowForward } from "react-icons/md";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import SmartText from "../../../../components/SmartText";

const Footer = ({ setCurrent }) => {
  const { template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );
  const { Text, Title } = Typography;

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <Space style={{ width: "100%" }} direction="vertical" size={0}>
          <Flex align="center" gap={10}>
            <Title style={{ margin: 0 }} level={3}>
              Footer
            </Title>
            <Tag>Optional</Tag>
          </Flex>
          <Text>
            Add a short line of text to the bottom of your message template. If
            you add the marketing opt-out button, the associated footer will be
            shown here by default.
          </Text>
        </Space>

        <Input
          placeholder="Enter text"
          size="large"
          defaultValue={template.footer}
          value={template.footer}
          disabled={template.footerDisabled}
          suffix={<SmartText text={template.footer} setText={(e) => setTemplate(prev=> ({...prev, footer: e}))}/>}
          onChange={(e) => setTemplate(prev=> ({...prev, footer: e.target.value}))}
          count={{
            show: true,
            max: 60,
          }}
        />

        <Flex align="center" justify="space-between">
          <Button
            className="flex-button"
            type="link"
            icon={<MdArrowBack />}
            onClick={() => setCurrent("2")}
          >
            Back to Body
          </Button>

          <Button
            type="primary"
            icon={<MdOutlineArrowForward />}
            className="flex-button"
            onClick={() => setCurrent("4")}
          >
            Continue to Buttons
          </Button>
        </Flex>
      </Space>
    </>
  );
};

export default Footer;
