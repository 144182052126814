import { Button, Flex, Space, Spin, Tooltip, Typography, message } from "antd";
import { useState } from "react";
import { TbUserCheck } from "react-icons/tb";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { MdCheckCircle, MdError } from "react-icons/md";
const ConfirmSender = ({ id, disabled, email }) => {
  const axios = useAxiosPrivate();
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    const response = await axios.post("/Sender/GetSenderConfirmation", {
      id: id,
    });

    if (response.data.success) {
      message.success("Confirmation message was sent!");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  return (
    <>
      {!disabled && (
        <Flex align="center" justify="start" gap={6}>
          <Spin spinning={loading}>
            <Tooltip title="Confirm">
            <div className="flex-button">
            <MdError
              style={{ color: "#e74a3b", fontSize: 18, cursor: "pointer" }}
              onClick={() => handleConfirm()}
              />
              </div>
              </Tooltip>
          </Spin>
          <Text
            style={{ fontWeight: 400 }}
            className="text-light"
          >
            {email}
          </Text>
        </Flex>
      )}
      {disabled && (
        <Flex align="center" justify="start" gap={6}>
          <MdCheckCircle style={{ color: "#1cc88a", fontSize: 18 }} />{" "}
          <Text
            style={{ fontWeight: 400 }}
            className="text-light"
          >
            {email}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default ConfirmSender;
