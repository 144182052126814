import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import moment from "moment/moment";
import dayjs from "dayjs";

export const CreateCampaignContext = createContext({
  data: "",
  currentStep: 0,
  setData: () => {},
  setCurrentStep: () => {},
});

export const CreateCampaignContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();
  const { id } = useParams();

  const [currentStep, setCurrentStep] = useState(0);

  const [data, setData] = useState(() => {
    return {
      id: null,
      name: "",
      color: "#ff00ff",
      subject: "",
      url: "",
      goal: "",
      notes: [],
      documents: [],
      audienceIds: [],
      templateId: 0,
      scheduleNow: false,
      startDate: null,
      endDate: null,
      startNow: true,
      loaded: false,
      canEdit: true,
      senderId: null,
      groupId:null,
      smsTemplateId: null,
      smsSenderId:null,
      whatsappPhoneNumberId:null,
      whatsappTemplateId:null
    };
  });

  useEffect(async () => {
    if (!id) {
      return;
    }

    const response = await axios.get(`/Campaign/${id}`);
    const data = response?.data?.data;
    
    setData({
      id: data.id,
      name: data.name,
      color: data.color ?? "#ff00ff",
      subject: data.subject,
      url: data.unsubscribeUrl,
      goal: data.goal,
      notes: data.notes?.map((c) => c.description),
      documents: data.documents?.map((c) => c.id),
      audienceIds: data.targetAudience?.map((c) => c.id),
      templateId: data.templateId,
      scheduleNow: data.scheduleNow,
      startDate:data.dateStarted,
      endDate: data.dateEnded,
      startNow: data.startNow,
      loaded: true,
      canEdit: data.canEdit ?? true,
      senderId: data.senderId,
      groupId: data.whatsappTemplateGroupId,
      smsTemplateId: data.smsTemplateId,
      smsSenderId: data.smsSenderId,
      whatsappTemplateId: data.whatsappTemplateId,
      whatsappPhoneNumberId: data.whatsappPhoneNumberId
    });
    setCurrentStep(data.step)

  }, []);

  return (
    <CreateCampaignContext.Provider
      value={{
        data: data,
        currentStep: currentStep,
        setData: setData,
        setCurrentStep: setCurrentStep,
      }}
    >
      {children}
    </CreateCampaignContext.Provider>
  );
};
