import { Button, Dropdown } from "antd";
import { BsFillTrash3Fill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";

const DealContactsOptions = ({ id, onConfirm }) => {
  const items = [
    {
      key: "1",
      icon: <BsFillTrash3Fill />,
      label: "Delete selected contact",
      danger: true,
      onClick: () => onConfirm(id),
    },
  ];

  return (
    <Dropdown trigger={["click"]} menu={{ items }}>
      <Button
        shape="circle"
        className="flex-button"
        type="text"
        icon={<IoMdMore style={{ fontSize: 20, color: "#1b254b" }} />}
      ></Button>
    </Dropdown>
  );
};

export default DealContactsOptions;
