import axios from "axios";
import env from "../env.json";

const BASE_URL = env.api;
export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json", 
             "Content-Encoding" :"identity" },
  withCredentials: true,
});
