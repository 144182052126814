import { useContext } from "react";
import { RiDraftFill } from "react-icons/ri";
import { Avatar, Col, Row } from "antd";
import { MdCampaign, MdFirstPage, MdLastPage } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import { BsFillClockFill } from "react-icons/bs";
import Stat from "../../shared/Stat";
import { CampaignContext } from "./CampaignContext";
import { DateFormat } from "../../dateformat";
import env from '../../env.json'
import { UserOutlined } from "@ant-design/icons";
const Stats = () => {
  const { stats } = useContext(CampaignContext);

  return (
    <>
      <Row gutter={[24,24]}>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.all}
            name="Total"
            icon={<MdCampaign style={{ fontSize: 22}} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.completed}
            name="Completed"
            icon={<FaCalendarCheck style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.invalid}
            name="Invalid"
            icon={<BiSolidErrorCircle style={{ fontSize: 22}} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.draft}
            name="Draft"
            icon={<RiDraftFill style={{ fontSize: 20 }} />}
            loading={false}
          />
        </Col>
        {/*<Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.reach}
            name="Reached"
            icon={<AiOutlineAreaChart style={{ fontSize: 22, color:"#422afb" }} />}
            loading={false}
          />
        </Col>*/}
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.scheduled}
            name="Scheduled"
            icon={<BsFillClockFill style={{ fontSize: 22 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.first).toLocaleDateString("en-US", DateFormat)}
            name="First"
            icon={<MdFirstPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={new Date(stats?.last).toLocaleDateString("en-US", DateFormat)}
            name="Last"
            icon={<MdLastPage style={{ fontSize: 24 }} />}
            loading={false}
          />
        </Col>
        <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
          <Stat
            value={stats?.authorName}
            name="Most authored"
            icon={<Avatar icon={<UserOutlined/>} size="large" src={`${env.fileUpload}${stats?.authorImage}`} />}
            loading={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default Stats;
