import { Divider, Space, Typography } from "antd";

const PrivacyPolicy = () => {
  const { Title, Text, Link } = Typography;

  return (
    <div style={{paddingRight:20}}>
      <Space align="center" style={{ width: "100%" }} direction="vertical">
        <Title>Privacy Policy</Title>
        <Text>Last updated February 8, 2024</Text>
      </Space>

      <Divider />

      <Text>
        This Privacy Policy is prepared by <Text strong>TRETEK</Text> and whose registered
        address is <Link strong href="https://tretek.io" target="_blank">TRETEK</Link> (“We”) are committed to protecting
        and preserving the privacy of our visitors when visiting our site or
        communicating electronically with us.
      </Text>
      <br />
      <Text>
        This policy sets out how we process any personal data we collect from
        you or that you provide to us through our website and social media
        sites. We confirm that we will keep your information secure and comply
        fully with all applicable Kosovo Data Protection legislation and
        regulations. Please read the following carefully to understand what
        happens to personal data that you choose to provide to us, or that we
        collect from you when you visit our sites. By submitting information you
        are accepting and consenting to the practices described in this policy.
      </Text>
      <br />

      <Title level={3}>Types of information we may collect from you</Title>
      <Text>
        We may collect, store and use the following kinds of personal
        information about individuals who visit and use our website and social
        media sites:
      </Text>
        <br />
      <Text>
        <Text strong>Information you supply to us.</Text> You may supply us with
        information about you by filling in forms on our website or social
        media. This includes information you provide when you submit a
        contact/inquiry form. The information you give us may include but is not
        limited to, your name, address, e-mail address, and phone number.
      </Text>
      <br />

      <Title level={3}>How we may use the information we collect</Title>
      <br />
      <Text>We use the information in the following ways:</Text>
      <br/>
      <Text>
        <Text strong>Information you supply to us.</Text> We will use this
        information:
        <br />
      </Text>
      <Text>
           • To provide you with information and/or services that you request from
        us;
        <br />
      </Text>
      <Text>   • To contact you to provide the information requested.</Text>

      <Title level={3}>Disclosure of your information</Title>
      <Text>
        Any information you provide to us will either be emailed directly to us
        or may be stored on a secure server.
      </Text>
      <br />

      <Text>
        We do not rent, sell or share personal information about you with other
        people or non-affiliated companies.
      </Text>
      <br />

      <Text>
        We will use all reasonable efforts to ensure that your personal data is
        not disclosed to regional/national institutions and authorities unless
        required by law or other regulations.
      </Text>
      <br />

      <Text>
        Unfortunately, the transmission of information via the internet is not
        completely secure. Although we will do our best to protect your personal
        data, we cannot guarantee the security of your data transmitted to our
        site; any transmission is at your own risk. Once we have received your
        information, we will use strict procedures and security features to try
        to prevent unauthorized access.
      </Text>

      <Title level={3}>Your rights – access to your personal data</Title>
      <Text>
        You have the right to ensure that your personal data is being processed
        lawfully (“Subject Access Right”). Your subject access right can be
        exercised in accordance with data protection laws and regulations. Any
        subject access request must be made in writing to <Link strong href="mailto:info@tretek.io">info@tretek.io</Link>. We
        will provide your personal data to you within the statutory time frames.
        To enable us to trace any of your personal data that we may be holding,
        we may need to request further information from you. If you complain
        about how we have used your information, you have the right to complain
        to the Information Commissioner’s Office (ICO).
      </Text>

      <Title level={3}>Changes to our privacy policy</Title>
      <Text>
        Any changes we may make to our privacy policy in the future will be
        posted on this page and, where appropriate, notified to you by e-mail.
        Please check back frequently to see any updates or changes to our
        privacy policy.
      </Text>

      <Title level={3}>Contact</Title>
      <Text>
        Questions, comments, and requests regarding this privacy policy are
        welcomed and should be addressed to <Link strong href="mailto:info@tretek.io">info@tretek.io</Link>.
      </Text>
    </div>
  );
};

export default PrivacyPolicy;
