import { icon } from "@fortawesome/fontawesome-svg-core"
import { Button, Dropdown } from "antd"
import { FaPen, FaRegTrashAlt } from "react-icons/fa"
import { IoMdMore } from "react-icons/io"

const ProxyOptions = ({id, onDelete, onUpdate}) => {
    const items = [
        {
            key: 1,
            label:"Update selected setting",
            icon: <FaPen />,
            onClick: () => onUpdate(id)
        },
        {
            key: 2,
            label:"Delete selected setting",
            danger: true,
            icon: <FaRegTrashAlt />,
            onClick: () => onDelete(id)
        }
    ]
    return <>
    <Dropdown menu={{ items }} trigger={["click"]}>
        
        <Button type="text" shape="circle"
        icon={<IoMdMore style={{ fontSize: 20 }} />}/>
        </Dropdown>
    </>
}
export default ProxyOptions