import React, { useContext, useState } from "react";
import YourTemplates from "./YourTemplates";
import TapZapTemplates from "../Email/TapZapTemplates";
import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  Space,
  Tabs,
  Typography,
} from "antd";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { CiTextAlignLeft } from "react-icons/ci";
import { HiOutlineTemplate } from "react-icons/hi";
import { FiPlus } from "react-icons/fi";
import { SearchContext } from "../../../context/SearchContext";
const ListTemplates = ({ type }) => {
  const { debouncedValue } = useContext(SearchContext);

  const { Title } = Typography;
  const [showFilters, setShowFilters] = useState(false);

  const [total, setTotal] = useState(0);

  const [addOpen, setAddOpen] = useState(false);
  return (
    <>
      <CreateTemplateModal open={addOpen} setOpen={setAddOpen} />
      <Card className="zero-margin-padding no-body-card end-to-end-header">
        <Tabs
          centered
          defaultActiveKey="1"
          items={[
            {
              key: "1",
              label: "Your designs",
              children: (
                <YourTemplates
                  setTotal={setTotal}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                  search={debouncedValue}
                />
              ),
            },
            {
              key: "2",
              label: "TAPZAP designs",
              children: (
                <TapZapTemplates
                  setTotal={setTotal}
                  search={debouncedValue}
                  showFilters={showFilters}
                  setShowFilters={setShowFilters}
                />
              ),
            },
          ]}
          tabBarExtraContent={{
            left: (
              <Title style={{ margin: 10 }} level={4}>
                All designs ({total})
              </Title>
            ),
            right: (
              <Space style={{ margin: 10 }}>
                <Button
                  size="large" style={{ borderRadius: 15 }}
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                 
                  Filter
                </Button>

                <Button
                  type="primary"
                  size="large"
                  className="flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  style={{ borderRadius: 15 }}
                  onClick={() => setAddOpen(true)}
                >
                  New design
                </Button>
              </Space>
            ),
          }}
        ></Tabs>
      </Card>
    </>
  );
};

const CreateTemplateModal = ({ open, setOpen }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  return (
    <>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        title={
          <Title style={{ margin: 0 }} level={4}>
            What template do you want to create?
          </Title>
        }
      >
        <Space direction="vertical" className="w-100" size={"large"}>
          <Text style={{ fontSize: 16 }}>
            Choose what kind of template you want to create from scratch and
            reuse it whenever you need it.
          </Text>
          <Row gutter={12}>
            <Col md={12}>
              <Button onClick={() => navigate("/Templates/CreateSimple")}
                size="large"
                icon={<CiTextAlignLeft />}
                block
                className="flex-button bold-button"
                style={{height:50}}
              >
                Simple & Personalized
              </Button>
            </Col>
            <Col md={12}>
              <Button onClick={() => navigate("/Templates/Create")}
                size="large"
                icon={<HiOutlineTemplate />}
                block
                className="flex-button bold-button"
                style={{height:50}}
              >
                Advanced & Templated
              </Button>
            </Col>
          </Row>
        </Space>
      </Modal>
    </>
  );
};
export default ListTemplates;
