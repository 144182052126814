import {
  Button,
  Dropdown,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DownOutlined } from "@ant-design/icons";
import SmartText from "../../../components/SmartText";
const AddSmsTemplate = ({ open, setOpen, onSubmit }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  const { Text, Title } = Typography;

  const [form] = Form.useForm();

  const [text, setText] = useState();

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post("/SmsTemplate", e);

    if (response.data.success) {
      setOpen(false);
      onSubmit(response.data);
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const handleAddUserdata = (key) => {
    if (key === 1) {
      form.setFieldValue(
        "text",
        `${form.getFieldValue("text") ?? ""} -fullname-`
      );
    } else if (key === 2) {
      form.setFieldValue(
        "text",
        `${form.getFieldValue("text") ?? ""} -firstname-`
      );
    } else if (key === 3) {
      form.setFieldValue(
        "text",
        `${form.getFieldValue("text") ?? ""} -lastname-`
      );
    } else if (key === 4) {
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ""} -email-`);
    } else {
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ""} -phone-`);
    }
  };
  const items = [
    {
      label: "User fullname",
      key: 1,
      onClick: () => handleAddUserdata(1),
    },
    {
      label: "User firstname",
      key: 2,
      onClick: () => handleAddUserdata(2),
    },
    {
      label: "User lastname",
      key: 3,
      onClick: () => handleAddUserdata(3),
    },
    {
      label: "User email",
      key: 4,
      onClick: () => handleAddUserdata(4),
    },
    {
      label: "User phone number",
      key: 5,
      onClick: () => handleAddUserdata(5),
    },
  ];
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Add new sms template
        </Title>
      }
      footer={null}
      destroyOnClose={true}
      onCancel={() => setOpen(false)}
    >
      <Form onFinish={submit} preserve={false} form={form}>
        <Text>Name</Text>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter a name for your template",
            },
            {
              max: 100,
              message: "Name maximum length is 100 characters",
            },
          ]}
        >
          <Input placeholder="Enter template name" size="large" />
        </Form.Item>

        <Flex align="center" justify="space-between" style={{ marginBottom: 5 }}>
          <Text>Text</Text>

          <Space>

            <Dropdown menu={{ items }} trigger={["click"]}>
              <Button style={{ width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }} onClick={(e) => e.preventDefault()}>
                <DownOutlined />
              </Button>
            </Dropdown>
            <SmartText text={text} setText={e => form.setFieldValue("text", e)} />
          </Space>
        </Flex>
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: "Please enter a message text",
            },
            {
              max: 1600,
              message:
                "The maximum length of the text message is 1600 characters",
            },
          ]}
        >
          <TextArea
            size="large"
            placeholder="Enter template text"
            onChange={(e) => setText(e.target.value)}
            count={{
              show: true,
            }}
            rows={10}
          />
        </Form.Item>

        <Flex align="center" justify="end" gap={10} style={{ marginTop: 20 }}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button type="primary" loading={loading} htmlType="submit">
            Save changes
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default AddSmsTemplate;
