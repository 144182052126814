import { Navigate, Outlet, useLoaderData, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const RequireLevel2Data = () => {
    const {auth} = useAuth();

    const location = useLocation();


    return auth?.step == 1 ? <Navigate to="/confirm-email" state={{from: location}} replace/> : 
        auth?.step == 2 ? <Navigate to="/create" state={{from: location}} replace/> : auth?.step == 3 ? <Outlet/> : <Navigate to="/create" state={{from: location}} replace/> 
}

export default RequireLevel2Data;