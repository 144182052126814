import { Collapse, Typography, Button, Space, Row, Flex } from "antd";
import emails from "../../../assets/images/emails.png";
import sms from "../../../assets/images/sms.png";
import whatsapp from "../../../assets/images/whatsapp.png";
import { useNavigate } from "react-router-dom";
import { CaretRightOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { GrMoreVertical } from "react-icons/gr";
import { FaArrowRight } from "react-icons/fa";

const CreateYourFirstEmail = () => {
  const { Title } = Typography;
  const { theme } = useContext(ThemeContext);

  return (
    <>
    <Space size={24} className="w-100" direction="vertical">

      <Collapse
        bordered={false}
        gap={24}
        style={{boxShadow: theme == 'light' ? "14px 17px 40px 4px rgba(112,144,176,.08)" : "rgba(0, 0, 0, 0.04) 14px 17px 40px 4px"}}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        items={[
          {
            key: "1",
            label: (
              <Flex align="center" justify="space-between">
                <Title level={5} style={{ margin: 0 }}>
                  Create your first email
                </Title>
                <Button
                  icon={
                    <GrMoreVertical
                      style={{ color: theme == "light" ? "#422afb" : "#fff" }}
                    />
                  }
                  className="flex-button"
                  style={{
                    background: theme == "light" ? "#f4f7fe" : "#1f294f",
                    height: 25,
                    width: 25,
                    border: "none",
                  }}
                ></Button>
              </Flex>
            ),
            children: <CreateEmailView />,
          },
         
        ]}
      ></Collapse>

      <Collapse  bordered={false}
        gap={24} 
        style={{boxShadow: theme == 'light' ? "14px 17px 40px 4px rgba(112,144,176,.08)" : "rgba(0, 0, 0, 0.04) 14px 17px 40px 4px"}}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )} items={[
         {
          key: 2,
          label: (
            <Flex align="center" justify="space-between">
              <Title level={5} style={{ margin: 0 }}>
                Send your first sms message
              </Title>
              <Button
                icon={
                  <GrMoreVertical
                    style={{ color: theme == "light" ? "#422afb" : "#fff" }}
                  />
                }
                className="flex-button"
                style={{
                  background: theme == "light" ? "#f4f7fe" : "#1f294f",
                  height: 25,
                  width: 25,
                  border: "none",
                }}
              ></Button>
            </Flex>
          ),
          children: <CreateSmsView />,
        },
      ]}>
      </Collapse>

      <Collapse  bordered={false}
        gap={24} 
        style={{boxShadow: theme == 'light' ? "14px 17px 40px 4px rgba(112,144,176,.08)" : "rgba(0, 0, 0, 0.04) 14px 17px 40px 4px"}}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )} items={[
        {
          key: 3,
          label: (
            <Flex align="center" justify="space-between">
              <Title level={5} style={{ margin: 0 }}>
                Check out whatsapp features
              </Title>
              <Button
                icon={
                  <GrMoreVertical
                    style={{ color: theme == "light" ? "#422afb" : "#fff" }}
                  />
                }
                className="flex-button"
                style={{
                  background: theme == "light" ? "#f4f7fe" : "#1f294f",
                  height: 25,
                  width: 25,
                  border: "none",
                }}
              ></Button>
            </Flex>
          ),
          children: <CreateWhatsappView />,
        },
      ]}>
      </Collapse>
    </Space>

    </>
  );
};

const CreateEmailView = () => {
  const { Title, Text } = Typography;
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          height: "250px",
          background: `url(${emails}) no-repeat`,
          backgroundPosition: "right",
          borderBottomRightRadius: 20,
        }}
      >
        <Flex
          align="start"
          justify="center"
          vertical
          gap={12}
          style={{ height: "250px", padding: 20 }}
        >
          <Space size={0} direction="vertical">
            <Title level={2} style={{ margin: 0 }}>
              Design high-performace emails in minutes
            </Title>
            <Text>
              Get started with flexible templates, drag and drop design tools,
              and our built in, expert advice.
            </Text>
          </Space>
          <Space>
            <Button
              size="large"
              onClick={() => navigate("/Campaigns/Create/0")}
              type="primary"
              icon={<FaArrowRight />}
              className="flex-button"
            >
              Create email campaign
            </Button>
            <Button
              size="large"
              type="link"
              onClick={() => navigate("/Templates/Tapzap")}
            >
              View everything we can build
            </Button>
          </Space>
        </Flex>
      </div>
    </>
  );
};

const CreateSmsView = () => {
  const { Title, Text } = Typography;
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        background: "linear-gradient(15.46deg, #4A25E1 26.3%, #7B5AFF 86.4%)",
      }}
    >
      <div
        style={{
          height: "250px",
          background: `url(${sms}) no-repeat`,
          backgroundPosition: "right",
          borderBottomRightRadius: 20,
        }}
      >
        <Flex
          align="start"
          justify="center"
          vertical
          gap={12}
          style={{ height: "250px", padding: 20 }}
        >
          <Space size={0} direction="vertical">
            <Title level={2} style={{ margin: 0, color: "#fff" }}>
              Increase your reach with SMS campaigns
            </Title>

            <Text style={{ color: "#fff" }}>
              Get started with flexible templates, drag and drop design tools,
              and our built in, expert advice.
            </Text>
          </Space>

          <Space>
            <Button
              size="large"
              icon={<FaArrowRight />}
              onClick={() => navigate("/Campaigns/Create/2")}
              className="flex-button"
            >
              Create sms campaign
            </Button>
            <Button
              size="large"
              type="text"
              style={{ color: "white" }}
              onClick={() => navigate("/Templates/Sms")}
            >
              Build your sms templates
            </Button>
          </Space>
        </Flex>
      </div>
    </div>
  );
};

const CreateWhatsappView = () => {
  const { Title, Text } = Typography;
  const { theme } = useContext(ThemeContext);
  const navigate = useNavigate();

  return (
    <div
      style={{
        background:
          "linear-gradient(135deg, rgba(143,252,160,1) 0%, rgba(11,203,251,1) 100%)",
      }}
    >
      <div
        style={{
          height: "250px",
          background: `url(${whatsapp}) no-repeat`,
          backgroundPosition: "right",
          borderBottomRightRadius: 20,
          backgroundSize: "cover",
        }}
      >
        <Flex
          align="start"
          justify="center"
          vertical
          gap={12}
          style={{ height: "250px", padding: 20 }}
        >
          <Space size={0} direction="vertical">
            <Title level={2} style={{ margin: 0 }}>
              Use whatsapp for your business
            </Title>
            <Text>
              Get started with flexible templates, drag and drop design tools,
              and our built in, expert advice.
            </Text>
          </Space>

          <Space>
            <Button
              size="large"
              onClick={() => navigate("/Campaigns/Create/1")}
              icon={<FaArrowRight />}
              className="flex-button"
            >
              Create whatsapp campaign
            </Button>
            <Button
              size="large"
              type="text"
              onClick={() => navigate("/Templates/Create/Whatsapp")}
            >
              Build your whatsapp template
            </Button>
          </Space>
        </Flex>
      </div>
    </div>
  );
};
export default CreateYourFirstEmail;
