import {
  Button,
  Card,
  Flex,
  Space,
  Table,
  Typography,
  Grid
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import { FiPlus } from "react-icons/fi";
import WhatsAppTemplateOptions from "./WhatsAppTemplateOptions";
import { MdOutlineClose } from "react-icons/md";
import { FaCheck } from "react-icons/fa";

const { useBreakpoint } = Grid;

const WhatsAppTemplateList = () => {
  const { Title, Text, Link } = Typography;
  const axios = useAxiosPrivate();
  const navigate = useNavigate();

  const screens = useBreakpoint();

  const [data, setData] = useState();
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(1);
  const perPage = 7;

  useEffect(async () => {
    const response = await axios.post("/Whatsapp/GetAll", {
      page: page,
      pageSize: perPage,
    });

    setData(response.data.data);
    setCount(response.data.count);
  }, [page]);

  const columns = [
    {
      title: "Data",
      render: (data, row) => (<Text strong type="link" onClick={() => navigate(`/Templates/Edit/Whatsapp/${row.id}`)}>{row.name}</Text>
      ),
    },
    {
      title: "APPROVED",
      responsive:['sm'],
      render: (data, row) => <>
        {row.status == "APPROVED" && <div
          style={{
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: "#1cc88a",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <FaCheck style={{ color: "white" }} />
        </div>}

        {(row.status != "APPROVED") && <div
          style={{
            width: 25,
            height: 25,
            borderRadius: 25,
            backgroundColor: "#e74a3b",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
        </div>}</>
    },
    {
      title: "Language",
      responsive:['sm'],
      render: (data, row) => <Text strong>{row.language}</Text>
    },
    {
      title: "Date",
      responsive:['md'],
      render: (data, row) => <Text strong>{new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}</Text>
    },
    {
      width: 50,
      render: (data, row) => (<WhatsAppTemplateOptions id={row.id} onDelete={handleDelete} />),
    },
  ];

  const handleDelete = (id) => {
    setData((prev) => prev.filter((d) => d.groupId != id));
  };

  return (
    <Space style={{ width: "100%" }} direction="vertical">
      <Card className="no-body-card end-to-end-header zero-margin-padding"
        title={
          <Flex align="center" justify="space-between" wrap="wrap"
            style={{
              ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
            }}>
            <Title style={{ margin: 0 }} level={4}>
              All designs ({count})
            </Title>

            <Button
              type="primary"
              size="large"
              block={screens.xs}
              style={{ borderRadius: 15 }}
              icon={<FiPlus style={{ fontSize: 20 }} />}
              className="primary-gradient-background flex-button bold-button"
              onClick={() => navigate("/Templates/Create/Whatsapp")}
            >
              New design
            </Button>
          </Flex>
        }
      ></Card>

      <Flex align="center" justify="space-between">

      </Flex>
      <Card className="zero-margin-padding">

        <Table
          dataSource={data}
          columns={columns}
          pagination={{
            page: page,
            total: count,
            pageSize: perPage,
            onChange: (page, pageSize) => {
              setPage(page);
            },
          }}
        ></Table>
      </Card>
    </Space>
  );
};

export default WhatsAppTemplateList;
