import {
  Button,
  Card,
  Typography,
  Checkbox,
  Flex,
  Space,
  Dropdown,
  message,
} from "antd";
import { TbListDetails } from "react-icons/tb";
import DeleteAudience from "../CRUD/DeleteAudience";
import AudienceCardStats from "./AudienceCardStats";
import { Link, useNavigate } from "react-router-dom";
import { FaCopy } from "react-icons/fa";
import { BsFillTrashFill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";
const AudienceCard = ({
  audience,
  onDelete,
  selectable,
  selected,
  setSelected,
  disabled = false,
}) => {
  const { Text, Title } = Typography;

  const navigate = useNavigate();

  return (
    <>
      <Card
        style={{
          height:"100px"
        }}
        key={audience.id}
      >
        <Flex
          style={{ width: "100%"}}
          justify="space-between"
          align="center"
          size={30}
        >
          <Space  direction="vertical" size={0} style={{width:"90%"}}>
            <Space style={{ width:"100%"}}>
            {selectable && (
                <Checkbox
                  size="large"
                  disabled={disabled}
                  checked={selected}
                  onChange={(e) => setSelected(!selected, audience.id)}
                />
              )}
              
              <Text strong onClick={() => navigate(`/audience/details/${audience.id}`)} type="link" level={5} style={{margin:0, width:"200px"}} ellipsis>
                {audience.name}
              </Text>
              
            </Space>

            <AudienceCardStats audience={audience} />
          </Space>

          <Flex
            align="center"
            justify="end"
          >
          
            <AudienceCardDropDown
              id={audience.id}
              onDelete={onDelete}
              apiId={audience.apiId}
            />

          </Flex>
        </Flex>
      </Card>
    </>
  );
};
export default AudienceCard;

const AudienceCardDropDown = ({ id, onDelete, apiId }) => {
  const items = [
    {
      key: "1",
      label: "Copy audience api id",
      icon: <FaCopy />,
      onClick: async () => {
        try {
          await navigator.clipboard.writeText(apiId);
          message.success("Audience api id copied to clipboard.");
        } catch (err) {
          message.error("Something went wrong.");
        }
      },
    },
    {
      key: "2",
      label: <Link to={`/audience/details/${id}`}>View details and edit</Link>,
      icon: <TbListDetails />,
    },
    {
      key: "3",
      label: <DeleteAudience id={id} onDelete={onDelete} />,
      danger: true,
      icon: <BsFillTrashFill />,
    },
  ];

  return (
    <>
      <Dropdown trigger={["click"]} menu={{ items }}>
        <Button type="text"
          className="flex-button"
          icon={<IoMdMore style={{fontSize:20}}/>}
          shape="circle"
        ></Button>
      </Dropdown>
    </>
  );
};
