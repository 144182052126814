import React, { useContext } from 'react'
import { DealsDetailsContext } from '../context/DealsDetailsContext'

const useDealsDetailsCtx = () => {
    const DealDetailsCtx = useContext(DealsDetailsContext)
  return (
    DealDetailsCtx
  )
}

export default useDealsDetailsCtx