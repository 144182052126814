import { Outlet } from "react-router-dom";
import { SmsListContext, SmsListContextProvider } from "./SmsListContext";

const SmsLayout = () => {
  return (
    <SmsListContextProvider>
      <Outlet />
    </SmsListContextProvider>
  );
};

export default SmsLayout;
