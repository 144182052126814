import { Button, Col, Flex, List, Row, Space, Typography } from "antd";
import { FaRegClock } from "react-icons/fa";

const SmsAudience = ({ details, handlePrint }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Title level={3}>Data</Title>

      <Row gutter={12}>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List
            bordered
            dataSource={[
              {
                name: "Audience",
                value: details.audience,
              },
              {
                name: "Template",
                value: details.subject,
              },
            ]}
            renderItem={(item) => (
              <List.Item>
                <Flex
                  align="center"
                  justify="space-between"
                  style={{ width: "100%" }}
                >
                  <Text strong>{item.name}</Text>
                  <Text>{item.value}</Text>
                </Flex>
              </List.Item>
            )}
          />
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <List
            bordered
            dataSource={[
              {
                name: "Delivered",
                value: new Date(details.delivered).toDateString(),
              },
              {
                name: "Subject",
                option: true,
              },
            ]}
            renderItem={(item) => <List.Item>
                {item.option&&<SmsOptions handlePrint={handlePrint}/>}
                {!item.option&&<Flex style={{width:"100%"}} align="center" justify="space-between">
                        <Text strong>{item.name}</Text>
                        <Text className="icon-flex text-light">
                            <FaRegClock/> {item.value}
                        </Text>
                    </Flex>}
            </List.Item>}
          />
        </Col>
      </Row>
    </>
  );
};

const SmsOptions = ({handlePrint}) => {
    return <Space>
        <Button size="small" onClick={() => handlePrint()}>Print</Button>•
        <Button size="small" disabled>Download</Button>
    </Space>
}

export default SmsAudience;
