import { Outlet } from "react-router-dom"
import { ProductListContextProvider } from "./ProductListContext";

const ProductLayout = () => {
    return <ProductListContextProvider>

        <Outlet />
    </ProductListContextProvider>

}

export default ProductLayout;