import { Button, Flex, message, Modal, Space, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";

const ImmediateActionConfirmationModal = ({ refresh, open, setOpen, amountToRemove, jobType, id }) => {
    const { Text, Title } = Typography;

    const axios = useAxiosPrivate();
    const [loading, setLoading] = useState(false);

    const submit = async () => {
        setLoading(true)
        let response = await axios.delete(`/CleanUp/results/${id}`);
        if(response.data.success){
            setOpen(false);
            refresh(prev => !prev)
        }else{
            message.error(response.data.message)
        }

        setLoading(false)
    }

    return <>

        <Modal open={open} title={<Title style={{ margin: 0 }} level={4}>
            Take Immediate Action
        </Title>} onCancel={() => setOpen(false)} footer={
            <Flex align="center" justify="end" gap={6}>
                <Button onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button type="primary" loading={loading} onClick={() => submit()}>
                    I Understand
                </Button>
            </Flex>
        }>

            <Space className="w-100" direction="vertical" size={24}>

                <Text style={{ fontSize: 16 }}>
                    * Warning, This action will permanently delete up to {amountToRemove} emails and cannot be undone. Please confirm before proceeding.
                </Text>

                <Text style={{ fontSize: 16 }}>* This action will only keep all the emails that TAPZAP Smart Things Smart Validation marked as <Text strong>{jobType == 1 ? "Safe" : jobType == 2 ? "Safe or Unknown" : "Safe or Accepts Mail"}</Text></Text>
            </Space>
        </Modal>
    </>
}

export default ImmediateActionConfirmationModal;