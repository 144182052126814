import { useContext } from "react";
import { ProductListContext } from "../ProductListContext";
import { DatePicker, Typography } from "antd";

const DateFromProductFilter =() => {

    const {filters ,setFilters} = useContext(ProductListContext)
    const {Text} = Typography;

    return <>
        <Text>From</Text>
        <DatePicker size="large"
            placeholder="Select created date start"
            defaultValue={filters?.from}
            onChange={(e) => setFilters(prev=>({
                ...prev,
                from: e
            }))}
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default DateFromProductFilter;