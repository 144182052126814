import {
  Input,
  Form,
  Button,
  Modal,
  Typography,
  Space,
  Segmented,
  message,
  Flex,
} from "antd";
import { useState } from "react";
import TapZapAudience from "../TapZapAudience";
import UploadCsv from "../UploadCsv";
import Api from "../Api";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const CreateAudience = ({ isModalOpen, setIsModalOpen, setAudiences }) => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();

  const [loading, setLoading] = useState(false);

  const [audienceType, setAudienceType] = useState(1);

  const [name, setName] = useState();

  const [selectedIndustries, setSelectedIndustries] = useState();
  const [selectedCountries, setSelectedCountries] = useState();

  const [file, setFile] = useState();

  const [method, setMethod] = useState(null);
  const [url, setUrl] = useState(null);
  const [auth, setAuth] = useState(null);
  const [http, setHttp] = useState("http://");
  const [headers, setHeaders] = useState();
  const [hasHeaders, setHasHeaders] = useState(false);
  const handleOk = async () => {
    setLoading(true);

    var formData = new FormData();

    formData.append("Name", name);

    if (audienceType == 1) {

      if(!headers || headers.length == 0){
        message.error("Provide headers");
        return;
      }



      formData.append("CsvContact", file);
      formData.append("AudienceType", 2);
      formData.append("HasHeader", hasHeaders);

      let counter = 0;
      let hasError = false;
      headers.forEach((header, ind) => {
        if (header.canBeSend) {

          if(!header.value){
            hasError = true;
            return;
          }

          formData.append(`csvHeaders[${counter}].name`, header.name);
          formData.append(`csvHeaders[${counter}].value`, header.value);
          formData.append(`csvHeaders[${counter}].ind`, header.ind);
          counter += 1;
        }
      });

      if(hasError){
        setLoading(false)
        message.error("Missing header value");
        return;
      }
    } else if (audienceType == 2) {
      selectedIndustries?.forEach((item, index) => {
        formData.append(`Industries[${index}]`, item);
      });

      selectedCountries?.forEach((item, index) => {
        formData.append(`Countries[${index}]`, item);
      });

      formData.append("AudienceType", 1);
    } else {
      formData.append("AudienceType", 3);
      formData.append("Method", method);
      formData.append("Url", http + url);
      formData.append("Authorization", auth);
    }

    const response = await axios.post(
      "/TargetAudience/CreateAudience",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    setLoading(false);
    if (response.data.success) {
      setAudiences((prev) => [...prev, response.data.data]);
      message.open({
        type: "success",
        content: "Audience created with success!",
        duration: 4,
      });
      setIsModalOpen(false);
    } else {
      message.open({
        type: "error",
        content: response.data.message,
        duration: 4,
      });
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Create a new audience
          </Title>
        }
        onCancel={handleCancel}
        onOk={handleOk}
        footer={
          <>
            <Flex align="center" justify="end">
              <Space>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button type="primary" loading={loading} onClick={handleOk}>
                  Save
                </Button>
              </Space>
            </Flex>
          </>
        }
      >
        <Space direction="vertical" style={{ width: "100%",  maxHeight:"60vh",
          overflowY:"auto", overflowX:"hidden" }} >
          <Form>
            <Form.Item>
              <label>Name</label>
              <Input
                placeholder="Enter audience name"
                allowClear
                size="large"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
          </Form>

          <Segmented
            block
            onChange={(e) =>
              setAudienceType(e == "csv" ? 1 : e == "api" ? 3 : 2)
            }
            options={[
              {
                label: (
                  <div
                    style={{
                      padding: 4,
                    }}
                  >
                    <Title level={5}>CSV</Title>
                    <Text>Import a csv file</Text>
                  </div>
                ),
                value: "csv",
              },
              {
                label: (
                  <div
                    style={{
                      padding: 4,
                    }}
                  >
                    <Title level={5}>TapZap</Title>
                    <Text>Use subscribers list</Text>
                  </div>
                ),
                value: "tapzap",
              },
              {
                label: (
                  <div
                    style={{
                      padding: 4,
                    }}
                  >
                    <Title level={5}>API</Title>
                    <Text>Use an endpoint</Text>
                  </div>
                ),
                value: "api",
              },
            ]}
          />
          {audienceType == 1 && (
            <UploadCsv
              setHasHeaders={setHasHeaders}
              hasHeader={hasHeaders}
              headers={headers}
              setHeaders={setHeaders}
              file={file}
              setFile={setFile}
            />
          )}
          {audienceType == 2 && (
            <TapZapAudience
              selectedCountries={selectedCountries}
              selectedIndustries={selectedIndustries}
              setSelectedCountries={setSelectedCountries}
              setSelectedIndustries={setSelectedIndustries}
            />
          )}
          {audienceType == 3 && (
            <Api
              method={method}
              url={url}
              auth={auth}
              http={http}
              setMethod={setMethod}
              setUrl={setUrl}
              setAuth={setAuth}
              setHttp={setHttp}
            />
          )}
        </Space>
      </Modal>
    </>
  );
};

export default CreateAudience;
