import { Button, Dropdown, Flex, Form, Input, Modal, Space, Typography, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DownOutlined } from "@ant-design/icons";
import SmartText from "../../../components/SmartText";
const EditSmsTemplate = ({ open, setOpen, id, onUpdate }) => {
    const [form] = Form.useForm();
  const [text, setText] = useState();

  const [loading, setLoading] = useState(false);
  const { Text, Title } = Typography;

  const handleAddUserdata = (key) => {
    if(key === 1){
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ''} -fullname-`);
    }else if(key === 2){
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ''} -firstname-`)
    }else if(key === 3){
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ''} -lastname-`)
    }else if(key === 4){
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ''} -email-`)
    }else{
      form.setFieldValue("text", `${form.getFieldValue("text") ?? ''} -phone-`)
    }
  }

  const axios = useAxiosPrivate();
    useEffect(async () => {
        const response = await axios.get(`/SmsTemplate/${id}`)
    
        if(response.data.success){
            form.setFieldValue('name', response.data.name);
            form.setFieldValue('text', response.data.text)
          setText(response.data.text)
          }
    }, [])

  const update = async (e) => {
    setLoading(true);
    const response = await axios.put(`/SmsTemplate`, { ...e, id: id });

    if (response.data.success) {
      onUpdate(response.data);
      setOpen(false)
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const items = [
    {
      label:"User fullname",
      key:1,
      onClick: () => handleAddUserdata(1)
    },
    {
      label:"User firstname",
      key:2,
      onClick: () => handleAddUserdata(2)
    },
    {
      label:"User lastname",
      key:3,
      onClick: () => handleAddUserdata(3)
    },
    {
      label:"User email",
      key: 4,
      onClick: () => handleAddUserdata(4)
    },
    {
      label:"User phone number",
      key:5,
      onClick: () => handleAddUserdata(5)
    }
  ]
  return (
    <Modal
    title={
      <Title style={{ margin: 0 }} level={4}>
        Edit sms template
      </Title>
    }
      open={open}
      destroyOnClose
      onCancel={() => setOpen(false)}
      footer={null}
    >
      <Form preserve={false} onFinish={update} form={form}>
        <Text>Name</Text>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter a name for your template",
            },
            {
              max: 100,
              message: "Name maximum length is 100 characters",
            },
          ]}
        >
          <Input size="large" placeholder="Please enter a name" />
        </Form.Item>
        <Flex align="center" justify="space-between" style={{marginBottom:5}}>
          <Text>Text</Text>

          <Space>

          <Dropdown menu={{ items }} trigger={["click"]}>
            <Button onClick={(e) => e.preventDefault()} style={{ width: 30, height: 30, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <DownOutlined />
            </Button>
          </Dropdown>
          <SmartText text={text} setText={(e) => {
            setText(e)
            form.setFieldValue("text", e)
          }}/>
          </Space>
        </Flex>
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: "Please provide a text for your sms",
            },
            {
              max: 1600,
              message:
                "The maximum length of the text message is 1600 characters",
            },
          ]}
        >
          <TextArea  autoSize={{ minRows: 7, maxRows: 10 }}
            size="large"
            count={{
              show: true,
            }}
            placeholder="Enter a text for your sms"
          ></TextArea>
        </Form.Item>
        <Flex align="center" justify="end" gap={10}>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button htmlType="submit" type="primary" loading={loading}>
            Save changes
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default EditSmsTemplate;
