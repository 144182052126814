import { Button, message } from "antd";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import SenderCreated from "../../pages/users/Senders/whatsapp/SenderCreated";
import { GrLinkNext } from "react-icons/gr";
const FacebookLogin = () => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "408327418299959",
        cookie: true,
        xfbml: true,
        version: "v19.0",
      });
    };

    // Load the Facebook SDK script
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const launchWhatsAppSignup = () => {
    setLoading(true);
    window.fbq &&
      window.fbq("trackCustom", "WhatsAppOnboardingStart", {
        appId: "408327418299959",
        feature: "whatsapp_embedded_signup",
      });

    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
        
          const tapzapResponse = axios.post(`/Whatsapp/token/generate/${code}`);
       

          setShowSuccess(true);
        } else {
          message.show("User cancelled login or did not fully authorize.");
        }

        setLoading(false);
      },
      {
        config_id: "422862510272971",
        response_type: "code",
        override_default_response_type: true,
        extras: {
          setup: {},
        },
        scope: "business_management,whatsapp_business_management"
      }
    );
  };

  return (
    <>
      <Button
        type="primary"
        size="large"
        className="primary-gradient-background bold-button flex-button"
        loading={loading}
        icon={<GrLinkNext />}
        onClick={launchWhatsAppSignup}
      >
        Continue to connect
      </Button>

      <SenderCreated open={showSuccess} setOpen={setShowSuccess} />
    </>
  );
};

export default FacebookLogin;
