import { Button, Card, Space, Tabs, Typography } from "antd";
import Header from "./CreateTemplateData/Header";
import Body from "./CreateTemplateData/Body";
import Footer from "./CreateTemplateData/Footer";
import {Buttons} from "./CreateTemplateData/Buttons";
import { useContext, useState } from "react";
import { WhatsAppTemplateContext } from "../../../context/WhatsAppTemplateContext";
import Name from "./CreateTemplateData/Name";

const CreateWhatsApptemplateData = () => {
  const {currentLanguage} = useContext(WhatsAppTemplateContext)
  
  const {Title, Text} = Typography
  const [current, setCurrent] = useState("1")

  return (<Space style={{width:"100%"}} direction="vertical" >
    <Card className="zero-margin-padding" style={{position:"sticky", top:85 }}>
      <Tabs activeKey={current} centered defaultActiveKey="1" onChange={e => setCurrent(e)} items={[
      {
        label:"Header",
        key:"1"
      },
      {
        label:"Body",
        key:"2"
      },
      {
        label:"Footer",
        key:"3"
      },
      {
        label:"Buttons",
        key:"4"
      }
    ]}/>

  <div style={{margin:20}}>

      <Title level={3} style={{margin:0}}>Create whatsapp template</Title>
      <Name/>
  </div>
</Card>

<Card>
      <Space style={{width:"100%"}} direction="vertical">

      
      {(current=="1")&&<Header setCurrent={setCurrent}/>}
        {(current=="2")&&<Body setCurrent={setCurrent}/>}
        {(current=="3")&&<Footer setCurrent={setCurrent}/>}
        {(current=="4")&&<Buttons setCurrent={setCurrent}/>}
   

      </Space>

    </Card></Space>
  );
};

export default CreateWhatsApptemplateData;
