import React, { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CardLoading from "../../../shared/CardLoading";

const ContactReachedPerDayChart = ({id}) => {

    const axios = useAxiosPrivate();
    const [months, setMonths] = useState();
    const [data, setData] = useState(() => []);
    const [loading, setLoading] = useState(() => false)

    const statuses = ["delivered", "processed", "dropped", "open", "deferred", "click", "bounce", "spamreport"];

    useEffect(() => {
      if(data.length == statuses.length){
        setLoading(false)
      }
    }, [data])

    useEffect(async () => {
      setLoading(true)  
  
      statuses.forEach(async (s) => {
  
        var url = `/Stats/reached/audience/perday?Status=${s}&Id=${id}`;
        if (id) {
          url += `&Id=${id}`;
        }
  
        const response = await axios.get(url);
        
        let color = "";

      if(s == "dropped"){
        color = "#763262"
      }else if(s == "delivered"){
        color="#80d8fa"
      }else if(s == "processed"){
        color = "#5f3aff"
      }else if(s == "open"){
        color = "#f0a100"
      }else if(s == "deferred"){
        color = "#0d430e"
      }else if(s == "click"){
        color = "#FF0060"
      }else if(s == "bounce"){
        color = "#4F200D"
      }else{
        color = "#FE6244"
      }

        setData(prev =>[...prev, { name: s, data: response.data.items.map((c) => c.count), color: color}]);
  
        if(!months){
          setMonths(response.data.items.map((c) => new Date(c.date).toDateString()));
        }
      });
    }, []);
  
    return <>
    {loading&&<CardLoading/>}
    {(data && data?.length == statuses.length) && <Chart data={data} categories={months} />}</>;
  };
  
  const Chart = ({ categories, data }) => {
    const [options, setOptions] = useState({
      chart: {
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
  
      markers: {
        size: 0,
      },
      xaxis: {
        categories,
      },
      yaxis: {
        title: {
          text: "",
        },
        labels: {
          show: false, // This hides the vertical (y-axis) labels
        },
      },
      grid: {
        show: false, // Hides both horizontal and vertical grid lines
      },
      
      legend: {
        show: false, // This hides the legend
      },
    });
  
    return (<>
      <div id="chart">
        <ApexCharts options={options} series={data} type="line" height={500} />
      </div>
    </>
    );
  };
export default ContactReachedPerDayChart;