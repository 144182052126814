import {
  Button,
  Flex,
  Space,
  Typography,
} from "antd";
import {
  MdAdd,
  MdArrowBack,
  MdCode,
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdInfo,
  MdOutlineArrowForward,
  MdOutlineZoomIn,
  MdOutlineZoomOut,
} from "react-icons/md";
import React, { useContext, useRef } from "react";
import { ContentState, EditorState, RichUtils } from "draft-js";
import Editor from "@draft-js-plugins/editor";
import "draft-js/dist/Draft.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import createEmojiPlugin from "draft-js-emoji-plugin";
import { WhatsAppTemplateContext } from "../../../../context/WhatsAppTemplateContext";
import SmartText from "../../../../components/SmartText";

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
const plugins = [emojiPlugin];

const Body = ({ setCurrent}) => {
  const { template, setTemplate } = useContext(
    WhatsAppTemplateContext
  );

  const { Text, Title } = Typography;
  const editorRef = useRef();

  const handleInlineStyle = (style) => {
    setTemplate(prev => ({...prev, body: RichUtils.toggleInlineStyle(prev.body, style)}));
  };

  const characterCount = template?.body?.getCurrentContent()?.getPlainText("")?.length;

  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical">
        <Flex align="center" gap={10} justify="space-between">
          <Space style={{ width: "100%" }} direction="vertical" size={0}>
            <Title style={{ margin: 0 }} level={3}>
              Body
            </Title>
            <Text>
              Enter the text for your message in the language you've selected.
            </Text>
          </Space>

          <Space>
          <SmartText text={template.body?.getCurrentContent().getPlainText()} setText={(e) => {
            const newContentState = ContentState.createFromText(e);
            const newEditorState = EditorState.createWithContent(newContentState);

            setTemplate(prev => ({...prev, body:  newEditorState}))
          }}/>
          <EmojiSelect />
          </Space>
        </Flex>

        <div
          style={{
            border: "1px solid #e1e1e1",
            padding: "20px",
            borderRadius: 20,
            minHeight: "400px",
            cursor: "text",
          }}
          onClick={() => editorRef.current.focus()}
        >
          <Editor
            editorState={template.body}
            onChange={(newState) => setTemplate(prev => ({...prev, body:  newState}))}
            plugins={plugins}
            ref={editorRef}
          />
        </div>

        <Flex justify="space-between" align="center">
          <Text>Characters {characterCount}/1024</Text>

          <Space>
            <Button
              icon={<MdFormatBold />}
              style={{ fontSize: 20 }}
              type="link"
              className="flex-button"
              onClick={() => handleInlineStyle("BOLD")}
            ></Button>
            <Button
              icon={<MdFormatItalic />}
              style={{ fontSize: 20 }}
              type="link"
              className="flex-button"
              onClick={() => handleInlineStyle("ITALIC")}
            ></Button>
            <Button
              icon={<MdFormatStrikethrough />}
              style={{ fontSize: 20 }}
              type="link"
              className="flex-button"
              onClick={() => handleInlineStyle("STRIKETHROUGH")}
            ></Button>
            <Button
              icon={<MdCode />}
              style={{ fontSize: 20 }}
              type="link"
              className="flex-button"
              onClick={() => handleInlineStyle("CODE")}
            ></Button>
            <Button icon={<MdAdd />} type="link" className="flex-button">
              Add variable
            </Button>
            <Button
              icon={<MdInfo />}
              type="link"
              className="flex-button"
            ></Button>

            <Button.Group>
              <Button
                shape="circle"
                className="flex-button"
                icon={<MdOutlineZoomOut style={{ fontSize: 20 }} />}
              />

              <Button
                shape="circle"
                className="flex-button"
                icon={<MdOutlineZoomIn style={{ fontSize: 20 }} />}
              />
            </Button.Group>
          </Space>
        </Flex>
        <Flex align="center" justify="space-between">
          <Button
            className="flex-button"
            type="link"
            icon={<MdArrowBack />}
            onClick={() => setCurrent("1")}
          >
            Back to header
          </Button>

          <Button
            type="primary"
            icon={<MdOutlineArrowForward />}
            className="flex-button"
            onClick={() => setCurrent("3")}
          >
            Continue to Footer
          </Button>
        </Flex>
      </Space>
    </>
  );
};
export default Body;
