import React, { memo, useContext } from 'react'
import CreateDealDrawer from '../CreateDealDrawer/CreateDealDrawer'
import useAxiosPrivate from '../../../hooks/useAxiosPrivate'
import { message } from 'antd';
import useDealsManagementCtx from "../../../hooks/useDealsManagementCtx";
import { DealListContext } from '../DealListContext';
import { CardStatus } from '../../../shared/enums';
const KanbanCardAdd = memo(({setKanbanCards}) => {
    const { isDrawerOpen, setIsDrawerOpen, setLoading } = useDealsManagementCtx();
    const {stats, setStats} = useContext(DealListContext)

    const axios = useAxiosPrivate();

    const onCardAdd = async (e) =>{
        setLoading(true)
        const response = await axios.post('/Deal/CreateDeal', {
            emailId:e.contacts,
            companyIds: e.companies,
            stage:e.status,
            ownerIds: e.assignedUser,
            amount:e.amount,
            closeDate:e.closeDate,
            title: e.title,
            productIds: e.products

        });

        if(response.data.success){
            if(e.status == CardStatus.New){
                setStats(prev => ({
                    ...prev,
                    total: prev.total + 1,
                    new: prev.new + 1
                }))
            }else if(e.status ==  CardStatus.Qualifying){
                setStats(prev => ({
                    ...prev,
                    total: prev.total + 1,
                    qualifying: prev.qualifying + 1
                }))
            }
            else if(e.status == CardStatus.DemoScheduled){
                setStats(prev => ({
                    ...prev, 
                    total: prev.total + 1,
                    demoScheduled: prev.demoScheduled + 1
                }))
            }else if(e.status == CardStatus.PendingCommitment){
                setStats(prev => ({
                    ...prev,
                    total: prev.total +1,
                    pendingCommitment: prev.pendingCommitment + 1
                }));
            }else if(e.status == CardStatus.InNegotiation){
                setStats(prev => ({
                    ...prev,
                    total: prev.total + 1,
                    inNegotiation: prev.inNegotiation + 1
                }));
            }else if(e.status == CardStatus.Won){
                setStats(prev => ({
                    ...prev,
                    total: prev.total + 1,
                    won: prev.won + 1
                }));
            }else{
                setStats(prev => ({
                    ...prev,
                    total: prev.total + 1,
                    lost: prev.lost + 1
                }));
            }

            setKanbanCards(prev=>{
                const newCard = {
                    title: response.data.title,
                    id: response.data.id,
                    status: response.data.stage,
                    company: response.data.company,
                    companyCount: response.data.companyCount,
                    contact: response.data.contactCount,
                    color: "red",
                    amount: response.data.amount,
                    owners: response.data.owners,
                    closeDate: response.data.closeDate,
                    position: response.data.position
                }
                return [
                    newCard,
                    ...prev
                ]
            })

            setIsDrawerOpen(false)
        }else{
            message.error(response.data.message)
        }

        setLoading(false)
    }
  return (
    <CreateDealDrawer onSubmit={onCardAdd}/>

  )
})

export default KanbanCardAdd