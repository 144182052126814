import { Children, createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { OrderDirection } from "../../shared/enums";
import {SearchContext} from '../../context/SearchContext'
export const CompanyListContext = createContext({
    filters: {
        authors: [],
        selectedAuthors: [],

        from: null,
        to:null,

        orderBy: null,
        orderDirection: null},
    setFilters: () => {},

    showFilters: false,
    setShowFilters: () => {},

    stats: {},
    setStats: () => {},

    companies: [],
    setCompanies: () => {},

    page: 1,
    setPage: () => {},

    loading: false,
    setLoading: () => {},
})

export const CompanyListContextProvider = ({children}) => {

    const {debouncedValue} = useContext(SearchContext)

    const [filters, setFilters] = useState()
    const [stats, setStats] = useState();
    const [showFilters, setShowFilters] = useState();
    
    const [companies, setCompanies] = useState();
    const [page, setPage] = useState(1);
    const perPage = 7;

    const [loading, setLoading] = useState(false)

    const axios = useAxiosPrivate();

    useEffect(async() => {
        const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));

    }, [])

    
    useEffect(async () => {
        setLoading(true);
        const response = await axios.post("/Company/GetPaged", {
          page: page,
          pageSize: perPage,
          search: debouncedValue,
          from: filters?.from,
          to: filters?.to,
          orderBy: filters?.orderBy,
          sortDirection: filters?.orderDirection,
          authors: filters?.selectedAuthors
        });
        setCompanies(response.data.items);
        setStats(prev => ({
            ...prev,
            all: response.data.total,
            first: response.data.first,
            last: response.data.last,
            authorName: response.data.authorName,
            authorImage: response.data.authorProfile
        }));
    
        setLoading(false);
      }, [page, filters, debouncedValue]);

    return <CompanyListContext.Provider value={{
        filters,
        setFilters,
        
        stats,
        setStats,

        showFilters,
        setShowFilters,

        companies,
        setCompanies,

        page,
        setPage,

        loading,
        setLoading
    }}>
        {children}
    </CompanyListContext.Provider>
}