import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Card, Empty, Flex, Space, Typography } from "antd";
import InitilasAvatar from "../../../shared/IntialsAvatar";
import env from "../../../env.json";
import { DateFormat } from "../../../dateformat";
const SmsTemplateCard = ({ template, setCurrentStep }) => {
  const { Title, Text } = Typography;

  return (
    <>
      <Card>
        {template && (
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Space size={0} direction="vertical" style={{ width: "100%" }}>
              <Title level={4} style={{ margin: 0 }}>
                {template?.name}
              </Title>
              <Text>{template?.text}</Text>
            </Space>

            <Flex align="center" justify="space-between">
              <Flex align="center" gap={5}>
                <InitilasAvatar
                  name={template.authorName}
                  src={`${env.fileUpload}${template.authorProfile}`}
                />
                <Text strong>{template.authorName}</Text>
              </Flex>

              <Text className="text-light">
                {new Date(template.createdAt).toLocaleDateString(
                  "en-US",
                  DateFormat
                )}
              </Text>
            </Flex>
          </Space>
        )}
        
        {!template && (
          <>
            <Flex align="center" justify="center" style={{ height: 400 }}>
              <div style={{ height: 160, textAlign: "center" }}>
                <Empty description="No template was selected" />

                <Button type="link" onClick={() => setCurrentStep(2)}>
                  Select a template
                </Button>
              </div>
            </Flex>
          </>
        )}
      </Card>
    </>
  );
};

export default SmsTemplateCard;
