import { createContext } from "react";

export const RegisterContext = createContext({

})


export const RegisterContextProvider = ({children}) => {
    return <RegisterContext.Provider value={{
        
    }}>
        {children}
    </RegisterContext.Provider>
}

