import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DeleteSmsTemplate = ({ id, onDelete }) => {
  const axios = useAxiosPrivate();

  const onSubmit = async () => {
    const response = await axios.delete(`/SmsTemplate/${id}`);

    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <Popconfirm
      okText="Yes, delete it"
      onConfirm={() => onSubmit()}
      title="Are you sure?"
      description="Are you sure you want to delete this sms template?"
    >
      Delete this template
    </Popconfirm>
  );
};

export default DeleteSmsTemplate;
