import { Button, Flex, Select, Typography } from "antd";
import { useContext, useState } from "react";
import { CardStatus } from "../../../shared/enums";
import IconSelectListItem from "../../../shared/IconSelectListItem";
import { MdClear, MdHandshake, MdOutlineEqualizer } from "react-icons/md";
import { FaFolderOpen, FaTrophy } from "react-icons/fa";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill } from "react-icons/ri";
import { FaHandshakeSimple } from "react-icons/fa6";
import { IoMdCheckmark, IoMdCloseCircle } from "react-icons/io";
import { DealListContext } from "../DealListContext";

const StatusFilter = () => {
  const { Text } = Typography;

  const {filters, setFilters} = useContext(DealListContext)

  const [statuses, setStatuses] = useState(() => [
    {
      label: <IconSelectListItem icon={<FaFolderOpen />} text="New" />,
      value: CardStatus.New,
    },
    {
      label: (
        <IconSelectListItem icon={<MdOutlineEqualizer />} text="Qualifying" />
      ),
      value: CardStatus.Qualifying,
    },
    {
      label: (
        <IconSelectListItem icon={<AiFillSchedule />} text="Demo Scheduled" />
      ),
      value: CardStatus.DemoScheduled,
    },
    {
      label: (
        <IconSelectListItem
          icon={<RiGitCommitFill />}
          text="Pending Commitment"
        />
      ),
      value: CardStatus.PendingCommitment,
    },
    {
      label: (
        <IconSelectListItem
          icon={<FaHandshakeSimple />}
          text="In Negotiation"
        />
      ),
      value: CardStatus.InNegotiation,
    },
    {
      label: <IconSelectListItem icon={<FaTrophy />} text="Won" />,
      value: CardStatus.Won,
    },
    {
      label: <IconSelectListItem icon={<IoMdCloseCircle />} text="Lost" />,
      value: CardStatus.Closed,
    },
  ]);

  const handleChanged = (e) => {
    let statuses = [];

    e.forEach(d => {
      let item = filters.statuses.find(c => c.value == d)

      statuses = [...statuses, item];
    })

    setFilters(prev => ({...prev, selectedStatuses: statuses}))
  }

  return (
    <>
      <Text>Statuses</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        placeholder="Select statuses to show"
        style={{ width: "100%" }}
        defaultValue={filters?.selectedStatuses}
        mode="multiple"
        options={statuses}
        onChange={handleChanged}
      />
    </>
  );
};

export default StatusFilter;
