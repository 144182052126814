import { Button, Flex, Select, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { ResponsiveMasonry } from "react-responsive-masonry";
import IconSelectListItem from "../../../shared/IconSelectListItem";
import { DealListContext } from "../DealListContext";
import { IoMdCheckmark } from "react-icons/io";
import { MdClear } from "react-icons/md";

const ProductFilter = () => {
  const { Text } = Typography;

  const {filters ,setFilters} = useContext(DealListContext)


  const handleOnChanged = (e) => {
    setFilters(prev => ({...prev, selectedProducts: e}))
  }
  return (
    <>
      <Text>Products</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        placeholder="Select products"
        style={{ width: "100%" }}
        onChange={handleOnChanged}
        defaultValue={filters?.selectedProducts}
        options={filters?.products}
        mode="multiple"
      />
    </>
  );
};
export default ProductFilter;
