import { Outlet } from "react-router-dom"
import { MeidaContextProvider } from "./MediaContext"

const MediaLayout = () => {
    return <>
        <MeidaContextProvider>
            <Outlet/>
        </MeidaContextProvider>
    </>
}

export default MediaLayout;