import {
  Skeleton,
  Badge,
  Button,
  Card,
  Flex,
  Space,
  Typography,
  Empty,
} from "antd";
import { useContext, useState } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect } from "react";
import SelectFrom from "../CreateUpdate/SelectFrom";
import { IoIosRemoveCircle } from "react-icons/io";
import { useParams } from "react-router-dom";
import SelectSmsFrom from "../../users/Senders/sms/SelectSmsFrom";
import { RiMapPinFill } from "react-icons/ri";
import SelectWhatsappFrom from "../../users/Senders/whatsapp/SelectWhatsappFrom";

const From = ({ setIsValid }) => {
  const { type } = useParams();

  const { data, setData } = useContext(CreateCampaignContext);

  const axios = useAxiosPrivate();
  const [sender, setSender] = useState();
  const [error, setError] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    setLoading(true);
    var response;

    if (type == 0) {
      response = await axios.get(
        `/Sender/GetDefaultSender${data.senderId ? "?Id=" + data.senderId : ""}`
      );
      if (response.data.success) {
        setSender(response.data.data);
        setData(prev => ({ ...prev, senderId: response.data.data.id }));
      } else {
        setError(response.data.message);
      }
      setIsValid(!error && response.data.data?.confirmed);
    } else if (type == 2) {
      response = await axios.get(`/PhoneNumbers/numbers/default`);

      if (response.data.success) {
        setSender(response.data);
        setData(prev => ({ ...prev, smsSenderId: response.data.id }));
      } else {
        setError(response.data.message);
      }
      setIsValid(!error && response.data.status == 2);
    }else{
      response = await axios.get("/Whatsapp/numbers/default")

      if(response.data.success){

        setSender(response.data);
        setData(prev => ({...prev, whatsappPhoneNumberId: response.data.id}))
      }else{
        setError(response.data.message)
      }
      setIsValid(!error);
    }
    setLoading(false);
  }, []);

  const { Title, Text } = Typography;

  const handleSenderChanged = (data) => {
    setSender(data);
  };

  return (
    <>
      {type == 0 && (
        <SelectFrom
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          handleSenderChanged={handleSenderChanged}
        />
      )}
      {type == 2 && (
        <SelectSmsFrom
          open={isOpen}
          setOpen={setIsOpen}
          handleSenderChanged={handleSenderChanged}
        />
      )}

      {type == 1&&(
        <SelectWhatsappFrom  open={isOpen}
        handleSenderChanged={handleSenderChanged}
        setOpen={setIsOpen}/>
      )}

      {loading && (
        <Card
          title={
            <Flex align="center" justify="space-between">
              <Space>
                <Skeleton.Avatar active />
                <Skeleton.Input active />
              </Space>

              <Skeleton.Input active />
            </Flex>
          }
        >
          <Skeleton.Input active className="w-100" />
        </Card>
      )}

      {!loading && (
        <Card
          title={
            <Flex align="center" justify="space-between">
              <Space>
                {(error ||
                  (type == 0 && !sender?.confirmed) ||
                  (type == 2 && sender?.status != 2)) && (
                  <IoIosRemoveCircle
                    style={{ fontSize: 22, color: "#e74a3b" }}
                  />
                )}
                {!error && (sender?.confirmed || sender?.status == 2) && (
                  <AiFillCheckCircle
                    style={{ fontSize: 22, color: "#1cc88a" }}
                  />
                )}
                <Title level={4} style={{ margin: 0 }}>
                  From
                </Title>
              </Space>
              <Button type="link" size="large" onClick={() => setIsOpen(true)}>
                Edit From
              </Button>
            </Flex>
          }
        >
          {sender && (
            <>
              {type == 0 && (
                <>
                  <Space style={{ width: "100%" }}>
                    {sender.default && (
                      <Badge
                        count="default"
                        style={{
                          backgroundColor: "#1cc88a",
                        }}
                      />
                    )}
                    <Title style={{ margin: 0 }} level={4}>
                      {sender.nickName}
                    </Title>
                  </Space>
                  <Text className="text-light">• {sender.email}</Text>
                </>
              )}

              {type == 1&&(
                <>
                <Space style={{ width: "100%" }}>
                  {sender.default && (
                    <Badge
                      count="default"
                      style={{
                        backgroundColor: "#1cc88a",
                      }}
                    />
                  )}
                  <Title style={{ margin: 0 }} level={4}>
                    {sender.name}
                  </Title>
                </Space>
                <Text className="text-light">• {sender.number}</Text>
              </>
              )}
              {type == 2 && (
                <>
                  <Flex align="center" justify="start" gap={10}>
                    <img
                      src={`https://flagsapi.com/${sender.countryIso}/shiny/64.png`}
                    />
                    <Space direction="vertical" size={0}>
                      <Space style={{ width: "100%" }}>
                        {sender.default && (
                          <Badge
                            count="default"
                            style={{
                              backgroundColor: "#1cc88a",
                            }}
                          />
                        )}
                        <Title style={{ margin: 0 }} level={4}>
                          {sender.number} {sender.phoneNumber}
                        </Title>
                      </Space>
                      <Text className="text-light icon-flex">
                        <RiMapPinFill /> {sender.region}
                      </Text>
                    </Space>
                  </Flex>
                </>
              )}
            </>
          )}

          {!sender && <Empty description="No senders active" />}
        </Card>
      )}
    </>
  );
};

export default From;
