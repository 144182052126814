import { useNavigate } from "react-router-dom";

import { RiWhatsappFill } from "react-icons/ri";
import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import { MdEmail, MdSms } from "react-icons/md";

const CreateCampaignModal = ({ open, setOpen }) => {
    const { Title, Text } = Typography;
    const navigate = useNavigate();
    return (
      <>
        <Modal
          open={open}
          onCancel={() => setOpen(false)}
          footer={null}
          title={
            <Title style={{ margin: 0 }} level={4}>
              What campaign do you want to create?
            </Title>
          }
        >
          <Space direction="vertical" className="w-100" size={"large"}>
            <Text style={{ fontSize: 16 }}>
              Choose what kind of campaign you want to create from scratch and
              reuse it whenever you need it.
            </Text>
            <Row gutter={12}>
              <Col md={8}>
                <Button onClick={() =>navigate(`/Campaigns/Create/0`)}
                  size="large"
                  icon={<MdEmail />}
                  block
                  className="flex-button bold-button"
                  style={{height:50}}
                >
                  Email
                </Button>
              </Col>
              <Col md={8}>
                <Button onClick={() => navigate(`/Campaigns/Create/1`)}
                  size="large"
                  icon={<RiWhatsappFill />}
                  block
                  className="flex-button bold-button"
                  style={{height:50}}
                >
                  Whatsapp
                </Button>
              </Col>
              <Col md={8}>
                <Button onClick={() => navigate(`/Campaigns/Create/2`)}
                  size="large"
                  icon={<MdSms />}
                  block
                  className="flex-button bold-button"
                  style={{height:50}}
                >
                  SMS
                </Button>
              </Col>
            </Row>
            <Text strong>* Campaigns can not change type after they are created!</Text>
          </Space>
        </Modal>
      </>
    );
  };

export default CreateCampaignModal;