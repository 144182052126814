import { Select, Skeleton, Typography } from "antd";
import { useContext } from "react";
import { CampaignContext } from "../CampaignContext";

const CampaignAuthorSelect = () => {
  const {filters, setFilters, authorLoading } = useContext(CampaignContext)

  const { Text } = Typography;

  const handleChanged = (e) => {
    setFilters(prev => ({
      ...prev,
      selectedAuthors: e
    }))
  }
  return (
    <>
      <Text>Authors</Text>
      {authorLoading ? (
        <Skeleton.Input active className="w-100" size="large" />
      ) : (
        <Select
          size="large"
          maxTagCount="responsive"
          defaultValue={filters?.selectedAuthors}
          placeholder="Select campaign filter authors"
          style={{ width: "100%" }}
          options={filters?.authors}
          mode="multiple"
          onChange={handleChanged}
        />
      )}
    </>
  );
};

export default CampaignAuthorSelect;
