import { DatePicker, Typography } from "antd"
import { useContext } from "react"
import { CleanUpContext } from "../CleanupContext"

const CleanUpJobFromDateFilter = () => {
    const {Text} = Typography
    const {filters, setFilters} = useContext(CleanUpContext)

    return <>
    <Text text="Order by">From</Text>
    <DatePicker size="large"
        onChange={(e) => setFilters(prev => ({...prev, from: e}))}
        defaultValue={filters?.from}
        placeholder="Select created date end"
        style={{ width: "100%", borderRadius: 15 }} />
</>
}

export default CleanUpJobFromDateFilter