import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Button, Card, Checkbox, Col, Flex, Modal, Row, Typography, message } from "antd";
const UserRoles = ({ isOpen, setIsOpen, userId, userRoles, setUserRoles }) => {
  const axiosPrivate = useAxiosPrivate();

  const {Title} = Typography

  const handleRolesChanged = async (roleid, e) => {
    var checked = e.target.checked;

    var removing = false;
    var response;
    if (checked) {
      response = await axiosPrivate.post("Users/AddToRole", {
        userId: userId,
        roleId: roleid,
      });
    } else {
      response = await axiosPrivate.post("Users/RemoveFromRole", {
        userId: userId,
        roleId: roleid,
      });
      removing = true;
    }

    if (response && response.data.success) {
      if (!removing) {
        setUserRoles([
          ...userRoles,
          {
            roleId: roleid,
          },
        ]);
      } else {
        setUserRoles(userRoles.filter((u) => u.roleId != roleid));
      }
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Title style={{ margin: 0 }} level={4}>
            User authorizations
          </Title>
        }
        onCancel={() => setIsOpen(false)}
        footer={<Flex justify="end">
          <Button onClick={() => setIsOpen(false)}>Close</Button>
        </Flex>}
      >
        <Row gutter={12}>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={1}
              name="SuperAdmin"
              checked={userRoles.some((r) => r.roleId == 1)}
              roleChanged={handleRolesChanged}
            />
          </Col>
          <Col xs={12}>
            <UserRole
              userId={userId}
              roleid={2}
              name="Admin"
              checked={userRoles.some((r) => r.roleId == 2)}
              roleChanged={handleRolesChanged}
            />
          </Col>
        </Row>

        <Row gutter={12} style={{marginTop:12}}>

<Col xs={12}>
        <UserRole
          userId={userId}
          roleid={3}
          name="Moderator"
          checked={userRoles.some((r) => r.roleId == 3)}
          roleChanged={handleRolesChanged}
          />
          </Col>
          <Col xs={12}>
        <UserRole
          userId={userId}
          roleid={4}
          name="Basic"
          checked={userRoles.some((r) => r.roleId == 4)}
          roleChanged={handleRolesChanged}
          />
          </Col>
          </Row>
      </Modal>
    </>
  );
};

const UserRole = ({ name, roleid, roleChanged, checked, userId }) => {
  return (
    <>
    <Card size="small">

      <Checkbox checked={checked} onChange={(e) => roleChanged(roleid, e)}>
        {name}
      </Checkbox>
    </Card>
    </>
  );
};

export default UserRoles;
