import { Button, Card, Flex, message, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import ProxyOptions from "./ProxyOptions";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import CreateProxyModal from "./CreateProxyModal";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal";
import UpdateProxyModal from "./UpdateProxyModal";

const ProxySettings = () => {
  const { Title, Text } = Typography;
  const [total, setTotal] = useState();
  const [showAdd, setShowAdd] = useState(false);
  const [items, setItems] = useState();
  const [updateOpen, setUpdateOpen] = useState(false)
  const [showDelete, setShowDelete] = useState(false);

  const [page, setPage] = useState(1);
  const [current, setCurrent] = useState();
  const perPage = 7;

  const axios = useAxiosPrivate();

  useEffect(async () => {
    const response = await axios.post(`/CleanUpSettings/list`, {
      page: page,
      pageSize: perPage,
    });

    setTotal(response.data.total);
    setItems(response.data.items);
  }, [page]);

  const columns = [
    {
      title: "Proxy",
      render: (row) => <Text strong>{row.proxyHost}</Text>,
    },
    {
      title: "Port",
      render: (row) => <Text strong>{row.proxyPort}</Text>,
    },
    {
      title: "User",
      render: (row) => <Text strong>{row.username}</Text>,
    },
    {
      title: "Email",
      render: (row) => <Text strong>{row.fromEmail}</Text>,
    },
    {
      title: "Hello name",
      render: (row) => <Text strong>{row.helloName}</Text>,
    },
    {
      title: "Smtp",
      render: (row) => <Text strong>{row.smtpPort}</Text>,
    },
    {
      width: 10,
      render: (row) => <ProxyOptions id={row.id} onDelete={onDelete} onUpdate={onUpdate} />,
    },
  ];

  const onUpdate = (id) => {
    setCurrent(id);
    setUpdateOpen(true)
  }

  const onAdd = (e) => {
    setItems((prev) => [e, ...prev]);
    setTotal((prev) => prev + 1);
  };

  const onDelete = (id) => {
    setCurrent(id);
    setShowDelete(true);
  };

  const handleDeleteConfirmed = async () => {
    const response = await axios.delete(`/CleanUpSettings/${current}`);

    if (response.data.success) {
      setTotal((prev) => prev - 1);
      setItems((prev) => prev.filter((c) => c.id != current));
    } else {
      message.error(response.data.message);
    }
  };

  const handleUpdateSubmit = (e) => {
    console.log(e)
    setItems(prev => prev.map(c => c.id == e.id ? e : c))
  }

  return (
    <>
      <DeleteConfirmationModal
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        onDeleteConfirm={handleDeleteConfirmed}
        item={current}
      />
      <CreateProxyModal open={showAdd} setOpen={setShowAdd} onAdded={onAdd} />

      {updateOpen&&<UpdateProxyModal onUpdate={handleUpdateSubmit} id={current} open={updateOpen} setOpen={setUpdateOpen}/>}
      
     <Flex align="center" justify="space-between" style={{marginBottom:5}}>
          <Title level={4} style={{ margin: 5 }}>
            Proxy settings ({total})
          </Title>

          <Space>
            <Button
              onClick={() => setShowAdd(true)}
              type="primary"
              size="large"
              className="primary-gradient-background flex-button bold-button"
              icon={<FiPlus style={{ fontSize: 20 }} />}
              style={{ borderRadius: 15 }}
            >
              New settings
            </Button>
          </Space>
        </Flex>

        <Card className="zero-margin-padding" style={{ boxShadow: "none" }}>
          <Table
            dataSource={items}
            columns={columns}
            pagination={{
              current: page,
              pageSize: perPage,
              total: total,
              onChange: (page, perPage) => setPage(page),
            }}
          ></Table>
        </Card>
    </>
  );
};

export default ProxySettings;
