import { Typography } from "antd"
import { useContext } from "react"
import {ThemeContext} from '../context/ThemeContext'

const ThemeLabel = ({text}) => {
    const {Text} = Typography
    const {theme} = useContext(ThemeContext)
    
    return <Text style={{
        color: theme == "dark" ? "#484f69" : "#bfc3d2"
    }}>{text}</Text>
}

export default ThemeLabel;