import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Segmented,
  Space,
  Typography,
  Grid
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import { DealsTabs } from "../../shared/enums";
import { DealsManagementContext } from "../../context/DealsManagementContext";
import { MdFilterAlt, MdFilterAltOff, MdTableRows } from "react-icons/md";
import { BsFillGrid1X2Fill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import DealStats from "./DealStats";
import Promo from "../../shared/Promo";
import dealBanner from "../../assets/images/deals-banner.png";
import CardItemsFilter from "./Filters/CardItemsFilter";
import CompanyFilter from "./Filters/CompanyFilter";
import CreatedDateFromFilter from "./Filters/CreatedDateFromFilter";
import ProductFilter from "./Filters/ProductFilter";
import StatusFilter from "./Filters/StatusFilter";
import CreatedDateToFilter from "./Filters/CreatedDateToFilter";
import { DealListContext } from "./DealListContext";

const { useBreakpoint } = Grid;

const Header = ({ activeTab, setActiveTab, showDrawer }) => {

  const { stats, setStats, filters, setFilters } = useContext(DealListContext);

  const screens = useBreakpoint();

  const [open, setOpen] = useState(() => ({
    total: 0,
    open: 0,
  }));

  const { setIsDrawerOpen } = useContext(DealsManagementContext);
  const axios = useAxiosPrivate();

  useEffect(async () => {
    const response = await axios.get("/Deal/open");

    setOpen({
      open: response.data.open,
      total: response.data.total,
    });
  }, []);

  useEffect(() => {
    if (screens.xs) {
      setActiveTab(DealsTabs.List);
    }else{
      setActiveTab(DealsTabs.Kanban);
    }
  }, [screens.xs, setActiveTab]);

  const { Title, Text } = Typography;
  return (
    <>
      <Space direction="vertical" size={24}>
        <Promo
          background={dealBanner}
          title="Deals Managment"
          text="Our Deal Management page provides a streamlined interface designed to help you efficiently manage and track your business deals from initiation to closure. This page is your central hub for monitoring deal progress, collaborating with team members, and ensuring every opportunity is maximized."
        />
        <DealStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between" wrap="wrap"
              style={{
                ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
              }}>
              <Title style={{ margin: 0 }} level={4}>
                Deals
              </Title>
              <Flex gap={10} wrap="wrap"
                style={{
                  ...(screens.xs ? { 'width': '100%' } : {})
                }}
              >
                {!screens.xs && (
                  <Segmented
                    size="large"
                    default="Kanban"
                    defaultValue="Kanban"
                    onChange={(e) => {
                      if (e === "List") {
                        setActiveTab(DealsTabs.List);
                      } else {
                        setActiveTab(DealsTabs.Kanban);
                      }
                    }}
                    options={[
                      {
                        value: "List",
                        icon: <MdTableRows />,
                      },
                      {
                        value: "Kanban",
                        icon: <BsFillGrid1X2Fill />,
                      },
                    ]}
                  />
                )}
                <Button
                  size="large"
                  className="flex-button"
                  block={screens.xs}
                  onClick={() => {
                    setFilters(prev => ({ ...prev, shown: !prev.shown }))
                  }}
                  icon={!filters?.shown ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  className="flex-button bold-button"
                  onClick={() => setIsDrawerOpen(true)}
                >
                  New Deal
                </Button>
              </Flex>
            </Flex>
          }
        >
          {filters?.shown && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col md={12}>
                <CardItemsFilter />
              </Col>
              <Col md={12}>
                <CompanyFilter />
              </Col>
              <Col md={12}>
                <ProductFilter />
              </Col>
              <Col md={14}>
                <StatusFilter />
              </Col>
              <Col md={5}>
                <CreatedDateFromFilter />
              </Col>
              <Col md={5}>
                <CreatedDateToFilter />
              </Col>
            </Row>
          )}
        </Card>

        {/*<Flex align="center" justify="space-between">
          <Card size="small">
            <Flex gap={12} align="center" justify="start  ">
              <Progress
                strokeColor="#422afb"
                showInfo={false}
                style={{ width: "100px" }}
                percent={(open?.open / open?.total) * 100}
              />
              <Text strong>
                {open.open} / {open.total} open deals
              </Text>
            </Flex>
          </Card>
          
        </Flex>*/}
      </Space>
    </>
  );
};

export default Header;
