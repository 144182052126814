import {
  Avatar,
  Badge,
  Flex,
  List,
  Modal,
  Radio,
  Space,
  Spin,
  Tooltip,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import env from "../../../env.json";
import { UserOutlined } from "@ant-design/icons";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import ConfirmSender from "../../users/Senders/ConfirmSender";

const SelectFrom = ({ isOpen, setIsOpen, handleSenderChanged }) => {
  const { data, setData } = useContext(CreateCampaignContext);

  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();
  const [senders, setSenders] = useState();

  useEffect(async () => {
    const response = await axios.get(`/Sender/GetAll?campaignId=${data.id}`);

    setSenders(response.data?.data?.map((c) => ({ ...c, loading: false })));
  }, []);

  const handleRadioChange = async (id) => {
    setSenders((prev) =>
      prev.map((c) => (c.id == id ? { ...c, loading: true } : c))
    );

    const response = await axios.put(`/Campaign/${data.id}/sender/${id}/email/set`);

    if (response.data.success) {
      message.success("New sender set to campaign!");
      const updatedItems = senders.map((item) => ({
        ...item,
        selected: item.id === id,
      }));

      setData({ ...data, senderId: id });

      setSenders(updatedItems);

      handleSenderChanged(response.data.data);
    } else {
      message.error("Failed to update sender");
    }
    setSenders((prev) =>
      prev.map((c) => (c.id == id ? { ...c, loading: false } : c))
    );
  };

  return (
    <Modal
      footer={false}
      open={isOpen}
      onOk={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      title={
        <Title style={{ margin: 0 }} level={4}>
          Select sender
        </Title>
      }
    >
      <Radio.Group
        style={{ width: "100%" }}
        value={data.senderId}
        disabled={!data.canEdit}
      >
        <List
          bordered={false}
          itemLayout="horizontal"
          dataSource={senders}
          style={{ height: "350px", overflowY: "auto", marginRight: "10px" }}
          renderItem={(item, index) => (
            <List.Item
              actions={[
                
                <Spin size="small" spinning={item.loading}>
                  <Radio
                    value={item.id}
                    onChange={() => handleRadioChange(item.id)}
                  />
                </Spin>,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Tooltip title={item.creatorFullName}>
                    <Avatar style={{marginLeft:20}} 
                      src={`${env.fileUpload}${item.creatorProfile}`}
                      icon={<UserOutlined />}
                    />
                  </Tooltip>
                }
                title={
                  <Space
                    size={0}
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    <Flex align="center" gap={10}>
                      <Title style={{ margin: 0 }} level={5}>
                        {item.nickName}
                      </Title>
                      {item.default && (
                        <Badge
                          count="default"
                          style={{
                            backgroundColor: "#422afb",
                          }}
                        />
                      )}
                    </Flex>
                    <ConfirmSender email={item.email} id={item.id} disabled={item.confirmed} />
                  </Space>
                }
              />
            </List.Item>
          )}
        />
      </Radio.Group>
    </Modal>
  );
};

export default SelectFrom;
