import { Button, Drawer, Flex, Form, message } from "antd";
import React, { useState } from "react";
import ContactsMultiselect from "../../../FormItems/ContactsMultiselect";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import { useParams } from "react-router-dom";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { AiFillSave } from "react-icons/ai";

const AddContact = ({ open, setOpen }) => {
  const axios = useAxiosPrivate();
  const {id} = useParams();
  const [loading, setLoading] = useState(false)
  const { contacts, setContacts } = useDealsDetailsCtx();

  const initialValue = {
    contacts: contacts?.map((el) => el.id),
  };
  
  function onClose() {
    setOpen(false);
  }

  const onSubmit = async (e) => {
    setLoading(true)
    const response = await axios.put('/Deal/Audience', {
      dealId: id,
      contactIds: e.contacts
    })

    if(response.data.success){
      setOpen(false);
      setContacts(response.data?.items?.map(d => ({
        id: d.id,
        name: `${d.name} ${(d.lastName) ? d.lastName: '' }`,
        email: d.email,
        phoneNumber: d.phone,
      })))

    }else{
      message.show(response.data.message);
    }

    setLoading(false)
  }
  return (
    <Drawer open={open} destroyOnClose onClose={onClose} title="Edit Contacts">
      <Form initialValues={initialValue} layout="vertical" onFinish={onSubmit}>
        <ContactsMultiselect />
        <Flex justify="end" gap={10} style={{ marginTop: "auto" }}>
          <Button type="text" onClick={onClose}>
            Close
          </Button>
          <Button icon={<AiFillSave/> } className="flex-button" htmlType="submit" type="primary" loading={loading}>
            Save changes
          </Button>
        </Flex>
      </Form>
    </Drawer>
  );
};

export default AddContact;
