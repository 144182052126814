import { Avatar, Flex, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import env from '../env.json';

const AuthorSelectListItem = ({ firstname, lastname, profile }) => {
    const { Text } = Typography;
    return <>
        <Flex
            style={{ height: "100%" }}
            align="center"
            justify="start"
            gap={6}
        >
            <Avatar
                size="small"
                src={`${env.fileUpload}${profile}`}
                icon={<UserOutlined />}
            />
            <Text>
                {firstname} {lastname}
            </Text>
        </Flex>

    </>
}

export default AuthorSelectListItem;