import { Col, Row } from "antd";
import Stat from "../../shared/Stat";
import { RiTaskFill } from "react-icons/ri";
import { FaCalendarTimes, FaCheckCircle, FaFlag, FaPhone } from "react-icons/fa";
import { MdEmail, MdLunchDining, MdMeetingRoom } from "react-icons/md";
import { TbCalendarDue } from "react-icons/tb";
import { useContext } from "react";
import { TaskContext } from "./TaskContext";

const TaskStats = () => {
  const {stats} = useContext(TaskContext)

  return (
    <Row gutter={[24, 24]}>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          value={stats?.total}
          icon={<RiTaskFill style={{ fontSize: 22 }} />}
          name="Total"
          loading={false}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
        value={stats?.overdue}
          name="Overdue"
          loading={false}
          icon={<FaCalendarTimes style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
          name="To do"
          value={stats?.toDo}
          loading={false}
          icon={<FaCheckCircle style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
        value={stats?.email}
          name="Email"
          loading={false}
          icon={<MdEmail style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
        value={stats?.call}
          name="Call"
          loading={false}
          icon={<FaPhone style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat value={stats?.meeting}
          name="Meeting"
          loading={false}
          icon={<MdMeetingRoom style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat value={stats?.lunch}
          name="Lunch"
          loading={false}
          icon={<MdLunchDining style={{ fontSize: 22 }} />}
        />
      </Col>
      <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
        <Stat
        value={stats?.deadline}
          name="Deadline"
          loading={false}
          icon={<FaFlag style={{ fontSize: 22 }} />}
        />
      </Col>
    </Row>
  );
};

export default TaskStats;
