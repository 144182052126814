import { Button, Dropdown, message } from "antd";
import { IoMdArrowDropdown, IoMdMore } from "react-icons/io";
import DeleteProduct from "./DeleteProduct";
import { BsTrashFill } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";
import { FaCopy } from "react-icons/fa";

const ProductListOptions = ({ id, onDelete, setShowEditing, setCurrent}) => {

    const copyApiId = async () => {
        try {
            await navigator.clipboard.writeText(`${id}`);
            message.success('Product api id copied to clipboard');
          } catch (err) {
            message.error('Failed to copy');
          }
    }

  const items = [
    {
        label:"Copy api id",
        key:"2",
        icon: <FaCopy />,
        onClick: () => copyApiId()
    },
    {
      label: "Edit product",
      key: "1",
      icon: <AiFillEdit />,
      onClick: () => {
        setShowEditing(true);
        setCurrent(id);}
    },
    {
      label: <DeleteProduct id={id} onDelete={onDelete}/>,
      key: "3",
      danger: true,
      icon: <BsTrashFill />,
    },
  ];

  return (
    <Dropdown trigger={["click"]} menu={{ items }}>
      <Button type="text"
        className="flex-button"
        shape="circle"
        icon={<IoMdMore style={{ fontSize: 20 }} />}
      ></Button>
    </Dropdown>
  );
};

export default ProductListOptions;
