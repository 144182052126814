import { useEffect, useState } from "react"
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { Card, Table, Typography } from "antd";
import TableLoading from '../../../shared/TableLoading'

const CleanUpSafe = ({refresh}) => {
    const { Text, Title } = Typography

    const { id } = useParams();

    const axios = useAxiosPrivate();

    const [page, setPage] = useState(1);

    const perPage = 7;

    const [items, setItems] = useState();

    const [loading, setLoading] = useState(false)
    const [total, setTotal] = useState(0)

    useEffect(async () => {
        setLoading(true)
        const response = await axios.post(`/CleanUp/results/list`, {
            jobId: id,
            page: page,
            pageSize: perPage,
            emailStatus: 1
        });

        setItems(response.data.items);
        setTotal(response.data.total)
        setLoading(false)

    }, [page])

    useEffect(() => {
        setPage(1)
    }, [refresh])
    const columns = [
        {
            title: "Email",
            render: (row) => <Text strong>{row.email}</Text>
        }
    ]

    return <Card style={{ boxShadow: "none" }} className="zero-margin-padding">

        {loading ? <TableLoading /> : <Table
            style={{ marginTop: 1 }}
            dataSource={items}
            columns={columns}
            pagination={{
                current: page,
                total: total,
                pageSize: perPage,
                showSizeChanger: false,
                onChange: (page) => setPage(page)
            }}
        />
        }
    </Card>
}

export default CleanUpSafe