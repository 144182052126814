import { Button, Card, Flex, message } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AiFillSave } from "react-icons/ai";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { FaBold } from "react-icons/fa";
const SignatureContent = ({ signature, onSuccess }) => {
  const axios = useAxiosPrivate();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const setContentFromHtml = (htmlContent) => {
    const contentState = stateFromHTML(htmlContent ?? "");
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  };

  const submit = async () => {
    const currentContent = editorState.getCurrentContent();
    const txt = stateToHTML(currentContent);

    const response = await axios.post("/Signature/UpdateSignatureContent", {
      id: signature.id,
      content: txt,
    });

    if (response.data.success) {
      message.success("Signature content updated");
      onSuccess(response.data.data);
    } else {
      message.error(response.data.message);
    }
  };

  useEffect(() => {
    setContentFromHtml(signature.text);
  }, [signature]);

  return (
    <Card
      title={signature.name}
      className="zero-margin-padding"
      style={{ minHeight: "700px", position: "sticky", top: "85px" }}
    >
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
       
      />

      <Flex align="center" justify="end" style={{ padding: 10 }}>
        <Button
          onClick={() => submit()}
          className="flex-button"
          icon={<AiFillSave />}
          style={{ marginRight: 15 }}
          type="primary"
        >
          Submit
        </Button>
      </Flex>
    </Card>
  );
};

export default SignatureContent;
