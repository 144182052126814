import { createContext } from "react";

export const CreateFollowupContext = createContext({
    
});

export const CreateFollowupContextProvider = ({children}) => {
    return <CreateFollowupContext.Provider value={{

    }}>
        {children}
    </CreateFollowupContext.Provider>
}