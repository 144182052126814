import Card from "antd/es/card/Card";
import { Col, Collapse, Flex, Row, Space, Typography } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const Promo = ({ title, text, endElements, background }) => {
  const { Title, Text } = Typography;
    const {theme} = useContext(ThemeContext)
  return (
    <Collapse  expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{boxShadow: theme == 'light' ? "14px 17px 40px 4px rgba(112,144,176,.08)" : "rgba(0, 0, 0, 0.04) 14px 17px 40px 4px"}}
      items={[
        {
          key: "1",
          label: <Title level={4} style={{ margin: 0 }}>{title}</Title>,
          children: (
            <Card className="zero-margin-padding">
              <div
                style={{
                  minHeight: "120px",
                  background: `url(${background}) no-repeat`,
                  borderBottomRightRadius: 15,
                  borderBottomLeftRadius: 15,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              >
                <Row
                  gutter={[6, 6]}
                  style={{ minHeight: "120px", padding: 20 }}
                >
                  <Col md={18} sm={24}>
                    <Space size={0} direction="vertical">
                      <Title level={3} style={{ margin: 0, color: "white" }}>
                        {title}
                      </Title>
                      <Text style={{ color: "white" }}>{text}</Text>
                    </Space>
                  </Col>
                  <Col sm={24} md={6}>
                    <Flex
                      align="center"
                      justify="end"
                      style={{ height: "100%", width: "100%" }}
                    >
                      {endElements}
                    </Flex>
                  </Col>
                </Row>
              </div>
            </Card>
          ),
        },
      ]}
      defaultActiveKey={["1"]}
    />
  );
};

export default Promo;
