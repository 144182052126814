import {
  Button,
  Card,
  Flex,
  Space,
  Table,
  Typography,
  Grid,
  Row,
  Col,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import AddSmsTemplate from "./AddSmsTemplate";
import TableLoading from "../../../shared/TableLoading";
import SmsTemplateOptions from "./SmsTemplateOptions";
import { DateFormat } from "../../../dateformat";
import EditSmsTemplate from "./EditSmsTemplate";
import { FiPlus } from "react-icons/fi";
import SmsStats from "./SmsStats";
import NoDataFound from "../../../shared/NoDataFound";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import SmsAuthorFilter from "./Filters/SmsAuthorFilter";
import SmsFromDateFilter from "./Filters/SmsFromDateFilter";
import SmsToDateFilter from "./Filters/SmsToDateFilter";
import SmsOrderByFilter from "./Filters/SmsOrderByFilter";
import SmsOrderDirectionFilter from "./Filters/SmsOrderDirectionFilter";
import { SmsListContext } from "./SmsListContext";

const { useBreakpoint } = Grid;

const SmsTemplates = () => {
  const { templates, setTemplates, loading, setLoading, stats, setStats } =
    useContext(SmsListContext);

  const { Title, Text } = Typography;

  const [showFilters, setShowFilters] = useState(false);

  const screens = useBreakpoint();

  const columns = [
    {
      title: "name",
      width: 300,
      render: (data, row, index) => <Text strong>{row.name}</Text>,
    },
    {
      title: "Text",
      responsive: ["sm"],
      render: (data, row, index) => (
        <div style={{ maxWidth: "450px" }}>
          <Text ellipsis strong>
            {row.text}
          </Text>
        </div>
      ),
    },
    {
      title: "Date",
      responsive: ["md"],
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
    {
      title: "",
      width: 50,
      render: (data, row, index) => (
        <SmsTemplateOptions
          id={row.id}
          onDelete={onDelete}
          setCurrent={setCurrent}
          setShowUpdate={setShowUpdate}
        />
      ),
    },
  ];

  const perPage = 7;
  const [page, setPage] = useState(1);
  const [showAdd, setShowAdd] = useState(() => false);

  const [showUpdate, setShowUpdate] = useState(() => false);
  const [current, setCurrent] = useState();

  const onAdd = (e) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    setTemplates((prev) => [
      ...prev,
      {
        name: e.name,
        author: e.authorName,
        createdAt: e.createdAt,
        profile: e.authorProfile,
        text: e.text,
        id: e.id,
      },
    ]);
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setTemplates((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (e) => {
    setTemplates((prev) =>
      prev.map((c) =>
        c.id == e.id
          ? {
              name: e.name,
              author: e.authorName,
              createdAt: e.createdAt,
              profile: e.authorProfile,
              text: e.text,
              id: e.id,
            }
          : c
      )
    );
  };
  return (
    <>
      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <SmsStats />
        <Card
          className="no-body-card end-to-end-header zero-margin-padding"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Designs
              </Title>

              <Flex
                wrap="wrap"
                gap={10}
                style={{
                  ...(screens.xs ? { flexBasis: "100%", gap: "10px" } : {}),
                }}
              >
                <Button
                  size="large"
                  className="flex-button"
                  block={screens.xs}
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                  onClick={() => setShowAdd(true)}
                >
                  New design
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: "24px" }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <SmsAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <SmsFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <SmsToDateFilter />
              </Col>

              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <SmsOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <SmsOrderDirectionFilter />
              </Col>
            </Row>
          )}
        </Card>
        <Card className="zero-margin-padding no-body-card end-to-end-header">
          {loading && <TableLoading />}
          {!loading && (
            <Table
              dataSource={templates}
              columns={columns}
              style={{ marginTop: 1 }}
              pagination={{
                current: page,
                pageSize: perPage,
                total: stats?.total,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
              locale={{
                emptyText: (
                  <NoDataFound
                    addText="New sms template"
                    onAdd={() => setShowAdd(true)}
                    description="Click the New Desing button on the Desings/Sms page to create a new sms desing"
                    title="You have not created any sms desings yet"
                  />
                ),
              }}
            ></Table>
          )}
        </Card>
      </Space>

      <AddSmsTemplate open={showAdd} setOpen={setShowAdd} onSubmit={onAdd} />

      {showUpdate && (
        <EditSmsTemplate
          open={showUpdate}
          setOpen={setShowUpdate}
          onUpdate={onUpdate}
          id={current}
        />
      )}
    </>
  );
};
export default SmsTemplates;
