import React, { useContext } from 'react'
import { DealsManagementContext } from '../context/DealsManagementContext'

const useDealsManagementCtx = () => {
    const dealsManagement  = useContext(DealsManagementContext)
  return (
    {...dealsManagement}
  )
}

export default useDealsManagementCtx