import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { EmailSenderContext } from "../EmailSenderContext";

const EmailSenderCreatedDateFromSelect = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(EmailSenderContext);

  return (
    <>
      <Text>From</Text>
      <DatePicker
        size="large"
        defaultValue={filters?.from}
        onChange={(e) => setFilters((prev) => ({ ...prev, from: e }))}
        placeholder="Select created date from"
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};

export default EmailSenderCreatedDateFromSelect;
