import { useState, useEffect } from "react";
import {Select, Typography} from "antd";

const OrderTemplatesDirection = ({onSortDirectionChanged}) => {
    const [options, setOptions] = useState();

    useEffect(() => {
        setOptions([
          {
            value: "Asc",
            label: "Asc",
          },
          {
            value: "Desc",
            label: "Desc",
          },
        ]);
      }, []);

      const {Text } = Typography
      const handleDirectionChanged = (e) => {
        onSortDirectionChanged(e);
      }
    return (
        <>
        <Text>Direction</Text>
        {options && (
          <Select size="large"
            style={{width:"100%"}}
            options={options}
            onChange={(e) => handleDirectionChanged(e)}
            defaultValue={options[0]}
          />
        )}
      </>
    )
}

export default OrderTemplatesDirection