import { Button, Card, Flex, Typography, Grid } from "antd";
import notfound from "../assets/images/notfound.png";
import notfounddark from "../assets/images/notfounddark.png";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";

const { useBreakpoint } = Grid;
const NoDataFound = ({ onAdd, addText, title, description }) => {
  const { Title, Text } = Typography;

  const { theme } = useContext(ThemeContext);
  const screens = useBreakpoint();
  return (
    <>
        <Flex align="center" justify="center" vertical style={{ height: 500 }}>
          <img width={screens.xs ? '100%' : '350px'} src={theme == "light" ? notfound : notfounddark} alt="no data" style={{ maxWidth: '350px' }} />
          <Title level={3} style={{ margin: 0, padding: 0 }}>
            {title}
          </Title>
          <Text>{description}</Text>
          <Button
            className="bold-button"
            size="large"
            onClick={() => onAdd()}
            style={{ marginTop: 10 }}
          >
            {addText}
          </Button>
        </Flex>
    </>
  );
};

export default NoDataFound;
