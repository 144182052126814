import { createContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { OrderDirection } from "../../shared/enums";

export const MediaContext = createContext({
  filters: {
    authors: [],
    selectedAuthors: [],
    search: null,
    from: null,
    to: null,
    types: null,
    orderBy: null,
    orderDirection: null
  },
  setFilters: () => {},
  showFilters: false,
  setShowFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    mostUsed: null,
  },
  setStats: () => {},

  documents: [],
  setDocuments: () => {},
});

export const MeidaContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();

  const [filters, setFilters] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [stats, setStats] = useState();

  useEffect(async () => {
    const statResponse = await axios.get("/Document/stats");

    setStats((prev) => ({
      ...prev,
      total: 0,
      first: statResponse.data.first,
      last: statResponse.data.last,
      mostUsed: {
        name: statResponse.data.authorName,
        profile: statResponse.data.authorProfile,
      },
    }));

    const authors = await axios.get(`Users/GetAuthors`);

    setFilters((prev) => ({
      ...prev,
      orderBy:"Created",
      orderDirection: OrderDirection.Dsc,
      selectedAuthors: [authors.data.yourId],
      authors: authors.data.data.map((d) => ({
        value: d.id,
        label: (
          <AuthorSelectListItem
            firstname={d.firstname}
            lastname={d.lastname}
            profile={d.profileImagePath}
          />
        ),
      })),
    }));
  }, []);

  return (
    <MediaContext.Provider
      value={{
        filters,
        setFilters,

        showFilters,
        setShowFilters,

        stats,
        setStats,
      }}
    >
      {children}
    </MediaContext.Provider>
  );
};
