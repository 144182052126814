import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { EmailEditor } from "react-email-editor";
import {
  Button,
  Card,
  Flex,
  Form,
  Typography,
  Select,
  Col,
  Row,
  Input,
  message,
  Modal,
  Space,
  FloatButton,
  Switch 
} from "antd";
import { AiOutlineRollback } from "react-icons/ai";
import { CommentOutlined, CustomerServiceOutlined } from '@ant-design/icons';
import TextArea from "antd/es/input/TextArea";
import logo from '../../../assets/images/logo.png'
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { ThemeContext } from "../../../context/ThemeContext";
const EditTemplate = () => {
  const [loading, setLoading] = useState(false);
  const { Title, Text } = Typography;
  const {theme} = useContext(ThemeContext)
  const emailEditorRef = useRef(null);
  const { Option } = Select;
  let { id } = useParams();

  const [selectedCategory, setSelectedCategory] = useState();
  const [templateCategoryId, setTemplateCategoryId] = useState();

  const handleSelectedCategoryChanged = (selected) => {
    setSelectedCategory(selected);
  };

  const [showBottom, setShowBottom] = useState(false)

  const [saveOpen, setSaveOpen] = useState(false)
  const [form] = Form.useForm();

  const axiosPrivate = useAxiosPrivate();

  const [templateHtml, setTemplateHtml] = useState("");
  const [templateStyle, setTemplateStyle] = useState("");

  const [loaded, setLoaded] = useState(false);

  const [options, setOptions] = useState([]);

  const navigate = useNavigate();

  const handleSave = async (e) => {
    setLoading(true);

    emailEditorRef.current.editor.exportHtml(async (data) => {
      const { design, html } = data;

      var formData = new FormData();

      formData.append("Id", id);
      formData.append("Html", html);
      formData.append("Name", e.name);
      formData.append("Style", JSON.stringify(design));
      formData.append("CategoryId", e.category);
      formData.append("Description", e.description);

      var response = await axiosPrivate.put("/Template", formData);

      setLoaded(false);
      if (!response || !response.data.success) {
        message.error(response.data.message);
        return;
      }
      navigate("/Templates/Yours");
    });
  };

  const onLoaded = () => {
    if (loaded) {
      return;
    }
    setLoaded(true);
    emailEditorRef.current.editor.loadDesign(JSON.parse(templateStyle));
  };

  useEffect(async () => {
    const response = await axiosPrivate.get(`/Template/${id}`);
    setTemplateHtml(response.data.data.html);
    setTemplateStyle(response.data.data.style);
    const ctgResponse = await axiosPrivate.get(
      "/Template/GetTemplateCategories"
    );

    setTemplateCategoryId(response.data.data.templateCategoryId);

    setOptions(
      ctgResponse.data.data.map((d) => ({ value: d.id, label: d.name }))
    );

    form.setFieldsValue({
      name: response.data.data.name,
      description: response.data.data.description,
      category: response.data.data.templateCategoryId,
    });
  }, []);

  return (
    <>
      <SaveModal adding={loading} open={saveOpen} setOpen={setSaveOpen} options={options} form={form} onSave={handleSave} />
      {templateStyle && (
        <EmailEditor
          ref={emailEditorRef}
          design={templateStyle}
          onReady={onLoaded}
          style={{ flex: 1 }}
        />
      )}

       

<div style={{
        height: "60px",
        width: "100%",
        position: "fixed",
        bottom: 0,
        display: showBottom ? "block" : "none",
      }}>

        <Flex style={{
          backdropFilter: "blur(24px)",
          background: theme == "dark" ? "hsla(228, 65%, 13%, .1)" : "hsla(0,0%,100%,.1)",
          marginLeft: 5,
          height: "60px",
          marginRight: 5,
          borderTop: `1px solid #cbd5e0`
        }} align="center" justify="space-between" gap={6}>
          <Flex align="center" justify="start" gap={6}>
            <img src={logo} width={30} loading="lazy" alt="logo" />
            <Text strong>&copy; Tretek {new Date().getFullYear()}.</Text>
          </Flex>
          <Space size={6}>
            <Button size="large" onClick={() => navigate(-1)}>Cancel</Button>
            <Button size="large" type="primary" onClick={() => setSaveOpen(true)}>Save changes</Button>
          </Space>
        </Flex>
      </div>

      <Button shape="circle"
        className="flex-button"
        onClick={() => setShowBottom(prev => !prev)}
        icon={showBottom ? <FaAngleDown /> : <FaAngleUp />}
        style={{
          marginBottom: showBottom ? 45 : 6,
          position: "absolute",
          left: "50%",
          bottom: 0,
          transform: "translate(-50%)"
        }}>
      </Button>
    </>
  );
};

const SaveModal = ({ open, setOpen, onSave, options, form, adding }) => {
  const { Title, Text } = Typography

  return <>
    <Modal open={open} onCancel={() => setOpen(false)} footer={
      <Flex align="center" justify="end" gap={6}>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button loading={adding} type="primary" onClick={() => form.submit()}> Save changes</Button>
      </Flex>
    } title={<Title style={{ margin: 0 }} level={4}>
      Save template changes
    </Title>}>
      <Form form={form} onFinish={(e) => onSave(e)}>
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: "Please provide a name!",
            }, {
              max: 50, message: "Name should be at most 50 characters long"
            }
          ]}
        >
          <Input
            size="large"
            placeholder="Enter a name for your design"
            allowClear
            count={{
              show: true,
              max: 50,
            }}
          />
        </Form.Item>
        <Text>Category</Text>
        <Form.Item
          name="category"
          rules={[
            {
              required: true,
              message: "A category is required",
            },
          ]}
        >
          <Select
            placeholder="Select a category for your template"
            size="large"
            style={{ width: "100%" }}
          >
            {options &&
              options.map((opt) => (
                <>
                  <Option size="large" value={opt.value}>
                    {opt.label}
                  </Option>
                </>
              ))}
          </Select>
        </Form.Item>

        <div>
          <Text>Description</Text>
          <Form.Item
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description!",
              },
            ]}
          >
            <TextArea
              size="large"
              count={{
                show: true,
                max: 100,
              }}
              placeholder="Enter a description for your template"
            />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  </>
}
export default EditTemplate;
