import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const RemoveEsEmail = ({ id, onDelete }) => {
  const axios = useAxiosPrivate();

  const remove = async () => {
    const response = await axios.delete(`/TargetAudience/delete/tapzap/${id}`);

    if (response.data.success) {
      onDelete(id);
    } else {
      message.error(response.data.message);
    }
  };

  return (
    <>
      <Popconfirm
        okText="Yes, remove it"
        onConfirm={remove}
        title="Are you sure?"
        description="Are you sure you want to remove this contact"
      >
        Remove contact
      </Popconfirm>
    </>
  );
};

export default RemoveEsEmail;
