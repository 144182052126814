import React from 'react'
import { Outlet } from 'react-router-dom/dist'
import "./Deals.css"
import { DealsManagementContextProvider } from '../../context/DealsManagementContext'
import { DealListContextProvider } from './DealListContext'
const DealsLayout = () => {
  return (
    <DealsManagementContextProvider>
      <DealListContextProvider>
        <Outlet/>
      </DealListContextProvider>
    </DealsManagementContextProvider>
  )
}

export default DealsLayout