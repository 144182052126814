import { Form, Select, Space, Typography } from "antd";

const TaskReminderSelection = ({ name }) => {
  const { Text } = Typography;
  const options = [
    {
      label: "15 minutes before",
      value: 1,
    },
    {
      label: "30 minutes before",
      value: 2,
    },
    {
      label: "1h before",
      value: 3,
    },
    {
      label: "2h before",
      value: 4,
    },
    {
      label: "1 day before",
      value: 5,
    },
    {
      label: "2 day before",
      value: 6,
    },
    {
      label: "1 week before",
      value: 7,
    },
  ];
  return (
    <Space direction="vertical" size={0} className="w-100">
      <Text>Remind before</Text>
      <Form.Item name={name}>
        <Select
          defaultValue={1}
          size="large"
          className="w-100"
          options={options}
        />
      </Form.Item>
    </Space>
  );
};

export default TaskReminderSelection;
