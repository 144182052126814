import { Children, createContext, useContext, useEffect, useState } from "react";
import { CampaignType } from "../../shared/enums";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { Avatar, Flex, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";
import env from '../../env.json'
import { CampaignStatusType, OrderDirection } from "../../shared/enums";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { SearchContext } from "../../context/SearchContext";

export const CampaignContext = createContext({
    filters: {
        authors: [],
        status: null,
        type: null,
        orderBy: null,
        orderDirection: null,
        search: null,
        selectedAuthors: null,
        from: null,
        to: null
    },
    setFilters: () => {},

    showFilters: false,
    setShowFilters: () => {},

    stats: [{
        all: 0,
        completed: 0,
        invalid: 0,
        draft: 0,
        reach: 0,
        scheduled: 0,
    }],
    setStats: () => {},

    type: CampaignType.EMAIL,
    setType : () => {},

    authors: [],
    setAuthors: () => {},

    you: null,
    setYou: () => {},

    authorLoading: false,
    setAuthorLoading: () => {},

    campaigns: [],
    setCampaings: () => {},
    campaignsLoading: false,
    setCampaingsLoading: () => {},
    count: 0,
    setCount: () => {}, 

    page: 1,
    setPage: () => {}
})

export const CampaignContextProvider = ({children}) => {
    const perPage = 7;

    const axiosPrivate = useAxiosPrivate();
    const { debouncedValue } = useContext(SearchContext);
    const [showFilters, setShowFilters] = useState(() => false);
    const [filters, setFilters] = useState(() => ({
        authors: [],
        status: null,
        type: null,
        orderBy: null,
        orderDirection: null,
        search: null,
        selectedAuthors: null,
        from: null,
        to:null
    }))
    
    const [stats, setStats] = useState()

    const [you, setYou] = useState()

    const [authorLoading, setAuthorLoading] = useState(false)
  
    const [campaigns, setCampaings] = useState();
    const [campaignsLoading, setCampaingsLoading] = useState(() => false)
    const [page, setPage] = useState(1)

    const fetchCampaigns = async (page) => {
      
        setCampaingsLoading(true);
    
        const response = await axiosPrivate.post(
          `/Campaign/GetAll`,
          {
            page: page,
            pageSize: perPage,
            authors: filters?.selectedAuthors,
            status: filters?.status,
            goal: filters?.goals,
            orderBy: filters?.orderBy,
            orderDirection: filters?.orderDirection,
            search: debouncedValue,
            types: filters?.type,
            from: filters?.from,
            to: filters?.to,
            search: debouncedValue
          }
        );

        setCampaings(
          response.data.items?.map((c) => ({ ...c, key: c.id }))
        );

        setStats(prev => ({
          ...prev,
          all: response.data.total,
          completed: response.data.completed,
          invalid: response.data.invalid,
          draft: response.data.draft,
          reach: response.data.reach,
          scheduled: response.data.scheduled,
          first: response?.data.first,
          last: response?.data?.last,
          authorImage: response?.data?.authorProfile,
          authorName: response?.data?.authorName
        }));
        setCampaingsLoading(false);
      };

      
      useEffect(() => {
        fetchCampaigns(page);
      }, [filters, debouncedValue, page]);
    
      useEffect(async () => {
        setAuthorLoading(true)
        const response = await axiosPrivate.get(`Users/GetAuthors`);
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            status: [
              CampaignStatusType.Finished,
              CampaignStatusType.NotStarted,
              CampaignStatusType.OnGoing,
              CampaignStatusType.Invalid
            ],
            orderBy: "Created",
            type:[
              CampaignType.EMAIL,
              CampaignType.SMS,
              CampaignType.WHATSAPP
            ],
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));

          setAuthorLoading(false)
    }, [])

    

    return (
        <CampaignContext.Provider value={{
            you,
            authorLoading,
            filters,
            setFilters,
            showFilters,
            setShowFilters,

            stats,
            setStats,
            
            campaigns,
            setCampaings,
            campaignsLoading,
            page,
            setPage
        }}>
            {children}
        </CampaignContext.Provider>
    )
}