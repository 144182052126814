import { Col, Row } from "antd";
import Stat from "../../../shared/Stat";
import { MdCleaningServices } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";
import { BiSolidErrorCircle } from "react-icons/bi";
import { RiDraftFill } from "react-icons/ri";

const CleanUpDetailsStats = ({total, safe, invalid, risky}) => {
    return (
        <>
          <Row gutter={[24, 24]}>
            <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
              <Stat
                name="Total"
                value={total}
                icon={<MdCleaningServices style={{ fontSize: 22 }} />}
                loading={false}
              />
            </Col>
            <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
              <Stat
                name="Safe"
                value={safe}
                icon={<FaCalendarCheck style={{ fontSize: 20 }} />}
                loading={false}
              />
            </Col>
            <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
              <Stat
                name="Invalid"
                value={invalid}
                icon={<BiSolidErrorCircle style={{ fontSize: 22 }} />}
                loading={false}
              />
            </Col>
            <Col xxl={6} xl={8} lg={8} md={8} sm={12} xs={24}>
              <Stat
                name="Risky"
                value={risky}
                icon={<RiDraftFill style={{ fontSize: 20 }} />}
                loading={false}
              />
            </Col>
          </Row>
        </>
      );
}

export default CleanUpDetailsStats