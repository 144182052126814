import { useState, useEffect } from "react";
import {Select, Typography} from "antd";

const TemplateVisibilitySelect = ({ visiblityOptionsChanged }) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    setOptions([
      {
        value: "1",
        label: "Public",
      },
      {
        value: "2",
        label: "Private",
      },
    ]);
  }, []);
  const {Text} = Typography
  const handleOPtionsChanged = (e) => {
    
    if(e?.length == 2){
        visiblityOptionsChanged()
    }else if(e?.length == 1){
        visiblityOptionsChanged(e[0])
    }else{
        visiblityOptionsChanged()
    }
  }

  return (
    <>
      <Text>Visibility</Text>
      {options && (
        <Select size="large"
          style={{width:"100%"}}
          options={options}
          onChange={(e) => handleOPtionsChanged(e)}
          mode="multiple"
          maxTagCount="responsive"
        />
      )}
    </>
  );
};
export default TemplateVisibilitySelect;
