import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { CampaignContext } from "../CampaignContext";

const CreateFromDateFilter = () => {
    const { Text } = Typography;

    const {filters, setFilters} = useContext(CampaignContext);

    return <>
        <Text text="Order by">From</Text>
        <DatePicker size="large"
            placeholder="Select created date start"
            defaultValue={filters?.from}
            onChange={(e) => setFilters(prev=>({
                ...prev,
                from: e
            }))}
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default CreateFromDateFilter;