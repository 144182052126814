import React, { useContext } from "react";
import { Layout, Typography, Flex, Space, Button } from "antd";
import UserProfileMenu from "./UserProfileMenu";
import { useLocation } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import { ThemeContext } from "../../../context/ThemeContext";
const Header = ({ collapsed, setCollapsed }) => {

  const { Header } = Layout;
  const { Title, Text } = Typography;

  const location = useLocation();

  const { theme } = useContext(ThemeContext);

  const getIcon = () => {
    if (location.pathname.includes("Campaigns")) {
      return "Campaigns";
    } else if (location.pathname.includes("Media")) {
      return "Media";
    } else if (location.pathname.includes("Templates")) {
      return "Designs";
    } else if (location.pathname.includes("audience")) {
      return "Audiences";
    } else if (location.pathname.includes("Users")) {
      return "Team";
    } else if (location.pathname.includes("Senders")) {
      return "Senders";
    } else if (location.pathname.includes("Deals")) {
      return "Deals";
    } else {
      return "Dashboard";
    }
  };
  return (
    <>
      <Header
        theme={"light"}
        style={{
          position: "sticky",
          top: 0,
          zIndex: 2,
          margin: "10px 0px",
          minHeight: "80px",
          maxWidth: "100%",
          borderRadius: "10px",
          backdropFilter: "blur(24px)",
          background:
            theme == "dark" ? "hsla(228, 65%, 13%, .1)" : "hsla(0,0%,100%,.1)",
          padding: "0.5rem 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          height: "fit-content"
        }}
      >
        <Space>
          <Button
            style={{
              fontSize: 22,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            shape="circle"
            size="large"
            type="text"
            onClick={() => setCollapsed(!collapsed)}
            icon={<IoMenu />}
          />

          <Flex vertical align="start" justify="center" gap={0}>
            <Text style={{ padding: 0, margin: 0 }}>
              {location.pathname?.substring(1).replace(/\//g, " / ")}
            </Text>
            <Title style={{ padding: 0, margin: 0, marginTop: "-10px" }}>
              {getIcon()}
            </Title>
          </Flex>
        </Space>

        <UserProfileMenu collapsed={collapsed} setCollapsed={setCollapsed} />
      </Header>
    </>
  );
};

export default Header;
