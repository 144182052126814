import { Button, Card, Flex, Modal, Result } from "antd";
import { useNavigate, useParams } from "react-router-dom";

const NumberPaymentDone = () => {
  const navigate = useNavigate();

  const { ios, areaCode } = useParams();

  return ( <Modal open={true} footer={null} onCancel={() => {
    if(!areaCode) {
      navigate(`/price/list/${ios}`);
  }else{
      navigate(`/price/list/${ios}/${areaCode}`);
  }
  }}>
   <Flex
    align="center"
    justify="center"
    style={{ width: "100%"}}
  >
    <Result
      status="success"
      title="Successfully Purchased New Phone Number!"
      subTitle="Number configuration takes 1-5 minutes, please wait."
      extra={[
        <Button
          type="primary"
          key="console"
          size="large"
          onClick={() => navigate("/Senders/sms")}
        >
          Check numbers
        </Button>,
        <Button
          key="buy"
          size="large"
          onClick={() => {
            if(!areaCode) {
                navigate(`/price/list/${ios}`);
            }else{
                navigate(`/price/list/${ios}/${areaCode}`);
            }
          }}
        >
          Buy another one
        </Button>,
      ]}
    />
    </Flex></Modal>
  );
};

export default NumberPaymentDone;
