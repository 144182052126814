import { useState } from "react";
import {Select, Typography} from "antd";
import ThemeLabel from "../../../shared/ThemeLabel";

const UserOrderBy = ({onChanged}) => {
    const {Text} = Typography
    const [options, setOptions] = useState(() => [
        {
            value :"Name",
            label: "Name",
        },
        {
            value: "Id",
            label: "Id"
        },
        {
            label: "Email",
            value: "Email"
        }
    ])

    const handleSelectedOrderByChanged = (e)=>{
        onChanged(e)
    }

    return (<>
    {
        options&&<>
        <ThemeLabel text="Order by"></ThemeLabel>
        <Select className="little-transparent-input"
            options={options}
            size="large"
            style={{width:"100%"}}
            onChange={handleSelectedOrderByChanged}/>
            </>
        }
    </>)
}

export default UserOrderBy;