import { Button, Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { TaskContext } from "../TaskContext";
import { TaskPriority } from "../../../shared/enums";

const TaskPriorityFilter = () => {
  const { Text } = Typography;
  const { filters, setFilters } = useContext(TaskContext);

  const options = [{
    value: TaskPriority.High,
    label:"High"
  },
  {
    value: TaskPriority.Normal,
    label: "Normal" 
  }
]
  return (
    <>
      <Text>Priority</Text>
      <Select
        options={options}
        size="large"
        maxTagCount="responsive"
        placeholder="Select task types"
        style={{ width: "100%" }}
        mode="multiple"
        defaultValue={filters?.priorities}
        onChange={e => setFilters(prev => ({...prev, priorities: e}))}
      />
    </>
  );
};

export default TaskPriorityFilter;
