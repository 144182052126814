import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState } from "react";

const SuspendCoupon = ({ coupon, handleSuspended }) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(() => false);

  const handleUpdate = async () => {
    setLoading(true);

    const response = await axios.patch(
      `/Coupon/${coupon.id}/suspend/${!coupon.suspended}`
    );

    if (response.data.success) {
      handleSuspended(coupon.id);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };
  return (
    <>
      <Popconfirm
        onConfirm={handleUpdate}
        okText="Yes, do it"
        title="Are you sure?"
        description="Are you sure you want to complete this operation?"
      >
        {!coupon.suspended && "Suspend coupon"}
        {coupon.suspended && "Set coupon live"}
      </Popconfirm>
    </>
  );
};

export default SuspendCoupon;
