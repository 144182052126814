import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Space,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";

const UpdateProduct = ({ show, setShow, id, onUpdate }) => {
  const [form] = Form.useForm();
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    const response = await axios.get(`/product/${id}`);

    if (response.data.success) {
      form.setFieldValue("name", response.data.name);
      form.setFieldValue("description", response.data.description);
      form.setFieldValue(
        "autoFollowupEmails",
        response.data.autoFollowupEmails
      );
      form.setFieldValue(
        "autoFollowupNotifications",
        response.data.autoFollowupNotifications
      );
    } else {
      message.error(response.data.message);
    }
  }, []);

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.put(`/Product/Update`, {
      name: e.name,
      id: id,
      description: e.description,
      autoFollowupEmails: e.autoFollowupEmails,
      autoFollowupNotifications: e.autoFollowupNotifications,
    });

    if (response.data.success) {
      onUpdate({
        id: id,
        name: e.name,
        description: e.description,
        autoFollowupEmails: e.autoFollowupEmails,
        autoFollowupNotifications: e.autoFollowupNotifications,
      });
      setShow(false);
    } else {
      message.error(response.data.message);
    }

    setLoading(false);
  };

  return (
    <Modal
      destroyOnClose
      title={
        <Title style={{ margin: 0 }} level={4}>
          Update product
        </Title>
      }
      open={show}
      onCancel={() => setShow(false)}
      footer={
        <Flex align="center" justify="end" gap={5}>
          <Button onClick={() => setShow(false)}>Cancel</Button>
          <Button
            type="primary"
            loading={loading}
            onClick={() => form.submit()}
          >
            Save changes
          </Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={submit}>
        <Text>Name</Text>
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please enter a product name" }]}
        >
          <Input size="large" placeholder="Enter a product name" />
        </Form.Item>

        <Text>Description</Text>
        <Form.Item name="description">
          <TextArea
            placeholder="Enter product description"
            size="large"
          ></TextArea>
        </Form.Item>

        <Space className="w-100" size={0} direction="vertical">
          <Form.Item name="autoFollowupEmails" valuePropName="checked">
            <Checkbox>Automatic email followups</Checkbox>
          </Form.Item>
          <Form.Item name="autoFollowupNotifications" valuePropName="checked">
            <Checkbox>Automatic notification followups</Checkbox>
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default UpdateProduct;
