import { Button, Card, Col, Flex, Grid, Row, Space, Table, Typography } from "antd";
import NotificationTemplatesStats from "./NotificationTemplatesStats";
import { useContext, useState } from "react";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import NotificationTemplatesAuthorFilter from "./Filters/NotificationTemplatesAuthorFilter";
import NotificationTemplatesFromDateFilter from "./Filters/NotificationTemplatesFromDateFilter";
import NotificationTemplatesToDateFilter from "./Filters/NotificationTemplatesToDateFilter";
import NotificationTemplatesOrderByFilter from "./Filters/NotificationTemplatesOrderByFilter";
import NotificationTemplatesOrderDirectionFilter from "./Filters/NotificationTemplatesOrderDirectionFilter";
import { NotificationTemplatesContext } from "./NotificationTemplatesContext";
import TableLoading from "../../../shared/TableLoading";
import NoDataFound from "../../../shared/NoDataFound";

const { useBreakpoint } = Grid;

const NotificationTemplatesList = () => {
    const {Text, Title} = Typography
    const [showFilters, setShowFilters] = useState(false)
  const screens = useBreakpoint();
    const perPage = 7;
  const {loading, templates, stats, page, setPage  } = useContext(NotificationTemplatesContext)

  const columns = [];

  return (
    <>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <NotificationTemplatesStats />

        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Designs
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { flexBasis: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  block={screens.xs}
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                >
                  New design
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <NotificationTemplatesAuthorFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <NotificationTemplatesFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <NotificationTemplatesToDateFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <NotificationTemplatesOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <NotificationTemplatesOrderDirectionFilter />
              </Col>
            </Row>
          )}
        </Card>


        <Card className="zero-margin-padding">
          {loading && <TableLoading />}
          {!loading && (
            <Table
              style={{ marginTop: 1 }}
              columns={columns}
              dataSource={templates}
              locale={{
                emptyText: (
                  <NoDataFound
                    title="You have not created any notification templates yet"
                    description="Click the new design button on Designs/NOtifications to create a new notification desing"
                    addText="New Notification Template"
                  />
                ),
              }}
              pagination={{
                current: page,
                pageSize: perPage,
                total: stats?.total,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
            />
          )}
        </Card>

      </Space>
    </>
  );
};

export default NotificationTemplatesList;
