import {
  Avatar,
  Card,
  Col,
  Collapse,
  Flex,
  Row,
  Skeleton,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import TemplateSkeletonCard from "../../templates/Components/TemplateSkeletonCard";

const Loading = () => {
  return (
    <Row gutter={12}>
      <Col xs={24} sm={24} md={18} lg={18} xl={18} xxl={18}>
        <Card
          title={
            <Flex align="center" justify="space-between">
              <Skeleton.Input active />

              <Space>
                <Skeleton.Input active />
                <Skeleton.Input active />
                <Skeleton.Avatar active />
              </Space>
            </Flex>
          }
        >
          <Skeleton.Input style={{ margin: "2em 0 1.5em 0" }} active />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                </Space>
              </Card>
            </Col>
          </Row>

          <Skeleton.Input style={{ margin: "2em 0 1.5em 0" }} active />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={6} ld={6} xl={6} xxl={6}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" />
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} ld={6} xl={6} xxl={6}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" />
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} ld={6} xl={6} xxl={6}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" />
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={6} ld={6} xl={6} xxl={6}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" />
                </Space>
              </Card>
            </Col>
          </Row>

          <Skeleton.Input style={{ margin: "2em 0 1.5em 0" }} active />
          <Row gutter={24}>
            <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                </Space>
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
              <Card>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                  <Skeleton.Input active className="w-100" size="small" />
                </Space>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
        <div style={{ position: "sticky", top: 85 }}>
          <TemplateSkeletonCard />
        </div>
      </Col>
    </Row>
  );
};

export default Loading;
