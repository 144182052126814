import { useContext } from "react";
import { CompanyListContext } from "../CompanyListContext";
import { DatePicker, Typography } from "antd";

const CompanyFromDateFilter = () => {
    const {filters ,setFilters} = useContext(CompanyListContext)

    const {Text} = Typography;

    return <>
        <Text>From</Text>
        <DatePicker size="large"
            placeholder="Select created date start"
            defaultValue={filters?.from}
            onChange={(e) => setFilters(prev=>({
                ...prev,
                from: e
            }))}
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default CompanyFromDateFilter;