import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Row,
  Select,
  Space,
  Typography,
  Checkbox,
  message,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import CreateSignature from "./CreateSignature";
import { List } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateFormat } from "../../../dateformat";
import { UserOutlined } from "@ant-design/icons";
import { MdEdit } from "react-icons/md";
import DeleteSignature from "./DeleteSignature";
import SignatureOptions from "./SignatureOptions";
import SignatureContent from "./SignatureContent";
import { FaRegClock } from "react-icons/fa";
import UpdateSignatureName from "./UpdateSignatureName";
import env from "../../../env.json";
import { FiPlus } from "react-icons/fi";

const SignatureList = () => {
  const axios = useAxiosPrivate();
  const { Title, Text, Link } = Typography;
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9999);

  const [signatures, setSignatures] = useState([]);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState();
  const [options, setOptions] = useState();

  const [defaultOnSend, setDefaultOnSend] = useState();
  const [defaultOnReply, setDefaultOnReply] = useState();

  const [currentSignature, setCurrentSignature] = useState();
  const [lines, setLines] = useState(false);

  useEffect(() => {
    setOptions(
      signatures?.map((c) => ({
        label: c.name,
        value: c.id,
      }))
    );
    
    setDefaultOnReply(signatures?.find(c => c.defaultOnReply));
    setDefaultOnSend(signatures?.find(c => c.defaultOnSend));
  }, [signatures]);

  useEffect(async () => {
    const response = await axios.post("/Signature/GetPaged", {
      pageSize: pageSize,
      page: page,
      search: search,
    });
    
    const getLinesResponse = await axios.get("/Signature/GetRemoveSignatureLines")

    setLines(getLinesResponse.data.data)
    setCount(response.data.count);
    setSignatures(response.data.data);
  }, []);

  const handleSuccess = (data) => {
    setSignatures((prev) => [...prev, data]);
    setCount((prev) => prev + 1);
  };

  const updateDefaultSend = async (e) => {
    const response = await axios.post(
      "/Signature/UpdateDefaultSendEmailSignature",
      { id: e }
    );

    if (response.data.success) {
      message.success("Default send signature updated");
    } else {
      message.error(response.data.message);
    }
  };

  const updateDefaultForward = async (e) => {
    const response = await axios.post(
      "/Signature/UpdateDefaultForwardEmailSignature",
      { id: e }
    );

    if (response.data.success) {
      message.success("Default send signature updated");
    } else {
      message.error(response.data.message);
    }
  };

  const onUpdateSuccess = (signature) => {
    setSignatures((prev) =>
      prev.map((c) =>
        c.id == signature.id ? { ...c, name: signature.name } : c
      )
    );
  };

  const onContentUpdateSuccess = (signature) => {
    setSignatures((prev) =>
    prev.map((c) =>
      c.id == signature.id ? { ...c, text: signature.text } : c
    )
  );
  }

  const updateLines = async (e) => {
   
    const response = await axios.post('/Signature/UpdateSignatureLines', {
        remove: e
    })

    if(response.data.success){
        message.success("Lines updated");
        setLines(e)
    }else{
        message.error(response.data.message)
    }
  }

  return (
    <>
      {updateOpen && (
        <UpdateSignatureName
          onSuccess={onUpdateSuccess}
          open={updateOpen}
          setOpen={setUpdateOpen}
          signature={currentSignature}
        />
      )}

      <CreateSignature
        open={open}
        setOpen={setOpen}
        onSuccess={handleSuccess}
      />
      
          <Flex align="center" justify="space-between" style={{marginBottom:5}}>
                  <Title style={{ margin: 5 }} level={4}>
                    Signatures ({count})
                  </Title>
                  <Button
                    className="primary-gradient-background flex-button bold-button"
                    type="primary"
                    size="large"
                    icon={<FiPlus style={{fontSize:20}}/>}
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    Add signature
                  </Button>
                </Flex>
                  
                  <Space className="w-100" direction="vertical" size={24}>

            <Card 
              className="zero-margin-padding"
              style={{boxShadow:"none" }}
              >
              <List
                bordered={false}
                style={{ border: "none"}}
                dataSource={signatures}
                renderItem={(item, ind) => (
                  <List.Item>
                    <Flex
                      align="center"
                      justify="space-between"
                      style={{ width: "100%", margin: "0px 15px" }}
                      >
                      <Space size="large">
                         <Text type="link" strong onClick={() => setCurrentSignature(item)}>
                            {item.name}
                          </Text>
                      </Space>

                      <Space>
                        <Tooltip title={item.authorName}>
                          <Avatar 
                            icon={<UserOutlined />}
                            src={`${env.fileUpload}${item.authorProfile}`}
                            />
                        </Tooltip>
                        <Button
                          shape="circle"
                          icon={<MdEdit />}
                          onClick={() => {
                            setUpdateOpen(true);
                            setCurrentSignature(item);
                          }}
                          ></Button>
                        <DeleteSignature
                          id={item.id}
                          onDelete={(id) => {
                            setSignatures((prev) =>
                              prev.filter((d) => d.id != id)
                          );
                          setCount((prev) => prev - 1);
                        }}
                        />
                      </Space>
                    </Flex>
                  </List.Item>
                )}
                ></List>
            </Card>

         {currentSignature && (
           <SignatureContent signature={currentSignature} onSuccess={onContentUpdateSuccess}/>
          )}
          </Space>
    </>
  );
};

export default SignatureList;
