import { Button, Card, Flex, Space, Typography, Grid } from "antd";
import { useState } from "react";
import CreateSenderModal from "./CreateSenderModal";
import { FiPlus } from "react-icons/fi";
import SenderTable from "./SenderTable";
import SenderStats from "./SenderStats";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import SenderFilters from "./SenderFilters";
const { useBreakpoint } = Grid;
const SendersList = () => {
  const perPage = 7;
  const [showFilters, setShowFilters] = useState(false)

  const { Text, Title } = Typography;
  const [total, setTotal] = useState();
  const [add, setAdd] = useState(false);
  const [data, setData] = useState();
  const screens = useBreakpoint();

  const handleDataAdded = (addedData) => {
    setTotal(total + 1);
    if (data.length < perPage) {
      setData([...data, addedData]);
    }
  };

  return (
    <>
      <CreateSenderModal
        isOpen={add}
        setIsOpen={setAdd}
        dataAdded={handleDataAdded}
      />

      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <SenderStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between" wrap="wrap"
              style={{
                ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
              }}>
              <Title level={4} style={{ margin: 0 }}>
                Senders
              </Title>

              <Flex gap={10} wrap="wrap"
                  style={{
                    ...(screens.xs ? { 'flexBasis': '100%', 'gap': '10px' } : {})
                  }}
                >
                <Button
                  size="large"
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  block={screens.xs}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  onClick={() => setAdd(true)}
                  type="primary"
                  size="large"
                  className="primary-gradient-background flex-button bold-button"
                  block={screens.xs}
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                >
                  New sender
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && <SenderFilters />}
        </Card>

        <SenderTable
          data={data}
          setData={setData}
          total={total}
          setTotal={setTotal}
        />
      </Space>
    </>
  );
};

export default SendersList;