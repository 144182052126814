import {
  Button,
  Card,
  Col,
  Dropdown,
  Flex,
  Progress,
  Row,
  Select,
  Space,
  Steps,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import { CardStatus } from "../../../shared/enums";
import useDealsDetailsCtx from "../../../hooks/useDealsDetailsCtx";
import DealDetaisWonModal from "../DealDetails/DealDetaisWonModal";
import DealDetailsLostModal from "../DealDetails/DealDetailsLostModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { FaFolderOpen, FaHandshakeSimple, FaTrophy } from "react-icons/fa6";
import { MdArrowDropDown, MdOutlineEqualizer } from "react-icons/md";
import { AiFillSchedule } from "react-icons/ai";
import { RiGitCommitFill } from "react-icons/ri";
import { IoMdCloseCircle } from "react-icons/io";
import { DateFormat } from "../../../dateformat";
import { DealListContext } from "../DealListContext";

const CardStatusBreadCrumbs = () => {
  const { Text, Title } = Typography;

  const axios = useAxiosPrivate();

  const {refreshStats} = useContext(DealListContext);

  const { dealStatus, setDealStatus, dealDetails } = useDealsDetailsCtx();
  const [showWonDealModal, setShowWonDealModal] = useState(false);
  const [showLostDealModal, setShowLostDealModal] = useState(false);
  const { id } = useParams();

  const changeStatus = async (newStage) => {
    if (dealStatus.status === id) return;

    if (newStage == CardStatus.Won || newStage == CardStatus.Closed) {
      //setShowLostDealModal(true)
      return;
    }

    const response = await axios.post(`/Deal/UpdateDealStatus`, {
      id: id,
      newStage: newStage,
    });

    if (response.data.success) {
      setDealStatus((prev) => ({ ...prev, status: newStage }));
      await refreshStats();
    } 
  };

  const items = [
    {
      label: "New",
      value: CardStatus.New,
      onClick: () => changeStatus(CardStatus.New),
      disabled: dealStatus.status == CardStatus.New,
      icon:<FaFolderOpen/>
    },
    {
      label: "Qualifying",
      value: CardStatus.Qualifying,
      onClick: () => changeStatus(CardStatus.Qualifying),
      disabled: dealStatus.status == CardStatus.Qualifying,
      icon:<MdOutlineEqualizer style={{fontSize:20}}/>
    },
    {
      label: "Demo Scheduled",
      value: CardStatus.DemoScheduled,
      onClick: () => changeStatus(CardStatus.DemoScheduled),
      disabled: dealStatus.status == CardStatus.DemoScheduled,
      icon:<AiFillSchedule/>
    },
    {
      label: "Pending Commitment",
      vlaue: CardStatus.PendingCommitment,
      onClick: () => changeStatus(CardStatus.PendingCommitment),
      disabled: dealStatus.status == CardStatus.PendingCommitment,
      icon:<RiGitCommitFill style={{fontSize:20}}/>
    },
    {
      label: "In Negotiation",
      value: CardStatus.InNegotiation,
      onClick: () => changeStatus(CardStatus.InNegotiation),
      disabled: dealStatus.status == CardStatus.InNegotiation,
      icon:<FaHandshakeSimple />
    },
    {
      label: "Won",
      value: CardStatus.Won,
      onClick: () => setShowWonDealModal(true),
      disabled: dealStatus.status == CardStatus.Won,
      icon:<FaTrophy/>
    },
    {
      label: "Lost",
      value: CardStatus.Closed,
      onClick: () => setShowLostDealModal(true),
      disabled: dealStatus.status == CardStatus.Closed,
      icon:<IoMdCloseCircle style={{fontSize:18}}/>
    },
  ];

  return (
    <>
      <Title style={{ margin: 5 }} level={4}>
        Stage
      </Title>

      <Card size="small" style={{ boxShadow: "none" }}>
        <Row gutter={20}>
          <Col md={1.5}>
            <Flex justify="end" style={{ height: "100%" }}>
              <Flex align="center" justify="center" style={{width:30, height:30, borderRadius:30, background:"#e3e3e3", color:"#1b254b"}}>

              {dealStatus.status == CardStatus.New ? (
                <FaFolderOpen style={{ fontSize: 18}} />
              ) : dealStatus.status == CardStatus.Qualifying ? (
                <MdOutlineEqualizer style={{ fontSize: 22 }} />
              ) : dealStatus.status == CardStatus.DemoScheduled ? (
                <AiFillSchedule style={{ fontSize: 22 }} />
              ) : dealStatus.status == CardStatus.PendingCommitment ? (
                <RiGitCommitFill style={{ fontSize: 22 }} />
              ) : dealStatus.status == CardStatus.InNegotiation ? (
                <FaHandshakeSimple style={{ fontSize: 22 }} />
              ) : dealStatus.status == CardStatus.Won ? (
                <FaTrophy style={{ fontSize: 22 }} />
              ) : (
                <IoMdCloseCircle style={{ fontSize: 22 }} />
              )}
              </Flex>

            </Flex>
          </Col>
          <Col md={18}>
            <Space direction="vertical" size={0} className="w-100">
              <Title style={{ margin: 0, padding: 0 }} level={4}>
                {dealStatus.status == CardStatus.New
                  ? "1 / 6 - New"
                  : dealStatus.status == CardStatus.Qualifying
                  ? "2 / 6 - Qualifying"
                  : dealStatus.status == CardStatus.DemoScheduled
                  ? "3 / 6 - Demo Scheduled"
                  : dealStatus.status == CardStatus.PendingCommitment
                  ? "4 / 6 - Pending Commitment"
                  : dealStatus.status == CardStatus.InNegotiation
                  ? "5 / 6 - In Negotiation"
                  : dealStatus.status == CardStatus.Won
                  ? "6 / 6 Won"
                  : "6 / 6 LOST"}
              </Title>
              <Progress
                className="w-100"
                strokeColor="#422afb"
                percent={dealStatus.status * 17}
                showInfo={false}
              />
              <Text>
                On this stage since{" "}
                {dealStatus?.inThisStage?.toLocaleDateString(
                  "en-US",
                  DateFormat
                )}
                , opened on{" "}
                {dealDetails?.createdDate?.toLocaleDateString(
                  "en-US",
                  DateFormat
                )}
              </Text>
            </Space>
          </Col>

          <Col md={4}>
            <Flex align="center" justify="center" style={{ height: "100%" }}>
              <Dropdown
                trigger={["click"]}
                className="w-100"
                size="large"
                menu={{ items }}
              >
                <Button
                  size="large"
                  className="flex-button"
                  style={{ fontWeight: "bold" }}
                >
                  Change <MdArrowDropDown fontSize={22} />
                </Button>
              </Dropdown>
            </Flex>
          </Col>
        </Row>
      </Card>
      <DealDetaisWonModal
        open={showWonDealModal}
        setOpen={setShowWonDealModal}
      />
      <DealDetailsLostModal
        open={showLostDealModal}
        setOpen={setShowLostDealModal}
      />
    </>
  );
};

export default CardStatusBreadCrumbs;
