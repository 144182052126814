import { Button, Flex, List, Space, Typography } from "antd";
import { FaLock } from "react-icons/fa";
import { RiPassValidFill } from "react-icons/ri";
import { FaSquarePhone } from "react-icons/fa6";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { GrLinkNext } from "react-icons/gr";
const PhoneNumber = ({current, setCurrent}) => {
  const { Title, Text } = Typography;   

  const navigate = useNavigate();

  const listItems = [
    {
      title: "Verification",
      icon: <FaLock />,
      description:
        "You will receive a verification code to authenticate your number on our platform.",
      link: "",
    },
    {
      title: "Number",
      icon: <FaSquarePhone />,
      description:
        "The phone number for your business must be a valid phone number which meets the WhatsApp business Platform criteria",
      link: "",
    },
    {
      title: "Validations",
      icon: <RiPassValidFill />,
      description:
        "You need a phone number that has not been associated to another Business Service provider.",
      link: "",
    },
  ];
  return (
    <Space style={{ width: "100%" }} direction="vertical" size="large">
      <Title level={4}>
        You must have a valid and a dedicated phone number to use WhatsApp
        business API.
      </Title>

      <List
        bordered={true}
        dataSource={listItems}
        renderItem={(item, ind) => (
          <>
            <List.Item>
              <Flex>
                <Space style={{ width: "100%" }} direction="vertical" size={0}>
                  <Flex align="center" justify="start" gap={10}>
                    {item.icon}
                    <Title level={4} style={{ margin: 0 }}>
                      {item.title}
                    </Title>
                  </Flex>

                  <Text>{item.description}</Text>
                </Space>
              </Flex>
            </List.Item>
          </>
        )}
      ></List>

      <Flex align="center" justify="space-between">
        <Button className="flex-button" size="large" type="link" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack /> Back to whatsapp senders
        </Button>

        <Button
          type="primary"
          size="large"
          className="primary-gradient-background bold-button flex-button"
          icon={<GrLinkNext/> }
          onClick={() => setCurrent(current + 1)}
        >
          Continue to Facebook
        </Button>
      </Flex>
    </Space>
  );
};

export default PhoneNumber;
