import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Flex,
  Popconfirm,
  Row,
  Space,
  Tag,
  Timeline,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../../../hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import { DateFormat } from "../../../../../dateformat";
import {
  CardStatus,
  DealActivityType,
  TaskStatus,
} from "../../../../../shared/enums";
import { FiDollarSign } from "react-icons/fi";
import parse from "html-react-parser";
import { RiEditFill } from "react-icons/ri";
import getTaskTypeIcon from "../../../../../shared/TaskTypeIcon";
import getTaskTypeText from "../../../../../shared/TaskTypeText";
import useDealsDetailsCtx from "../../../../../hooks/useDealsDetailsCtx";
import moment from "moment";
import CreateFollowUpTaskModal from "../../../CreateDealDrawer/CreateFollowUpTaskModal";
import InitilasAvatar from "../../../../../shared/IntialsAvatar";
import env from "../../../../../env.json";
const DealDetailsTimeline = () => {
  const axios = useAxiosPrivate();

  const { id } = useParams();

  const { Title, Text } = Typography;

  const { history, setHistory, setActivities } = useDealsDetailsCtx();

  const [showFollowUp, setShowFollowUp] = useState(() => false);
  const [currentTask, setCurrentTask] = useState();

  const getText = (type, item) => {
    if (type == DealActivityType.DealCreated) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          Deal created
        </Title>
      );
    } else if (type == DealActivityType.DealStageChanged) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          Deal activity
        </Title>
      );
    } else if (type == DealActivityType.AddedFile) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          File created
        </Title>
      );
    } else if (type == DealActivityType.AddedNote) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          Note created
        </Title>
      );
    } else if (type == DealActivityType.CreatedTask) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          Task created{" "}
          <Text>
            • {new Date(item.createdAt).toLocaleDateString("en-US", DateFormat)}
          </Text>
        </Title>
      );
    } else if (type == DealActivityType.TaskCompleted) {
      return (
        <Title style={{ margin: 0, padding: 0, marginTop: -5 }} level={5}>
          Task marked as done{" "}
          <Text>
            • {new Date(item.createdAt).toLocaleDateString("en-US", DateFormat)}
          </Text>
        </Title>
      );
    }
  };

  const getDealStage = (type) => {
    if (type == CardStatus.New) {
      return "New";
    } else if (type == CardStatus.Qualifying) {
      return "Qualifying";
    } else if (type == CardStatus.DemoScheduled) {
      return "Demo Scheduled";
    } else if (type == CardStatus.PendingCommitment) {
      return "Pending Commitment";
    } else if (type == CardStatus.InNegotiation) {
      return "In Negotiation";
    } else if (type == CardStatus.Won) {
      return "Won";
    } else {
      return "Lost";
    }
  };

  const getIcon = (type, item) => {
    if (type == DealActivityType.DealStageChanged) {
      return <FiDollarSign style={{ color: "#1b254b", fontSize: 20 }} />;
    } else if (type == DealActivityType.DealCreated) {
      return <FiDollarSign style={{ color: "#1b254b", fontSize: 20 }} />;
    } else if (type == DealActivityType.AddedNote) {
      return <RiEditFill style={{ color: "#1b254b", fontSize: 18 }} />;
    } else if (type == DealActivityType.AddedFile) {
      return <RiEditFill style={{ color: "#1b254b", fontSize: 18 }} />;
    } else if (
      type == DealActivityType.CreatedTask ||
      type == DealActivityType.TaskCompleted
    ) {
      return getTaskTypeIcon(item.task.taskType, 20, "#1b254b");
    }
  };

  const handleDeleteFile = async (id, actId) => {
    const response = await axios.delete(`/DealFile/${id}`);

    if (response.data.success) {
      setHistory((prev) => prev.filter((c) => c.id != actId));
    } else {
      message.error(response.data.message);
    }
  };

  const handleDeleteTask = async (id, actId) => {
    const response = await axios.delete(`/DealTasks/${id}`);

    if (response.data.success) {
      setHistory((prev) => prev.filter((c) => c.id != actId));
    } else {
      message.error(response.data.message);
    }
  };

  const reopenTask = async (id, actId) => {
    const response = await axios.put(`/DealTasks/reopen/${id}`);

    if (response.data.success) {
      setHistory((prev) => prev.filter((d) => d.id != actId));
      setActivities((prev) => [
        ...prev,
        {
          id: response.data.id,
          name: response.data.name,
          notes: response.data.notes,
          owners: response.data.owners,
          reminder: response.data.reminder,
          dueDate: moment(response.data.dueDate),
          dueTime: moment(response.data.dueTime),
          createdAt: response.data.createdAt,
          type: response.data.taskType,
          done: response.data.done,
          completedDate: response.data.completedDate,
          overDue: response.data.overDue,
          highPriority: response.data.highPriority,
          dueToday: response.data.dueToday,
        },
      ]);
    } else {
      message.error(response.data.message);
    }
  };

  const handleDeleteNote = async (id, actId) => {
    const response = await axios.delete(`/deal/note/${id}`);

    if (response.data.success) {
      setHistory((prev) => prev.filter((c) => c.id != actId));
    } else {
      message.error(response.data.message);
    }
  };
  return (
    <>
      {!history || history?.length == 0 ? (
        <Text className="text-light" strong>
          No history found for this deal!
        </Text>
      ) : (
        <Timeline
          style={{ width: "100%" }}
          items={history?.map((c, ind) => ({
            dot: (
              <div
                key={ind}
                style={{
                  height: "30px",
                  width: "30px",
                  borderRadius: "30px",
                  backgroundColor: "#e3e3e3",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <InitilasAvatar
                  name={c.authorName}
                  src={`${env.fileUpload}${c.authorProfile}`}
                />
                {/*getIcon(c.dealActivityType, c)*/}
              </div>
            ),
            children: (
              <Flex style={{ width: "100%" }} justify="space-between">
                <Space direction="vertical" size={0} className="w-100">
                  {getText(c.dealActivityType, c)}

                  {c.dealActivityType == 2 && (
                    <Space className="w-100" direction="vertical" size={0}>
                      <Text>
                        Deal stage moved to '{getDealStage(c.dealStage)}'
                      </Text>
                      <Text>
                        {new Date(c.createdAt).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}{" "}
                        • {c.authorName}
                      </Text>
                    </Space>
                  )}

                  {c.dealActivityType == DealActivityType.DealCreated && (
                    <Space className="w-100" size={0} direction="vertical">
                      <Text>{c.authorName} created a new deal</Text>
                      <Text>
                        {new Date(c.createdAt).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}{" "}
                        • {c.authorName}
                      </Text>
                    </Space>
                  )}

                  {c.dealActivityType == DealActivityType.AddedNote && (
                    <Space direction="vertical" size={0} className="w-100">
                      <Text>
                        {new Date(c.createdAt).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}{" "}
                        • {c.authorName}
                      </Text>
                      <Card
                        className="w-100"
                        style={{ boxShadow: "none" }}
                        size="small"
                      >
                        {parse(c.note.note)}

                        <Flex align="center" justify="end">
                          <Popconfirm
                            title="Are you sure?"
                            okText="Yes, delete it"
                            description="Are you sure you want to remove this note?"
                            onConfirm={() => handleDeleteNote(c.note.id, c.id)}
                          >
                            <Button type="text" danger>
                              Remove
                            </Button>
                          </Popconfirm>
                        </Flex>
                      </Card>
                    </Space>
                  )}

                  {c.dealActivityType == DealActivityType.AddedFile && (
                    <Space direction="vertical" size={0} className="w-100">
                      <Text>
                        {new Date(c.createdAt).toLocaleDateString(
                          "en-US",
                          DateFormat
                        )}{" "}
                        • {c.authorName}
                      </Text>

                      <Card style={{ boxShadow: "none" }}>
                        <div>
                          <Text strong>{c.file.name}</Text>
                        </div>

                        <Flex align="center" justify="end" gap={12}>
                          <Button type="link">Download</Button>
                          <Popconfirm
                            title="Are you sure?"
                            description="Are you sure you want to delete this file?"
                            okText="Yes, delete it"
                            onConfirm={() => handleDeleteFile(c.file.id, c.id)}
                          >
                            <Button type="link" danger>
                              Remove
                            </Button>
                          </Popconfirm>
                        </Flex>
                      </Card>
                    </Space>
                  )}

                  {c.dealActivityType == DealActivityType.TaskCompleted && (
                    <Space direction="vertical" size={0} className="w-100">
                      <Collapse
                        expandIconPosition="end"
                        items={[
                          {
                            label: (
                              <Flex
                                align="center"
                                gap={"10px"}
                                justify="space-between"
                              >
                                <Flex gap={10}>
                                  <div style={{ marginTop: 5 }}>
                                    <Flex
                                      align="center"
                                      justify="center"
                                      style={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 30,
                                        background: "#e3e3e3",
                                      }}
                                    >
                                      {getTaskTypeIcon(
                                        c.task.taskType,
                                        20,
                                        "#1b254b"
                                      )}
                                    </Flex>
                                  </div>
                                  <Space size={0} direction="vertical">
                                    <Flex align="center">
                                      <Typography.Title
                                        style={{ margin: 0, padding: 0 }}
                                        level={4}
                                      >
                                        {c.task?.isFollowUp && "Follow-up: "}{" "}
                                        {c.task?.taskName}
                                      </Typography.Title>
                                    </Flex>

                                    <Typography.Text>
                                      {c?.task?.dueDate ? (
                                        <>
                                          {new Date(
                                            c?.task?.dueDate
                                          )?.toLocaleDateString(
                                            "en-US",
                                            DateFormat
                                          )}
                                        </>
                                      ) : (
                                        <>No due date</>
                                      )}
                                    </Typography.Text>
                                  </Space>
                                </Flex>
                                <Space align="center">
                                  <Space size={0}>
                                    <Tag
                                      className="icon-flex black-color-tag"
                                      color="#e3e3e3"
                                    >
                                      {getTaskTypeIcon(c.task.taskType, 15)}{" "}
                                      {getTaskTypeText(c.task.taskType)}
                                    </Tag>

                                    <Tag
                                      className="black-color-tag"
                                      color="green"
                                    >
                                      Done
                                    </Tag>
                                  </Space>
                                </Space>
                              </Flex>
                            ),
                            children: (
                              <Space
                                className="w-100"
                                direction="vertical"
                                style={{ padding: 20 }}
                              >
                                <Row gutter={12}>
                                  <Col xs={6}>
                                    <Space
                                      className="w-100"
                                      direction="vertical"
                                      size={0}
                                    >
                                      <Title
                                        level={4}
                                        style={{ margin: 0, padding: 0 }}
                                      >
                                        Due date
                                      </Title>
                                      <Text>
                                        {c?.task?.dueDate ? (
                                          <>
                                            {new Date(
                                              c.task.dueDate
                                            ).toLocaleTimeString(
                                              "en-US",
                                              DateFormat
                                            )}
                                          </>
                                        ) : (
                                          <>No due date</>
                                        )}
                                      </Text>
                                    </Space>
                                  </Col>

                                  <Col xs={6}>
                                    <Space
                                      className="w-100"
                                      direction="vertical"
                                      size={0}
                                    >
                                      <Title
                                        level={4}
                                        style={{ margin: 0, padding: 0 }}
                                      >
                                        Reminder
                                      </Title>
                                      <Text>
                                        {c.task.reminder
                                          ? "Remind"
                                          : "Dont remind"}
                                      </Text>
                                    </Space>
                                  </Col>

                                  <Col xs={6}>
                                    <Space
                                      className="w-100"
                                      direction="vertical"
                                      size={0}
                                    >
                                      <Title
                                        level={4}
                                        style={{ margin: 0, padding: 0 }}
                                      >
                                        User assigned
                                      </Title>
                                      <Text>
                                        {c.task.assignedUsers?.map((d) => (
                                          <Space key={ind}>
                                            <Text>{d}</Text>
                                          </Space>
                                        ))}
                                      </Text>
                                    </Space>
                                  </Col>

                                  <Col xs={6}>
                                    <Space
                                      className="w-100"
                                      direction="vertical"
                                      size={0}
                                    >
                                      <Title
                                        level={4}
                                        style={{ margin: 0, padding: 0 }}
                                      >
                                        Note
                                      </Title>
                                      <Text>{c.task.notes}</Text>
                                    </Space>
                                  </Col>
                                </Row>

                                <Flex align="center" justify="end" gap={12}>
                                  {!c.task.isFollowUp && (
                                    <Button
                                      onClick={() => {
                                        setShowFollowUp(true);
                                        setCurrentTask(c.task.id);
                                      }}
                                      size="large"
                                    >
                                      Create follow up task
                                    </Button>
                                  )}

                                  <Button
                                    size="large"
                                    onClick={() => reopenTask(c.task.id, c.id)}
                                  >
                                    Re-open Task
                                  </Button>
                                </Flex>
                              </Space>
                            ),
                          },
                        ]}
                      />
                    </Space>
                  )}
                </Space>
              </Flex>
            ),
          }))}
        />
      )}

      <CreateFollowUpTaskModal
        open={showFollowUp}
        setOpen={setShowFollowUp}
        id={currentTask}
        onCreated={(response) => {
          setActivities((prev) => [
            ...prev,
            {
              id: response.data.id,
              name: response.data.name,
              notes: response.data.notes,
              owners: response.data.owners,
              reminder: response.data.reminder,
              dueDate: moment(response.data.dueDate),
              dueTime: moment(response.data.dueTime),
              createdAt: response.data.createdAt,
              type: response.data.taskType,
              done: response.data.done,
              completedDate: response.data.completedDate,
              overDue: response.data.overDue,
              highPriority: response.data.highPriority,
              dueToday: response.data.dueToday,
              isFollowUp: true,
            },
          ]);
          
          setHistory((prev) =>
            prev.map((d) => (d.id == id ? { ...d, isParent: true } : d))
          );
        }}
      />
    </>
  );
};

export default DealDetailsTimeline;
