import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import axios from "../../api/axios";
import {
  Button,
  Col,
  Flex,
  Form,
  Grid,
  Input,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import logo from "../../assets/images/favicon.png";
import background from "../../assets/images/tapzaplogin.png";
import { ThemeContext } from "../../context/ThemeContext";
import ThemeButton from "../ThemeButton";

const {useBreakpoint} = Grid
const ForgotPassword = () => {
  const screens = useBreakpoint();
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submit = async (e) => {
    setLoading(true);
    const response = await axios.post(`/Account/forgot-password`, {
      email: e.email,
    });

    if (response && response.data.success) {
      message.success("Reset password email sent!");
    } else {
      message.error(response.data.message);
    }
    setLoading(false);
  };

  const { theme } = useContext(ThemeContext);
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
          <Flex
            justify="center"
            align="center"
            style={{
              height: "100vh",
              background: theme == "light" ? "#fff" : "#0b1437",
            }}
          >
            <Space direction="vertical" style={{ width: "350px" }}>
              <img src={logo} height={35} loading="lazy"/>

              <div>
                <Title style={{ margin: 0 }}>Forgot password</Title>
                <Text className="text-light">
                  Enter your email to reset your password!
                </Text>
              </div>
              <Form onFinish={submit}>
                <Space style={{ width: "100%" }} direction="vertical">
                  <div>
                    <Text>Email</Text>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please provide your email address!",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter Email Address..."
                      />
                    </Form.Item>
                  </div>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className="primary-gradient-background"
                  >
                    Reset Password
                  </Button>
                </Space>
              </Form>

              <Flex align="center" justify="center" gap={0}>
                <Text>Not registered yet? </Text>
                <Button type="link" onClick={() => navigate("/register")}>
                  Create an account!
                </Button>
              </Flex>
            </Space>
          </Flex>
        </Col>

        {(screens.lg || screens.xl || screens.xxl) &&  <Col lg={9} xl={9} xxl={9}> <div
            className="shadow"
            style={{
              background: `url(${background})`,
              width: "100%",
              height: "100%",
              backgroundSize: "cover"
            }}
          >
            <div style={{ position: "relative", top: "95%", left: "10%" }}>
              <Space>
                <Button type="link" className="white-link-button">
                  Support
                </Button>
                <Button
                  type="link"
                  className="white-link-button"
                  onClick={() => navigate("/policy/terms-of-usage")}
                >
                  Terms of use
                </Button>
                <Button
                  type="link"
                  className="white-link-button"
                  onClick={() => navigate("/policy/prviacy-policy")}
                >
                  Privacy policy
                </Button>
              </Space>
            </div>
          </div>
        </Col>}
      </Row>

      <ThemeButton/>
    </>
  );
};
export default ForgotPassword;
