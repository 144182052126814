import { Card, Space } from "antd";
import React from "react";
import DealDetailsData from "./DealDetailsData";
import DealContacts from "./DealContacts/DealContacts";
import DealCompanies from "./DealCompanies/DealCompanies";
import DealProducts from "./DealProducts/DealProducts";

const DealDetailsAside = () => {
  return (
    <Space className="w-100" size={24} direction="vertical">
      <DealDetailsData /> 
      <DealContacts />
      <DealCompanies />
      <DealProducts />
    </Space>
  );
};

export default DealDetailsAside;
