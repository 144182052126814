import { Flex, Space, Spin, Statistic, Typography } from "antd";
import Card from "antd/es/card/Card";
import { useContext } from "react";
import CountUp from "react-countup";
import { ThemeContext } from "../../../context/ThemeContext";
const formatter = (value) => <CountUp end={value} separator="," />;
const DashboardNumericStats = ({ name, icon, value, loading }) => {
  const { Text } = Typography;

  const {theme} = useContext(ThemeContext);

  return (
    <>
      <Card size="small">
        <Spin spinning={loading}>
          <Flex align="center" justify="start" gap={6}>
            <Flex
              align="center"
              justify="center"
              style={{
                width: "50px",
                height: "50px",
                background: theme == "light" ? "#f4f7fe" : "#1b254b",
                borderRadius: "50px",
              }}
            >
              {icon}
            </Flex>
            <Space direction="vertical" size={0}>
              <Text className="text-light">{name}</Text>

              <Statistic
                style={{ marginTop: "-5px" }}
                precision={0}
                value={value}
                formatter={formatter}
                valueStyle={{ fontWeight: 600 }}
              />
            </Space>
          </Flex>
        </Spin>
      </Card>
    </>
  );
};

export default DashboardNumericStats;
