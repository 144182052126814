import { Flex, Typography } from "antd"

const IconSelectListItem = ({icon, text}) => {
    const {Text} = Typography
    return <Flex align="center" justify="start" gap={6} style={{height:"100%"}}>
        {icon}

        <Text>{text}</Text>
    </Flex>
}

export default IconSelectListItem;