import { Button, Space, Card, Flex, Row, Col, Empty, Typography, Grid } from "antd";
import { useState, useEffect, useContext } from "react";
import CreateAudience from "../CRUD/CreateAudience";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import AudienceCard from "../Card/AudienceCard";
import AudienceDetails from "../CRUD/AudienceDetails";
import useDebounce from "../../../hooks/useDebounce";
import { MdFilterAlt, MdFilterAltOff, MdTableRows } from "react-icons/md";
import { FiPlus } from "react-icons/fi";
import { SearchContext } from "../../../context/SearchContext";
import emails from "../../../assets/images/backgroundprices.png";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import AudienceCardSkeletons from "../Card/AudienceCardSkeleton";
import Promo from "../../../shared/Promo";
import AudienceStats from "../AudienceStats";
import AudienceAuthorsFilter from "../Filters/AudienceAuthorsFilter";
import AudienceFromDateFilter from "../Filters/AudienceFromDateFilter";
import AudienceToDateFilter from "../Filters/AudienceToDateFilter";
import AudienceOrderByFilter from "../Filters/AudienceOrderByFilter";
import { AudienceOrderDirectionFilter } from "../Filters/AudienceOrderDirectionFilter";
import { AudienceListContext } from "../AudienceListContext";

const { useBreakpoint } = Grid;

const AudienceList = ({
  selectable,
  setSelected,
  selected,
  disabled,
  title,
}) => {

  const { loading, setLoading, page, setPage, stats, setStats, audiences, setAudiences } = useContext(AudienceListContext)
  const [showFilters, setShowFilters] = useState(false)
  const { Title } = Typography;
  const axios = useAxiosPrivate();
  const perPage = 16;
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onDelete = (id) => {
    setAudiences(audiences.filter((a) => a.id != id));
    setStats(prev => ({...prev, total: prev.total - 1}));
  };

  const handleSelectedChanged = (gotSelected, id) => {
    if (!selectable || disabled) {
      return;
    }

    if (gotSelected) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((s) => s != id));
    }
  };

  return (
    <>
      <CreateAudience
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setAudiences={setAudiences}
      />

      <Space style={{ width: "100%" }} direction="vertical" size={24}>
        <Promo title="Unlock your potencial"
          text="Tapzap currently offers over 2 million + contacts to chose from,
              All available at the click of a fingertip"
          endElements={<Button
            icon={<FaArrowRight />}
            size="large"
            className="flex-button"
            onClick={() => navigate("/")}
          >
            View subscription plans
          </Button>}
          background={emails} />

        <AudienceStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex align="center" justify="space-between" wrap="wrap"
              style={{
                ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
              }}
            >
              {!title && (
                <Title style={{ margin: 0 }} level={4}>
                  Audiences
                </Title>
              )}
              {title && (
                <Title level={5} style={{ margin: 0 }}>
                  {title}
                </Title>
              )}

              <Space>

                <Button
                  size="large"
                  className="flex-button"
                  block={screens.xs}
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  onClick={() => setIsModalOpen(true)}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                >
                  New audience
                </Button>
              </Space>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <AudienceAuthorsFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <AudienceFromDateFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <AudienceToDateFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <AudienceOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <AudienceOrderDirectionFilter />
              </Col>
            </Row>
          )}

        </Card>

        <Space direction="vertical" size={24} style={{ width: "100%" }}>
          <Row gutter={[24, 24]} wrap>
            {audiences &&
              audiences.map((audience) => (
                <Col
                  key={audience.id}
                  xs={24}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={6}
                  xxl={6}
                >
                  <AudienceCard
                    disabled={disabled}
                    audience={audience}
                    onDelete={onDelete}
                    selectable={selectable}
                    selected={
                      selected &&
                      selected?.findIndex((f) => f == audience.id) >= 0
                    }
                    setSelected={handleSelectedChanged}
                  />
                </Col>
              ))}
          </Row>

          {audiences?.length < stats?.total && (
            <div style={{ textAlign: "center" }}>
              <Button loading={loading} onClick={() => setPage(prev => prev + 1)}>
                Show more audiences
              </Button>
            </div>
          )}
        </Space>
      </Space>

    </>
  );
};

export default AudienceList;
