import { useEffect, useState } from "react"
import {Select, Typography} from "antd";

const OrderTemplatesSelect = ({onOrderChanged}) => {
    const [options, setOptions] = useState()
    const {Text} = Typography
    useEffect(() => {
        setOptions([
            {
                value: "name",
                label: "Name"
            },
            {
                value: "Created",
                label: "Created"
            },
        ])
    }, 
    []);

    return (<>
        <Text>Order by</Text>
        {options&&<Select size="large" style={{width:'100%'}} options={options} onChange={(e) => onOrderChanged(e)}/>}
    </>)
}

export default OrderTemplatesSelect