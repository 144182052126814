import { Button, Divider, Flex, Form, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {PlusOutlined} from "@ant-design/icons"
import AddContactModal from "../Modals/AddContactModal";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const ContactsMultiselect = ({
  name = "contacts",
  label = "Associate Deal to Contacts",
  placeholder,
}) => {
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [addContactModal,setAddContactModal] = useState(false)
  
  const {Text} = Typography
  useEffect( async () => {
    setLoading(true);
      const response =await axios.get('/Deal/GetPossibleContactsForDeal');

      setOptions(response.data?.dealOwners?.map(c => ({
        label: c.name && c.lastName ? `${c.name} ${c.lastName}` : c.email,
        value: c.userId 
      })))
      setLoading(false)
  }, []);

  return (
    <>
    <Space direction="vertical" size={0} className="w-100">

      <Text>{label}</Text>
    <Form.Item name={name} rules={[{ type: "array" }]}>
      <Select
        mode="multiple"
        size="large"
        loading={loading}
        placeholder={placeholder}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider style={{ margin: "8px 0" }} />
            <Flex style={{ padding: "0 8px 4px" }}>
             
              <Button type="text" icon={<PlusOutlined />} style={{width:"100%"}} onClick={()=>setAddContactModal(true)}>
                Add Contact
              </Button>
            </Flex>
          </>
        )}
        >
        {options.map((option) => {
          return (
            <Select.Option key={option.value} value={option.value}>
              {option.label}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
        </Space>
    
        </>
  );
};

export default ContactsMultiselect;
