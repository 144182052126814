import { Button, Divider, Flex, Form, Select, Space, Typography } from "antd";
import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { PlusOutlined } from "@ant-design/icons";
import AddProductModal from "../Modals/AddProductModal";
const ProductsMultiSelect = ({
  name = "products",
  label = "Associate Deal To Product/Project",
  placeholder = "Please Select a Product/Project",
  onAdd,
}) => {
  const { Text } = Typography;

  const axios = useAxiosPrivate();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  useEffect(async () => {
    setLoading(true);
    const response = await axios.get("/Product", {});

    setOptions(response.data.list.map((c) => ({ label: c.name, value: c.id })));
    setLoading(false);
  }, []);
  return (
    <>
      <Space size={0} className="w-100" direction="vertical">
        <Text>{label}</Text>
        <Form.Item name={name} rules={[{ type: "array" }]}>
          <Select size="large"
            mode="multiple"
            placeholder={placeholder}
            loading={loading}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: "8px 0" }} />
                <Flex style={{ padding: "0 8px 4px" }}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    style={{ width: "100%" }}
                    onClick={() => setAddProductModal(true)}
                  >
                    Create New Product
                  </Button>
                </Flex>
              </>
            )}
          >
            {options.map((option) => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Space>

      <AddProductModal
        open={addProductModal}
        setOpen={setAddProductModal}
        setOptions={setOptions}
        onAdd={onAdd}
      />
    </>
  );
};

export default ProductsMultiSelect;
