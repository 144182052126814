import React, { useContext, useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import {
  Button,
  Card,
  Dropdown,
  Flex,
  Space,
  Typography,
} from "antd";
import {ThemeContext} from '../../../context/ThemeContext'
import { BiSolidBarChartAlt2 } from "react-icons/bi";
import CardLoading from "../../../shared/CardLoading";
const ContactReachedPerMonthChart = ({
  id,
  statuses,
  type,
  title,
  children,
}) => {
  const axios = useAxiosPrivate();
  const [months, setMonths] = useState();
  const [data, setData] = useState(() => []);

  const [loading, setLoading] = useState(false)
  
  useEffect(async () => {
    setLoading(true)
    statuses.forEach(async (s) => {
      var url = `/Stats/GetReachedAudienceStats?Status=${s}`;
      if (id) {
        url += `&Id=${id}`;
      }

      const response = await axios.get(url);

      let color = "";

      if (s == "dropped") {
        color = "#eff4fb";
      } else if (s == "delivered") {
        color = "#6ad2ff";
      } else if (s == "processed") {
        color = "#422afb";
      } else if (s == "open") {
        color = "#4F200D";
      } else if (s == "deferred") {
        color = "#0d430e";
      } else if (s == "click") {
        color = "#422afb";
      } else if (s == "bounce") {
        color = "#6ad2ff";
      } else {
        color = "#FF0060";
      }
      setData((prev) => [
        ...prev,
        {
          name: s,
          data: response.data.items.map((c) => c.count),
          color: color,
        },
      ]);
      
      if (!months) {
        setMonths(response.data.items.map((c) => c.monthName));
      }

    });
  }, []);

  useEffect(() => {
    if(data.length == statuses.length){
      setLoading(false)
    }
  }, [data])
  return (
    <>
    {loading&&<CardLoading/>}
      {data && data?.length == statuses.length && (
        <Chart
          title={title}
          data={data}
          categories={months}
          chartType={type}
          children={children}
        />
      )}
    </>
  );
};

const Chart = ({ title, categories, data, chartType, children }) => {
  const [type, setType] = useState(() => chartType);
  const [options, setOptions] = useState({
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: true,
    },
    plotOptions: {
      bar: {
        borderRadius: 10, // Sets the radius of the bar corners
        horizontal: false,
        columnWidth: '30px'
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
     
    },

    markers: {
      size: 0,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: "#a3aed0",
          fontSize: "16px",
          fontFamily: "DM Sans",
        },
      },
      axisBorder: {
        show: false, // This hides the border line on the top of the x-axis
      },
      axisTicks: {
        show: false, // Optionally, hide the ticks if needed
      },
    },

    yaxis: {
      title: {
        text: "",
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#422afb", "#53c1ff"],
    legend: {
      show: false,
    },
  });

  const [items, setItems] = useState([
    {
      value: "line",
      label: <div style={{ width: "100px" }}>Lines</div>,
      key: 1,
      onClick: () => setType("line"),
    },
    {
      value: "bar",
      label: <div style={{ width: "100px" }}>Bars</div>,
      key: 2,
      onClick: () => setType("bar"),
    },
    {
      value: "radar",
      label: <div style={{ width: "100px" }}>Radar</div>,
      key: 3,
      onClick: () => setType("radar"),
    },
  ]);

  const {theme} = useContext(ThemeContext);
  const { Title, Text } = Typography;
  return (
    <Card title={false}
    >
      <Space  style={{width:"100%"}} direction="vertical" size="large">

      <Flex align="center" justify="space-between">
    <Title level={4} style={{ margin: 0, padding: 0 }}>
      {title}
    </Title>

    <Dropdown menu={{ items }} trigger={["click"]}>
      <Button
        icon={
          <BiSolidBarChartAlt2
            style={{ fontSize: 20, color: theme=="light" ? "#422afb":"#ffffff" }}
          />
        }
        className="flex-button"
        style={{
          background: theme == "light" ? "#f4f7fe" : "#1f294f",
          height: 40,
          width: 40,
          border: "none",
        }}
      ></Button>
    </Dropdown>
  </Flex>
      <Flex style={{ width: "100%" }} align="start" justify="start" gap={12}>
        {children}

        <div style={{ width: "100%" }}>
          {type == "line" && (
            <ApexCharts
              options={options}
              series={data}
              type="line"
              height={350}
            />
          )}
          {type == "radar" && (
            <ApexCharts
              options={options}
              series={data}
              type="radar"
              height={350}
            />
          )}
          {type == "bar" && (
            <ApexCharts
              options={options}
              series={data}
              type="bar"
              height={350}
            />
          )}
        </div>
      </Flex>
      </Space>

    </Card>
  );
};
export default ContactReachedPerMonthChart;
