import { useContext } from "react";
import { NotificationTemplatesContext } from "../NotificationTemplatesContext";
import { DatePicker, Typography } from "antd";

const NotificationTemplatesFromDateFilter = () => {
  const { filters, setFilters } = useContext(NotificationTemplatesContext);
  const { Text } = Typography;
  return (
    <>
      <Text>To</Text>
      <DatePicker
        size="large"
        placeholder="Select created date start"
        defaultValue={filters?.from}
        onChange={(e) =>
          setFilters((prev) => ({
            ...prev,
            to: e,
          }))
        }
        style={{ width: "100%", borderRadius: 15 }}
      />
    </>
  );
};

export default NotificationTemplatesFromDateFilter;
