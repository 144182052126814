import { Grid, Layout } from "antd";
import useLocalStorage from "../hooks/useLocalStorage";
import { useState } from "react";
import SideMenu from './Navigation/SideMenu'
import { Outlet } from "react-router-dom";
import AppFooter from './Navigation/Footer/Footer'
import SubscriptionModal from '../pages/audience/subscription/SubscriptionModal'
import ApplyCouponModal from '../pages/audience/subscription/ApplyCouponModal'
import Header from './Navigation/Header/Header'
const { useBreakpoint } = Grid;

const ApplicationLayout = () => {
    const screens = useBreakpoint();

  const { Content } = Layout;

    const [collapseValue, setCollapseValue] = useLocalStorage("collapse", false);
    const [collapsed, setCollapsed] = useState(() => collapseValue);
    
    const updateCollapse = (e) => {
        setCollapseValue(e);
        setCollapsed(e);
      };
    return <Layout style={{ minHeight: "100vh" }}>
    <SideMenu collapsed={collapsed} setCollapsed={updateCollapse} />

    <Layout
      style={{
        minHeight: "100vh",
        marginLeft: "24px",
        marginRight: "24px",
      }}
    >
      <Header collapsed={collapsed} setCollapsed={updateCollapse} />

      <Content>
        <Outlet />
      </Content>
       <AppFooter />
    </Layout>

    <SubscriptionModal />
    <ApplyCouponModal />
  </Layout>
}

export default ApplicationLayout;