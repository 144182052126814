import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { Card, Checkbox, Flex, List, Typography } from "antd";

const TemplateCategories = ({ handleSelectedCategoriesChanged, isForUser }) => {
  const axiosPrivate = useAxiosPrivate();

  const { Title, Text } = Typography;
  const [categories, setCategories] = useState([]);

  useEffect(async () => {
    const response = await axiosPrivate.get(
      `/Template/${
        isForUser ? "GetCompanyTemplateCategories" : "GetTemplateCategories"
      }`
    );
    setCategories(
      response.data.data.map((c) => {
        return { ...c, selected: false };
      })
    );
  }, []);

  const onSelectedCategoriesChanged = (checked, id) => {
    const index = categories.findIndex((c) => c.id == id);
    if (index == -1) {
      return;
    }

    const updatedObject = { ...categories[index], selected: checked };

    const updatedList = [...categories];
    updatedList[index] = updatedObject;
    handleSelectedCategoriesChanged(
      updatedList?.filter((c) => c.selected).map((c) => c.id)
    );
    setCategories(updatedList);
  };

  return (
    <Card title="Categories">
      <List
        dataSource={categories}
        renderItem={(item) => (
          <Flex
            align="center"
            justify="space-between"
            style={{ borderBottom: "1px solid rgba(0,0,0,.1)" }}
          >
            <Checkbox onChange={(e) => onSelectedCategoriesChanged(e.target.checked, item.id)}>
              {item.name}
            </Checkbox>

            <Title level={5} style={{ margin: 5 }}>
              {item.count}
            </Title>
          </Flex>
        )}
      ></List>
    </Card>
  );
};

export default TemplateCategories;
