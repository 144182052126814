import { Button, Dropdown } from "antd";
import { BsFillTrash3Fill } from "react-icons/bs";
import { IoMdMore } from "react-icons/io";

const DealProductsOptions = ({ id, onConfirm }) => {


  const items = [
    {
      key: "1",
      icon: <BsFillTrash3Fill />,
      label: "Delete selected product",
      danger: true,
      onClick:() => onConfirm(id)
    },
  ];
  return (
    <>
      <Dropdown menu={{ items }} trigger={["click"]}>
        <Button
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore style={{ fontSize: 20, color: "#1b254b" }} />}
        ></Button>
      </Dropdown>
    </>
  );
};

export default DealProductsOptions;
