import { Popconfirm, message } from "antd";
import useAxiosPrivate from "../../hooks/useAxiosPrivate"

const DeleteProduct = ({id, onDelete}) => {
    const axios = useAxiosPrivate();

    const onSubmit = async () => {
        const response = await axios.delete(`/Product/${id}`)

        if(response.data.success){
            onDelete(id)
        }else{
            message.error(response.data.message)
        }
    }

    return <>
        <Popconfirm onConfirm={() => onSubmit()} title="Delete this product" description="Changes can not be reverted!" okText="Yes, delete it">
            Delete selected product
        </Popconfirm>
    </>
}

export default DeleteProduct