import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import TemplateCategories from "../Filters/TemplateCategories";
import TemplateCard from "../Components/TemplateCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import TemplateDateFilter from "../Filters/TemplateDateFilter";
import OrderTemplatesDirection from "../Filters/OrderTemplatesDirection";
import OrderTemplatesSelect from "../Filters/OrderTemplatesSelect";
import { Row, Col, Button, Empty, Space } from "antd";
import TemplateSkeletonCard from "../Components/TemplateSkeletonCard";

const TapZapTemplates = ({ showFilters, search, setTotal }) => {
  const axiosPrivate = useAxiosPrivate();

  const [dateFilter, setDateFilter] = useState({
    to: null,
    from: null,
  });
  const perPage = 6;

  const [totalRows, setTotalRows] = useState(0);
  const [page, setPage] = useState(1);

  const [sortDirection, setSortDirection] = useState("Desc");
  const [orderBy, setOrderBy] = useState("Created");

  const [categories, setCategories] = useState(null);

  const [templates, setTemplates] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchTemplates = async (page, add, controller) => {
    setLoading(true);
    const response = await axiosPrivate.post(
      `/Template/GetDefaultTemplates`,
      {
        pageSize: perPage,
        page: page,
        from: dateFilter.from,
        to: dateFilter.to,
        categorieIds: categories,
        orderBy: orderBy,
        sortDirection: sortDirection,
        search: search,
      },
      { signal: controller.signal }
    );

    if (add) {
      setTemplates([...templates, ...response.data.data]);
    } else {
      setTemplates(response.data.data);
      setPage(1);
    }

    setTotalRows(response.data.count);
    setTotal(response.data.count)
    setLoading(false);
  };

  useEffect(async() => {
    const controller = new AbortController();
    setLoading(true);
    const response = await axiosPrivate.post(
      `/Template/GetDefaultTemplates`,
      {
        pageSize: perPage,
        page: 1,
        from: dateFilter.from,
        to: dateFilter.to,
        categorieIds: categories,
        orderBy: orderBy,
        sortDirection: sortDirection,
        search: search,
      },
      { signal: controller.signal }
    );

    setTemplates(response.data.data);
    
    setTotalRows(response.data.count);
    setTotal(response.data.count)

    setLoading(false);
    setPage(1)
    return () => controller.abort();
  }, [categories, dateFilter, orderBy, sortDirection, search]);

  const handleSelectedCategoriesChanged = (newList) => {
    setCategories(newList);
  };

  const handleDateFilterChanged = (e) => {
    setDateFilter({
      to: e[1].$d,
      from: e[0].$d,
    });
  };

  const handlePageChanged = async (val) => {
    setLoading(true);
    const response = await axiosPrivate.post(
      `/Template/GetDefaultTemplates`,
      {
        pageSize: perPage,
        page: val,
        from: dateFilter.from,
        to: dateFilter.to,
        categorieIds: categories,
        orderBy: orderBy,
        sortDirection: sortDirection,
        search: search,
      }
    );

    setTemplates([...templates, ...response.data.data]);
    setPage(page+1)

    setTotalRows(response.data.count);
    setTotal(response.data.count)

    setLoading(false);
  };

  const handleSortDirectionChanged = (newDir) => {
    setSortDirection(newDir);
  };

  const handleOrderByChanged = (newOrder) => {
    setOrderBy(newOrder);
  };

  return (
    <Row gutter={24} style={{margin:10}}>
      {showFilters && (
        <Col sm={24} xs={24} md={5} lg={5} xl={5} xxl={5}>
          <TemplateCategories
            handleSelectedCategoriesChanged={handleSelectedCategoriesChanged}
          />
        </Col>
      )}
      <Col
        sm={24}
        xs={24}
        md={showFilters ? 19 : 24}
        lg={showFilters ? 19 : 24}
        xl={showFilters ? 19 : 24}
        xxl={showFilters ? 19 : 24}
      >
        <Space style={{ width: "100%" }} direction="vertical" size="large">
          {showFilters && (
            <Row gutter={24}>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <OrderTemplatesSelect onOrderChanged={handleOrderByChanged} />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <OrderTemplatesDirection
                  onSortDirectionChanged={handleSortDirectionChanged}
                />
              </Col>
              <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                <TemplateDateFilter
                  dateFilter={dateFilter}
                  dateChanged={(e, value) => handleDateFilterChanged(e)}
                />
              </Col>
            </Row>
          )}
          <ResponsiveMasonry
            columnsCountBreakPoints={{
              350: 1,
              750: 2,
              900: showFilters ? 3 : 4,
            }}
          >
            <Masonry gutter="1rem">
              {templates?.map((template, ind) => (
                <TemplateCard template={template} key={ind} />
              ))}

              {loading && <TemplateSkeletonCard />}
              {loading && <TemplateSkeletonCard />}
              {loading && <TemplateSkeletonCard />}
              {loading && <TemplateSkeletonCard />}
            </Masonry>
          </ResponsiveMasonry>

          {!loading && totalRows <= templates?.length && (
            <Empty description="Looks like you have reached the end"></Empty>
          )}

          {!loading && templates.length < totalRows && (
            <div style={{ textAlign: "center" }}>
              <Button onClick={() => handlePageChanged(page + 1)}>
                See more
              </Button>
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default TapZapTemplates;
