import { Card, Flex, Space, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { ThemeContext } from "../../../context/ThemeContext";

const CampaignTypes = () => {
  const { Title, Text } = Typography;
  const [data, setData] = useState()

  const axios = useAxiosPrivate();

  const {theme} = useContext(ThemeContext)

  const [chartOptions, setChartOptions] = useState({
    options: {
      colors: ["#422afb", "#6ad2ff", "#eff4fb"],
      labels: [], // Initially set to an empty array
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      legend: { show: false },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    },
    series: [], // Initially set to an empty array
  });
  useEffect(async () => {
    const response = await axios.get("/stats/audiences/types");

    setData(response.data)
    setChartOptions((prevState) => ({
      ...prevState,
      options: {
        ...prevState.options,
        labels: ["Email", "Sms", "Whatsapp"],
      },
      series: [response.data.email, response.data.sms, response.data.whatsapp],
    }));
  }, []);
  return (
    <>
      <Card>
        <Space direction="vertical" style={{ width: "100%" }} size="large">
          <Flex align="center" justify="space-between">
            <Title level={4} style={{ margin: 0 }}>
              Marketing platforms
            </Title>

            <div style={{width:"90px", background: theme=="light" ? "#f4f7fe" :"#1f294f", textAlign:"center", borderRadius:7.5}}>
                <Text strong style={{color:"#9a9a9a"}}>All time</Text>
            </div>
          </Flex>
          <Flex align="center" justify="center">
            <Chart
              options={chartOptions.options}
              series={chartOptions.series}
              type="pie"
              width="300"
            />
          </Flex>

          <Flex align="center" justify="space-between">
            <ChartBlock text="Email" color="#422afb" value={((data?.email / data?.total) * 100).toFixed(1) + "%"}/>
            <Text className="text-light" style={{fontSize:30}}>|</Text>
            <ChartBlock text="Sms" color="#93ddfa"  value={((data?.sms / data?.total) * 100).toFixed(1)+"%"}/>
          </Flex>
        </Space>
      </Card>
    </>
  );
};

const ChartBlock = ({ value, color, text }) => {
  const { Text, Title } = Typography;

  return (
    <Flex align="top" justify="start" gap={6}>
      <div
        style={{ marginTop:5, width: 10, height: 10, borderRadius: 10, background: color }}
      ></div>

      <Space direction="vertical" size={0} align="top">
        <Text className="text-light" style={{ margin: 0, padding: 0 }}>
          {text}
        </Text>
        <Title level={4} style={{ margin: 0 }}>
          {value}
        </Title>
      </Space>
    </Flex>
  );
};

export default CampaignTypes;
