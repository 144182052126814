import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { SearchContext } from "../../../context/SearchContext";
import { OrderDirection } from "../../../shared/enums";
import AuthorSelectListItem from "../../../shared/AuthorSelectListItem";

export const NotificationTemplatesContext = createContext({
  filters: {
    authors: [],
    selectedAuthors: [],

    from: null,
    to: null,

    orderBy: "",
    orderDirection: "",
  },
  setFilters: () => {},

  stats: {
    total: 0,
    first: null,
    last: null,
    authorName: null,
    authorProfile: null,
  },
  setStats: () => {},

  templates: [],
  setTemplates: () => {},

  page: 1,
  setPage: () => {},

  loading: false,
  setLoading: () => {},
});

export const NotificationTemplatesContextProvider = ({ children }) => {
  const axios = useAxiosPrivate();

  const [filters, setFilters] = useState();
  const [stats, setStats] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const {debouncedValue} = useContext(SearchContext)
  const perPage = 7;

  useEffect(async () => {
    const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev =>({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
              value: d.id,
              label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath}/>),
            }))}
          ));
  }, []);

  return (
    <NotificationTemplatesContext.Provider
      value={{
        filters,
        setFilters,

        stats,
        setStats,

        page,
        setPage,

        loading,
        setLoading,
      }}
    >
      {children}
    </NotificationTemplatesContext.Provider>
  );
};
