import { Card, Flex, Space, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import ApexCharts from "react-apexcharts";

const SmsCampaigns = () => {
  const { Text, Title } = Typography;
  const axios = useAxiosPrivate();
  const [data, setData] = useState();
  const [categories, setCategories] = useState();
  const [series, setSeries] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get("/Stats/campaigns/sms/stats");
      setData(response.data);
      setCategories(
        response.data.items.map((c) => ({ name: c.monthName, data: [c.count] }))
      );

      setSeries([{
        data: response.data.items.map(c => c.count)
      }])
    };

    
    fetchData();
  }, []);

  return (
    <Card title={null}>
      <Space style={{ width: "100%" }} direction="vertical" size={0}>
        <Flex align="center" justify="space-between">
          <Text className="text-light">Sms campaigns</Text>

          <Text strong style={{ color: "#e74a3b" }}>
            {((isNaN(data?.errors / data?.all) ? 0 : (data?.errors / data?.all)) * 100).toFixed(1)}%
          </Text>
        </Flex>

        <Title style={{ margin: 0, padding: 0 }}>{data?.all}</Title>
      </Space>
      {(categories && series) && (
        <Chart
          categories={categories.map((c) => c.name)}
          data={series}
        />
      )}
    </Card>
  );
};

const Chart = ({ categories, data }) => {
  const [options, setOptions] = useState({
    fill: {
      type: "gradient",
      gradient: {
        shade: "light",
        type: "vertical",
        shadeIntensity: 0.25,
        colorStops: [
          {
            offset: 0,
            color: "#2e0dbb",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#fcfcfc",
            opacity: 0,
          },
        ],
        inverseColors: true,
        opacityFrom: 0.85,
        opacityTo: 0.85,
        stops: [0, 100],
      },
    },
    chart: {
      id: "salesChart",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      stacked: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 7.5, 
        horizontal: false,
        columnWidth: "15px",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      colors: ["transparent"],
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          colors: "#a3aed0",
          fontSize: "16px",
          fontFamily: "DM Sans",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: "",
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    legend: {
      show: false,
    },
  });

  return <ApexCharts type="bar" options={options} series={data} height={275} />;
};

export default SmsCampaigns;