import { createContext, useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import AuthorSelectListItem from "../../shared/AuthorSelectListItem";
import { OrderDirection } from "../../shared/enums";
import { SearchContext } from "../../context/SearchContext";
import { ResponsiveMasonry } from "react-responsive-masonry";

export const AudienceListContext = createContext({
    stats: {
        total: 0,
        first: null,
        last:null,
        authorName:"",
        authorImage: ""
    },
    setStats: () => { },

    filters: {
        authors: [],
        selectedAuthors: [],

        from: null,
        to: null,

        orderBy: null,
        orderDirection: null
    },
    setFilters: () => { },


    audiences: [],
    setAudiences: () => { },

    loading: false,
    setLoading: () => { },

    page: 1,
    setPage: () => { }
})

export const AudienceListContextProvider = ({ children }) => {

    const perPage = 16;
    const { debouncedValue } = useContext(SearchContext)
    const axios = useAxiosPrivate();

    const [filters, setFilters] = useState()
    const [stats, setStats] = useState();

    const [page, setPage] = useState(1);

    const [loading, setLoading] = useState(false);
    const [audiences, setAudiences] = useState([])
    
    useEffect(async () => {
        const response = await axios.get(`Users/GetAuthors`);
        setFilters(prev => ({
            ...prev,
            orderDirection: OrderDirection.Dsc,
            orderBy: "Created",
            selectedAuthors: [response.data.yourId],
            authors: response.data.data.map((d) => ({
                value: d.id,
                label: (<AuthorSelectListItem firstname={d.firstname} lastname={d.lastname} profile={d.profileImagePath} />),
            }))
        }
        ));
    }, [])

    const fetchAudiences = async (resetPage = false) => {
        setLoading(true);

        const response = await axios.post("/TargetAudience/GetTargetAudiences", {
            page: resetPage ? 1 : page,
            pageSize: perPage,
            search: debouncedValue,
            authors: filters?.selectedAuthors,
            from: filters?.from,
            to: filters?.to,
            sortDirection: filters?.orderDirection,
            orderBy: filters?.orderBy
        });

        if (resetPage) {
            setAudiences(response.data.items);
            setPage(1); 
        } else {
            setAudiences(prev => [
                ...prev,
                ...response.data.items,
            ]);
        }

        setStats(prev => ({
            ...prev,
            total: response.data.total,
            first: response.data.first,
            last: response.data.last,
            authorName: response.data.authorName,
            authorImage: response.data.authorProfile
        }));
        setLoading(false);
    };

    useEffect(() => {
        fetchAudiences(true);
    }, [filters, debouncedValue]);

    useEffect(() => {
        if (page > 1) {
            fetchAudiences();
        }
    }, [page]);

    return <AudienceListContext.Provider value={{
        filters,
        setFilters,

        stats,
        setStats,

        page,
        setPage,

        audiences,
        setAudiences,
        
        loading,
        setLoading,
    }}>
        {children}
    </AudienceListContext.Provider>
}