import { Card, Col, Flex, Row, Space, Tabs, Typography } from "antd";
import { useState } from "react";
import { FaKey, FaSignal, FaSignature } from "react-icons/fa";
import { MdCleaningServices, MdDashboard, MdOutlinePayment, MdPeople } from "react-icons/md";
import YourApiKeys from "./api/YouApiKeys";
import ProxySettings from "./proxy/ProxySettings";
import useAuth from "../../hooks/useAuth";
import { PiSignatureFill } from "react-icons/pi";
import { RiPriceTagFill } from "react-icons/ri";
import { BiSolidCoupon } from "react-icons/bi";
import UserList from "../users/UserManagement/UserList";
import SignatureList from "../templates/Signatures/SignatureList";
import SubscriptionList from "../subscriptions/SubscriptionList";
import ManageCoupons from "../prices/ManageCoupons";
import ManagePrices from "../prices/ManagePrices";
import CleanUpLayout from "../cleanup/CleanUpLayout";

const ApiProxyLayout = () => {
  const { Text, Title } = Typography;

  const [currentTab, setCurrentTab] = useState(1);
  const { auth } = useAuth();

  const items = [
    {
      key: 1,
      label: <Flex style={{minWidth:150}} align="center" justify="center" gap={6}><FaKey /> Developers</Flex>
    },
    {
      key: 4,
      label: <Flex align="center" justify="center" gap={6}><PiSignatureFill style={{ fontSize: 16 }} /> Signatures</Flex>
    },
    {
      key: 5,
      label: <Flex align="center" justify="center" gap={6}><MdOutlinePayment style={{ fontSize: 16 }} /> Subscriptions</Flex>
    },
    {
      key: 6,
      label:  <Flex align="center" justify="center" gap={6}><MdCleaningServices/> Jobs</Flex>
    }
  ];

  if (auth?.roles?.find((c) => c == "SuperAdmin")) {
    items.push({
      key: 3,
      label:  <Flex align="center" justify="center" gap={6}><MdPeople style={{ fontSize: 16 }} /> Team Managment</Flex>
    });
  }

  if (auth?.roles?.find((c) => c == "ApplicationOwner")) {
    items.push({
      key: 8,
      label: <Flex align="center" justify="center" gap={6}><RiPriceTagFill /> Prices & Limits</Flex>
    });

    items.push({
      key: 9,
      label: <Flex align="center" justify="center" gap={6}><BiSolidCoupon /> Coupons</Flex>
    });
  }

  return (
    <Space className="w-100" direction="vertical" size={24} >
      <Card
        className="zero-margin-padding no-body-card end-to-end-header"
        title={
          <>
            <Flex align="center" justify="space-between" wrap="wrap">
              <Title style={{ margin: 0 }} level={4}>
                Settings
              </Title>
            </Flex>
          </>
        }
      ></Card>

      <Card bodyStyle={{padding:0, margin:0}}>
        <Tabs 
        
          items={items}
          onChange={(e) => setCurrentTab(e)}
        />

      <div style={{margin:20}}>

        {currentTab == 1 ? (
          <Space className="w-100" direction="vertical" size={24} >

            <YourApiKeys />
            <ProxySettings />
          </Space>
        ) : currentTab == 3 ? (
          <UserList />
        ) : currentTab == 4 ? (
          <SignatureList />
        ) : currentTab == 5 ? (
          <SubscriptionList />
        ) : currentTab == 6 ? <CleanUpLayout/> : currentTab == 8 ? (
          <ManagePrices />
        ) : (
          <ManageCoupons />
        )}
        </div>
      </Card>
    </Space>
  );
};

export default ApiProxyLayout;
