import {
  Button,
  Card,
  Col,
  Flex,
  Row,
  Space,
  Table,
  Typography,
  Grid,
  Tag,
  Checkbox,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import TableLoading from "../../shared/TableLoading";
import { DateFormat } from "../../dateformat";
import UpdateProduct from "./UpdateProduct";
import AddProduct from "./AddProduct";
import ProductListOptions from "./ProductListOptions";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import NoDataFound from "../../shared/NoDataFound";
import { ProductListContext } from "./ProductListContext";
import AuthorProductFilter from "./Filters/AuthorProductFilter";
import DateToProductFilter from "./Filters/DateToProductFilter";
import DateFromProductFilter from "./Filters/DateFromProductFilter";
import OrderByProductFilter from "./Filters/OrderByProductFilter";
import OrderDirectionProductFilter from "./Filters/OrderDirectionProductFilter";
import ProductStats from "./Stats";
import { ThemeContext } from "../../context/ThemeContext";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";

const { useBreakpoint } = Grid;

const ProductList = () => {
  const axios = useAxiosPrivate();

  const {
    stats,
    setStats,
    page,
    setPage,
    showFilters,
    setShowFilters,
    loading,
    products,
    setProducts,
  } = useContext(ProductListContext);

  const { Title, Text } = Typography;

  const navigate = useNavigate();
  const screens = useBreakpoint();

  const perPage = 7;

  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const [current, setCurrent] = useState();

  const columns = [
    {
      title: "Name",
      render: (data, row, index) => <Text strong>{row.name}</Text>,
    },
    {
      title: "Description",
      render: (row) => <Text strong>{row.description}</Text>,
    },
    {
      title: "Emails",
      width: 100,
      render: (row) => (
        <Flex className="w-100" align="center" justify="center">
          {" "}
          <Checkbox onChange={(e) => handleUpateFollowups(row.id, e.target.checked, row.autoFollowupNotifications)} checked={row?.autoFollowupEmails}></Checkbox>
        </Flex>
      ),
    },
    {
      title: "Notifications",
      width: 100,
      render: (row) => (
        <Flex className="w-100" align="center" justify="center">
          <Checkbox onChange={(e) => handleUpateFollowups(row.id, row.autoFollowupEmails, e.target.checked)} checked={row?.autoFollowupNotifications}></Checkbox>
        </Flex>
      ),
    },
    {
      title: "Deals",
      render: (data, row, index) => (
        <div>
          {row.productDeals?.map((c, ind) => (
            <Text
              key={ind}
              type="link"
              strong
              onClick={() => navigate(`/Deals/Details/${c.id}`)}
            >
              {c.name}
              {ind < row.productDeals?.length - 1 && ", "}
            </Text>
          ))}

          {row.subscribersCount > 2 && (
            <Text type="link" strong>
              ,+{row.subscribersCount - 2} more..
            </Text>
          )}
        </div>
      ),
    },
    {
      title: "Date",
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
    {
      title: "",
      width: 10,
      render: (data, row, index) => (
        <ProductListOptions
          id={row.id}
          onDelete={onDelete}
          setShowEditing={(e) => setShowEdit(e)}
          setCurrent={(e) => setCurrent(e)}
        />
      ),
    },
  ];

  const onAdd = (prod) => {
    setStats((prev) => ({ ...prev, total: prev.total + 1 }));

    if (products.length < perPage) {
      setProducts((prev) => [...prev, prod]);
    }
  };

  const onDelete = (id) => {
    setStats((prev) => ({ ...prev, total: prev.total - 1 }));

    setProducts((prev) => prev.filter((c) => c.id != id));
  };

  const onUpdate = (prod) => {
    setProducts((prev) =>
      prev.map((c) =>
        c.id == prod.id
          ? {
              ...c,
              name: prod.name,
              description: prod.description,
              autoFollowupEmails: prod.autoFollowupEmails,
              autoFollowupNotifications: prod.autoFollowupNotifications,
            }
          : c
      )
    );
  };

  const handleUpateFollowups = async (id, emails, notifications) => {
    let response = await axios.put(`/Product/Update/FolloUps`, {
      id: id,
      emails: emails,
      notifications: notifications,
    });
  
    if(response.data){
      setProducts(prev => prev.map(c => c.id == id ? {
        ...c,
        autoFollowupEmails: emails,
        autoFollowupNotifications: notifications,
      } : c))
    }else{
      message.error(response.data.message)
    }
  }
  
  return (
    <>
      <Space direction="vertical" size={24} style={{ width: "100%" }}>
        <ProductStats />
        <Card
          className="zero-margin-padding no-body-card end-to-end-header"
          title={
            <Flex
              align="center"
              justify="space-between"
              wrap="wrap"
              style={{
                ...(screens.xs ? { gap: "10px", padding: "10px 0" } : {}),
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Products
              </Title>
              <Flex
                gap={10}
                wrap="wrap"
                style={{
                  ...(screens.xs ? { flexBasis: "100%" } : {}),
                }}
              >
                <Button
                  size="large"
                  block={screens.xs}
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>
                <Button
                  type="primary"
                  size="large"
                  block={screens.xs}
                  onClick={() => setShowAdd(true)}
                  className="primary-gradient-background flex-button bold-button"
                  icon={<FiPlus style={{ fontSize: 20 }} />}
                >
                  New product
                </Button>
              </Flex>
            </Flex>
          }
        >
          {showFilters && (
            <Row gutter={[24, 24]} style={{ margin: 24 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                <AuthorProductFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <DateFromProductFilter />
              </Col>
              <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
                <DateToProductFilter />
              </Col>
              <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <OrderByProductFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <OrderDirectionProductFilter />
              </Col>
            </Row>
          )}
        </Card>
        <Card className="zero-margin-padding">
          {loading && <TableLoading />}
          {!loading && (
            <Table
              style={{ marginTop: 1 }}
              columns={columns}
              dataSource={products}
              locale={{
                emptyText: (
                  <NoDataFound
                    title="You have not created any products yet"
                    description="Click the new product button on Dealing/Product to create a new product"
                    addText="New Product"
                  />
                ),
              }}
              pagination={{
                current: page,
                pageSize: perPage,
                total: stats?.total,
                onChange: (page, pageSize) => {
                  setPage(page);
                },
              }}
            />
          )}
        </Card>
      </Space>

      <AddProduct show={showAdd} setShow={setShowAdd} onAdd={onAdd} />
      {showEdit && (
        <UpdateProduct
          show={showEdit}
          setShow={setShowEdit}
          id={current}
          onUpdate={onUpdate}
        />
      )}
    </>
  );
};

export default ProductList;
