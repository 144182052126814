import { useContext, useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import TableLoading from "../../../shared/TableLoading";
import {
  Button,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { DateFormat } from "../../../dateformat";
import { useParams, useNavigate } from "react-router-dom";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
const SelectableWhatsappTemplates = () => {
  const {id} = useParams();

  const navigate = useNavigate();
  const { data, currentStep, setCurrentStep, setData } = useContext(
    CreateCampaignContext
    );
    const { Link, Text } = Typography;
    
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate();
  const perPage = 7;
  const [templates, setTemplates] = useState([]);
  const [count, setCount] = useState();
  const [page, setPage] = useState(() => 1);
  const columns = [
    {
      title: "Data",
      render: (data, row) => (
        <Text strong type="link" onClick={() => navigate(`/Templates/Edit/Whatsapp/${row.id}`)}>
            {row.name}
          </Text>
      ),
    },
    {
      title: "Header",
      render: (data, row) => <Text strong>{row.headerType}</Text>,
    },
    {
      title: "CTA & QR",
      render: (data, row) => (
        <Space align="center">
          <Text strong>{data.ctaButtons}</Text> | <Text strong>{data.qrButtons}</Text>
        </Space>
      ),
    },
    {
      title:"Date",
      render:(data, row) => <Text strong>{new Date(row.createdAt).toLocaleDateString('en-US',DateFormat)}</Text>
    }
  ];

  useEffect(async () => {
    setLoading(true);
    const response = await axios.post("/Whatsapp/GetAll", {
      page: page,
      pageSize: perPage,
    });

    setCount(response.data.count);
    setTemplates(response.data.data.map((c) => ({ ...c, key: c.id })));
    setLoading(false);
  }, [page]);

  const handleChange =async (c) => {
    const response = await axios.post(`/Campaign/AssignTemplate`, {
        campaignId: parseInt(id),
        templateId: c[0]
    })

    if(response.data.success){
      setData(prev => ({...prev, whatsappTemplateId: c[0]}))
    }
    else{
      message.error(response.data.message)
    }
  } 
  return (
    <>
      {loading && <TableLoading />}
      <Table
        rowSelection={{
          type: "radio",
          columnWidth: 48,
          selectedRowKeys: [data.whatsappTemplateId],
          onChange: (d) => handleChange(d)
        }}
        dataSource={templates}
        columns={columns}
        pagination={{ page: page, total: count, pageSize: perPage, onChange: (page, pageSize) => setPage(page)}}
      ></Table>
    </>
  );
};

export default SelectableWhatsappTemplates;
