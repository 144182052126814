import { Form, Modal, Typography } from "antd";
import AddNote from './AddNote'
const CampaignNotes = ({ isOpen, setIsOpen }) => {
  const {Title} = Typography
  const [form] = Form.useForm();

  const handleSave = () => {
    form.submit();
    setIsOpen(false)
  }

  return (
      <Modal
        open={isOpen}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Campaign notes
          </Title>
        }
        onCancel={() => setIsOpen(false)}
        onOk={() => handleSave()} 
        okText="Save"
      >
        <AddNote form={form}/>
      </Modal>
  );
};

export default CampaignNotes;
