import { Select, Typography } from "antd";
import { useContext } from "react";
import { SmsListContext } from "../SmsListContext";

const SmsAuthorFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(SmsListContext);

  const handleChanged = (e) => {
    setFilters((prev) => ({
      ...prev,
      selectedAuthors: e,
    }));
  };

  return (
    <>
      <Text>Authors</Text>
      <Select
        size="large"
        maxTagCount="responsive"
        defaultValue={filters?.selectedAuthors}
        placeholder="Select campaign filter authors"
        style={{ width: "100%" }}
        options={filters?.authors}
        mode="multiple"
        onChange={handleChanged}
      />
    </>
  );
};

export default SmsAuthorFilter;
