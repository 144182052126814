import { useContext, useState } from "react";
import { SubscribeContext } from "../../../context/SubscribeContext";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  InputNumber,
  List,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import { FaCartShopping } from "react-icons/fa6";
import { MdOutlineCheck } from "react-icons/md";
import { loadStripe } from "@stripe/stripe-js";
import env from '../../../env.json'
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const SubscriptionModal = () => {
  
  const { show, setShow } = useContext(SubscribeContext);

  const [professionalCardPrice, setProfessionalCardPrice] = useState(490);
  const [enterpriseCardPrice, setEnterpriseCardPrice] = useState(2900);

  const [profesionalContratValue, setProfessionalContractValue] =
    useState(3000);
  const [enterpriseContratValue, setEnterpriseContratValue] = useState(20000);

  let currentProfessionalValue = 3000;
  let currentEnterpriseValue = 20000;

  const handleProfessionalContractBlur = () => {
    if (profesionalContratValue <= 3000) {
      setProfessionalContractValue(3000);
    }
  };

  const handleEnterpriseContractBlur = () => {
    if (enterpriseContratValue <= 20000) {
      setEnterpriseContratValue(20000);
    }
  };

  function onContractNumberChangeProfessional(e) {
    setProfessionalContractValue(e);

    if (e >= 3000) {
      let shtes = e - currentProfessionalValue;
      var sa = shtes / 5000;
      if (shtes % 5000 != 0) {
        sa = parseInt(shtes / 5000) + 1;
        setProfessionalContractValue(3000 + 5000 * sa);
      }
      setProfessionalCardPrice(490 + sa * 225);
    } else {
      setProfessionalCardPrice(490);
    }
  }

  function handleProfessionalUp() {
    setProfessionalContractValue((prev) => {
      const newValue = prev + 5000;

      if (newValue >= 3000) {
        let shtes = newValue - currentProfessionalValue;
        var sa = shtes / 5000;
        if (shtes % 5000 !== 0) {
          sa = parseInt(shtes / 5000) + 1;
          return 3000 + 5000 * sa;
        }
        setProfessionalCardPrice(490 + sa * 225);
      } else {
        setProfessionalCardPrice(490);
      }

      return newValue;
    });
  }

  function handleEnterpriseUp() {
    setEnterpriseContratValue((prev) => {
      const newValue = prev + 10000;

      if (newValue >= 20000) {
        let shtes = newValue - currentEnterpriseValue;
        var sa = shtes / 10000;
        if (shtes % 10000 !== 0) {
          sa = parseInt(shtes / 10000) + 1;
          return 20000 + 10000 * sa;
        }
        setEnterpriseCardPrice(2900 + sa * 100);
      } else {
        setEnterpriseCardPrice(2900);
      }

      return newValue;
    });
  }

  function handleProfessionalDown() {
    if (profesionalContratValue > 3000) {
      setProfessionalContractValue((prev) => {
        const newValue = prev - 5000;

        if (newValue >= 3000) {
          let shtes = newValue - currentProfessionalValue;
          var sa = shtes / 5000;
          if (shtes % 5000 !== 0) {
            sa = parseInt(shtes / 5000) + 1;
            return 3000 + 5000 * sa;
          }
          setProfessionalCardPrice(490 + sa * 225);
        } else {
          setProfessionalCardPrice(490);
        }

        return newValue;
      });
    }
  }

  function handleEnterpriseDown() {
    if (enterpriseContratValue > 20000) {
      setEnterpriseContratValue((prev) => {
        const newValue = prev - 10000;

        if (newValue >= 20000) {
          let shtes = newValue - currentEnterpriseValue;
          var sa = shtes / 10000;
          if (shtes % 10000 !== 0) {
            sa = parseInt(shtes / 10000) + 1;
            return 20000 + 10000 * sa;
          }
          setEnterpriseCardPrice(2900 + sa * 100);
        } else {
          setEnterpriseCardPrice(2900);
        }

        return newValue;
      });
    }
  }

  function onContractNumberChangeEnterprise(e) {
    setEnterpriseContratValue(e);

    if (e >= 20000) {
      let shtes = e- currentEnterpriseValue;
      var sa = shtes / 10000;
      if (shtes % 10000 != 0) {
        sa = parseInt(shtes / 10000) + 1;
        setEnterpriseContratValue(20000 + 10000 * sa);
      }
      setEnterpriseCardPrice(2900 + sa * 100);
    } else {
      setEnterpriseCardPrice(2900);
    }
  }

  return (
    <>
      <Modal
        width={760}
        open={show}
        footer={null}
        onCancel={() => setShow(false)}
        title="Upgrade to other plans"
      >
        <Row gutter={12}>
          <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
            <SubType
              name="Profesional"
              description="Comprehensive marketing software for automation, reporting, and campaigns"
              items={[
                { name: "Email campaigns" },
                { name: "Whatsapp campaigns" },
                { name: "Customize your audience" },
                { name: "Design you template or" },
                { name: "Use TapZap templates" },
              ]}
              price={"$" + parseFloat(professionalCardPrice).toLocaleString()}
              contractInputValueProfesional={profesionalContratValue}
              setProfessionalContractValue={setProfessionalContractValue}
              setProfessionalCardPrice={setProfessionalCardPrice}
              handleProfessionalUp={handleProfessionalUp}
              handleProfessionalDown={handleProfessionalDown}
              handleContractBlurProfesional={handleProfessionalContractBlur}
              handleInputContractChangeProfesional={
                onContractNumberChangeProfessional
              }
              contractDesc=" Includes 3,000 contacts"
              contractDescPrice="$225/month per 5,000"
              type={2}
            />
          </Col>
          <Col xs={24} sm={24} lg={12} xl={12} xxl={12}>
            <SubType
              primary={true}
              name="Enterprise"
              price={"$" + parseFloat(enterpriseCardPrice).toLocaleString()}
              description="Our most powerful marketing software for advanced control and flexibility"
              contractDescPrice="$100/month per 10,000"
              contractDesc=" Includes 20,000 contacts"
              items={[
                { name: "Email campaigns" },
                { name: "Whatsapp campaigns" },
                { name: "Customize your audience" },
                { name: "Design you template or" },
                { name: "Use TapZap templates" },
              ]}
              contractInputValueProfesional={enterpriseContratValue}
              handleContractBlurProfesional={handleEnterpriseContractBlur}
              handleProfessionalUp={handleEnterpriseUp}
              handleProfessionalDown={handleEnterpriseDown}
              handleInputContractChangeProfesional={
                onContractNumberChangeEnterprise
              }
              type={3}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const SubType = ({
  name,
  description,
  primary,
  items,
  contractInputValueProfesional,
  setProfessionalContractValue,
  setProfessionalCardPrice,
  handleProfessionalUp,
  handleProfessionalDown,
  handleContractBlurProfesional,
  handleInputContractChangeProfesional,
  contractDesc,
  contractDescPrice,
  price,
  type
}) => {
  const { Title, Text } = Typography;
  const axios = useAxiosPrivate();

  const stripePromise = loadStripe(env.stripePK);

  const [loading, setLoading] = useState(() => false);

  const pay = async () => {
    setLoading(true);

    const response =  await axios.post(`/AudienceSubscription/subscribe`, {
      type:type,
      amount:contractInputValueProfesional
    });

    if(response.data.success){
      const stripe = await stripePromise;

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId, 
      });

      if(result.error){
        message.error(result.error.message)
      }
    }else{
      message.error(response.data.message);
    }
    setLoading(false)
  }

  return (
    <Card
      style={{ width: "350px" }}
      actions={[
        <Button
          size="large"
          classNames="flex-button bold-button"
          type={primary ? "primary" : ""}
          style={{ width: "90%" }}
          icon={<FaCartShopping />}
          loading={loading}
          onClick={() => pay()}
          disabled
        >
          Subscribe
        </Button>,
      ]}
    >
      <Space style={{ width: "100%" }} direction="vertical" size="large">
        <Space direction="vertical" size={0}>
          <Text>{name}</Text>

          <Title style={{ margin: 0 }}>
            {price}
            <Text>/mo</Text>
          </Title>

          <div style={{ height: "80px" }}>
            <Text className="text-light">{description}</Text>
          </div>
        </Space>

        <Divider style={{ margin: 0 }} />
        <Flex align="center" justify="space-between">
          <Space size={0} direction="vertical">
            <Text strong style={{ color: "#422afb" }}>
            {contractDesc}
            </Text>
            <Text>
             {contractDescPrice}
            </Text>
          </Space>

          <InputNumber size="large" onBlur={handleContractBlurProfesional}
                    value={contractInputValueProfesional}
                    onChange={handleInputContractChangeProfesional} />
        </Flex>

        <Divider style={{ margin: 0 }} />
        <List
          dataSource={items}
          renderItem={(item, index) => (
            <Flex align="center" justify="space-between" gap={5}>
              <Text>{item.name}</Text>
              <Text type="success">
                <MdOutlineCheck style={{ fontSize: 18 }} />{" "}
              </Text>
            </Flex>
          )}
        ></List>
      </Space>
    </Card>
  );
};

export default SubscriptionModal;
