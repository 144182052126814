import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Flex, Layout, Segmented, Space, Typography } from "antd";
import { Policy } from "../../shared/enums";
import PrivacyPolicy from "./PrivacyPolicy";
import { useNavigate } from "react-router-dom";
import TermsOfService from "./TermsOfService";

const PoliciesWrapper = ({ view }) => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Flex gap="small" align="center" vertical style={{ height: "15vh" }}>
          <Segmented
            style={{ marginTop: 10 }}
            size="small"
            defaultValue={view}
            options={[
              
              {
                label: (
                  <Card
                    className="transparent-card"
                    onClick={() => navigate("/policy/terms-of-usage")}
                  >
                    <Title level={5} style={{ margin: 0 }}>
                      Terms of usage
                    </Title>
                  </Card>
                ),
                value: Policy.TERMS,
              },
              {
                label: (
                  <Card
                    className="transparent-card"
                    onClick={() => navigate("/policy/prviacy-policy")}
                  >
                    <Title level={5} style={{ margin: 0 }}>
                      Privacy Policy
                    </Title>
                  </Card>
                ),
                value: Policy.PRIVACY,
              },

            ]}
          />
        </Flex>

        <Card
          style={{
            maxWidth: "1200px",
            margin: "auto",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            overflowY: "auto",
            flex: 1,
          }}
        >
          {view == Policy.PRIVACY && <PrivacyPolicy />}
          {view == Policy.TERMS && <TermsOfService/>}
        </Card>
      </div>
    </>
  );
};

export default PoliciesWrapper;
