import {
  Segmented,
  Typography,
  Space,
  Modal,
  DatePicker,
  Flex,
  Button,
  Form,
  Row,
  Col,
  message,
} from "antd";
import { useContext, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useEffect } from "react";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { GrNext } from "react-icons/gr";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const SendTime = ({ isOpen, setIsOpen }) => {
  const { type } = useParams();

  const axios = useAxiosPrivate();
  const [form] = Form.useForm();

  const { Title, Text } = Typography;

  const [scheduleType, setScheduleType] = useState();
  const [loading, setLoading] = useState(false);
  const { data, currentStep, setCurrentStep, setData } = useContext(
    CreateCampaignContext
  );

  useEffect(() => {
    if (type == "2" || type == "1") {
      setScheduleType("now");
      setScheduleType("now");
    } else {
      form.setFieldValue("start", dayjs(data.startDate ?? new Date()));
      form.setFieldValue("end", dayjs(data.endDate ?? new Date()));
      setScheduleType(data.startNow ? "now" : "schedule");
    }
  }, []);

  const save = async (e) => {
   
    if (e && e.start && e.end) {
      setData({ ...data, startDate: e.start.$d, endDate: e.end.$d });
    }
    setLoading(true);
    const response = await axios.post("/Campaign/AssignScheduleToCampaign", {
      campaignId: data.id,
      startDate: e?.start?.$d,
      endDate: e?.end?.$d,
      startNow: data.startNow,
    });

    setLoading(false);
    if (response.data.success) {
      setCurrentStep(currentStep + 1);
      setIsOpen(false);
    } else {
      message.error(response.data.message);
    }
  };

  const handleCancel = () => {
    setIsOpen(false);
  };

  const onSegClick = (val) => {
   
    setData({ ...data, startNow: val == "now" });
    setScheduleType(val)
  };
  return (
    <>
      <Modal
        open={isOpen}
        title={
          <Title style={{ margin: 0 }} level={4}>
            Send time
          </Title>
        }
        onCancel={handleCancel}
        footer={
          <>
            <Flex align="center" justify="end">
              <Space>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button
                  type="primary"
                  onClick={() => (data.startNow ? save() : form.submit())}
                  loading={loading}
                  disabled={!data.canEdit}
                  className="flex-button"
                  size="large"
                  icon={ <GrNext />}
                >
                  Continue to the last step
                </Button>
              </Space>
            </Flex>
          </>
        }
      >
        <Space style={{ width: "100%" }} direction="vertical">
          <Form form={form} onFinish={save}>
            <Segmented
              disabled={!data.canEdit}
              block
              value={scheduleType}
              onChange={(e) => onSegClick(e)}
              options={[
                {
                  label: 
                  <Flex style={{height:50}} align="center" justify="center"><Title level={5} style={{margin:0}}>NOW</Title></Flex>,
                  value: "now",
                },
                {
                  disabled: type == "2" || type == "1",
                  label:<Flex style={{height:50}} align="center" justify="center"><Title level={5} style={{margin:0}}>LATER</Title></Flex>,
                  value: "schedule",
                },
              ]}
            />

            {scheduleType == "now" && (
              <>
                <Text>
                  * This campaign will start in the next 1-5 minutes after it
                  has been fully saved.
                </Text>
              </>
            )}
            {scheduleType == "schedule" && (
              <>
                <Text>
                  This campaign will start in the next 1-5 minutes after its
                  start date time but it has to be fully saved.
                </Text>
                <Row gutter={24}>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Text>Start date</Text>
                    <Form.Item
                      name="start"
                      rules={[
                        { required: true, message: "Provide start date" },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        size="large"
                        disabled={!data.canEdit}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                    <Text>End date</Text>
                    <Form.Item
                      name="end"
                      rules={[
                        {
                          required: true,
                          message: "Provide end date",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        size="large"
                        disabled={!data.canEdit}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Space>
      </Modal>
    </>
  );
};

export default SendTime;
