import { Button, Card, Flex, Space, Table, Typography, Grid } from "antd";
import { useNavigate } from "react-router-dom";
import { MdTableRows } from "react-icons/md";
import { BsGrid1X2Fill } from "react-icons/bs";
import { FiPlus } from "react-icons/fi";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../../../context/SearchContext";
import useDebounce from "../../../../hooks/useDebounce";
import { DateFormat } from "../../../../dateformat";
import TableLoading from "../../../../shared/TableLoading";
import { FaArrowRight } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import emails from "../../../../assets/images/backgroundwhatsapp.jpg";
import { MdOutlineClose } from "react-icons/md";
import WhatsappSenderOptions from "./WhatsappSenderOptions";
import Promo from "../../../../shared/Promo";

const { useBreakpoint } = Grid;

const WhatsappSenderList = () => {
  const { Text, Title } = Typography;

  const navigate = useNavigate();
  const axios = useAxiosPrivate();

  const screens = useBreakpoint();

  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(() => false);
  const perPage = 10;

  const { debouncedValue } = useContext(SearchContext);
  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      const response = await axios.get(
        `/Whatsapp/numbers?page=${page}&pageSize=${perPage}&search=${debouncedValue}`
      );

      setData(response.data.data);
      setTotal(response.data.count);
      setLoading(false);
    };

    fetch();
  }, [debouncedValue, page]);

  const columns = [
    {
      title: "Name",
      render: (data, row) => <Text strong>{row.name}</Text>,
    },
    {
      title: "Number",
      responsive:['sm'],
      render: (data, row) => <Text strong>{row.number}</Text>,
    },
    {
      title: "Status",
      responsive:['sm'],
      render: (data, row) => <Text strong>{row.status}</Text>,
    },
    {
      title: "Default",
      responsive:['md'],
      render: (data, row) => (
        <>
          {row.default && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#1cc88a",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaCheck style={{ color: "white" }} />
            </div>
          )}

          {!row.default && (
            <div
              style={{
                width: 25,
                height: 25,
                borderRadius: 25,
                backgroundColor: "#e74a3b",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MdOutlineClose style={{ color: "white", fontSize: 18 }} />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Date",
      responsive:['md'],
      render: (data, row) => (
        <Text strong>
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      ),
    },
    {
      width: 50,
      render: (data, row) => (
        <WhatsappSenderOptions id={row.id} handleUpdate={updateDefault} />
      ),
    },
  ];

  const updateDefault = (id) => {
    setData((prev) =>
      prev.map((c) =>
        c.id == id ? { ...c, default: true } : { ...c, default: false }
      )
    );
  };
  return (
    <Space style={{ width: "100%" }} direction="vertical" size={24}>
      <Promo
        background={emails}
        title="Send campaigns with whatsapp"
        text=" Tapzap currently offers over 2 million + contacts to chose from,
              <br />
              All available at the click of a fingertip"
        endElements={
          <Button
            icon={<FaArrowRight />}
            size="large"
            className="flex-button"
            style={{ width: "230px" }}
            onClick={() => navigate("/Campaigns/Create/1")}
          >
            Send a campaign
          </Button>
        }
      />
      <Card
        className="no-body-card end-to-end-header zero-margin-padding"
        title={
          <Flex align="center" justify="space-between" wrap="wrap"
          style={{
            ...(screens.xs ? { 'gap': '10px', 'padding': '10px 0' } : {})
          }}
          >
            <Title level={4} style={{ margin: 0 }}>
              Senders
            </Title>

              <Button
                size="large"
                className="primary-gradient-background flex-button bold-button"
                block={screens.xs}
                icon={<FiPlus style={{ fontSize: 20 }} />}
                onClick={() => navigate("/Templates/NewWhatsAppSender")}
                type="primary"
              >
                New sender
              </Button>
          </Flex>
        }
      ></Card>

      <Card className="zero-margin-padding">
        {loading && <TableLoading />}

        {!loading && (
          <Table
            dataSource={data}
            columns={columns}
            style={{ marginTop: 1 }}
            pagination={{
              pageSize: perPage,
              total: total,
              current: page,
              onChange: (page, pageSize) => setPage(page),
            }}
          />
        )}
      </Card>
    </Space>
  );
};

export default WhatsappSenderList;
