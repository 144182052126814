import { Button, Popconfirm, message } from "antd";
import { useState } from "react";
import { BsTrash3Fill } from "react-icons/bs";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";

const DeleteSignature = ({ id, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const axios = useAxiosPrivate()

  const confirm = async () => {
    setLoading(true)
    const response = await axios.post('/Signature/Delete', {
        id: id
    })

    if(response.data.success){
        message.success("Signature deleted")
        onDelete(id);
    }else{
        message.error(response.data.message)
    }
    setLoading(false)
  };

  const cancel = () => {};

  return (
    <Popconfirm
      title="Delete the signature"
      description="Are you sure to delete this signature?"
      onConfirm={confirm}
      onCancel={cancel}
      okText="Yes"
      cancelText="No"
    >
      <Button shape="circle" loading={loading} icon={<BsTrash3Fill />} danger></Button>
    </Popconfirm>
  );
};

export default DeleteSignature;
