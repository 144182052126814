import useConfig from "antd/es/config-provider/hooks/useConfig";
import { useContext, useEffect, useState } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import { useParams } from "react-router-dom";
import TableLoading from "../../../shared/TableLoading";
import { Space, Table, Typography, message } from "antd";
import { snapCenterToCursor } from "@dnd-kit/modifiers";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { FaRegClock } from "react-icons/fa";
import InitilasAvatar from "../../../shared/IntialsAvatar";
import SmsTemplateOptions from "./SmsTemplateOptions";
import { DateFormat } from "../../../dateformat";
import env from '../../../env.json'
const SelectableSmsTemplate = () => {
  const { id } = useParams();
    const {Text, Title} = Typography 
  const axios = useAxiosPrivate();

  const { data, currentStep, setCurrentStep, setData } = useContext(
    CreateCampaignContext
  );

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const perPage = 10;
  const columns = [ {
    title: "name",
    width: 300,
    render: (data, row, index) => (
      <Space direction="vertical" size={0}>
        <Text strong>
          {(page - 1) * perPage + index + 1}. {row.name}
        </Text>
        <Text className="text-light">
          <FaRegClock />{" "}
          {new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}
        </Text>
      </Space>
    ),
  },
  {
    title: "Text",
    render: (data, row, index) => (
      <div style={{ maxWidth: "450px" }}>
        <Text ellipsis>{row.text}</Text>
      </div>
    ),
  },
  {
    title: "",
    width: 50,
    render: (data, row, index) => (
      <Space>
        <InitilasAvatar
          name={row.author}
          src={`${env.fileUpload}${row.profile}`}
        />
      </Space>
    ),
  },];
  const [count, setCount] = useState(0);
  const [templates, setTemplates] = useState();

  useEffect(async () => {
    setLoading(true);
    const response = await axios.post(`/SmsTemplate/List`,{
      page:page,
      pageSize: perPage
    });
    setTemplates(
        response.data.items.map((c) => ({
          name: c.name,
          author: c.authorName,
          createdAt: c.createdAt,
          profile: c.authorProfile,
          text: c.text,
          id: c.id,
          key: c.id
        }))
      );
  
      setCount(response.data.total);
    setLoading(false);
  }, [page]);

  const handleChange =async (c) => {

    const response = await axios.post(`/Campaign/AssignTemplate`, {
        campaignId: parseInt(id),
        templateId: c[0]
    })

    if(response.data.success){
        message.success("Message template updated");
        setData(prev => ({...prev, smsTemplateId: c[0]}))
    }
    else{
        message.error(response.data.message)
    }

  } 

  return (
    <>
      {loading && <TableLoading />}
      {!loading && (
        <Table rowSelection={{
            type: "radio",
            columnWidth: 48,
            selectedRowKeys: [data.smsTemplateId],
            onChange: (d) => handleChange(d)
        }}
          columns={columns}
          dataSource={templates}
          pagination={{ page: page, total: count, pageSize: perPage }}
        />
      )}
    </>
  );
};

export default SelectableSmsTemplate;
