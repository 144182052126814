import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import React from "react";
import {
  Button,
  Layout,
  Menu,
  Typography,
  Flex,
  Card,
  Space,
  Avatar,
  Tag,
  Grid,
} from "antd";
import {
  MdCampaign,
  MdEmail,
  MdDashboard,
  MdDesignServices,
  MdHandshake,
  MdBusinessCenter,
  MdSms,
  MdCleaningServices,
} from "react-icons/md";
import { BiSolidNotification } from "react-icons/bi";
import { RiChatFollowUpFill, RiTaskFill, RiWhatsappFill } from "react-icons/ri";
import { BsPeopleFill } from "react-icons/bs";
import { IoDocument, IoMenu } from "react-icons/io5";
import { FaBuilding, FaProjectDiagram, FaUsers } from "react-icons/fa";
import logo from "../../assets/images/logo-circle.png";
import { ThemeContext } from "../../context/ThemeContext";
import { SubscribeContext } from "../../context/SubscribeContext";
import useAuth from "../../hooks/useAuth";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import ApexCharts from "react-apexcharts";
import { BiSolidFactory } from "react-icons/bi";
import kFormatter from "../../shared/kFormat";

const { useBreakpoint } = Grid;

const SideMenu = ({ collapsed, setCollapsed }) => {
  const screens = useBreakpoint();

  const { theme } = useContext(ThemeContext);
  const location = useLocation();
  const { Title, Text } = Typography;
  const { auth } = useAuth();

  const [key, setKey] = useState(() => {
    return location.pathname;
  });

  const { Sider } = Layout;

  const navigate = useNavigate();

  const handleMenuClick = (e) => {
    navigate(e.key);
  };

  return (
    <Sider
      width={293}
      trigger={null}
      collapsedWidth={0}
      collapsible
      collapsed={collapsed}
      style={{
        height: "100vh",
        position: screens.xs ? "fixed" : "sticky",
        top: 0,
        left: 0,
        bottom: 0,
        zIndex: 999,
        borderRight: `1px solid #${theme == "dark" ? "222e59" : "f0f0f0"}`,
        overflow: "auto"
      }}
    >
      <Flex style={{ minHeight: "100vh", overflowY: "auto" }} vertical justify="space-between">
        <div>

          <Flex
            align="center"
            justify="center"
            gap={6}
            style={{
              height: 100,
              borderBottom: `1px solid #${theme == "dark" ? "222e59" : "cbd5e0"}`,
            }}
          >
            <Title style={{ whiteSpace: "nowrap", margin: 0, fontSize: 30 }} level={3}>
              TAPZAP
            </Title>
            <Text style={{ fontSize: 30 }}>
              {auth.subType == 1 ? "FREE" : auth.subType == 2 ? "PRO" : "ENT"}
            </Text>

            {screens.xs &&
              <Button style={{
                fontSize: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
                shape="circle"
                size="large"
                type="text" icon={<IoMenu />} onClick={() => setCollapsed(true)}></Button>
            }
          </Flex>

          <Menu
            theme={theme}
            mode="inline"
            onClick={handleMenuClick}
            style={{ background: "transparent", border: "none", marginTop: 15, width: "100%" }}
            defaultSelectedKeys={[key]}
            items={[
              {
                icon: <MdCampaign style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Campaigns",
                key: "/Campaigns/List/Email"
              },
              {
                key: "/Media",
                icon: <IoDocument style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Documents",
                link: "/Media",
              },
              {
                key: "3",
                icon: <MdDesignServices style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Designs",
                children: [
                  {
                    key: "/Templates/Whatsapp",
                    label: "Whatsapp",
                    icon: <RiWhatsappFill style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Templates/Yours",
                    label: "Email",
                    icon: <MdEmail style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Templates/Sms",
                    label: "Sms",
                    icon: <MdSms className="menu-icon" />,
                  },
                  /*{
                    key: "/Templates/Notification",
                    label: "Notification",
                    icon: <BiSolidNotification className="menu-icon" />,
                  },*/
                ],
              },
              {
                key: "/Sender",
                icon: <BsPeopleFill style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Senders",
                children: [
                  {
                    key: "/Senders/whatsapp",
                    label: "Whatsapp",
                    icon: <RiWhatsappFill style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Senders",
                    label: "Email",
                    icon: <MdEmail style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Senders/sms",
                    label: "Sms",
                    icon: <MdSms style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                ],
              },
              {
                key: "/audience",
                icon: <FaUsers style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Target Audiences",
              },
              {
                key: "/Deals",
                icon: <MdBusinessCenter style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Dealing",
                children: [
                  {
                    key: "/Deals/Management",
                    label: "Deals",
                    icon: <MdHandshake style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Deals/Tasks",
                    label: "Tasks",
                    icon: <RiTaskFill style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Deals/Products",
                    label: "Products",
                    icon: <FaProjectDiagram style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                  {
                    key: "/Deals/Companies",
                    label: "Companies",
                    icon: <FaBuilding style={{ fontSize: 20 }} className="menu-icon" />,
                  },
                ],
              },
              /*{
                key: "/Followup",
                label: "Follow ups",
                icon: <RiChatFollowUpFill  style={{ fontSize: 20 }} className="menu-icon" />,
              }*/,
              {
                key: "/",
                icon: <MdDashboard style={{ fontSize: 20 }} className="menu-icon" />,
                label: "Main Dashboard",
              },
            ]}
          />
        </div>

        <div
          style={{
            height: auth.subType == 1 ? "240px" : "",
            textAlign: "center",
          }}
        >
          {auth.subType == 1 && <UpgradeToPro />}
          {auth.subType != 1 && <Charts />}
        </div>
      </Flex>
    </Sider>
  );
};

const Charts = () => {
  const axios = useAxiosPrivate();
  const { Title, Text } = Typography;
  const [tenant, setTenant] = useState();
  const [categories, setCategories] = useState();
  const [data, setData] = useState(() => []);

  const { theme } = useContext(ThemeContext);

  const statuses = ["click", "processed"];

  const [sum, setSum] = useState(() => 0);
  const [clickSum, setClickSum] = useState(() => 0)
  useEffect(() => {
    const fetchTenant = async () => {
      const response = await axios.get(`/tenant/current`);

      console.log(response)
      setTenant(response.data);
    };

    const fetchStats = async () => {
      statuses.forEach(async (s) => {
        var url = `/Stats/GetReachedAudienceStats?Status=${s}&Intervals=6`;

        const response = await axios.get(url);

        let color = "";

        if (s == "dropped") {
          color = "#ee0808";
        } else if (s == "delivered") {
          color = "#ee0808";
        } else if (s == "processed") {
          setSum(response.data.items.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0))
          color = "#fcfbff";
        } else if (s == "open") {
          color = "#ee0808";
        } else if (s == "deferred") {
          color = "#ee0808";
        } else if (s == "click") {
          color = "#67c2fd";
          setClickSum(response.data.items.reduce((acc, curr) => acc + curr.count, 0))
        } else if (s == "bounce") {
          color = "#ee0808";
        } else {
          color = "#ee0808";
        }
        setData((prev) => [
          ...prev,
          {
            name: s,
            data: response.data.items.map((c) => c.count),
            color: color,
          },
        ]);

        if (!categories) {
          setCategories(response.data.items.map((c) => c.monthName));
        }
      });
    };

    fetchStats();
    fetchTenant();
  }, []);

  return (
    <>

      <div style={{ position: "relative", margin: 10 }}>
        {sum > 0 &&
          <Card
            size="small"
            style={{
              margin: 10,
              height: "100%",
              background: "rgb(129,133,254)",
              background:
                "linear-gradient(159deg, rgba(129,133,254,1) 0%, rgba(66,43,243,1) 41%, rgba(66,42,250,1) 100%)",
            }}
          >
            <Space size="large" style={{ width: "100%" }} direction="vertical">
              <Space
                direction="vertical"
                style={{ width: "100%" }}
                size={0}
                align="center"
              >
                <Title style={{ color: "#fff", margin: 0 }} level={3}>
                  {kFormatter(sum)}
                </Title>
                <Text style={{ color: "#fff" }}>Total responses</Text>
                <Tag
                  color="green"
                  style={{ fontWeight: "bold", marginTop: 10 }}
                  bordered={false}
                >
                  {((isNaN((clickSum / sum)) ? 0 : (clickSum / sum)) * 100).toFixed(2)}%
                </Tag>
              </Space>

              {(categories && data) && (
                <Chart categories={categories} data={data} />
              )}
            </Space>
          </Card>
        }

        <Flex align="center" justify="center" gap={6}>
          <Avatar size="large" icon={<BiSolidFactory />} src={`data:${tenant?.logoMimeType};base64,${tenant?.logoBase64}`}/>
          <Space direction="vertical" style={{ textAlign: "left" }} size={0}>
            <Title style={{ margin: 0 }} level={5}>
              {tenant?.name}
            </Title>
            <Text className="text-light">{tenant?.userEmail}</Text>
          </Space>
        </Flex>
      </div>
    </>
  );
};

const Chart = ({ categories, data }) => {
  const [options, setOptions] = useState({
    chart: {
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
    xaxis: {
      categories,
      labels: {
        style: {
          colors: "#fff",
          fontSize: "12px",
          fontFamily: "DM Sans",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false, // Optionally, hide the ticks if needed
      },
    },

    yaxis: {
      title: {
        text: "",
      },
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    colors: ["#422afb", "#53c1ff"],
    legend: {
      show: false,
    },
  });

  return (
    <ApexCharts options={options} series={data} type="line" height={130} />
  );
};

const UpgradeToPro = () => {
  const { theme } = useContext(ThemeContext);
  const { show, setShow } = useContext(SubscribeContext);
  const { Title, Text } = Typography;

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: "absolute",
          left: "50%",
          top: -35,
          transform: "translateX(-50%)",
          zIndex: 10,
          height: 80,
          width: 80,
          borderRadius: 80,
          border: `4px solid ${theme == "light" ? "white" : "#111c44"}`,
          background:
            "linear-gradient(159deg, rgba(129,133,254,1) 0%, rgba(66,42,250,1) 100%)",
        }}
      >
        <Flex align="center" justify="center" style={{ height: "100%" }}>
          <img src={logo} width={35} height={35} />
        </Flex>
      </div>

      <Card
        size="small"
        style={{
          margin: 10,
          height: "100%",
          background:
            "linear-gradient(159deg, rgba(129,133,254,1) 0%, rgba(66,43,243,1) 41%, rgba(66,42,250,1) 100%)",
        }}
      >
        <Space size={0} direction="vertical" style={{ marginTop: 30 }}>
          <Title level={4} style={{ margin: 0, color: "white", marginTop: 10 }}>
            Upgrade to PRO
          </Title>
          <Text style={{ color: "white" }}>
            Comprehensive marketing software for automation, reporting, and
            campaigns.
          </Text>

          <button
            size="large"
            style={{
              marginTop: 40,
              background: "rgba(255,255,255,.2)",
              boxShadow: "0px 10px 15px rgba(255,255,255,.5) inset",
              color: "white",
              width: "200px",
              border: "none",
              fontSize: 16,
              padding: "10px 10px",
              fontWeight: 500,
              borderRadius: 40,
              cursor: "pointer",
            }}
            onClick={() => setShow(true)}
          >
            Upgrade to PRO
          </button>
        </Space>
      </Card>
    </div>
  );
};

export default SideMenu;
