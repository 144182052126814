import {
  Button,
  DatePicker,
  Drawer,
  Flex,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
} from "antd";
import React, { memo, useEffect, useState } from "react";
import { CardStatus } from "../../../shared/enums";
import useDealsManagementCtx from "../../../hooks/useDealsManagementCtx";
import ContactsMultiselect from "../FormItems/ContactsMultiselect";
import ProductsMultiSelect from "../FormItems/ProductsMultiSelect";
import CompaniesMultiselect from "../FormItems/CompaniesMultiselect";
import AssignedUsersSelect from "../FormItems/AssignedUsersSelect";
import { MdClose } from "react-icons/md";
import { useForm } from "antd/es/form/Form";

const CreateDealDrawer = ({ onSubmit }) => {
  const [form] = useForm();

  const { Title, Text } = Typography;
  const { isDrawerOpen, setIsDrawerOpen, loading } = useDealsManagementCtx();
  
  function onClose() {
    setIsDrawerOpen(false);
  }

  const handleSubmit = (e) => {
    onSubmit(e);
  };

  const statusOptions = [
    { value: CardStatus.New, label: "New" },
    { value: CardStatus.Qualifying, label: "Qualifying" },
    { value: CardStatus.DemoScheduled, label: "Demo Scheduled" },
    { value: CardStatus.PendingCommitment, label: "Pending Commitment" },
    { value: CardStatus.InNegotiation, label: "In Negotiation" },
    { value: CardStatus.Closed, label: "Closed" },
    { value: CardStatus.Won, label: "Won" },
  ];

  const newCompanyAdded = (e) => {
    if(form.getFieldValue("companies")){
      form.setFieldValue("companies", [...form.getFieldValue("companies"), e.id]);
    }else{
      form.setFieldValue("companies", [e.id]);
    }
  }
  
  const newProductAdded = (e) => {
    if(form.getFieldValue("products")){
      form.setFieldValue("products", [...form.getFieldValue("products"), e])
    }else{
      form.setFieldValue("products", [e])
    }
  }

  return (
    <Drawer
      destroyOnClose
      open={isDrawerOpen}
      className="dealDrawerLayout"
      closeIcon={false}
      title={
        <Flex align="center" justify="space-between">
          <Title style={{ margin: 0 }} level={4}>
            Create new deal
          </Title>

          <Button
            icon={<MdClose />}
            type="text"
            style={{ fontSize: 20 }}
            onClick={() => onClose()}
            className="flex-button"
          ></Button>
        </Flex>
      }
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical"  initialValues={{
        status: CardStatus.New
      }}>
        <ContactsMultiselect placeholder="Select deal contacts" />
        <CompaniesMultiselect placeholder="Select deal companies" onAddNewCompany={newCompanyAdded} />
        <ProductsMultiSelect placeholder="Select deal products" onAdd={newProductAdded}/>

        <Space direction="vertical" className="w-100" size={0}>
          <Text>Deal Title</Text>
          <Form.Item
            name="title"
            rules={[
              {
                required: true,
                message: "Please enter a title!",
              },
            ]}
          >
            <Input size="large" placeholder="Please enter a deal title" />
          </Form.Item>
        </Space>

        <Space direction="vertical" size={0} className="w-100">
          <Text>Deal Stage</Text>
          <Form.Item
            name="status"
            rules={[
              {
                required: true,
                message: "Please enter a stage!",
              },
            ]}
          >
            <Select
              size="large"
              placeholder="Enter a stage"
              options={statusOptions}
            />
          </Form.Item>
        </Space>
        <AssignedUsersSelect setDefault={(e) => form.setFieldValue("assignedUser", [e])} placeholder="Please select an owner"/>

        <Space size={0} direction="vertical" className="w-100">
          <Text>Amount</Text>
          <Form.Item name="amount">
            <InputNumber
              className="w-100"
              placeholder="Please enter an amount"
              size="large"
            />
          </Form.Item>
        </Space>

        <Space direction="vertical" size={0}>
          <Text>Close Date</Text>
          <Form.Item name="closeDate">
            <DatePicker
              size="large"
              className="w-100"
              style={{ borderRadius: 15 }}
            />
          </Form.Item>
        </Space>
        <div className="formFooter">
          <Flex align="center" justify="end" gap={10} style={{ width: "100%" }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Save changes
            </Button>
          </Flex>
        </div>
      </Form>
    </Drawer>
  );
};

export default CreateDealDrawer;
