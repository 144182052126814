import { Button, Card, Empty, Flex } from "antd";
import { useContext } from "react";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import YourTemplateCard from "../../templates/Components/YourTemplateCard";
import { useParams } from "react-router-dom";
import WhatsAppTemplateCard from "../../templates/Whatsapp/WhatsAppTemplateCard";
import SmsTemplateCard from "../../templates/Sms/SmsTemplateCard";

const Template = () => {
  const { data, setCurrentStep } = useContext(CreateCampaignContext);

  const { type } = useParams();

  const axios = useAxiosPrivate();
  const [template, setTemplate] = useState();

  useEffect(async () => {
    let url = `/Whatsapp/template/${data.whatsappTemplateId}`;

    if(!data.whatsappTemplateId && type == 1){
      return;
    }

    if(type == 0){
      url = `/Template/${data.templateId}`;
    }
    
    if(!data.templateId && type == 0){
      return;
    }

    if(type == 2){
      url = `/SmsTemplate/${data.smsTemplateId}`
    }

    if(!data.smsTemplateId && type == 2){
      return;
    }

    const response = await axios.get(url);

    if(type == 2){
      setTemplate(response.data);
    }else{
      setTemplate(response.data.data);
    }
  }, []);
  return (
    <>
      {type == 0 && (
        <>
          {template && <YourTemplateCard template={template} preview />}
          {!template && (
            <Card style={{ height: 400 }}>
              <Flex align="center" justify="center" style={{ height: 400 }}>
                <div style={{ height: 160, textAlign: "center" }}>
                  <Empty description="No template was selected" />

                  <Button type="link" onClick={() => setCurrentStep(2)}>
                    Select a template
                  </Button>
                </div>
              </Flex>
            </Card>
          )}

        </>
      )}

      {(type==1)&&<WhatsAppTemplateCard template={template}/>}

      {(type == 2) && <SmsTemplateCard template={template}/>}
    </>
  );
};

export default Template;
