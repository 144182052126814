import { Button, Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { DealListContext } from "../DealListContext";
import { IoMdCheckmark } from "react-icons/io";
import { MdClear } from "react-icons/md";

const CompanyFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(DealListContext);

  const handleOnChanged = (e) => {
    setFilters((prev) => ({ ...prev, selectedCompanies: e }));
  };
  return (
    <>
      <Text>Companies</Text>
      <Select
        onChange={handleOnChanged}
        size="large"
        maxTagCount="responsive"
        placeholder="Select companies"
        options={filters?.companies}
        defaultValue={filters?.selectedCompanies}
        style={{ width: "100%" }}
        mode="multiple"
      />
    </>
  );
};

export default CompanyFilter;
