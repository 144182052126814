import { useContext, useState } from "react";
import { Select, Typography } from "antd";
import ThemeLabel from "../../../shared/ThemeLabel";
import { CampaignContext } from "../CampaignContext";
import { CampaignStatusType } from "../../../shared/enums";

const CampaignStatusSelect = ({ onChanged }) => {
  const {filters, setFilters} = useContext(CampaignContext)

  const {Text} = Typography
  const [optons, setOptions] = useState([
    {
      value: CampaignStatusType.NotStarted,
      label: "Not started",
    },
    {
      value: CampaignStatusType.OnGoing,
      label: "On going",
    },
    {
      value: CampaignStatusType.Finished,
      label: "Finished",
    },
    {
      value: CampaignStatusType.Invalid,
      label: "Invalid",
    },
  ]);

  const handleChange = (e) => {
    setFilters(prev => ({
      ...prev, 
      status: e
    }))
  }
  return (
    <>
      <Text text="Status">Status</Text>
      <Select size="large"
        style={{width:"100%"}}
        mode="multiple"
        defaultValue={filters?.status}
        maxTagCount="responsive"
        placeholder="Select statuses to filter"
        onChange={(e) => handleChange(e)}
        options={optons}
      />
    </>
  );
};

export default CampaignStatusSelect;
