import { Button } from "antd";
import { IoMdMore } from "react-icons/io";

const CleanUpListOptions = () => {
    return <>
    <Button
          shape="circle"
          className="flex-button"
          type="text"
          icon={<IoMdMore  style={{ fontSize: 20 }} />}
        ></Button>
    </>
}

export default CleanUpListOptions;