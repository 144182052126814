import { Button, Card, Col, Flex, Row, Space, Table, Typography } from "antd";
import FollowupStats from "./FollowupStats";
import { useState } from "react";
import FollowupAuthorFilter from "./filters/FollowupAuthorFilter";
import FollowupToDateFilter from "./filters/FollowupToDateFilter";
import FollowupFromDateFilter from "./filters/FollowupFromDateFilter";
import { FiPlus } from "react-icons/fi";
import { MdFilterAlt, MdFilterAltOff } from "react-icons/md";
import NoDataFound from "../../shared/NoDataFound";
import FollowupOptions from "./FollowupOptions";
import FollowupOrderByFilter from "./filters/FollowupOrderByFilter";
import FollowupOrderDirectionFilter from "./filters/FollowUpOrderDirectionFilter";
import { useNavigate } from "react-router-dom";

const FollowupList = () => {
    const navigate = useNavigate();

  const { Text, Title } = Typography;

  const [showFilters, setShowFilters] = useState(false);

  const columns = [
    {
      title: "Name",
    },
    {
      title: "Description",
    },
    {
      width: 40,
      render: (row) => <FollowupOptions />,
    },
  ];

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={24}>
      <Card>
        <FollowupStats />
      </Card>

      <Card
        className="zero-margin-padding no-body-card end-to-end-header"
        title={
          <>
            <Flex align="center" justify="space-between" wrap="wrap">
              <Title style={{ margin: 0 }} level={4}>
                Follow ups
              </Title>

              <Flex wrap="wrap" gap={10}>
                <Button
                  size="large"
                  className="flex-button"
                  onClick={() => setShowFilters(!showFilters)}
                  icon={!showFilters ? <MdFilterAlt /> : <MdFilterAltOff />}
                >
                  Filter
                </Button>

                <Button
                  type="primary"
                  size="large"
                  className="flex-button bold-button"
                  onClick={() => navigate("/Followup/create")}
                  icon={<FiPlus style={{ fontSize: 18 }} />}
                >
                  New follow up
                </Button>
              </Flex>
            </Flex>
          </>
        }
      >
        {showFilters && (
          <Row gutter={[24, 24]} style={{ margin: "24px" }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
              <FollowupAuthorFilter />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <FollowupFromDateFilter />
            </Col>
            <Col xs={24} sm={24} md={6} lg={6} xl={6} xxl={6}>
              <FollowupToDateFilter />
            </Col>

            <Col xs={24} sm={24} md={5} lg={5} xl={5} xxl={5}>
                <FollowupOrderByFilter />
              </Col>
              <Col xs={24} sm={24} md={3} lg={3} xl={3} xxl={3}>
                <FollowupOrderDirectionFilter />
              </Col>

          </Row>
        )}
      </Card>

      <Card className="zero-margin-padding">
        <Table
          columns={columns}
          locale={{
            emptyText: (
              <NoDataFound
                addText="New Followup"
                description="Click the New Follow Up button on the Follow ups page to create a new follow up"
                title="You have not created any Followups yet"
              />
            ),
          }}
        />
      </Card>
    </Space>
  );
};
export default FollowupList;
