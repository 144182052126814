import { useContext, useState } from "react";
import { Flex, Select, Typography } from "antd";
import { CampaignContext } from "../CampaignContext";
import { BsCalendarDateFill } from "react-icons/bs";
import { MdTitle } from "react-icons/md";

const CampaignOrderBy = ({ onChanged }) => {
  const { filters, setFilters } = useContext(CampaignContext)
  const { Text } = Typography;
  const options = [
    {
      value: "Created",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <BsCalendarDateFill />
        Date created
      </Flex>
    },
    {
      value: "Name",
      label: <Flex style={{ height: "100%" }}
        align="center"
        justify="start"
        gap={6}>
        <MdTitle />
        Name
      </Flex>
      ,
    },
  ];

  const handleChanged = (e) => {
    setFilters(prev => ({
      ...prev,
      orderBy: e
    }))
  }
  return (
    <>
      <Text text="Order by">Order by</Text>
      <Select
        size="large"
        style={{ width: "100%" }}
        options={options}
        defaultValue={filters?.orderBy}
        placeholder="Select filter order by column"
        onChange={(e) => handleChanged(e)}
      />
    </>
  );
};

export default CampaignOrderBy;
