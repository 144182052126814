import { DatePicker, Typography } from "antd";
import { useContext } from "react";
import { CampaignContext } from "../CampaignContext";

const CreatedToDateFilter = () => {
    const {Text} = Typography

    const {filters, setFilters} = useContext(CampaignContext)

    return <>
        <Text text="Order by">To</Text>
        <DatePicker size="large"
            onChange={(e) => setFilters(prev => ({...prev, to: e}))}
            defaultValue={filters?.to}
            placeholder="Select created date end"
            style={{ width: "100%", borderRadius: 15 }} />
    </>
}

export default CreatedToDateFilter;