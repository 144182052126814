import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useState } from "react";
import SendTestEmail from "../Email/SendTestEmail";
import { Badge, Button, Space, Tooltip, message } from "antd";
import { FcLikePlaceholder, FcLike } from "react-icons/fc";
import { TbColorPicker } from "react-icons/tb";
const CardDropDown = ({ template, color }) => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [currentTemplate, setCurrentTemplate] = useState(template);

  const handleTemplateUse = () => {
    navigate(`/Templates/Create/${template.id}`);
  };

  const likeTemplate = async () => {
    const response = await axiosPrivate.post("/Template/Like", {
      templateId: currentTemplate.id,
    });

    if (response && response.data.success) {
      message.success("Nice, action completed successfully!");
    } else {
      message.error(response.data.message);
    }

    setCurrentTemplate(prev => ({
      ...prev,
      liked: !prev.liked,
      noLikes: prev.noLikes + (!prev.liked ? 1 : -1),
    }));
  };

  return (
    <Space>
      <Tooltip title={currentTemplate.liked ? "Remove like" : "Like template"}>
        <Badge count={currentTemplate.noLikes}>
          <Button
            onClick={() => likeTemplate()}
            shape="circle"
            icon={currentTemplate.liked ? <FcLike /> : <FcLikePlaceholder />}
          />
        </Badge>
      </Tooltip>

      <Tooltip title="Edit template">
        <Button
          icon={<TbColorPicker />}
          shape="circle"
          onClick={() => handleTemplateUse(currentTemplate.id)}
        ></Button>
      </Tooltip>

      <SendTestEmail
        className="btn"
        onlyIcon={true}
        template={currentTemplate.id}
        color={color}
      />
    </Space>
  );
};

export default CardDropDown;
