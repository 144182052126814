import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import logo from "../../../../assets/images/logo.png";
import { useContext, useEffect, useState } from "react";
import { CompanyStepsContext } from "./CompanyStepsContext";

const ThirdStep = () => {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false);

  const {currentStep, setCurrentStep, basics, setBasics } = useContext(CompanyStepsContext);

  const { Title, Text } = Typography;

  useEffect(() => {
    form.setFieldsValue(basics);
  }, []);

  const onSubmit = (e) => {
    setBasics(e);

    setCurrentStep(prev => prev + 1);
};

  return (
    <>
      <Flex align="center" justify="center" style={{ height: "100vh" }}>
        <Space
          size="large"
          direction="vertical"
          style={{ width: "450px", height: "500px" }}
        >
          <Space className="w-100" size={0} direction="vertical">
            <img src={logo} height={35} />
            <Title style={{ margin: 0 }}>Let's start with the basics</Title>
            <Text style={{ fontSize: 16 }}>
              First we need to know a few things about you
            </Text>
          </Space>

          <Form form={form} onFinish={onSubmit}>
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Space className="w-100" direction="vertical" size={0}>
                  <Text>First name</Text>
                  <Form.Item name="firstName" rules={[
                    {
                        required: true,
                        message: "Please enter your firstname"
                    }
                  ]}>
                    <Input
                      size="large"
                      placeholder="Enter your firstname"
                      allowClear
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col span={12}>
                <Space className="w-100" direction="vertical" size={0}>
                  <Text>Last name</Text>
                  <Form.Item name="lastName" rules={[
                    {
                        required: true,
                        message: "Please enter your lastname"
                    }
                  ]}>
                    <Input
                      size="large"
                      placeholder="Enter your lastname"
                      allowClear
                    />
                  </Form.Item>
                </Space>
              </Col>
            </Row>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>Company name</Text>
              <Form.Item name="companyName">
                <Input
                  size="large"
                  placeholder="Enter your company name"
                  allowClear
                />
              </Form.Item>
            </Space>

            <Space className="w-100" direction="vertical" size={0}>
              <Text>Website</Text>
              <Form.Item name="companyWebsite">
                <Input
                  size="large"
                  placeholder="Enter your website"
                  allowClear
                  disabled={isDisabled}
                />
              </Form.Item>
            </Space>

            <Form.Item name="companyHasWebsite" valuePropName="checked">
              <Checkbox onChange={e => setIsDisabled(e.target.checked)}>Company doesn't have a website</Checkbox>
            </Form.Item>
          </Form>

          <Space className="w-100" direction="vertical">
            <Button
              size="large"
              block
              type="primary"
              onClick={() => form.submit()}
            >
              Continue
            </Button>
          </Space>
        </Space>
      </Flex>
    </>
  );
};

export default ThirdStep;
