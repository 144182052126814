import { Card, Flex, Table, Typography } from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { DateFormat } from "../../../dateformat";
import { useNavigate } from "react-router-dom";
import TableLoading from '../../../shared/TableLoading'

const Top5Campaigns = () => {
  const { Title, Text } = Typography;

  const axios = useAxiosPrivate();

  const [data, setData] = useState()

  const navigate = useNavigate();

  const [loading, setLoading] = useState(() => false);

  const columns = [
    {
        title:"Name",
        render:(data, row) => <div style={{width:"130px"}}><Text onClick={() => navigate(`/Campaigns/Details/${row.id}`)} strong type="link" ellipsis>{row.name}</Text></div>
    },
    {
        title:"Click",
        responsive:['sm'],
        render: (data, row) => <Text strong>{((row.clicks / row.requests) * 100).toFixed(2)}%</Text>
    },
    {
        title:"Open",
        responsive:['sm'],
        render:(data, row) => <Text strong>{row.open}</Text>
    },
    {
        title:"Date",
        responsive:['md'],
        render:(data, row) => <Text strong>{new Date(row.createdAt).toLocaleDateString("en-US", DateFormat)}</Text>
    }
  ]
  useEffect(async () => {
    setLoading(true);
    const response = await axios.get(`/Stats/campaigns/top/${5}`);

    setData(response.data.items)
    setLoading(false);
  }, [])

  return (
    <>
      <Card title={null}>
        <Flex align="center" justify="space-between">
            <Title style={{margin:0}} level={4}>Most successful campaigns</Title>
        </Flex>
          {loading&&<TableLoading height={55}/>}
          {!loading&&<Table columns={columns} pagination={false} dataSource={data}/>}
      </Card>
    </>
  );
};

export default Top5Campaigns;
