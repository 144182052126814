import { Flex, Select, Typography } from "antd";
import { useContext } from "react";
import { BsCalendarDateFill } from "react-icons/bs";
import { MdTitle } from "react-icons/md";
import { TaskContext } from "../TaskContext";

const TaskOrderByFilter = () => {
  const { Text } = Typography;

  const { filters, setFilters } = useContext(TaskContext);

  const options = [
    {
      value: "Created",
      label: (
        <Flex style={{ height: "100%" }} align="center" justify="start" gap={6}>
          <BsCalendarDateFill />
          Date created
        </Flex>
      ),
    },
    {
      value: "name",
      label: (
        <Flex style={{ height: "100%" }} align="center" justify="start" gap={6}>
          <MdTitle />
          Name
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Text text="Order by">Order by</Text>
      <Select
        size="large"
        style={{ width: "100%" }}
        defaultValue={filters?.orderBy}
        onChange={(e) => setFilters((prev) => ({ ...prev, orderBy: e }))}
        options={options}
        placeholder="Select filter order by column"
      />
    </>
  );
};

export default TaskOrderByFilter;
