import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  List,
  Progress,
  Result,
  Row,
  Space,
  Spin,
  Typography,
} from "antd";
import { AiFillCheckCircle } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import CountUp from "react-countup";
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
const formatter = (value) => <CountUp end={value} separator="," />;
const Optimizer = ({ id }) => {
  const axios = useAxiosPrivate();

  const [suggestion, setSuggestion] = useState([]);

  const { Title, Text } = Typography;
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState();

  const [missing, setMissing] = useState(false)
  useEffect(async () => {
    if(!id){
      setMissing(true);
      return;
    }
      setLoading(true)
      const response = await axios.get(`/Campaign/GetTemplateRatings?templateId=${id}`)

      setData(response.data.data);
      
    if (response.data.data.skimmability < 5) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "Skimmability",
          description:
            "Use clear headings and subheadings, concise and bulleted text, bold and highlight important text, visual elements, use descriptive links and logical flow.",
          severity: 2,
        },
      ]);
    }

    if (response.data.data.liksAndCtas < 3) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "typography",
          description:
            "The number of links and CTAs in your email is crucial. Too many can confuse and overwhelm readers, affecting clarity, user experience, and conversion rates. Striking a balance, testing variations, and ensuring relevance to content are key for an effective and engaging email.",
          severity: 2,
        },
      ]);
    }
    if (response.data.data.liksAndCtas > 3) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "Liks & Ctas",
          description:
            "Your email, includes way to many links, and CTAs. You may consider removing some of them!",
          severity: 2,
        },
      ]);
    }
    if (response.data.data.textAndVisuals < 2) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "Text & Visuals",
          description:
            "Your email should include more font types and visual such as images.",
          severity: 2,
        },
      ]);
    }

    if (response.data.data.typography <= 4) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "Typography",
          description:
            "Your email should include a variety of headings, paragraphs and fonts!",
          severity: 2,
        },
      ]);
    }
    if (response.data.data.typography > 5) {
      setSuggestion((prev) => [
        ...prev,
        {
          type: "Typography",
          description:
            "Your email should less variety of headings, paragraphs and fonts!",
          severity: 2,
        },
      ]);
    }
    setLoading(false)
  }, []);

  return (
    <>
      <Title level={3}>Ratings</Title>
      {
        missing&& 
        <Result
          status="404"
          title="TAPZAP 404 - NOT FOUND"
          subTitle="Sorry, we could not analyse the desing, most likely it has been moved or deleted."
          />
      }

      {
        (!missing)&&<>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
          <ProgressItem
            loading={loading}
            name="Skimmability"
            from={data?.skimmability}
            to={5}
            success={data?.skimmability == 5}
          />
          <ProgressItem
            loading={loading}
            name="Links & CTAs"
            from={data?.liksAndCtas}
            to={3}
            success={data?.liksAndCtas >= 3}
          />
        </Col>
        <Col xs={24} sm={24} md={12} ld={12} xl={12} xxl={12}>
          <ProgressItem
            loading={loading}
            name="Text & Visuals"
            from={data?.textAndVisuals}
            to={2}
            success={data?.textAndVisuals == 2}
            />
          <ProgressItem
            loading={loading}
            name="Typography"
            from={data?.typography}
            to={5}
            success={data?.typography >= 5}
            />
        </Col>
      </Row>
    {(!loading)&&<>
      
      <Title level={3}>Suggestions</Title>
      {suggestion.length == 0 && (
        <Empty description="You did great no changes needed!" />
      )}

      {suggestion.length > 0 && (
        <List
          bordered
          dataSource={suggestion}
          renderItem={(item) => (
            <List.Item>

            <Space style={{ width: "100%" }} direction="vertical">
              <Flex align="center" justify="space-between">
                <Text strong>{item.type}</Text>

                <Text strong style={{color:"#e74a3b"}}>{item.severity}</Text>
              </Flex>

              <Text>{item.description}</Text>
            </Space>
            </List.Item>
            )}
            />
            )}</>
          }</>
}
    </>
  );
};

const ProgressItem = ({ name, from, to, success, loading }) => {
  const { Text } = Typography;
  const words = ["Nice", "Well done", "Nicely done", "Way to go"];
  return (
    <Spin spinning={loading}>

    <Card
      bordered={false}
      className="shadowless"
      size="small"
      style={{ marginBottom: 12 }}
      >
      <Flex align="center" justify="space-between">
        <Text strong>{name}</Text>

        <Text className="text-light">
          {from} out of {to}
        </Text>
      </Flex>
      <div style={{ width: "100%" }}>
        <Progress
          percent={(from * 100) / to}
          style={{ margin: 0 }}
          showInfo={false}
          size="small"
          strokeColor={success ? "#1cc88a" : "#e74a3b"}
          />
      </div>
      {success && (
        <Flex align="center" justify="start" gap={5}>
          <AiFillCheckCircle style={{ color: "#1cc88a", fontSize: 18 }} />
          <Text style={{ color: "#1cc88a" }}>
            {words[Math.floor(Math.random() * 3)]}
          </Text>
        </Flex>
      )}
      {!success && (
        <Flex align="center" justify="start" gap={5}>
          <RiCloseCircleFill style={{ color: "#e74a3b", fontSize: 18 }} />
          <Text style={{ color: "#e74a3b" }}>View suggestion</Text>
        </Flex>
      )}
    </Card>
          </Spin>
  );
};
export default Optimizer;
