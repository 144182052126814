import { useContext, useState } from "react";
import { Flex, Select, Typography } from "antd";
import { CampaignContext } from "../CampaignContext";
import { CampaignType } from "../../../shared/enums";
import { MdEmail, MdSms } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";

const CampaignTypeSelect = ({ onChanged }) => {
  const {filters, setFilters} = useContext(CampaignContext)

  const {Text} = Typography

  const [options, setOptions] = useState([
    {
      value: CampaignType.EMAIL,
      label: <Flex style={{ height: "100%" }}
      align="center"
      justify="start"
      gap={6}>
       <MdEmail/>
        Email</Flex>,
    },
    {
      value: CampaignType.WHATSAPP,
      label: <Flex style={{ height: "100%" }}
      align="center"
      justify="start"
      gap={6}>
       <RiWhatsappFill/>
        Whatsapp</Flex>,
    },
    {
      value: CampaignType.SMS,
      label: <Flex style={{ height: "100%" }}
      align="center"
      justify="start"
      gap={6}>
       <MdSms/>
        Sms</Flex>,
    }
  ]);

  const handleChanged = (e) => {
    setFilters(prev => ({...prev,
      type: e
    }))
  }
  return (
    <>
      <Text text="Goal">Type</Text>
      <Select size="large"
        style={{ width: "100%" }}
        options={options}
        mode="multiple"
        placeholder="Select campaign type"
        maxTagCount="responsive"
        defaultValue={filters?.type}
        onChange={(e) => handleChanged(e)}
      />
    </>
  );
};
export default CampaignTypeSelect;
