import { Select, Typography } from "antd";
import { MediaContext } from "../MediaContext";
import { useContext } from "react";

const MediaOrderBy = () => {
  const { Text } = Typography;
  
  const {filters, setFilters} = useContext(MediaContext)

  const options =[
    {
      value: "Name",
      label: "Name",
    },
    {
      value: "Created",
      label: "Created",
    },
  ];

  const handleOrderByChanged = (e) => {
    setFilters(prev => ({...prev,
      orderBy: e
    }))
  };

  return (
    <>
      <Text>Order by</Text>
      <Select
        size="large"
        options={options}
        style={{ width: "100%" }}
        defaultValue={filters?.orderBy}
        allowClear
        onChange={handleOrderByChanged}
      />
    </>
  );
};

export default MediaOrderBy;
