import { useContext, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import useLocalStorage from "../../hooks/useLocalStorage";
import axios from "../../api/axios";
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Grid,
  Typography,
  message,
} from "antd";
import logo from "../../assets/images/favicon.png";
import background from "../../assets/images/tapzaplogin.png";
import { ThemeContext } from "../../context/ThemeContext";
import ThemeButton from "../ThemeButton";
const LOGIN_URL = "/Account/authenticate";

const {useBreakpoint} = Grid
const Login = () => {
  const screens = useBreakpoint();


  const { Title, Text } = Typography;
  const [loading, setLoading] = useState();
  const { setAuth, setUser} = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [refreshToken, setRefreshToken] = useLocalStorage("refreshToken", "");

  const handleSubmit = async (e) => {
    setLoading(true);
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({
          email: e.email,
          password: e.password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (!response || !response.data.success) {
        message.error(response.data.message);
        return;
      }

      const accessToken = response?.data?.data?.jwToken;
      const email = response?.data?.data?.email
      const refreshToken = response?.data?.data?.refreshToken;
      const roles = response?.data?.data?.roles;
      
      const refToken ={
        refreshToken: refreshToken,
        email: email
      };

      setRefreshToken(JSON.stringify(refToken));
      
      setAuth({roles: roles, accessToken: accessToken, email: email, emailConfirmed: response?.data?.data?.isVerified, step: response?.data?.data?.step });
      navigate(from, { replace: true });
    } catch (err) {
      var errorMessage = "";
      if (!err?.response) {
        errorMessage = "No Server Response";
      } else if (err.response?.status === 400) {
        errorMessage = "Missing Username or Password";
      } else if (err.response?.status === 401) {
        errorMessage = "Unauthorized";
      } else {
        errorMessage = "Login Failed";
      }

      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const {theme}= useContext(ThemeContext);

  return (<>
    <Row>
      <Col xs={24} sm={24} md={24} lg={15} xl={15} xxl={15}>
        <Flex
          justify="center"
          align="center"
          style={{
            height: "100vh",
            background: theme == "light" ?"#fff" :"#0b1437"
          }}
        >
          <Space direction="vertical" style={{width:"350px"}}>
            <div>
                <img src={logo} width={35} loading="lazy"/>
             
              <Title style={{ margin: 0 }}>Sign In</Title>
              <Text className="text-light">
                Enter your email and password to sign in!
              </Text>
            </div>

            <Form onFinish={handleSubmit}>
              <div>
                <Text>Email</Text>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please provide a valid email address",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter your email address"
                    allowClear
                  />
                </Form.Item>
              </div>
              <div>
                <Text>Password</Text>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      message: "Enter a valid password",
                      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter your password"
                    allowClear
                  />
                </Form.Item>
              </div>
              <Flex align="center" justify="space-between">
                <Checkbox style={{ borderRadius: "5px !important" }}>
                  Keep me logged in
                </Checkbox>
                <Button type="link" onClick={() => navigate("/forgotpassword")}>
                  Forgot Password?
                </Button>
              </Flex>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                block
                className="primary-gradient-background"
                size="large"
              >
                Sign In
              </Button>
            </Form>

            <Flex align="center" justify="center" gap={0}>
              <Text>Not registered yet? </Text>
              <Button type="link" onClick={() => navigate("/register")}>
                Create an account!
              </Button>
            </Flex>
          </Space>
        </Flex>
      </Col>
      {/*xs | sm | md | lg | xl | xxl*/}
      {(screens.lg || screens.xl || screens.xxl) &&  <Col lg={9} xl={9} xxl={9}>
        <div
          className="shadow"
          style={{
            background: `url(${background})`,
            backgroundPosition:"left",
            backgroundSize:"cover",
            height:"100%",
            width:"100%",
            backgroundRepeat:"no-repeat"
          }}
          >
          <div style={{position:"relative", top:"95%", left:"10%"}}>

            <Space>

          <Button type="link" className="white-link-button">Support</Button>
          <Button type="link" className="white-link-button" onClick={() => navigate('/policy/terms-of-usage')}>Terms of use</Button>
          <Button type="link" className="white-link-button" onClick={() => navigate('/policy/prviacy-policy')}>Privacy policy</Button>
          </Space>
          </div>

          
        </div>
      </Col>
        }
    </Row>

          <ThemeButton/>
  </>

  );
};

export default Login;
