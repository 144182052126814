import React, { createContext, useState } from "react";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

export const DealsManagementContext = createContext({
  stats: {},
  setStats: () => {},

  filters: {},
  setFilters: {},

  isDrawerOpen: false,
  setIsDrawerOpen: () => {},
  loading: false,
  setLoading: () => {},
});

export const DealsManagementContextProvider = ({ children }) => {

  const axios = useAxiosPrivate();

  

  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);
  const [loading, setLoading] = useState(() => false);
  return (
    <DealsManagementContext.Provider value={{ isDrawerOpen, setIsDrawerOpen, loading, setLoading }}>
      {children}
    </DealsManagementContext.Provider>
  );
};
