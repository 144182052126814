import { Card, Typography, Flex, Button, Space } from "antd";
import { useContext } from "react";
import { AiFillCheckCircle } from "react-icons/ai";
import { CreateCampaignContext } from "../../../context/CreateCampaignContext";

const Subject = () => {
  const { Text, Title } = Typography;
  const { data, setCurrentStep } = useContext(CreateCampaignContext);
  return (
    <Card
      title={
        <>
          <Flex align="center" justify="space-between">
            <Space>
              <AiFillCheckCircle style={{ fontSize: 22, color: "#1cc88a" }} />
              <Title level={4} style={{ margin: 0 }}>
                Subject
              </Title>
            </Space>
            <Button type="link" size="large" onClick={() => setCurrentStep(0)}>
              Edit Subject
            </Button>
          </Flex>
        </>
      }
    >
      <Title level={4} style={{margin:0}}>{data.name}</Title>
      <Text className="text-light">Preview text: {data.goal}</Text>
    </Card>
  );
};

export default Subject;
