import {
  Avatar,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
} from "antd";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import { BiSolidCaretRightSquare } from "react-icons/bi";
import { MdEmail, MdSms } from "react-icons/md";
import { RiWhatsappFill } from "react-icons/ri";
import { TbArrowBackUp } from "react-icons/tb";

const CreateSenderModal = ({ user, isOpen, setIsOpen, dataAdded }) => {
  const axios = useAxiosPrivate();
  const { Text, Title } = Typography;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const [step, setStep] = useState(1);
  const [type, setType] = useState();

  useEffect(() => {
    if (!user) return;

    form.setFieldValue("fullName", `${user.firstname} ${user.lastname}`);
    form.setFieldValue("email", user.email);
  }, []);

  const submit = async (e) => {
    try {
      setLoading(true);

      const response = await axios.post("/Sender/CreateSender", {
        ...e,
        userId: user?.id,
        advanced: type == 1
      });

      if (response.data.success) {
        form.resetFields();
        dataAdded(response.data.data);
        setIsOpen(false);
      } else {
        message.error(response.data.message);
      }
      setLoading(false);
    } catch (ex) {
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <Modal
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      title={
        <Flex align="center" justify="start">
          {step > 1 && (
            <Button
              type="link"
              onClick={() => {
                setStep(1);
              }}
              icon={<TbArrowBackUp style={{ fontSize: 20 }} />}
            />
          )}
          <Title style={{ margin: 0 }} level={4}>
            What kind of sender you want to create?
          </Title>
        </Flex>
      }
      footer={
        step > 1 ? (
          <Flex align="center" justify="end">
            <Space>
              <Button onClick={() => setIsOpen(false)}>Cancel</Button>
              <Button
                loading={loading}
                type="primary"
                onClick={() => form.submit()}
              >
                Save changes
              </Button>
            </Space>
          </Flex>
        ) : null
      }
    >
      <Space direction="vertical" className="w-100" size="large">
        {step == 1 && (
          <>
            <Text style={{ fontSize: 16 }}>
              Choose what kind of email sender you want to create from scratch
              and reuse it whenever you need it.
            </Text>

            <Row gutter={12}>
              <Col md={12}>
                <Button
                  size="large"
                  icon={<MdEmail />}
                  block
                  className="flex-button bold-button"
                  onClick={() => {
                    setStep(2);
                    setType(1);
                  }}
                  style={{ height: 50 }}
                >
                  Simple
                </Button>
              </Col>
              <Col md={12}>
                <Button
                  size="large"
                  icon={<MdSms />}
                  block
                  onClick={() => {
                    setStep(2);
                    setType(2);
                  }}
                  className="flex-button bold-button"
                  style={{ height: 50 }}
                >
                  Personal
                </Button>
              </Col>
            </Row>

            <Text strong style={{ fontSize: 16 }}>
              * Email sender type changes after creation are prohibited.
            </Text>
          </>
        )}
        {step > 1 && (
          <>
            {type == 1 ? (
              <Space className="w-100" size="large" direction="vertical">
                <Text style={{ fontSize: 16 }}>
                  Simple senders will calculate all required fields.
                </Text>
                <Form onFinish={submit} form={form}>
                  <Text>Full name</Text>
                  <Form.Item
                    name="fullName"
                    rules={[
                      {
                        required: true,
                        message: "Please provice a name",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter senders full name..."
                      disabled={user}
                    />
                  </Form.Item>
                  <Text>Email</Text>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please provide an email",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      placeholder="Enter senders email..."
                      disabled={user}
                    />
                  </Form.Item>
                  <Text>Nickname</Text>
                  <Form.Item
                    name="nickName"
                    rules={[
                      { required: true, message: "Please enter your nickname" },
                    ]}
                  >
                    <Input size="large" placeholder="Enter nickname" />
                  </Form.Item>
                </Form>
              </Space>
            ) : (
              <Space className="w-100" size="large" direction="vertical">
                <Text style={{ fontSize: 16 }}>
                  Personal senders include more data about the sender, such as
                  address, city and country.
                </Text>
                <Form onFinish={submit} form={form}>
                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Full name</Text>
                      <Form.Item
                        name="fullName"
                        rules={[
                          {
                            required: true,
                            message: "Please provice a name",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter senders full name..."
                          disabled={user}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Email</Text>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Please provide an email",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          placeholder="Enter senders email..."
                          disabled={user}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Nickname</Text>
                      <Form.Item
                        name="nickName"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your nickname",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter nickname" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Address</Text>
                      <Form.Item
                        name="address"
                        rules={[
                          {
                            required: true,
                            message: "Please provide your address",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter your address" />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={24}>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>City</Text>
                      <Form.Item
                        name="city"
                        rules={[
                          {
                            required: true,
                            message: "Please provide your city",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter your city" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                      <Text>Country</Text>
                      <Form.Item
                        name="country"
                        rules={[
                          {
                            required: true,
                            message: "Please provide your country",
                          },
                        ]}
                      >
                        <Input size="large" placeholder="Enter your country" />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Space>
            )}
          </>
        )}
      </Space>
    </Modal>
  );
};

export default CreateSenderModal;
